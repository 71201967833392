<template>
  <div class="mt-5">
    <p>SignalR POC:</p>
    <ul v-for="(notification, index) in notifications" :key="index">
      <li>{{ notification }}</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SignalRPoc',
  computed: mapGetters({
    notifications: 'common/notifications',
  }),
};
</script>

<style scoped></style>
