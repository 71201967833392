<template>
  <v-list-item v-bind:dense="true" v-bind:href="url">
    <v-list-item-icon class="mkicon mkicon-mail_outline"></v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="text-wrap overflow-text">{{ subject }}</v-list-item-title>
    </v-list-item-content></v-list-item
  >
</template>

<script>
export default {
  props: {
    subject: { type: String, required: true },
    url: { type: String, required: true },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.overflow-text {
  overflow-wrap: break-word;
}
</style>
