import { isNullOrUndefined, isStringNullUndefinedOrWhitespace } from '@/utils/helperFunctions';
import { adminTemplateNameMaxLength } from '@/modules/AdminTemplates/utils';
import { defaultTemplateTypeId } from '.';

export const adminTemplateCreationRules = {
  name: [
    (v) => !isStringNullUndefinedOrWhitespace(v) || `Template Name is required.`,
    (v) =>
      (!isNullOrUndefined(v) && v.length <= adminTemplateNameMaxLength) ||
      `Template name must be shorter than ${adminTemplateNameMaxLength} characters.`,
  ],
  text: [(v) => !isStringNullUndefinedOrWhitespace(v) || `Template Text is required.`],
  templateType: [(v) => v !== defaultTemplateTypeId || `Template Type selection is required.`],
};
