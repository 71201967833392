export const adminTemplatesMutations = {
  SET_CREATE_ADMIN_TEMPLATE_NAME(state, adminTemplateName) {
    state.createOrEditAdminTemplate.name = adminTemplateName;
  },
  SET_CREATE_ADMIN_TEMPLATE_TEXT(state, adminTemplateText) {
    state.createOrEditAdminTemplate.text = adminTemplateText;
  },
  SET_CREATE_ADMIN_TEMPLATE_TYPE(state, adminTemplateType) {
    state.createOrEditAdminTemplate.templateType = adminTemplateType;
  },
  SET_CREATE_ADMIN_TEMPLATE_FORM_VALID(state, isValid) {
    state.createOrEditAdminTemplate.isFormValid = isValid;
  },
  SET_CURRENT_SELECTED_ADMIN_TEMPLATE(state, adminTemplate) {
    state.selectedAdminTemplate = adminTemplate;
  },
  SET_SEARCH_ADMIN_TEMPLATE_TYPE(state, adminTemplateType) {
    state.searchAdminTemplate.adminTemplateSearchTemplateType = adminTemplateType;
  },
  SET_SEARCH_ADMIN_TEMPLATE_TEXT(state, adminTemplateText) {
    state.searchAdminTemplate.searchText = adminTemplateText;
  },
  SET_FILTERED_ADMIN_TEMPLATES(state, adminTemplates) {
    state.searchAdminTemplate.filteredTemplates = adminTemplates;
  },
  SET_ALL_ADMIN_TEMPLATES(state, adminTemplates) {
    state.searchAdminTemplate.allTemplates = adminTemplates;
  },
  SET_AVAILABLE_TEMPLATE_TYPES(state, templateTypes) {
    state.searchAdminTemplate.allTemplateTypes = templateTypes;
  },
  SET_VALIDATION_ERRORS(state, validationErrors) {
    if (validationErrors && validationErrors.length !== 0) {
      for (let ve of validationErrors) {
        state.createOrEditAdminTemplate.validationErrors.push({
          errorCode: ve.errorCode,
        });
      }
    } else {
      state.createOrEditAdminTemplate.validationErrors = [];
    }
  },
  SET_SEARCH_RESULTS_PAGINATION_INFO(state, paginationInfo) {
    state.searchAdminTemplate.searchResultsPaginationInfo = paginationInfo;
  },
  RESET_VALIDATION_ERRORS(state) {
    state.createOrEditAdminTemplate.validationErrors = [];
  },
  REMOVE_VALIDATION_ERROR(state, index) {
    state.createOrEditAdminTemplate.validationErrors.splice(index, 1);
  },
};
