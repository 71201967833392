import adminMessageCenterRoutes from '@/modules/AdminMessageCenter/router';

export const MESSAGE_VIEW_TYPE = {
  MessageLog: 'message-log',
  Message: 'message',
  MessageInbox: 'message-inbox',
  MessageThreadDetails: 'message-thread-detail',
};

export const MESSAGE_SUB_VIEW_TYPE = {
  AllMessages: { path: 'all-messages', displayText: 'All Messages' },
  SentMessages: { path: 'sent-messages', displayText: 'Sent Messages' },
  ScheduledMessages: { path: 'scheduled-messages', displayText: 'Scheduled Messages' },
  ReceivedMessages: { path: 'received-messages', displayText: 'Received Messages' },
  Notifications: { path: 'notifications-messages', displayText: 'Notifications' },
  TriggeredCommunications: { path: 'triggered-messages', displayText: 'Triggered Communications' },
  NewMessage: { path: 'new', displayText: 'Create Message' },
  EditMessage: { path: 'edit-message', displayText: 'Edit Message' },
  MessageInbox: { path: 'message-inbox', displayText: 'Message Inbox' },
};

export const AdminMessageListFilterViewApiMapping = {
  'all-messages': 'All',
  'scheduled-messages': 'ScheduledMessages',
  'sent-messages': 'SentMessages',
  'received-messages': 'ReceivedMessages',
  'notifications-messages': 'Notifications',
  'triggered-messages': 'TriggeredCommunications',
  'message-inbox': 'Message Inbox',
  'message-thread-details': 'Message Thread Details',
};

export const McMessageSentStatus = {
  Draft: 0,
  NoneSent: 1,
  SomeSent: 2,
  AllSent: 3,
};

export const McMessageSendType = {
  SecureMessage: 0,
  EmailMessage: 1,
  Notification: 2,
};

export const McScheduleType = {
  SpecificDates: 0,
  RecurringPattern: 1,
};

export const adminMessageListFilter = [
  {
    id: 'All',
    text: 'All Messages',
  },
  {
    id: 'Read',
    text: 'Read Messages',
  },
  {
    id: 'Unread',
    text: 'Unread Messages',
  },
];

export const createdByList = [
  {
    id: '##',
    text: '',
  },
  {
    id: '<Portal Name>',
    text: '<Portal Name>',
  },
  {
    id: '<Customer Service Email>',
    text: '<Customer Service Email>',
  },
  {
    id: '<System Generated Email Address>',
    text: '<System Generated Email Address>',
  },
  {
    id: 'Other',
    text: 'Other',
  },
];

export const messageSubjectMaxLength = 250;
export const messageCenterSideMenuItems = [
  {
    displayText: 'Create Message',
    route: adminMessageCenterRoutes[2].name,
    params: { viewType: MESSAGE_VIEW_TYPE.Message, subViewType: MESSAGE_SUB_VIEW_TYPE.NewMessage.path },
    selected: false,
    visible: false,
  },
  {
    displayText: 'Message Log',
    visible: true,
    active: true,
    children: [
      {
        displayText: MESSAGE_SUB_VIEW_TYPE.AllMessages.displayText,
        route: adminMessageCenterRoutes[2].name,
        params: { viewType: MESSAGE_VIEW_TYPE.MessageLog, subViewType: MESSAGE_SUB_VIEW_TYPE.AllMessages.path },
        selected: false,
      },
      {
        displayText: MESSAGE_SUB_VIEW_TYPE.SentMessages.displayText,
        route: adminMessageCenterRoutes[2].name,
        params: { viewType: MESSAGE_VIEW_TYPE.MessageLog, subViewType: MESSAGE_SUB_VIEW_TYPE.SentMessages.path },
        selected: false,
      },
      {
        displayText: MESSAGE_SUB_VIEW_TYPE.ScheduledMessages.displayText,
        route: adminMessageCenterRoutes[2].name,
        params: { viewType: MESSAGE_VIEW_TYPE.MessageLog, subViewType: MESSAGE_SUB_VIEW_TYPE.ScheduledMessages.path },
        selected: false,
      },
      {
        displayText: MESSAGE_SUB_VIEW_TYPE.ReceivedMessages.displayText,
        route: adminMessageCenterRoutes[2].name,
        params: { viewType: MESSAGE_VIEW_TYPE.MessageLog, subViewType: MESSAGE_SUB_VIEW_TYPE.ReceivedMessages.path },
        selected: false,
      },
      {
        displayText: MESSAGE_SUB_VIEW_TYPE.Notifications.displayText,
        route: adminMessageCenterRoutes[2].name,
        params: { viewType: MESSAGE_VIEW_TYPE.MessageLog, subViewType: MESSAGE_SUB_VIEW_TYPE.Notifications.path },
        selected: false,
      },
      {
        displayText: MESSAGE_SUB_VIEW_TYPE.TriggeredCommunications.displayText,
        route: adminMessageCenterRoutes[2].name,
        params: {
          viewType: MESSAGE_VIEW_TYPE.MessageLog,
          subViewType: MESSAGE_SUB_VIEW_TYPE.TriggeredCommunications.path,
        },
        selected: false,
      },
    ],
  },
];

export const RecipientCriteriaDropdown = {
  SelectAll: 0,
  RecipientCriteria: 1,
  Individual: 2,
  PlatformTriggers: 4,
};

export const StopSendingAfterType = {
  EndDate: 0,
  NumberOfOccurrences: 1,
};

export const McRecurringPatternFrequencyType = {
  Daily: 0,
  Weekly: 1,
  Monthly: 2,
  Yearly: 3,
};

export const McDailyRecurringIntervalType = {
  EveryNDays: 0,
  EveryWeekday: 1,
};

export const McMonthlyRecurringIntervalType = {
  DayOfMonth: 0,
  DayOfWeek: 1,
};

export const McWeekOfMonth = {
  First: 1,
  Second: 2,
  Third: 3,
  Fourth: 4,
  Last: 5,
};

export const DayOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const McYearlyRecurringIntervalType = {
  DayOfMonth: 0,
  DayOfWeek: 1,
};

export const weekOfMonthListTxt = [
  {
    id: McWeekOfMonth.First,
    text: 'First',
  },
  {
    id: McWeekOfMonth.Second,
    text: 'Second',
  },
  {
    id: McWeekOfMonth.Third,
    text: 'Third',
  },
  {
    id: McWeekOfMonth.Fourth,
    text: 'Fourth',
  },
  {
    id: McWeekOfMonth.Last,
    text: 'Last',
  },
];

export const weekDaysFull = [
  {
    id: DayOfWeek.Monday,
    text: 'Monday',
  },
  {
    id: DayOfWeek.Tuesday,
    text: 'Tuesday',
  },
  {
    id: DayOfWeek.Wednesday,
    text: 'Wednesday',
  },
  {
    id: DayOfWeek.Thursday,
    text: 'Thursday',
  },
  {
    id: DayOfWeek.Friday,
    text: 'Friday',
  },
  {
    id: DayOfWeek.Saturday,
    text: 'Saturday',
  },
  {
    id: DayOfWeek.Sunday,
    text: 'Sunday',
  },
];

export const MonthFullName = [
  {
    id: 1,
    text: 'January',
  },
  {
    id: 2,
    text: 'February',
  },
  {
    id: 3,
    text: 'March',
  },
  {
    id: 4,
    text: 'April',
  },
  {
    id: 5,
    text: 'May',
  },
  {
    id: 6,
    text: 'June',
  },
  {
    id: 7,
    text: 'July',
  },
  {
    id: 8,
    text: 'August',
  },
  {
    id: 9,
    text: 'September',
  },
  {
    id: 10,
    text: 'October',
  },
  {
    id: 11,
    text: 'November',
  },
  {
    id: 12,
    text: 'December',
  },
];
