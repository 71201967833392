import { isNullOrUndefined, isKonfigOptionValueTruthy } from '@/utils/helperFunctions';

const platformOptionsGetters = {
  currentUserMenuOptions: (state) => {
    return state.menuOptions;
  },
  currentUserKonfigOptions: (state) => {
    return state.konfigOptions.options;
  },
  booleanOptionValue: (state) => (optionId) => {
    if (isNullOrUndefined(state.konfigOptions) || isNullOrUndefined(state.konfigOptions.options)) {
      return false;
    }

    const option = state.konfigOptions.options.find((ko) => ko.optionId === optionId);
    if (isNullOrUndefined(option)) {
      return false;
    }

    return isKonfigOptionValueTruthy(option.optionValue);
  },
  optionValue: (state) => (optionId) => {
    const option = state.konfigOptions.options.find((ko) => ko.optionId === optionId);
    if (isNullOrUndefined(option)) {
      return null;
    }

    return option.optionValue;
  },
};

export default platformOptionsGetters;
