import { DateTime } from 'luxon';
import { triggerTopicTypeStatic } from '@/modules/TriggersAdmin/utils';
import { adminRoutes } from '@/utils/constants';

/**
 * Validates the existence of an object, used in place of node utils isNullOrUndefined (deprecated).
 *
 * @param {object} objectToValidate
 */
export const isNullOrUndefined = (objectToValidate) => objectToValidate === null || objectToValidate === undefined;

/**
 * Validates the existence and emptiness of a string.
 *
 * @param {string} stringToValidate
 */
export const isStringNullUndefinedOrEmpty = (stringToValidate) =>
  typeof stringToValidate === 'string' ? stringToValidate.length === 0 : isNullOrUndefined(stringToValidate);

export const isObjectEmpty = (obj) => isNullOrUndefined(obj) || Object.keys(obj).length === 0;

// TODO: write test case for these

export const isArrayEmpty = (arr) => isNullOrUndefined(arr) || arr.length === 0;

/**
 * Validates the existence and emptiness of a string, counting whitespace-only as empty.
 *
 * @param {string} stringToValidate
 */
export const isStringNullUndefinedOrWhitespace = (stringToValidate) =>
  typeof stringToValidate === 'string' ? stringToValidate.trim().length === 0 : isNullOrUndefined(stringToValidate);

/**
 * Converts a UTC date string to a localized date string.
 *
 * @param {string} utcString
 */
export const convertUtcStringToLocal = (utcString) =>
  DateTime.fromISO(utcString, {
    zone: 'utc',
  }).setZone('local');

/**
 * Creates a user friendly date from the given UTC string in the format MM/DD/YYYY.
 *
 * @param utcDateString
 * @returns {string}
 */
export const getFriendlyDate = (utcDateString) =>
  convertUtcStringToLocal(utcDateString).toLocaleString(DateTime.DATE_SHORT);

/**
 * Replaces all nulls in an array of objects with empty value.
 * Required for sorting and preventing other null-check related bugs
 * This is a generic version of convertNullValuesInUserProfileArrayToEmpty
 * and will replace it soon.
 *
 * @param [{object}] array
 */
export const convertNullValuesInArrayOfObjectsToEmpty = (array) => {
  array.forEach(function(obj) {
    Object.keys(obj).forEach(function(key) {
      if (isNullOrUndefined(obj[key])) {
        obj[key] = '';
      }
    });
  });
  return array;
};

/**
 * Replaces all nulls in an array of userProfiles with empty value.
 * Required for sorting and preventing other null-check related bugs
 *
 * @param [{object}] users
 */

export const convertNullValuesInUserProfileArrayToEmpty = (users) => {
  users.forEach(function(user) {
    Object.keys(user.userProfile).forEach(function(key) {
      if (isNullOrUndefined(user.userProfile[key])) {
        user.userProfile[key] = '';
      }
    });
  });
  return users;
};

/**
 * Checks for input context and document mode (IE specific window objects) to determine if user agent is IE11.
 *
 * @returns {boolean}
 */
export const isIE11 = () =>
  !isNullOrUndefined(window.MSInputMethodContext) && !isNullOrUndefined(document.documentMode);

/**
 * Converts an ambiguous value returned from Konfig options endpoint (true, "Yes", " yes", etc.) to a boolean
 *
 * @param konfigOptionValue
 * @returns {boolean}
 */
export const isKonfigOptionValueTruthy = (value) => {
  if (typeof value === 'boolean') {
    return value === true;
  } else if (typeof value === 'number') {
    return value === 1;
  } else {
    const valueString = value
      .toString()
      .trim()
      .toLowerCase();
    return valueString === 'yes' || valueString === 'y';
  }
};

/**
 * Generic ascending sorting for date values from a collection.
 *
 * @param {string} previousDateUtc
 * @param {string} currentDateUtc
 * @returns {number} sort order
 */
export const sortValuesByDateAscending = (previousDateUtc, currentDateUtc) =>
  convertUtcStringToLocal(previousDateUtc) - convertUtcStringToLocal(currentDateUtc);

/**
 * Generic descending sorting for date values from a collection.
 *
 * @param {string} previousDateUtc
 * @param {string} currentDateUtc
 * @returns {number} sort order
 */
export const sortValuesByDateDescending = (previousDateUtc, currentDateUtc) =>
  sortValuesByDateAscending(currentDateUtc, previousDateUtc);

/**
 * Generic ascending alphabetical sorting for string values from a collection.
 * @param {string} previousName
 * @param {string} currentName
 * @returns {number} sort order
 */
//pass undefined for locales to use the base defined in browser
export const sortValuesByNameAscending = (previousName, currentName) =>
  previousName.localeCompare(currentName, undefined, { sensitivity: 'base' });

/**
 * Generic descending alphabetical sorting for string values from a collection.
 * @param {string} previousName
 * @param {string} currentName
 * @returns {number} sort order
 */
export const sortValuesByNameDescending = (previousName, currentName) =>
  sortValuesByNameAscending(currentName, previousName);

/**
 * Generates a deep copy of the provided object with no references to the original.
 * Works with arrays too because JS arrays are also objects.
 * @param {object || array} object
 * @returns {object || array} parsed object
 */
export const generateDeepObjectCopy = (object) => JSON.parse(JSON.stringify(object));

/**
 * Converts Rule from the API to a format digestable by the CreateOrEditTrigger state slice
 * @param {object} Rule (from API)
 * @returns {object} Rule (for CreateOrEditTrigger)
 */
export const mapTriggerRules = (rule) => {
  const formattedConditions = rule.conditions.map((c) => {
    return mapTriggerCondition(c);
  });
  const val = {
    ...rule,
    metIfCondition: rule.metIf,
    metIfIsValid: true,
    tempId: rule.id,
    conditions: formattedConditions,
  };
  return val;
};
export const mapTriggerCondition = (condition) => {
  return {
    category: condition.categoryId,
    variable: condition.statementId,
    topicId: condition.topicId,
    selectedTopicId: condition.topicType === triggerTopicTypeStatic ? condition.topicId : condition.dynamicTopicId,
    dynamicTopicId: condition.dynamicTopicId,
    tempId: condition.id,
    variableValueIsValid: true,
    variableValues: {
      primaryValue: condition.primaryValue,
      secondaryValue: condition.secondaryValue,
    },
    ruleId: condition.ruleId,
    variableIsStatement: condition.isOption,
  };
};

export const isAdminRoute = (route) => {
  return adminRoutes.some((adminRoute) => route.indexOf(adminRoute) > -1) || route.startsWith('/admin-');
};

/**
 * Validates that param is a number and greater than 0
 * @param {any} id
 * @returns {boolean}
 */
export function isValidRouteParamId(id) {
  return !isNullOrUndefined(id) && !isNaN(id) && Number(id) > 0;
}

//trailing call may be ignored by this implementation, does not matter for the current use case
export function simpleThrottle(fn, wait) {
  let throttled = false;
  return function(...args) {
    if (!throttled) {
      fn.apply(this, args);
      throttled = true;
      setTimeout(() => {
        throttled = false;
      }, wait);
    }
  };
}
/**
 *@param {dateString} dateString
 *@returns {string} formatted (M/D/YYYY H:MM AM/PM)
 */
export function formatDate(dateString) {
  const date = new Date(dateString);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  const formattedDate = `${month}/${day}/${year} ${hours}:${formattedMinutes} ${ampm}`;

  return formattedDate;
}
/**
 * @returns {string} formatted (M/D/YYYY)
 */
export function getJanuaryFirstOfCurrentYear() {
  const now = new Date();
  const currentYear = now.getFullYear();
  const januaryFirst = new Date(currentYear, 0, 1);
  return januaryFirst.toISOString();
}
/**
 * @returns {object} date range object
 */
export function getLastMonthDateRange() {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  // If current month is January, last month is December of the previous year
  const firstDayOfLastMonth = new Date(currentYear, currentMonth - 1, 1);
  const lastDayOfLastMonth = new Date(currentYear, currentMonth, 0);

  return {
    from: firstDayOfLastMonth.toISOString(),
    to: lastDayOfLastMonth.toISOString(),
  };
}
/**
 * @returns {object} date range object
 */
export function getLastQuarterDateRange() {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  let startMonth, endMonth, endDay;

  if (currentMonth <= 2) {
    // Current Quarter: Q1 (Jan-Mar), Last Quarter: Q4 (Oct-Dec) of the previous year
    startMonth = 9; // October
    endMonth = 11; // December
    endDay = 31; // Last day of December
    return {
      from: new Date(currentYear - 1, startMonth, 1).toISOString(),
      to: new Date(currentYear - 1, endMonth, endDay).toISOString(),
    };
  } else if (currentMonth <= 5) {
    // Current Quarter: Q2 (Apr-Jun), Last Quarter: Q1 (Jan-Mar)
    startMonth = 0; // January
    endMonth = 2; // March
    endDay = 31; // Last day of March
  } else if (currentMonth <= 8) {
    // Current Quarter: Q3 (Jul-Sep), Last Quarter: Q2 (Apr-Jun)
    startMonth = 3; // April
    endMonth = 5; // June
    endDay = 30; // Last day of June
  } else {
    // Current Quarter: Q4 (Oct-Dec), Last Quarter: Q3 (Jul-Sep)
    startMonth = 6; // July
    endMonth = 8; // September
    endDay = 30; // Last day of September
  }

  return {
    from: new Date(currentYear, startMonth, 1).toISOString(),
    to: new Date(currentYear, endMonth, endDay).toISOString(),
  };
}
/**
 * @returns {object} date range object
 */
export function getLastYearDateRange() {
  const now = new Date();
  const currentYear = now.getFullYear();

  const firstDayOfLastYear = new Date(currentYear - 1, 0, 1);
  const lastDayOfLastYear = new Date(currentYear - 1, 11, 31);

  return {
    from: firstDayOfLastYear.toISOString(),
    to: lastDayOfLastYear.toISOString(),
  };
}
