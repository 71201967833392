import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { menuManagementBaseUrl } from '@/modules/AdminMenuManagement/utils/endpoints';
import { isStringNullUndefinedOrEmpty } from '@/utils/helperFunctions';
import { buildMenuItemRequest } from '@/modules/AdminMenuManagement/utils';

export const menuActions = {
  resetMenuWizard({ commit, dispatch }) {
    commit('RESET_MENU_WIZARD');
    dispatch('updateGroupTree');
  },
  setSelectedExistingMenu({ commit }, payload) {
    commit('SET_SELECTED_EXISTING_MENU', payload);
  },
  setMenuName({ commit }, menuName) {
    commit('SET_MENU_NAME', menuName.trimStart());
  },
  setMenuStatus({ commit }, menuStatus) {
    commit('SET_MENU_STATUS', menuStatus);
  },
  setEditFormDirty({ commit }, payload) {
    commit('SET_IS_EDIT_DIRTY', payload);
  },
  setEditMode({ commit }, mode) {
    commit('SET_EDIT_MODE', mode);
  },
  setIsEditStepOneValid({ commit }, valid) {
    commit('SET_EDIT_STEP_ONE_VALID', valid);
  },
  setIsEditStepTwoValid({ commit }, valid) {
    commit('SET_EDIT_STEP_TWO_VALID', valid);
  },
  async createMenu({ commit, getters, dispatch, rootGetters }, payload) {
    try {
      const group = getters.getSelectedGroup;

      const menuItems = buildMenuItemRequest(getters.menuTree, getters.isEditMode);
      const menuToCreate = {
        name: getters.createMenuName,
        selectedGroupId: group.groupId,
        menuItems: menuItems,
        normalizedName: getters.createMenuName.toLowerCase(),
        status: payload,
        excludedGroupIds: getters.getExcludedGroups.map((g) => g.groupId),
      };

      const options = {
        url: `${rootGetters['common/commonApiBaseUrl']}${menuManagementBaseUrl}`,
        method: 'POST',
        data: menuToCreate,
      };

      const response = await mkNetworkRequestWrapper(options);
      return response.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async deleteMenu({ commit, dispatch, getters, rootGetters }, menuId) {
    try {
      const options = {
        url: `${rootGetters['common/commonApiBaseUrl']}${menuManagementBaseUrl}/${menuId}`,
        method: 'DELETE',
      };

      const response = await mkNetworkRequestWrapper(options);
      if (response.status === 204) {
        const menus = getters.searchResults.filter((m) => m.id !== menuId || m.id === -1);

        commit('SET_SEARCH_RESULTS', menus);
        commit('SET_TOTAL_SEARCH_RESULTS', menus.length > 1 ? getters.totalSearchResultsCount - 1 : menus.length);

        dispatch('loadStandardMenu');

        return true;
      }

      return false;
    } catch (e) {
      dispatch('common/displayErrorNotification', e, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },

  async updateMenu({ commit, getters, dispatch, rootGetters }, payload) {
    try {
      const menuItems = buildMenuItemRequest(getters.menuTree, getters.isEditMode);
      const updatedMenu = {
        name: getters.createMenuName,
        menuItems: menuItems,
        status: getters.menuStatus,
      };
      const options = {
        url: `${rootGetters['common/commonApiBaseUrl']}${menuManagementBaseUrl}/${payload}`,
        method: 'PUT',
        data: updatedMenu,
      };
      const response = await mkNetworkRequestWrapper(options);
      return response.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },

  async verifyUniqueMenuName({ dispatch, commit, rootGetters }, { menuName, menuId }) {
    try {
      if (isStringNullUndefinedOrEmpty(menuName)) {
        return null;
      }

      const params = new URLSearchParams({
        menuName,
      });

      if (menuId) {
        params.append('menuId', menuId);
      }

      const options = {
        url: `${rootGetters['common/commonApiBaseUrl']}${menuManagementBaseUrl}/verify?${params.toString()}`,
        method: 'GET',
      };

      const response = await mkNetworkRequestWrapper(options);
      return response.data.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
      return null;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadExistingMenuDetails({ dispatch, commit, rootGetters }, menuId) {
    try {
      const response = await mkNetworkRequestWrapper({
        url: `${rootGetters['common/commonApiBaseUrl']}${menuManagementBaseUrl}/${menuId}`,
        method: 'GET',
      });

      const menu = response.data?.data?.menu;
      commit('SET_SELECTED_MENU', menu);

      const selectedGroups = response.data?.data?.menu?.assignedGroups ?? [];
      commit('SET_MENU_MANAGEMENT_SELECTED_GROUPS', selectedGroups);
      commit('SET_ASSIGNED_GROUP_IDS', [...selectedGroups.map((g) => g.groupId)]);

      const groupIds = selectedGroups[0].idPath.split(':').filter((item) => item !== '');
      const openGroupNodes = groupIds.map((groupId) => {
        return { groupId: parseInt(groupId) };
      });
      commit('SET_OPEN_GROUP_NODES', openGroupNodes);

      await dispatch('loadInitialAvailableGroups', { groupIds });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadMenuForEdit({ dispatch, commit, rootGetters }, menuId) {
    try {
      const response = await mkNetworkRequestWrapper({
        url: `${rootGetters['common/commonApiBaseUrl']}${menuManagementBaseUrl}/${menuId}`,
        method: 'GET',
      });
      commit('SET_EDIT_MODE', true);

      const menu = response.data?.data?.menu;

      commit('SET_MENU_NAME', menu.name);
      commit('SET_MENU_STATUS', menu.status);
      commit('SET_SELECTED_MENU', menu);

      const selectedGroups = response.data?.data?.menu?.assignedGroups ?? [];

      commit('SET_MENU_MANAGEMENT_SELECTED_GROUPS', selectedGroups);
      commit('SET_ASSIGNED_GROUP_IDS', [...selectedGroups.map((g) => g.groupId)]);

      const groupIds = selectedGroups[0].idPath.split(':').filter((item) => item !== '');
      const openGroupNodes = groupIds.map((groupId) => {
        return { groupId: parseInt(groupId) };
      });
      commit('SET_OPEN_GROUP_NODES', openGroupNodes);

      dispatch('loadInitialAvailableGroups', { groupIds });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
