<template>
  <v-snackbar
    id="toast-message"
    class="snackbar--global"
    v-model="isOpen"
    v-bind:timeout="timeout"
    v-bind:top="true"
    v-bind:centered="centered"
    v-bind:right="right"
    v-bind:color="color"
    v-bind:app="true"
  >
    <div class="d-flex flex-row align-center justify-center">
      <div id="message" class="text-center">{{ msg }}</div>
      <v-btn
        id="view-on-dashboard-btn"
        class="ml-2"
        v-show="isClosable"
        color="white"
        text
        v-on:click="onSnackbarClose"
      >
        {{ buttonText }}
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { snackbarTimeout, indefiniteSnackbarTimeout } from '@/modules/Common/utils/constants';
import { mapGetters, mapActions } from 'vuex';
import { snackbarTypeError, snackbarTypeNotification, snackbarTypeSuccess } from '../utils/constants';

export default {
  name: 'Snackbar',
  data() {
    return {
      snackbarTimeout: snackbarTimeout,
      indefiniteSnackbarTimeout: indefiniteSnackbarTimeout,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.$store.state.common.notificationSnackbar.open;
      },
      set() {
        if (!this.isClosable) {
          this.$store.commit('common/CLOSE_NOTIFICATION');
        }
      },
    },
    color: function() {
      let color = '';
      switch (this.type) {
        case snackbarTypeSuccess:
          color = 'success';
          break;
        case snackbarTypeError:
          color = 'error';
          break;
        case snackbarTypeNotification:
          color = 'info';
          break;
        default:
          color = this.type;
          break;
      }
      return color;
    },
    centered: function() {
      return this.type !== snackbarTypeNotification;
    },
    right: function() {
      return this.type === snackbarTypeNotification;
    },
    timeout: function() {
      return this.isClosable ? indefiniteSnackbarTimeout : snackbarTimeout;
    },
    ...mapGetters({
      type: 'common/notificationType',
      msg: 'common/notificationMessage',
      isClosable: 'common/notificationIsClosable',
      buttonText: 'common/notificationButtonText',
      onClose: 'common/notificationOnCloseCallback',
    }),
  },
  methods: {
    ...mapActions({
      closeNotification: 'common/closeNotification',
    }),
    onSnackbarClose: function() {
      this.onClose();
      this.closeNotification();
    },
  },
};
</script>
<style lang="scss" scoped>
#message {
  word-break: break-word;
}
</style>
