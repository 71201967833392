import { isNullOrUndefined } from '@/utils/helperFunctions';
import { enrolledMembersPageSize } from '@/modules/SocialCommunities/utils/constants';

export const socialCommunitiesMutations = {
  RESET_COMMUNITY_WALL_STATE(state, payload) {
    state.communityPrivacySettings = state.communityPrivacySettings.filter((ps) => ps.communityId !== payload);
    state.posts = state.posts.filter((p) => p.communityId !== payload);
    state.comments = state.comments.filter((c) => c.communityId !== payload);
    state.postPins = state.postPins.filter((pp) => pp.communityId !== payload);
    state.postReactions = state.postReactions.filter((pr) => pr.communityId !== payload);
    state.commentReactions = state.commentReactions.filter((cr) => cr.communityId !== payload);
    state.postFlags = state.postFlags.filter((pf) => pf.communityId !== payload);
    state.commentFlags = state.commentFlags.filter((cf) => cf.communityId !== payload);
    state.communityHeroItems = state.communityHeroItems.filter((hi) => hi.communityId !== payload);
    state.userInfo = state.userInfo.filter((ui) => ui.communityId !== payload);
    state.communityTotalLeaderCounts = state.communityTotalLeaderCounts.filter((tlc) => tlc.communityId !== payload);
  },
  RESET_COMMUNITY_INFO_ADMIN(state) {
    state.communityInfo = [];
  },
  SET_CURRENT_COMMUNITY_ID(state, payload) {
    state.currentCommunityId = payload;
  },
  SET_COMMUNITY_FILTER_ID(state, payload) {
    state.communityFilterId = payload;
  },
  INCREMENT_COMMUNITY_MANAGEMENT_TOTAL_COUNT(state) {
    state.communityManagementTotalCount += 1;
  },
  DECREMENT_COMMUNITY_MANAGEMENT_TOTAL_COUNT(state) {
    state.communityManagementTotalCount -= 1;
  },
  SET_COMMUNITY_MANAGEMENT_TOTAL_COUNT(state, payload) {
    state.communityManagementTotalCount = payload;
  },
  SET_CREATE_COMMUNITY_DESIGN_METHOD(state, payload) {
    state.createCommunity.designMethod = payload;
  },
  SET_CREATE_COMMUNITY_CATEGORY(state, payload) {
    state.createCommunity.selectedCategory = payload;
  },
  SET_CREATE_COMMUNITY_TEMPLATE(state, payload) {
    state.createCommunity.selectedTemplate = payload;
  },
  SET_CREATE_COMMUNITY_ICON(state, payload) {
    state.createCommunity.selectedIcon = payload;
  },
  SET_CREATE_COMMUNITY_BACKGROUND_GRADIENT(state, payload) {
    state.createCommunity.selectedBackgroundGradient = payload;
  },
  SET_CREATE_COMMUNITY_NAME(state, payload) {
    state.createCommunity.name = payload;
  },
  SET_CREATE_COMMUNITY_DESCRIPTION(state, payload) {
    state.createCommunity.description = payload;
  },
  SET_CREATE_COMMUNITY_GROUPS(state, payload) {
    state.createCommunity.groups = payload;
  },
  SET_CREATE_COMMUNITY_LEADERS_ONLY(state, payload) {
    state.createCommunity.leadersOnly = payload;
  },
  SET_CREATE_COMMUNITY_TRIGGERS(state, payload) {
    state.createCommunity.triggers = payload;
  },
  ADD_CREATE_COMMUNITY_HERO_IMAGE(state, payload) {
    state.createCommunity.heroImages.push(payload);
  },
  REMOVE_CREATE_COMMUNITY_HERO_IMAGE(state, payload) {
    state.createCommunity.heroImages = state.createCommunity.heroImages.filter((hi) => hi.imageFileName !== payload);
  },
  REMOVE_CREATE_COMMUNITY_ALL_HERO_IMAGES(state) {
    state.createCommunity.heroImages = [];
  },
  SET_COMMUNITY_INFO(state, payload) {
    payload.forEach((c) => {
      const existingCommunity = state.communityInfo.find((ci) => ci.id === c.id);
      if (isNullOrUndefined(existingCommunity)) {
        state.communityInfo.push(c);
      }
    });
  },
  SET_COMMUNITY_PRIVACY_SETTINGS(state, payload) {
    payload.forEach((ps) => {
      const existingPrivacySetting = state.communityPrivacySettings.find((eps) => eps.communityId === ps.communityId);
      if (isNullOrUndefined(existingPrivacySetting)) {
        state.communityPrivacySettings.push(ps);
      }
    });
  },
  // because the shape of communityresponse is different from user and admin, we
  // need to have two separate mutations here. this one always replaces even if
  // it's found because admins can possibly start on a wall and navigate back, at which
  // point the community will exist in state without all the admin-only properties
  SET_COMMUNITY_INFO_ADMIN(state, payload) {
    payload.forEach((c) => {
      const existingCommunity = state.communityInfo.find((ci) => ci.id === c.id);
      if (!isNullOrUndefined(existingCommunity)) {
        state.communityInfo = state.communityInfo.filter((ci) => ci.id !== c.id);
      }
      state.communityInfo.push(c);
    });
  },
  SET_COMMUNITY_TOTAL_POST_COUNT(state, payload) {
    payload.forEach((pc) => {
      const existingPostCount = state.communityTotalPostCounts.find((epc) => epc.communityId === pc.communityId);
      if (isNullOrUndefined(existingPostCount)) {
        state.communityTotalPostCounts.push(pc);
      }
    });
  },
  SET_COMMUNITY_POSTS(state, payload) {
    payload.forEach((p) => {
      const existingPost = state.posts.find((ep) => ep.id === p.id);
      if (isNullOrUndefined(existingPost)) {
        state.posts.push(p);
      }
    });
  },
  SET_COMMUNITY_POST_PINS(state, payload) {
    payload.forEach((p) => {
      const existingPin = state.postPins.find((ep) => ep.pinId === p.pinId);
      if (isNullOrUndefined(existingPin)) {
        state.postPins.push(p);
      }
    });
  },
  REMOVE_COMMUNITY_POST_PINS(state, payload) {
    payload.forEach((pinId) => {
      const existingPin = state.postPins.find((ep) => ep.pinId === pinId);
      if (!isNullOrUndefined(existingPin)) {
        state.postPins = state.postPins.filter((pp) => pp.pinId !== pinId);
      }
    });
  },
  SET_COMMUNITY_COMMENTS(state, payload) {
    payload.forEach((c) => {
      const existingComment = state.comments.find((ec) => ec.id === c.id);
      if (isNullOrUndefined(existingComment)) {
        state.comments.push(c);
      }
    });
  },
  SET_COMMUNITY_HERO_ITEMS(state, payload) {
    if (!isNullOrUndefined(payload)) {
      payload.forEach((hi) => {
        const existingHeroItem = state.communityHeroItems.find((ehi) => ehi.id === hi.id);
        if (isNullOrUndefined(existingHeroItem)) {
          state.communityHeroItems.push(hi);
        }
      });
    }
  },
  SET_COMMUNITY_POST_REACTIONS(state, payload) {
    payload.forEach((pr) => {
      const existingReaction = state.postReactions.find(
        (er) => er.reactionId === pr.reactionId && er.postId === pr.postId,
      );
      if (isNullOrUndefined(existingReaction)) {
        state.postReactions.push(pr);
      }
    });
  },
  REMOVE_COMMUNITY_POST_REACTIONS(state, payload) {
    payload.forEach((reactionId) => {
      const existingReaction = state.postReactions.find((er) => er.reactionId === reactionId);
      if (!isNullOrUndefined(existingReaction)) {
        state.postReactions = state.postReactions.filter((pr) => pr.reactionId !== reactionId);
      }
    });
  },
  SET_COMMUNITY_COMMENT_REACTIONS(state, payload) {
    payload.forEach((cr) => {
      const existingReaction = state.commentReactions.find(
        (er) => er.reactionId === cr.reactionId && er.commentId === cr.commentId,
      );
      if (isNullOrUndefined(existingReaction)) {
        state.commentReactions.push(cr);
      }
    });
  },
  REMOVE_COMMUNITY_COMMENT_REACTIONS(state, payload) {
    payload.forEach((reactionId) => {
      const existingReaction = state.commentReactions.find((er) => er.reactionId === reactionId);
      if (!isNullOrUndefined(existingReaction)) {
        state.commentReactions = state.commentReactions.filter((cr) => cr.reactionId !== reactionId);
      }
    });
  },
  SET_COMMUNITY_POST_FLAGS(state, payload) {
    payload.forEach((pf) => {
      const existingFlag = state.postFlags.find(
        (ef) => ef.flagId === pf.flagId && ef.createdUserContactId === pf.createdUserContactId,
      );
      if (isNullOrUndefined(existingFlag)) {
        state.postFlags.push(pf);
      }
    });
  },
  REMOVE_COMMUNITY_POST_FLAGS(state, payload) {
    payload.forEach((flagId) => {
      const existingFlag = state.postFlags.find((ef) => ef.flagId === flagId);
      if (!isNullOrUndefined(existingFlag)) {
        state.postFlags = state.postFlags.filter((pf) => pf.flagId !== flagId);
      }
    });
  },
  SET_COMMUNITY_COMMENT_FLAGS(state, payload) {
    payload.forEach((cf) => {
      const existingFlag = state.commentFlags.find(
        (ef) => ef.flagId === cf.flagId && ef.createdUserContactId === cf.createdUserContactId,
      );
      if (isNullOrUndefined(existingFlag)) {
        state.commentFlags.push(cf);
      }
    });
  },
  REMOVE_COMMUNITY_COMMENT_FLAGS(state, payload) {
    payload.forEach((flagId) => {
      const existingFlag = state.commentFlags.find((ef) => ef.flagId === flagId);
      if (!isNullOrUndefined(existingFlag)) {
        state.commentFlags = state.commentFlags.filter((cf) => cf.flagId !== flagId);
      }
    });
  },
  OPEN_POST_EDITOR(state, payload) {
    const postEditingPayload = {
      isEditorOpen: true,
      postId: payload.postId,
      parentPostId: payload.parentPostId,
      originalPostText: payload.originalPostText,
      postType: payload.postType,
    };
    state.postEditing = postEditingPayload;
  },
  CLOSE_POST_EDITOR(state) {
    const postEditingPayload = {
      isEditorOpen: false,
      postId: 0,
      parentPostId: 0,
      originalPostText: '',
      postType: null,
    };
    state.postEditing = postEditingPayload;
  },
  UPDATE_EDITED_POST(state, payload) {
    state.posts = state.posts.map((post) => {
      if (post.id === payload.id && post.communityId === payload.communityId) {
        return {
          ...post,
          text: payload.text,
          imageUri: payload.imageUri,
          edited: true,
          editedDateUtc: payload.editedDateUtc,
        };
      }

      return post;
    });
  },
  UPDATE_EDITED_COMMENT(state, payload) {
    state.comments = state.comments.map((comment) => {
      if (comment.id === payload.id && comment.communityId === payload.communityId) {
        return {
          ...comment,
          text: payload.text,
          imageUri: payload.imageUri,
          edited: true,
          editedDateUtc: payload.editedDateUtc,
        };
      }

      return comment;
    });
  },
  INCREMENT_COMMUNITY_POST_COUNT(state, payload) {
    let existingPostCount = state.communityTotalPostCounts.find((epc) => epc.communityId === payload);
    if (!isNullOrUndefined(existingPostCount)) {
      existingPostCount.totalPostCount++;
    }
  },
  DECREMENT_COMMUNITY_POST_COUNT(state, payload) {
    let existingPostCount = state.communityTotalPostCounts.find((epc) => epc.communityId === payload);
    if (!isNullOrUndefined(existingPostCount)) {
      existingPostCount.totalPostCount = Math.max(0, existingPostCount.totalPostCount - 1);
    }
  },
  DELETE_POST(state, payload) {
    const post = state.posts.find((p) => p.id === payload.id && p.communityId === payload.communityId);
    if (!isNullOrUndefined(post)) {
      state.posts = state.posts.filter((p) => p.id !== payload.id && p.communityId === payload.communityId);
    }
  },
  DELETE_COMMENT(state, payload) {
    const comment = state.comments.find((c) => c.id === payload.id && c.communityId === payload.communityId);
    if (!isNullOrUndefined(comment)) {
      state.comments = state.comments.filter((c) => c.id !== payload.id && c.communityId === payload.communityId);
    }
  },
  ADD_USER_INFO(state, payload) {
    payload.forEach((ui) => {
      const existingUserInfo = state.userInfo.find(
        (eui) => eui.contactId === ui.contactId && eui.communityId === ui.communityId,
      );
      if (isNullOrUndefined(existingUserInfo)) {
        state.userInfo.push(ui);
      }
    });
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },
  SET_COMMUNITY_TOTAL_MEMBER_COUNT(state, payload) {
    payload.forEach((tmc) => {
      const existingMemberCount = state.communityTotalMemberCounts.find((emc) => emc.communityId === tmc.communityId);
      if (isNullOrUndefined(existingMemberCount)) {
        state.communityTotalMemberCounts.push(tmc);
      } else {
        existingMemberCount.totalMemberCount = tmc.totalMemberCount;
      }
    });
  },
  SET_COMMUNITY_TOTAL_LEADER_COUNT(state, payload) {
    payload.forEach((tlc) => {
      const existingLeaderCount = state.communityTotalLeaderCounts.find((elc) => elc.communityId === tlc.communityId);
      if (isNullOrUndefined(existingLeaderCount)) {
        state.communityTotalLeaderCounts.push(tlc);
      }
    });
  },
  SET_CURRENT_COMMUNITY_FILTERED_MEMBERS(state, payload) {
    state.filteredCommunityMembers = payload;
  },
  RESET_FILTERED_COMMUNITY_MEMBERS(state) {
    state.filteredCommunityMembers = [];
  },
  TOGGLE_JOINED(state, payload) {
    const community = state.communityInfo.find((c) => c.id === payload);
    community.userJoined = !community.userJoined;
  },
  DISABLE_COMMUNITY(state, payload) {
    state.communityInfo.find((c) => c.id === payload.communityId).status = payload.status;
    state.communityInfo.find((c) => c.id === payload.communityId).disableDateUtc = payload.disableDateUtc;
  },
  ENABLE_COMMUNITY(state, payload) {
    state.communityInfo.find((c) => c.id === payload.communityId).status = payload.status;
    state.communityInfo.find((c) => c.id === payload.communityId).launchDateUtc = payload.launchDateUtc;
  },
  UPDATE_CURRENT_COMMUNITY(state, payload) {
    const community = state.communityInfo.find((c) => c.id === payload.communityId);
    if (!isNullOrUndefined(community)) {
      community.name = payload.communityInfo.name;
      community.subtitle = payload.communityInfo.subtitle;
      community.icon = payload.communityInfo.icon;
      community.backgroundGradient = payload.communityInfo.backgroundGradient;
      community.leadersOnly = payload.communityInfo.leadersOnly;
    }
  },
  REMOVE_CURRENT_COMMUNITY_HERO_ITEMS(state, payload) {
    state.communityHeroItems = state.communityHeroItems.filter((hi) => hi.communityId !== payload);
  },
  REMOVE_FLAGGED_POST(state, payload) {
    // removing from postFlags is fine because admins can't flag anything. if this behavior
    // ever changes, this mutation will be an issue
    state.postFlags = state.postFlags.filter((pf) => pf.postId !== payload.postId);
  },
  REMOVE_FLAGGED_COMMENT(state, payload) {
    // removing from commentFlags is fine because admins can't flag anything. if this behavior
    // ever changes, this mutation will be an issue
    state.commentFlags = state.commentFlags.filter((cf) => cf.commentId !== payload.postId);
  },
  DELETE_FLAGGED_POST(state, payload) {
    state.postFlags = state.postFlags.filter((pf) => pf.postId !== payload.postId);
    state.posts = state.posts.filter((p) => p.id !== payload.postId);
  },
  DELETE_FLAGGED_COMMENT(state, payload) {
    state.commentFlags = state.commentFlags.filter((cf) => cf.commentId !== payload.commentId);
    state.comments = state.comments.filter((c) => c.id !== payload.commentId);
  },
  SET_COMMUNITY_GROUPS(state, payload) {
    state.communityGroups = payload;
  },
  // TODO: This may or may not be needed for future purposes, rip out if need be
  UPDATE_COMMUNITY_GROUPS(state, payload) {
    payload.forEach((g) => {
      const existingGroup = state.communityGroups.find(
        (eg) => eg.groupId === g.groupId && eg.communityId === g.communityId,
      );
      if (!isNullOrUndefined(existingGroup)) {
        // we want to overwrite if this exists. in the current create circle flow, it is possible for an admin
        // to create a circle, assign groups, add participants, and then have an existing state slice that still
        // indicates the circle has no members due to it being set when it was created
        state.communityGroups = state.communityGroups.filter(
          (cg) => cg.groupId !== g.groupId && cg.communityId !== g.communityId,
        );
      }
      state.communityGroups.push(g);
    });
  },
  SET_AVAILABLE_USERS(state, payload) {
    state.participants = {
      ...state.participants,
      communityId: payload.communityId,
      groupIds: payload.groupIds,
      users: payload.users.filter((u) => !isNullOrUndefined(u.userProfile)),
    };
  },
  SET_PAGINATION_START_POINT_USERS(state, payload) {
    state.participantsStartPointUsers = Number(payload);
  },
  SET_PAGINATION_START_POINT_MEMBERS(state, payload) {
    state.participantsStartPointMembers = Number(payload);
  },
  SET_SEARCH_INPUT_USERS(state, payload) {
    state.participantsSearchStringUsers = payload;
  },
  SET_SEARCH_INPUT_MEMBERS(state, payload) {
    state.participantsSearchStringMembers = payload;
  },
  SET_CURRENT_MEMBERS_ENROLLED_SEARCH_STRING(state, payload) {
    state.currentMembersEnrolledSearchString = payload;
  },
  ADD_COMMUNITY_LEADER(state, payload) {
    const user = state.userInfo.find((u) => u.contactId === payload.contactId && u.communityId === payload.communityId);
    if (!isNullOrUndefined(user)) {
      user.isCommunityLeader = true;
    }
  },
  REMOVE_COMMUNITY_LEADER(state, payload) {
    const user = state.userInfo.find((u) => u.contactId === payload.contactId && u.communityId === payload.communityId);
    if (!isNullOrUndefined(user)) {
      user.isCommunityLeader = false;
    }
  },
  ADD_COMMUNITY_MEMBERS(state, payload) {
    payload.forEach((p) => {
      const user = state.userInfo.find((u) => u.communityId === p.communityId && u.contactId === p.contactId);
      if (user) {
        user.isMemberOfSelectedCommunity = true;
      }
    });
  },
  REMOVE_COMMUNITY_MEMBERS(state, payload) {
    payload.forEach((p) => {
      const user = state.userInfo.find((u) => u.communityId === p.communityId && u.contactId === p.contactId);
      if (user) {
        user.isMemberOfSelectedCommunity = false;
        user.isCommunityLeader = false;
      }
    });
  },
  INCREMENT_POST_COMMENT_COUNT(state, payload) {
    let existingCommentCount = state.posts.find((p) => p.id === payload);
    if (!isNullOrUndefined(existingCommentCount)) {
      existingCommentCount.commentCount++;
    }
  },
  DECREMENT_POST_COMMENT_COUNT(state, payload) {
    let existingCommentCount = state.posts.find((p) => p.id === payload);
    if (!isNullOrUndefined(existingCommentCount)) {
      existingCommentCount.commentCount = Math.max(0, existingCommentCount.commentCount - 1);
    }
  },
  SET_CURRENT_MEMBERS_ENROLLED_META(state, payload) {
    const members =
      !isNullOrUndefined(payload.resetMembers) && payload.resetMembers
        ? payload.communityMembers
        : [...state.currentMembersEnrolledUsersMeta.communityMembers, ...payload.communityMembers];

    state.currentMembersEnrolledUsersMeta = {
      communityMembers: members,
      totalMemberCount: payload.totalMemberCount,
      links: payload.links,
      currentPaginationPoint: state.currentMembersEnrolledUsersMeta.currentPaginationPoint,
    };
  },
  RESET_CURRENT_MEMBERS_ENROLLED_META(state) {
    state.currentMembersEnrolledUsersMeta = {
      communityMembers: [],
      totalMemberCount: 0,
      links: {},
      currentPaginationPoint: enrolledMembersPageSize,
    };
  },
  RESET_ALL_MEMBERS_PAGINATION_BREAKPOINT(state) {
    state.currentMembersEnrolledUsersMeta.currentPaginationPoint = enrolledMembersPageSize;
  },
  INCREMENT_ALL_MEMBERS_PAGINATION_BREAKPOINT(state) {
    state.currentMembersEnrolledUsersMeta.currentPaginationPoint += enrolledMembersPageSize;
  },
  SET_ASSIGNED_TRIGGERS_FOR_COMMUNITY(state, { communityId, assignedTriggerIds }) {
    state.assignedTriggers[communityId] = assignedTriggerIds;
    state.assignedTriggers = Object.assign({}, state.assignedTriggers);
  },
  SET_TOTAL_CURRENT_MEMBERS_COUNT(state, totalMemberCount) {
    state.totalCurrentMembersCount = totalMemberCount;
  },
};
