import { adminMenuManagementMutations } from '@/modules/AdminMenuManagement/store/mutations';
import { adminMenuManagementGetters } from '@/modules/AdminMenuManagement/store/getters';
import { adminMenuManagementActions } from '@/modules/AdminMenuManagement/store/actions';
import { designMethods } from '@/modules/AdminMenuManagement/utils/constants';

export const adminMenuManagementState = {
  showTree: false,
  search: {
    allMenus: [],
    searchResults: [],
    searchParams: {
      menuName: '',
      groupName: '',
      menuStatus: -1,
    },
    paginationInfo: {},
    totalCount: 0,
    searching: false,
  },
  availableGroups: [],
  currentStep: 1,
  isEditMode: false,
  isEditGroupLoading: true,
  isEditFormDirty: false,
  createNewMenu: {
    designMethod: designMethods.createFromScratch,
    lastUsedMenuItemId: 0,
    menuName: '',
    menuTree: [],
    status: '',
    selectedExistingMenu: null,
    selectedGroups: [],
    assignedGroupIds: [],
    excludedGroups: [],
    previouslySelectedGroups: [],
    selectedMenuItem: null,
    parentMenuItems: [{ id: -1, name: 'Parent Menu Item', disabled: true }],
    validationErrors: [],
    predefinedItems: [],
    isMenuItemFormDirty: false,
    selectedMenu: null,
    isAvidonEnabledForSelectedGroups: false,
  },
  isStepOneValid: true,
  isStepTwoValid: true,
  editMenu: {
    openGroupNodes: [],
  },
  menus: [],
  standardComponents: [],
  surveys: [],
  thirdPartyLinks: [],
  cmsTiles: [],
  avidonCourses: [],
  avidonFlows: [],
  avidonPages: [],
  loadingItems: false,
};

const adminMenuManagementStore = {
  namespaced: true,
  state: adminMenuManagementState,
  mutations: adminMenuManagementMutations,
  getters: adminMenuManagementGetters,
  actions: adminMenuManagementActions,
};
export default adminMenuManagementStore;
