export const gendersAssignedAtBirth = [
  {
    text: 'Female',
    value: 'F',
  },
  {
    text: 'Male',
    value: 'M',
  },
];
