import {
  McMessageSendType,
  McMessageSentStatus,
  McScheduleType,
  StopSendingAfterType,
  McRecurringPatternFrequencyType,
  McDailyRecurringIntervalType,
  McMonthlyRecurringIntervalType,
  McYearlyRecurringIntervalType,
  McWeekOfMonth,
  DayOfWeek,
  RecipientCriteriaDropdown,
} from '@/modules/AdminMessageCenter/utils/constants';

export const adminMessageCenterMutations = {
  SET_SEARCH_PARAMS(state, searchParams) {
    state.searchParams = {
      ...state.searchParams,
      ...searchParams,
    };
  },
  SET_SEARCH_RESULTS(state, messages) {
    state.searchResults = messages;
  },
  SET_SEARCH_IS_LOADING(state, isLoading) {
    state.searchResultsIsLoading = isLoading;
  },
  SET_MESSAGE_THREAD_DATA(state, thread) {
    state.messageThread = thread;
  },
  SET_MESSAGE_THREADS_DATA(state, threads) {
    state.messageThreads = threads;
  },
  SET_MEMBER_ID(state, id) {
    state.memberId = id;
  },
  SET_THREAD_REPLY(state, message) {
    state.threadReply = message;
  },
  SET_MESSAGE_DATA(state, messageData) {
    state.messageData = {
      ...state.messageData,
      ...messageData,
    };
  },
  SET_MESSAGE_FILTER_OPTION(state, filterOptions) {
    state.messageData.filterOptions = {
      ...state.messageData.filterOptions,
      ...filterOptions,
    };
  },
  SET_SELECTED_CRITERIA(state, criteria) {
    state.selectedCriteria = criteria;
  },
  SET_SELECTED_CRITERIA_OPTIONS(state, criteria) {
    state.selectedCriteriaOptions = criteria;
  },
  RESET_MESSAGE_DATA(state) {
    state.messageData = {
      isDraft: true,
      messageId: 0,
      body: '',
      createdBy: '',
      createdByText: '',
      sendType: McMessageSendType.SecureMessage,
      subject: '',
      status: McMessageSentStatus.Draft,
      hippaWarning: false,
      notificationLink: null,
      membersCanReply: false,
      isScheduledToSendWithinAnHour: false,
      filterOptions: {
        filterType: RecipientCriteriaDropdown.SelectAll,
        filterCriteria: [],
        triggerIds: [],
        stopSendingTriggeredMessagesOn: null,
      },
      groupIds: [],
      schedule: {
        scheduleType: McScheduleType.SpecificDates,
        specificDateOccurrences: [],
        recurringPattern: {
          startDate: {
            dateTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString(),
            timeZoneId: 'Pacific Standard Time',
            dateTimeOffset: null,
          },
          stopSendingAfterType: StopSendingAfterType.EndDate,
          stopSendingDate: {
            dateTime: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
            timeZoneId: 'Pacific Standard Time',
            dateTimeOffset: null,
          },
          stopSendingNumberOfOccurrences: 1,
          frequencyType: McRecurringPatternFrequencyType.Daily,
          dailyRecurringProperties: {
            intervalType: McDailyRecurringIntervalType.EveryNDays,
            intervalDays: 1,
          },
          weeklyRecurringProperties: {
            intervalWeeks: 1,
            daysOfWeek: [],
          },
          monthlyRecurringProperties: {
            intervalType: McMonthlyRecurringIntervalType.DayOfMonth,
            intervalMonths: 1,
            dayOfMonth: 1,
            week: McWeekOfMonth.Third,
            dayOfWeek: DayOfWeek.Thursday,
          },
          yearlyRecurringProperties: {
            intervalType: McYearlyRecurringIntervalType.DayOfMonth,
            intervalYears: 1,
            month: 6,
            dayOfMonth: 1,
            week: McWeekOfMonth.Third,
            dayOfWeek: DayOfWeek.Thursday,
          },
        },
      },
      attachments: [],
    };
  },
};
