import {
  triggersBasePathUrl,
  triggerSortByCreationDateAscending,
  triggerSortByCreationDateDescending,
  triggerSortByNameAscending,
  triggerSortByNameDescending,
  triggerStatusAll,
} from '@/modules/TriggersAdmin/utils';
import {
  triggersAdminSearchTriggerInputTextLengthThreshold,
  createTriggerConditionPlaceholderId,
} from '@/modules/TriggersAdmin/utils';
import {
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty,
  isStringNullUndefinedOrWhitespace,
  sortValuesByDateAscending,
  sortValuesByDateDescending,
  sortValuesByNameAscending,
  sortValuesByNameDescending,
  mapTriggerRules,
} from '@/utils/helperFunctions';

const conditionTopicIsSelected = (condition) => condition.selectedTopicId >= 0;
const conditionCategoryIsSelected = (condition) => condition.category >= 0;
const conditionVariableIsSelected = (condition) => condition.variable >= 0;
const conditionIsInvalid = (condition) => {
  return (
    !condition.variableValueIsValid ||
    !conditionTopicIsSelected(condition) ||
    !conditionVariableIsSelected(condition) ||
    !conditionCategoryIsSelected(condition)
  );
};

export const triggersAdminGetters = {
  triggersApiBaseUrl: (state, getters, rootState) => {
    return rootState.common.apiUrl;
  },
  createTriggerName: (state) => {
    return state.triggers.createOrEditTrigger.name;
  },
  createTriggerDescription: (state) => {
    return state.triggers.createOrEditTrigger.description;
  },
  createTriggerMetIfCondition: (state) => {
    return state.triggers.createOrEditTrigger.metIfCondition;
  },
  createOrEditTriggerTriggerStatus: (state) => {
    return state.triggers.createOrEditTrigger.triggerStatus;
  },
  triggerQueryTotal: (state) => {
    return state.triggers.queryTotal;
  },
  anyCustomTriggers: (state) => {
    return state.triggers.customTriggersCount > 0;
  },
  createTriggerRules: (state) => {
    return state.triggers.createOrEditTrigger.rules;
  },
  createOrEditTriggerRule: (state, getters) => (ruleIndex) => {
    const rules = getters.createTriggerRules;

    return isNullOrUndefined(rules) || isNullOrUndefined(rules[ruleIndex]) ? undefined : rules[ruleIndex];
  },
  createOrEditTriggerRuleConditions: (state, getters) => (ruleIndex) => {
    const rule = getters.createOrEditTriggerRule(ruleIndex);

    return isNullOrUndefined(rule) ? [] : rule.conditions;
  },
  createOrEditTriggerRuleCondition: (state, getters) => (ruleIndex, conditionIndex) => {
    const ruleConditions = getters.createOrEditTriggerRuleConditions(ruleIndex);

    return ruleConditions[conditionIndex];
  },
  filteredAdminTiles: (state) => {
    let filteredTriggers = state.triggers.adminTriggers;

    // Filter on triggers based on the current trigger status from the dropdown
    if (state.triggers.filterId !== triggerStatusAll) {
      filteredTriggers = filteredTriggers.filter((t) => t.status === state.triggers.filterId);
    }

    // Filter the current triggers if a search string greater than the minimum search value is available
    if (
      !isStringNullUndefinedOrWhitespace(state.triggers.searchText) &&
      state.triggers.searchText.length >= triggersAdminSearchTriggerInputTextLengthThreshold
    ) {
      filteredTriggers = filteredTriggers.filter(
        (t) =>
          !isStringNullUndefinedOrEmpty(t.name) &&
          t.name.toLowerCase().includes(state.triggers.searchText.toLowerCase()),
      );
    }

    // Apply the current sorting status
    switch (state.triggers.sortBy) {
      case triggerSortByCreationDateAscending:
        filteredTriggers = filteredTriggers.sort((previousTrigger, currentTrigger) =>
          sortValuesByDateAscending(previousTrigger, currentTrigger),
        );
        break;
      case triggerSortByCreationDateDescending:
        filteredTriggers = filteredTriggers.sort((previousTrigger, currentTrigger) =>
          sortValuesByDateDescending(previousTrigger, currentTrigger),
        );
        break;
      case triggerSortByNameAscending:
        filteredTriggers = filteredTriggers.sort((previousTrigger, currentTrigger) =>
          sortValuesByNameAscending(previousTrigger.name, currentTrigger.name),
        );
        break;
      case triggerSortByNameDescending:
        filteredTriggers = filteredTriggers.sort((previousTrigger, currentTrigger) =>
          sortValuesByNameDescending(previousTrigger.name, currentTrigger.name),
        );
        break;
    }

    return filteredTriggers;
  },
  triggerFilterId: (state) => {
    return state.triggers.filterId;
  },
  triggerFilterOriginTypeId: (state) => {
    return state.triggers.filterOriginTypeId;
  },
  triggerSearchText: (state) => {
    return state.triggers.searchText;
  },
  triggerSortBy: (state) => {
    return state.triggers.sortBy;
  },
  duplicateTriggerName: (state) => {
    return state.triggers.duplicatedTriggerName;
  },
  hasValidDuplicateTriggerName: (state) => {
    return !isStringNullUndefinedOrWhitespace(state.triggers.duplicatedTriggerName);
  },
  reloadTriggersOnSearch: (state) => {
    return isNullOrUndefined(state.triggers.reloadTriggers) ? true : state.triggers.reloadTriggers.onSearch;
  },
  reloadTriggersOnFilter: (state) => {
    return isNullOrUndefined(state.triggers.reloadTriggers) ? true : state.triggers.reloadTriggers.onFilter;
  },
  reloadTriggersOnSort: (state) => {
    return isNullOrUndefined(state.triggers.reloadTriggers) ? true : state.triggers.reloadTriggers.onSort;
  },
  nextPageTriggerMetasLink: (state, getters) => {
    const hasNextPaginationLinkAvailable =
      isNullOrUndefined(state.triggers.paginationLinks) || isNullOrUndefined(state.triggers.paginationLinks.next);

    return hasNextPaginationLinkAvailable
      ? `${getters.triggersApiBaseUrl}${triggersBasePathUrl}`
      : state.triggers.paginationLinks.next;
  },
  anyPageTriggerMetasLink: (state, getters) => {
    const hasNextPaginationLinkAvailable =
      isNullOrUndefined(state.triggers.paginationLinks) || isNullOrUndefined(state.triggers.paginationLinks.page);

    return hasNextPaginationLinkAvailable
      ? `${getters.triggersApiBaseUrl}${triggersBasePathUrl}`
      : state.triggers.paginationLinks.page;
  },
  numberOfTriggersAvailable: (state) => {
    return isNullOrUndefined(state.triggers.triggerTotal) ? 0 : state.triggers.triggerTotal;
  },
  loadingTriggers: (state) => {
    return isNullOrUndefined(state.triggers.loadingTriggers) ? false : state.triggers.loadingTriggers;
  },
  createTriggerRulesInvalidCount: (state) => {
    const invalidCount = state.triggers.createOrEditTrigger.rules.filter((r) => {
      if (!r.metIfIsValid) {
        return true;
      }
      return r.conditions.some((c) => {
        return conditionIsInvalid(c);
      });
    });
    return invalidCount.length;
  },
  createTriggerRulesValid: (state) => {
    const invalidCount = state.triggers.createOrEditTrigger.rules.filter((r) => {
      if (!r.metIfIsValid) {
        return true;
      }
      return r.conditions.some((c) => {
        return conditionIsInvalid(c);
      });
    });
    return invalidCount.length === 0;
  },
  categoriesHaveBeenLoaded: (state) => {
    return (
      !isNullOrUndefined(state.triggers.availableConditions.categories) &&
      state.triggers.availableConditions.categories.length > 0
    );
  },
  conditionCategories: (state) => {
    return state.triggers.availableConditions.categories;
  },
  conditionCategory: (state) => (categoryId) => {
    return state.triggers.availableConditions.categories.find((c) => c.id === categoryId);
  },
  conditionTopicOptions: (state) => (categoryId) => {
    return state.triggers.availableConditions.topics[categoryId];
  },
  conditionTopicOptionsWithoutPlaceholders: (state) => (categoryId) => {
    return state.triggers.availableConditions.topics[categoryId]?.filter((option) => option.placeholder !== true);
  },
  conditionTopicOption: (state) => (categoryId, topicId) => {
    const topics = state.triggers.availableConditions.topics[categoryId];
    return topics?.find((t) => t.id === topicId);
  },
  conditionStatementOptions: (state) => (topicId) => {
    return state.triggers.availableConditions.statements[topicId];
  },
  conditionStatementOption: (state) => (topicId, statementId) => {
    const statements = state.triggers.availableConditions.statements[topicId];

    return statements.find((s) => s.id === statementId);
  },
  conditionStatementOptionValues: (state) => (statementId) => {
    return state.triggers.availableConditions.statementOptions[statementId];
  },
  conditionStatementDropdownOptions: (state) => (statementId) => {
    const statementOptions = state.triggers.availableConditions.statementOptions[statementId].options;

    return isNullOrUndefined(statementOptions) ? [] : statementOptions;
  },
  conditionStatementDropdownMeta: (state) => (statementId) => {
    return state.triggers.availableConditions.statementOptions[statementId].statement;
  },
  currentCreatedTriggerForm: (state) => {
    return state.triggers.createOrEditTrigger;
  },
  createTriggerStepOneValid: (state) => {
    return state.triggers.createOrEditTrigger.stepOneValid;
  },
  createTriggerStepTwoValid: (state, getters) => {
    return state.triggers.createOrEditTrigger.stepTwoMetIfValid && getters.createTriggerRulesValid;
  },
  createTriggerStepThreeValid: (state) => {
    return (
      !isNullOrUndefined(state.triggers.createOrEditTrigger.triggerStatus) &&
      state.triggers.createOrEditTrigger.triggerStatus !== createTriggerConditionPlaceholderId
    );
  },
  triggerFromId: (state) => (triggerId) => {
    return state.triggers.adminTriggers.find((at) => at.id === triggerId);
  },
  formattedTriggerRulesFromId: (state) => (triggerId) => {
    const trigger = state.triggers.adminTriggers.find((at) => at.id === triggerId);
    if (!isNullOrUndefined(trigger) && trigger.rules.length > 0) {
      return trigger.rules.map((r) => mapTriggerRules(r));
    }

    return [];
  },
  currentSelectedTrigger: (state) => {
    return state.triggers.selectedTrigger;
  },
  currentSelectedTriggerStatus: (state) => {
    return isNullOrUndefined(state.triggers.selectedTrigger) ? -1 : state.triggers.selectedTrigger.status;
  },
  currentSelectedTriggerRules: (state) => {
    return state.triggers.selectedTrigger.rules;
  },
  currentSelectedTriggerRule: (state) => (ruleId) => {
    return isNullOrUndefined(state.triggers.selectedTrigger.rules)
      ? null
      : state.triggers.selectedTrigger.rules.find((r) => r.id === ruleId);
  },
  currentSelectedTriggerRuleConditions: (_, getters) => (ruleId) => {
    const triggerRule = getters.currentSelectedTriggerRule(ruleId);

    if (isNullOrUndefined(triggerRule) || isNullOrUndefined(triggerRule.conditions)) {
      return null;
    }
    return triggerRule.conditions;
  },
  currentSelectedTriggerRuleCondition: (_, getters) => (ruleId, conditionId) => {
    const ruleConditions = getters.currentSelectedTriggerRuleConditions(ruleId);

    return isNullOrUndefined(ruleConditions) ? null : ruleConditions.find((c) => c.id === conditionId);
  },
  assignableTriggers: (state) => {
    return state.triggers.assignableTriggers;
  },
};
