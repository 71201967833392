export const AllMessages = {
  name: 'AllMessages',
  path: '/admin-message-center/main-view',
};

export const MessageCustomView = {
  name: 'MessagesView',
  path: '/admin-message-center/main-view/:viewType/',
};

export const MessageSubView = {
  name: 'MessageSubView',
  path: '/admin-message-center/main-view/:viewType/:subViewType',
};
