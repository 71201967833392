export const Home = {
  name: 'Home',
  path: '/',
};

export const About = {
  name: 'About',
  path: '/about',
};

export const Authenticated = {
  name: 'Authenticated',
  path: '/authenticated',
};

export const PageNotFound = {
  name: 'PageNotFound',
  path: '*',
};

export const FeatureSettings = {
  name: 'FeatureSettings',
  path: '/feature-settings',
};

export const HealthCheck = {
  name: 'HealthCheck',
  path: '/health',
};
