<template>
  <v-container class="px-4" fluid>
    <BackButton v-bind:destination="navigateBackTo" />
    <v-switch
      v-model="navbarEnabledFlag"
      v-bind:label="`Navbar: ${navbarEnabledFlag}`"
      v-on:change="setNavbarEnabled($event)"
    ></v-switch>
    <v-switch
      v-model="step4EnabledFlag"
      v-bind:label="`Create Circle Step 4: ${step4EnabledFlag}`"
      v-on:change="setStep4Enabled($event)"
    ></v-switch>
    <v-switch
      v-model="showSelectTriggersFlag"
      v-bind:label="`Create Circle Assign Triggers: ${showSelectTriggersFlag}`"
      v-on:change="setAssignTriggersToSocialCirclesEnabled($event)"
    ></v-switch>
    <div class="d-flex align-end container--pal-requests">
      <v-text-field
        label="Number of My Pals Notifications"
        class="textfield mr-4"
        v-model="mockNumberOfPalRequests"
        type="number"
        hide-details
        single-line
      ></v-text-field>
      <v-btn color="info" v-on:click="setMockNumberOfPalRequests(mockNumberOfPalRequests)">Update</v-btn>
    </div>

    <SignalRPoc></SignalRPoc>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BackButton from '@/modules/Common/components/BackButton';
import { SocialCommunities } from '@/modules/SocialCommunities/router/socialCommunitiesRoutesConstants';
import SignalRPoc from '@/modules/Common/components/SignalRPoc.vue';

export default {
  name: 'FeatureSettings',
  components: { BackButton, SignalRPoc },
  props: {
    navigateBackTo: {
      type: String,
      required: false,
      default: () => SocialCommunities.name,
    },
  },
  data() {
    return {
      navbarEnabledFlag: false,
      step4EnabledFlag: false,
      showSelectTriggersFlag: false,
      socialCommunities: SocialCommunities,
      mockNumberOfPalRequests: null,
    };
  },
  computed: mapGetters({
    navbarEnabled: 'common/navbarEnabledState',
    step4Enabled: 'common/step4EnabledState',
    showSelectTriggers: 'common/showSelectTriggers',
  }),
  methods: mapActions({
    setNavbarEnabled: 'common/setNavbarEnabled',
    setStep4Enabled: 'common/setStep4Enabled',
    setMockNumberOfPalRequests: 'common/setMockNumberOfPalRequests',
    setAssignTriggersToSocialCirclesEnabled: 'common/setAssignTriggersToSocialCirclesEnabled',
  }),
  mounted() {
    this.navbarEnabledFlag = this.navbarEnabled;
    this.step4EnabledFlag = this.step4Enabled;
    this.showSelectTriggersFlag = this.showSelectTriggers;
  },
};
</script>

<style scoped>
.textfield {
  width: 15rem;
}

.container--pal-requests {
  width: 25rem;
}
</style>
