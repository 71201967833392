<template>
  <div>
    <div
      class="d-flex align-center px-3 mb-2"
      v-bind:class="hasNotifications ? 'justify-space-between' : 'justify-end'"
    >
      <v-btn id="mark-all-as-read-btn" v-if="hasNotifications" v-on:click="markAllNotificationsAsRead()" text small>
        {{ markAllAsReadText }}
      </v-btn>
      <a id="settings-link" v-bind:href="this.settingsUrl">
        {{ settingsLinkText }}
      </a>
    </div>

    <Notification
      v-for="(notification, index) in notificationList"
      v-bind:notification="notification"
      v-bind:key="index"
      v-bind:class="notification"
    />

    <v-alert class="text-center font-weight-bold" color="grey--text text--darken-1" v-if="!hasNotifications">
      {{ noNewNotificationsText }}
    </v-alert>

    <div class="text-center px-4 mt-4">
      <v-btn id="view-all-notifications-btn" class="white--text" v-bind:href="viewAllNotificationsUrl" small block>
        {{ viewAllNotificationsText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Notification from '@/modules/Common/components/Notification';
import {
  settingsLinkText,
  buildMkLegacyUri,
  userProfileCommunicatonPreferences,
  communicationsNotificationsSection,
  viewAllNotificationsText,
  markAllAsReadText,
  noNewNotificationsText,
} from '@/modules/Common/utils';
export default {
  name: 'NotificationsList',
  components: { Notification },
  computed: {
    ...mapGetters({
      notificationList: 'common/notifications',
      originUriLegacy: 'common/originUriLegacy',
    }),
    settingsUrl: function() {
      return buildMkLegacyUri(this.originUriLegacy, userProfileCommunicatonPreferences);
    },
    viewAllNotificationsUrl: function() {
      return buildMkLegacyUri(this.originUriLegacy, communicationsNotificationsSection);
    },
    hasNotifications: function() {
      return this.notificationList.length !== 0;
    },
  },
  data() {
    return {
      markAllAsReadText: markAllAsReadText,
      noNewNotificationsText: noNewNotificationsText,
      settingsLinkText: settingsLinkText,
      viewAllNotificationsText: viewAllNotificationsText,
    };
  },
  methods: {
    ...mapActions({
      markAllNotificationsAsRead: 'common/markAllNotificationsAsRead',
    }),
  },
};
</script>

<style lang="scss" scoped>
#settings-link,
#mark-all-as-read-btn {
  color: #76949f;
  text-underline-offset: 0.25rem;
  text-decoration: underline;
}

#view-all-notifications-btn {
  background-color: #b3d89c;
  text-transform: none;
  font-weight: 400;
  font-size: 0.9rem;

  &:hover {
    background-color: #97ca77;
  }
}
</style>
