import { isNullOrUndefined } from '@/utils/helperFunctions';
import { snackbarCloseText, maxMessageCount } from '@/modules/Common/utils/constants';

export const commonMutations = {
  SET_STATE(state, payload) {
    state[payload.tokenName] = payload.tokenValue;
  },
  SET_NOTIFICATION(state, payload) {
    state.notificationSnackbar.open = true;
    state.notificationSnackbar.msg = payload.msg;
    state.notificationSnackbar.type = payload.type;
    state.notificationSnackbar.isClosable = Boolean(payload.isClosable).valueOf();
    state.notificationSnackbar.buttonText = isNullOrUndefined(payload.buttonText)
      ? snackbarCloseText
      : payload.buttonText;
    state.notificationSnackbar.onClose = isNullOrUndefined(payload.onClose) ? () => {} : payload.onClose;
  },
  CLOSE_NOTIFICATION(state) {
    state.notificationSnackbar.open = false;
    state.notificationSnackbar.msg = '';
    state.notificationSnackbar.type = null;
    state.notificationSnackbar.isClosable = false;
    state.notificationSnackbar.buttonText = snackbarCloseText;
    state.notificationSnackbar.onClose = () => {};
  },
  INCREMENT_CALLS_IN_FLIGHT(state) {
    state.callsInFlight += 1;
  },
  SET_LOADING_TRANSLUCENT(state, payload) {
    state.isLoadingTranslucent = isNullOrUndefined(payload) ? false : payload;
  },
  DECREMENT_CALLS_IN_FLIGHT(state) {
    state.callsInFlight = Math.max(0, state.callsInFlight - 1);
  },
  SET_SCROLL_Y_POSITION(state, payload) {
    state.scrollYPosition = Number(payload);
  },
  SET_NAVBAR_FEATURE_FLAG(state, payload) {
    state.features = {
      ...state.features,
      navbarEnabled: payload.navbarEnabled,
    };
  },
  SET_STEP_4_FEATURE_FLAG(state, payload) {
    state.features = {
      ...state.features,
      step4Enabled: payload.step4Enabled,
    };
  },
  SET_ASSIGN_TRIGGERS_TO_SOCIAL_CIRCLE_FLAG(state, payload) {
    state.features = {
      ...state.features,
      selectTriggersOnSocialCircle: payload,
    };
  },
  SET_MOCK_NUMBER_OF_PAL_REQUESTS(state, payload) {
    state.features = {
      ...state.features,
      mockNumberOfPalRequests: payload.mockNumberOfPalRequests,
    };
  },
  SET_ERROR_LOGGING(state, payload) {
    state.errorLogging = payload;
  },
  SET_BUILD_NUMBER(state, payload) {
    state.version.major = payload.major;
    state.version.build = payload.build;
  },
  SET_MODAL_CONFIRMATION_BUTTON_DISABLED(state, payload) {
    state.modal.confirmationButtonDisabled = payload;
  },
  SET_API_HEALTH(state, payload) {
    state.apiHealth = payload;
  },
  SET_SIDE_MENU_ENABLED(state, payload) {
    state.sideMenuEnabled = payload;
  },
  ADD_NOTIFICATION(state, payload) {
    state.notifications.unshift(payload);
    state.totalUnreadNotificationCount += 1;
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload.notifications;
    state.totalUnreadNotificationCount = payload.totalUnreadNotificationCount ?? 0;
  },
  ADD_MESSAGE(state, payload) {
    if (state.messages.length === maxMessageCount) {
      state.messages.pop();
    }

    state.messages.unshift(payload);
    state.totalUnreadMessagesCount++;
  },
  SET_MESSAGES(state, payload) {
    state.messages = payload.messages;
    state.totalUnreadMessagesCount = payload.totalUnreadMessagesCount ?? 0;
  },
  REMOVE_MESSAGE(state, payload) {
    state.messages = state.messages.filter((m) => !m.url.includes(payload));
    state.totalUnreadMessagesCount--;
    if (state.totalUnreadMessagesCount < 0) {
      state.totalUnreadMessagesCount = 0;
    }
  },
  SET_UNREAD_MESSAGE_COUNT(state, payload) {
    state.totalUnreadMessagesCount = payload;
  },
  REMOVE_NOTIFICATION(state, payload) {
    state.notifications = state.notifications.filter((n) => n.notificationId !== payload);
    state.totalUnreadNotificationCount--;
    if (state.totalUnreadNotificationCount < 0) {
      state.totalUnreadNotificationCount = 0;
    }
  },
  SET_OVERRIDE_IS_LOADING(state, payload) {
    state.overrideIsLoading = payload;
  },
  SET_CSRF_TOKEN(state, payload) {
    state.csrfToken = payload;
  },
  SET_ORIGIN_URI_LEGACY(state, payload) {
    console.log('originUriLegacy(mutations)=' + payload);
    state.originUriLegacy = payload;
  },
};
