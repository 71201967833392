import { isNullOrUndefined } from '@/utils/helperFunctions';
import {
  createTriggerConditionCategoryPlaceholderText,
  createTriggerConditionPlaceholderId,
  createTriggerConditionTopicPlaceholderText,
  createTriggerTopicVariablePlaceholderText,
  createTriggerPlaceholderCondition,
} from '@/modules/TriggersAdmin/utils';

const conditionExists = (state, ruleIndex, conditionIndex) => {
  const existingRule = state.triggers.createOrEditTrigger.rules[ruleIndex];
  return !isNullOrUndefined(existingRule) && !isNullOrUndefined(existingRule.conditions[conditionIndex]);
};

export const triggersAdminMutations = {
  SET_CREATE_TRIGGER_NAME(state, triggerName) {
    state.triggers.createOrEditTrigger.name = triggerName;
  },
  SET_CREATE_TRIGGER_DESCRIPTION(state, triggerDescription) {
    state.triggers.createOrEditTrigger.description = triggerDescription;
  },
  SET_CREATE_TRIGGER_MET_IF_CONDITION(state, hasMetIfCondition) {
    state.triggers.createOrEditTrigger.metIfCondition = hasMetIfCondition;
  },
  SET_CREATE_OR_EDIT_TRIGGER_SAVE_VALUE(state, triggerStatus) {
    state.triggers.createOrEditTrigger.triggerStatus = triggerStatus;
  },
  SET_TRIGGER_FILTER(state, triggerStatus) {
    state.triggers.filterId = !isNullOrUndefined(triggerStatus) ? Number(triggerStatus) : triggerStatus;
  },
  SET_TRIGGER_ORIGIN_TYPE_FILTER(state, originTypeId) {
    state.triggers.filterOriginTypeId = originTypeId;
  },
  SET_TRIGGER_SEARCH_TEXT(state, triggerSearchText) {
    state.triggers.searchText = triggerSearchText;
  },
  SET_TRIGGERS_QUERY_TOTAL(state, triggersQueryTotal) {
    state.triggers.queryTotal = triggersQueryTotal;
  },
  SET_CUSTOMTRIGGERS_COUNT(state, customTriggersCount) {
    state.triggers.customTriggersCount = customTriggersCount;
  },
  ADD_CREATE_TRIGGER_RULE(state) {
    const rules = state.triggers.createOrEditTrigger.rules;
    const newId = rules[rules.length - 1].tempId + 1;
    state.triggers.createOrEditTrigger.rules.push({
      tempId: newId,
      metIfCondition: 0,
      metIfIsValid: false,
      conditions: [{ ...createTriggerPlaceholderCondition }],
    });
  },
  POPULATE_STATE_FOR_EXISTING_TRIGGER(state, payload) {
    state.triggers.createOrEditTrigger.rules = [...payload];
  },
  DELETE_CREATE_TRIGGER_RULE(state, payload) {
    if (state.triggers.createOrEditTrigger.rules[payload]) {
      state.triggers.createOrEditTrigger.rules = state.triggers.createOrEditTrigger.rules.filter(
        (rule, index) => index !== payload,
      );
    }
  },
  ADD_CREATE_TRIGGER_RULE_CONDITION(state, payload) {
    const rules = state.triggers.createOrEditTrigger.rules;
    if (rules[payload]) {
      const newId = rules[payload].conditions[rules[payload].conditions.length - 1].tempId + 1;
      const placeholderCondition = { ...createTriggerPlaceholderCondition, tempId: newId };
      state.triggers.createOrEditTrigger.rules[payload].conditions.push(placeholderCondition);
    }
  },
  DELETE_CREATE_TRIGGER_RULE_CONDITION(state, payload) {
    if (conditionExists(state, payload.ruleIndex, payload.conditionIndex)) {
      state.triggers.createOrEditTrigger.rules[payload.ruleIndex].conditions = state.triggers.createOrEditTrigger.rules[
        payload.ruleIndex
      ].conditions.filter((condition, index) => index !== payload.conditionIndex);
    }
  },
  RESET_CREATE_TRIGGER_RULES(state) {
    state.triggers.createOrEditTrigger.rules = [
      {
        tempId: 0,
        metIfCondition: 0,
        metIfIsValid: false,
        conditions: [{ ...createTriggerPlaceholderCondition }],
      },
    ];
  },
  SET_CONDITION_VARIABLE_IS_VALID(state, payload) {
    if (conditionExists(state, payload.ruleIndex, payload.conditionIndex)) {
      state.triggers.createOrEditTrigger.rules[payload.ruleIndex].conditions[
        payload.conditionIndex
      ].variableValueIsValid = payload.isValid;

      // needed for Vuex to react to nested state change, for validation purposes
      state.triggers.createOrEditTrigger.rules[payload.ruleIndex].conditions = [
        ...state.triggers.createOrEditTrigger.rules[payload.ruleIndex].conditions,
      ];
    }
  },
  SET_TRIGGER_SORT_BY_OPTION(state, triggerSortByOption) {
    state.triggers.sortBy = !isNullOrUndefined(triggerSortByOption) ? Number(triggerSortByOption) : triggerSortByOption;
  },
  SET_RULE_MET_IF_CONDITION(state, payload) {
    if (state.triggers.createOrEditTrigger.rules[payload.ruleIndex]) {
      state.triggers.createOrEditTrigger.rules[payload.ruleIndex].metIfCondition = payload.metIfCondition;
    }
  },
  SET_RULE_MET_IF_VALID(state, payload) {
    if (state.triggers.createOrEditTrigger.rules[payload.ruleIndex]) {
      state.triggers.createOrEditTrigger.rules[payload.ruleIndex].metIfIsValid = payload.metIfIsValid;
    }
  },
  SET_CONDITION_CATEGORY(state, payload) {
    if (conditionExists(state, payload.ruleIndex, payload.conditionIndex)) {
      state.triggers.createOrEditTrigger.rules[payload.ruleIndex].conditions[payload.conditionIndex].category =
        payload.category;
    }
  },
  SET_CONDITION_TOPIC(state, payload) {
    if (conditionExists(state, payload.ruleIndex, payload.conditionIndex)) {
      let condition = state.triggers.createOrEditTrigger.rules[payload.ruleIndex].conditions[payload.conditionIndex];
      condition.selectedTopicId = payload.selectedTopicId;
      condition.dynamicTopicId = payload.dynamicTopicId;
      condition.topicId = payload.topicId;
    }
  },
  SET_CONDITION_VARIABLE(state, payload) {
    if (conditionExists(state, payload.ruleIndex, payload.conditionIndex)) {
      state.triggers.createOrEditTrigger.rules[payload.ruleIndex].conditions[payload.conditionIndex].variable =
        payload.variable;
    }
  },
  SET_CONDITION_VARIABLE_IS_STATEMENT(state, payload) {
    if (conditionExists(state, payload.ruleIndex, payload.conditionIndex)) {
      state.triggers.createOrEditTrigger.rules[payload.ruleIndex].conditions[
        payload.conditionIndex
      ].variableIsStatement = payload.variableIsStatement;
    }
  },
  SET_CONDITION_VARIABLE_VALUES(state, payload) {
    if (conditionExists(state, payload.ruleIndex, payload.conditionIndex)) {
      state.triggers.createOrEditTrigger.rules[payload.ruleIndex].conditions[payload.conditionIndex].variableValues =
        payload.variableValues;
    }
  },
  RESET_TRIGGER_FLASH(state) {
    state.triggers.adminTriggers = state.triggers.adminTriggers.map((t) => {
      return {
        ...t,
        flash: false,
      };
    });
  },
  FLASH_MOST_RECENT_TRIGGER(state) {
    if (!isNullOrUndefined(state.triggers.adminTriggers[0])) {
      state.triggers.adminTriggers[0].flash = true;
      state.triggers.adminTriggers = [...state.triggers.adminTriggers];
    }
  },
  SET_ADMIN_TRIGGERS(state, payload) {
    if (payload.overrideCurrentTriggers) {
      state.triggers.adminTriggers = payload.adminTriggers;
    } else {
      payload.adminTriggers.forEach((t) => {
        const existingTrigger = state.triggers.adminTriggers.find((t) => t.id === t.id);
        if (!isNullOrUndefined(existingTrigger)) {
          state.triggers.adminTriggers = state.triggers.adminTriggers.filter((at) => at.id !== t.id);
        }
        state.triggers.adminTriggers.push(t);
      });
    }
  },
  UPDATE_TRIGGER_STATUS(state, payload) {
    const existingTrigger = state.triggers.adminTriggers.find((at) => at.id === payload.id);

    if (!isNullOrUndefined(existingTrigger)) {
      existingTrigger.status = payload.status;
    }
  },
  UPDATE_TRIGGER_DATES(state, payload) {
    const existingTrigger = state.triggers.adminTriggers.find((at) => at.id === payload.id);

    if (!isNullOrUndefined(existingTrigger)) {
      existingTrigger.disabledDateUtc = payload.disabledDateUtc;
      existingTrigger.launchDateUtc = payload.launchDateUtc;
      existingTrigger.updatedDateUtc = payload.updatedDateUtc;
    }
  },
  UPDATE_TRIGGER_NAME_AND_DESCRIPTION(state, payload) {
    const existingTrigger = state.triggers.adminTriggers.find((at) => at.id === payload.id);

    if (!isNullOrUndefined(existingTrigger)) {
      existingTrigger.name = payload.name;
      existingTrigger.description = payload.description;
      existingTrigger.status = payload.status;
    }
  },
  UPDATE_TRIGGER(state, payload) {
    const existingTrigger = state.triggers.adminTriggers.find((at) => at.id === payload.id);
    if (!isNullOrUndefined(existingTrigger)) {
      state.triggers.adminTriggers = state.triggers.adminTriggers.filter((at) => at.id !== existingTrigger.id);
      state.triggers.adminTriggers = [payload, ...state.triggers.adminTriggers];
    }
  },
  REMOVE_ADMIN_TRIGGER(state, triggerId) {
    const existingTrigger = state.triggers.adminTriggers.find((at) => at.id === triggerId);
    if (!isNullOrUndefined(existingTrigger)) {
      state.triggers.adminTriggers = state.triggers.adminTriggers.filter((at) => at.id !== triggerId);
      state.triggers.queryTotal -= 1;
      state.triggers.triggerTotal -= 1;
    }
  },
  ADD_ADMIN_TRIGGER(state, trigger) {
    state.triggers.adminTriggers = [trigger, ...state.triggers.adminTriggers];
  },
  ADD_ADMIN_TRIGGERS(state, triggers) {
    const triggersToAdd = triggers.reduce((triggerAccumulator, trigger) => {
      const existingTrigger = state.triggers.adminTriggers.find((at) => at.id === trigger.id);

      // In the case of duplicating a trigger, filter out the duplicated trigger as it may be returned in the response while paging triggers
      if (isNullOrUndefined(existingTrigger)) {
        triggerAccumulator.push(trigger);
      }

      return triggerAccumulator;
    }, []);

    state.triggers.adminTriggers = [...state.triggers.adminTriggers, ...triggersToAdd];
  },
  SET_DUPLICATED_TRIGGER_NAME(state, triggerName) {
    state.triggers.duplicatedTriggerName = triggerName;
  },
  SET_RELOAD_TRIGGERS_CONDITIONS(state, triggerReloadConditions) {
    state.triggers.reloadTriggers.onFilter = isNullOrUndefined(triggerReloadConditions)
      ? true
      : triggerReloadConditions.onFilter;
    state.triggers.reloadTriggers.onSort = isNullOrUndefined(triggerReloadConditions)
      ? true
      : triggerReloadConditions.onSort;
    state.triggers.reloadTriggers.onSearch = isNullOrUndefined(triggerReloadConditions)
      ? true
      : triggerReloadConditions.onSearch;
  },
  SET_PAGINATION_LINKS(state, links) {
    state.triggers.paginationLinks = links;
  },
  SET_TRIGGER_TOTAL(state, triggerTotal) {
    state.triggers.triggerTotal = triggerTotal;
  },
  SET_LOADING_TRIGGERS(state, isLoading) {
    state.triggers.loadingTriggers = isLoading;
  },
  SET_CREATE_TRIGGER_STEP_TWO_MET_IF_VALID(state, isValid) {
    state.triggers.createOrEditTrigger.stepTwoMetIfValid = isValid;
  },
  SET_CREATE_TRIGGER_STEP_ONE_VALID(state, isValid) {
    state.triggers.createOrEditTrigger.stepOneValid = isValid;
  },
  RESET_CURRENT_TRIGGERS(state) {
    state.triggers.adminTriggers = [];
  },
  SET_AVAILABLE_CONDITION_CATEGORIES(state, categories) {
    const placeholderCategory = {
      id: createTriggerConditionPlaceholderId,
      description: createTriggerConditionCategoryPlaceholderText,
    };

    state.triggers.availableConditions.categories = [placeholderCategory, ...categories];
  },
  SET_AVAILABLE_CONDITION_TOPICS(state, { topics, categoryId }) {
    const placeholderTopic = {
      id: createTriggerConditionPlaceholderId,
      topicId: createTriggerConditionPlaceholderId,
      description: createTriggerConditionTopicPlaceholderText,
    };

    state.triggers.availableConditions.topics = {
      ...state.triggers.availableConditions.topics,
      [categoryId]: [placeholderTopic, ...topics],
    };
  },
  SET_AVAILABLE_CONDITION_STATEMENTS(state, { statements, topicId }) {
    const placeholderStatement = {
      id: createTriggerConditionPlaceholderId,
      description: createTriggerTopicVariablePlaceholderText,
    };

    state.triggers.availableConditions.statements = {
      ...state.triggers.availableConditions.statements,
      [topicId]: [placeholderStatement, ...statements],
    };
  },
  SET_TRIGGER_CONDITION_STATEMENT_OPTIONS(state, { statementOptions, statementId }) {
    state.triggers.availableConditions.statementOptions = {
      ...state.triggers.availableConditions.statementOptions,
      [statementId]: statementOptions,
    };
  },
  SET_CURRENT_SELECTED_TRIGGER(state, trigger) {
    state.triggers.selectedTrigger = trigger;
  },
  SET_CONDITION_SELECTED_TOPIC_ID(state, { triggerId, ruleId, conditionId, selectedTopicId }) {
    const existingTrigger = state.triggers.adminTriggers.find((at) => at.id === triggerId);
    if (!isNullOrUndefined(existingTrigger)) {
      let rule = existingTrigger.rules.find((r) => r.id === ruleId);
      let condition = rule.conditions.find((c) => c.id === conditionId);

      condition.selectedTopicId = selectedTopicId;
    }
  },
  SET_ASSIGNABLE_TRIGGERS(state, triggersForAssignment) {
    state.triggers.assignableTriggers = triggersForAssignment;
  },
};
