export const TangoStore = {
  name: 'TangoStoreFront',
  path: '/tango-store/store-front',
};
export const TangoCart = {
  name: 'TangoCart',
  path: '/tango-store/cart',
};
export const TangoCardRedemption = {
  name: 'TangoRedemption',
  path: '/tango-store/redemption/:cardId',
};
export const TangoOrderComplete = {
  name: 'TangoOrderComplete',
  path: '/tango-store/reciept',
};
export const TangoStoreClosed = {
  name: 'TangoStoreClosed',
  path: '/tango-store/store-closed',
};
