import mkNetworkRequestWrapperLegacy from '@/utils/requestWrapperLegacy';

import {
  adminMessageCenterBaseUrl,
  fetchMessageLogsEndpoint,
  deleteMessageEndpoint,
  getMessageDetailsEndpoint,
  saveMessageDetailsEndpoint,
  fetchMessageThreads,
  fetchMessageThread,
  changeReadStatusEndpoint,
  sendThreadReplyEndpoint,
  endScheduleMessageEndpoint,
} from '@/modules/AdminMessageCenter/utils/endpoints';

export const adminMessageCenterActions = {
  resetMessageData({ commit }) {
    commit('RESET_MESSAGE_DATA');
  },
  setSearchParams({ commit }, searchParams) {
    commit('SET_SEARCH_PARAMS', searchParams);
  },
  setMessageData({ commit }, messageData) {
    commit('SET_MESSAGE_DATA', messageData);
  },
  setMessageFilterOption({ commit }, filterOption) {
    commit('SET_MESSAGE_FILTER_OPTION', filterOption);
  },
  setThreadReplyData({ commit }, threadReply) {
    commit('SET_THREAD_REPLY', threadReply);
  },
  setSelectedCriteria({ commit }, criteria) {
    commit('SET_SELECTED_CRITERIA', criteria);
  },
  setSelectedCriteriaOptions({ commit }, options) {
    commit('SET_SELECTED_CRITERIA_OPTIONS', options);
  },
  resetSelectedCriteriaOptions({ commit }) {
    commit('SET_SELECTED_CRITERIA_OPTIONS', []);
  },
  async changeReadStatus({ commit, dispatch, rootGetters }, messageInfo) {
    try {
      const postData = {
        read: messageInfo.status,
      };
      const params = new URLSearchParams({});
      params.append('memberMessageId', messageInfo.messageId);
      params.append('memberContactId', messageInfo.memberId);

      const token = rootGetters['common/csrfToken'];
      const originUri = rootGetters['common/originUriLegacy'];
      const options = {
        url: `${originUri}/${adminMessageCenterBaseUrl}/${changeReadStatusEndpoint}?${params.toString()}`,
        method: 'POST',
        data: JSON.stringify(postData),
        headers: { 'X-CSRFToken': token },
      };
      await mkNetworkRequestWrapperLegacy(options);

      return true;
    } catch (e) {
      dispatch('common/displayErrorNotification', e, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async sendReplyToThread({ commit, dispatch, rootGetters, getters }, message) {
    try {
      const postData = {
        replyBody: getters.threadReply,
      };
      const params = new URLSearchParams({});
      params.append('memberMessageId', message.messageId);
      params.append('memberContactId', message.memberId);

      const token = rootGetters['common/csrfToken'];
      const originUri = rootGetters['common/originUriLegacy'];
      const options = {
        url: `${originUri}/${adminMessageCenterBaseUrl}/${sendThreadReplyEndpoint}?${params.toString()}`,
        method: 'POST',
        data: JSON.stringify(postData),
        headers: { 'X-CSRFToken': token },
      };

      await mkNetworkRequestWrapperLegacy(options);

      return true;
    } catch (e) {
      dispatch('common/displayErrorNotification', e, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async fetchMessageThreadDetails({ commit, dispatch, rootGetters }, messageInfo) {
    try {
      const params = new URLSearchParams({});
      params.append('memberMessageId', messageInfo.messageId);

      const originUri = rootGetters['common/originUriLegacy'];

      const options = {
        url: `${originUri}/${adminMessageCenterBaseUrl}/${fetchMessageThread}?${params.toString()}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapperLegacy(options);

      commit('SET_MESSAGE_THREAD_DATA', response.data);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async fetchMessageDetails({ commit, dispatch, rootGetters }, messageId) {
    try {
      const params = new URLSearchParams({});
      params.append('messageId', messageId);

      let originUri = rootGetters['common/originUriLegacy'];

      console.log(`${originUri}/${adminMessageCenterBaseUrl}/${fetchMessageThreads}?${params.toString()}`);
      const options = {
        url: `${originUri}/${adminMessageCenterBaseUrl}/${fetchMessageThreads}?${params.toString()}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapperLegacy(options);

      commit('SET_MESSAGE_THREADS_DATA', response.data);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async fetchMessages({ commit, dispatch, getters, rootGetters }) {
    commit('SET_SEARCH_IS_LOADING', true);
    try {
      const params = new URLSearchParams({});

      if (getters.searchParams.df) {
        params.append('df', getters.searchParams.df);
      }
      if (getters.searchParams.dt) {
        params.append('dt', getters.searchParams.dt);
      }
      params.append('tab', getters.searchParams.tab);
      params.append('page', getters.searchParams.page - 1);
      params.append('receivedMessageStatus', getters.searchParams.receivedMessageStatus);

      const originUri = rootGetters['common/originUriLegacy'];

      const options = {
        url: `${originUri}/${adminMessageCenterBaseUrl}/${fetchMessageLogsEndpoint}?${params.toString()}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapperLegacy(options);

      commit('SET_SEARCH_PARAMS', { page: response.data.pageNumber + 1 });

      commit('SET_SEARCH_RESULTS', response.data);

      commit('SET_SEARCH_IS_LOADING', false);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getMessageDetails({ commit, dispatch, rootGetters }, messageId) {
    try {
      commit('SET_SEARCH_IS_LOADING', true);

      const params = new URLSearchParams({});

      params.append('messageId', messageId);

      const originUri = rootGetters['common/originUriLegacy'];

      const options = {
        url: `${originUri}/${adminMessageCenterBaseUrl}/${getMessageDetailsEndpoint}?${params.toString()}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapperLegacy(options);

      commit('SET_MESSAGE_DATA', response.data);

      commit('SET_SEARCH_IS_LOADING', false);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async deleteMessage({ commit, dispatch, rootGetters }, messageId) {
    try {
      const token = rootGetters['common/csrfToken'];
      const originUri = rootGetters['common/originUriLegacy'];
      const options = {
        url: `${originUri}/${adminMessageCenterBaseUrl}/${deleteMessageEndpoint}?messageId=${messageId}`,
        method: 'POST',
        headers: { 'X-CSRFToken': token },
      };

      await mkNetworkRequestWrapperLegacy(options);

      dispatch('fetchMessages');

      return true;
    } catch (e) {
      dispatch('common/displayErrorNotification', e, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async saveMessage({ commit, dispatch, getters, rootGetters }) {
    try {
      const data = new FormData();

      getters.messageData.attachments.forEach((attachment, index) => {
        if (attachment.localUrl) data.append(`file${index}`, attachment.file);
      });
      dispatch('updateSelectedCriteria');
      data.append('messageData', JSON.stringify(getters.messageData));
      const token = rootGetters['common/csrfToken'];
      const originUri = rootGetters['common/originUriLegacy'];
      const options = {
        url: `${originUri}/${adminMessageCenterBaseUrl}/${saveMessageDetailsEndpoint}`,
        method: 'POST',
        data: data,
        headers: { 'X-CSRFToken': token },
      };

      return await mkNetworkRequestWrapperLegacy(options);
    } catch (e) {
      if (e.request) {
        return e.request;
      } else {
        dispatch('common/displayErrorNotification', e, { root: true });
      }
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  setMemberId({ commit }, memberId) {
    commit('SET_MEMBER_ID', memberId);
  },
  updateSelectedCriteria({ getters }) {
    getters.selectedCriteriaOptions.forEach((c) => {
      const criteriaToUpdate = getters.messageData.filterOptions.filterCriteria.find((filter) => filter.id === c.id);
      criteriaToUpdate.selected = true;
      criteriaToUpdate.options = c.options;
    });
    const removedItems = getters.messageData.filterOptions.filterCriteria.filter((filter) => filter.selected === true);
    removedItems.forEach((item) => {
      if (!getters.selectedCriteriaOptions.find((option) => item.id === option.id)) {
        item.selected = false;
      }
    });
  },
  async endSchedule({ commit, dispatch, rootGetters }, endScheduleInfo) {
    try {
      const token = rootGetters['common/csrfToken'];
      const originUri = rootGetters['common/originUriLegacy'];

      let runExpireDateQuery = endScheduleInfo.runExpireDate ? `&runExpireDate=${endScheduleInfo.runExpireDate}` : '';

      const options = {
        url: `${originUri}/${adminMessageCenterBaseUrl}/${endScheduleMessageEndpoint}?messageId=${endScheduleInfo.messageId}${runExpireDateQuery}`,
        method: 'POST',
        headers: { 'X-CSRFToken': token },
      };
      await mkNetworkRequestWrapperLegacy(options);
      return true;
    } catch (e) {
      dispatch('common/displayErrorNotification', e, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
