export const triggersAdminCreateTriggerButtonText = 'Create New Trigger';
export const triggersAdminCreateTriggerHeaderText = 'Create New Trigger';
export const createTriggerStepOneHeader = 'Trigger Details';
export const createTriggerStepOneDetailsHeader = 'Trigger Name and Description';
export const createTriggerStepThreeHeader = 'Launch:';
export const createTriggerStepThreeDetailsHeader = 'Launch or save your trigger:';
export const createTriggerStepTwoHeader = 'Rules';
export const createTriggerStepTwoTriggerMetTooltipText =
  'This specifies when the Trigger is considered valid.\nFor example, you can set the rule to be valid once all, at least one, at least two, or at least three of the added conditions are met.';
export const createTriggerStepTwoRuleMetTooltipText =
  'This specifies when the rule is considered valid.\nFor example, you can set the rule to be valid once all, at least one, at least two, or at least three of the added conditions are met.';
export const createTriggerStepTwoTriggerMetPreText = 'Trigger is met if';
export const createTriggerStepTwoTriggerMetPostText = 'of the following rules are met:';
export const createTriggerStepTwoRuleMetPreText = 'This rule is met if';
export const createTriggerStepTwoRuleMetPostText = 'of the following conditions are met:';
export const createTriggerStepTwoRuleNumberText = 'Rule';
export const createTriggerStepTwoConditionNumberText = 'Condition';
export const createOrEditTriggerBtnCancel = 'Cancel';
export const createTriggerBtnBack = 'Back';
export const createOrEditTriggerBtnDone = 'Done';
export const createTriggerBtnAddRule = 'Add a Rule';
export const createTriggerBtnAddCondition = 'Add a Condition';
export const createTriggerBtnNext = 'Next';
export const createTriggerBtnYes = 'Yes';
export const createTriggerCancelModalHeader = 'Cancel Trigger Creation';
export const createTriggerCancelModalText = 'Your progress will not be saved. Would you like to proceed?';
export const createTriggerRuleTitleText = 'Rule';
export const createTriggerConditionTitleText = 'Condition';
export const deleteTriggerModalHeader = 'Delete Trigger';
export const deleteTriggerModalText = 'Are you sure you want to delete this trigger?';
export const disableTriggerModalHeader = 'Disable';
export const disableTriggerModalText = 'Are you sure you want to disable this trigger?';
export const duplicateTriggerModalHeader = 'Duplicate Trigger';
export const duplicateTriggerModalText = 'What would you like to call the duplicated Trigger?';
export const duplicateTriggerModalConfirmationText = 'Your Trigger was duplicated as a draft.';
export const duplicateTriggerToastNotificationCloseButtonText = 'View on dashboard';
export const disableTriggerConfirmationText = 'is now disabled.';
export const deleteTriggerNotAllowedText = 'You can only delete triggers which have never been launched.';
export const deleteTriggerConfirmationText = 'has been removed from your library.';
export const moveToReadyForLaunchTriggerModalHeader = 'Move Trigger to Ready for Launch';
export const moveToReadyForLaunchTriggerModalText =
  'Are you sure you want to move this trigger to Ready for Launch status?';
export const moveToReadyForLaunchTriggerConfirmationText = 'has been moved to ready for launch.';
export const moveToLaunchedTriggerConfirmationText = 'has been launched!';
export const triggerFormFieldTriggerNamePlaceholder = 'Trigger name';
export const triggerFormFieldTriggerDescriptionPlaceholder = 'Trigger description';
export const triggersAdminIntroText =
  'Triggers allow you to create rules and conditions to target specific sets of users. You can set up triggers based on different categories to create personalized user experiences across your portal.';
export const triggersAdminDashboardHeaderText = 'Triggers Dashboard';
export const noTriggersText = 'There are no triggers which fit the search criteria.';
export const noDraftTriggersText = 'No triggers have are in draft yet.';
export const noReadyForLaunchTriggersText = 'No triggers are ready for launch.';
export const noScheduledForLaunchTriggersText = 'No triggers have been scheduled for launch.';
export const noLaunchedTriggersText = 'No triggers have been launched.';
export const noDisabledTriggersText = 'No triggers have been disabled';
export const triggerTileRulesTextPlural = 'Rules';
export const triggerTileRulesTextSingular = 'Rule';
export const triggerActionsButtonText = 'Actions';
export const triggersAdminBrowseTriggerButtonText = 'Browse Trigger Library';
export const triggersAdminSearchTriggerInputText = 'Search';
export const triggerTileMenuItemView = 'View';
export const triggerTileMenuItemDelete = 'Delete';
export const triggerTileMenuItemDraft = 'Move to Draft';
export const triggerTileMenuItemLaunch = 'Launch';
export const triggerTileMenuItemMoveToReadyForLaunch = 'Move to Ready for Launch';
export const triggerTileMenuItemDisable = 'Disable';
export const triggerTileMenuItemDuplicate = 'Duplicate';
export const triggerLastEditedText = 'Last edited on';
export const triggerLaunchedOnText = 'Launched on';
export const triggerDisabledOnText = 'Disabled on';
export const triggerCreatedOnText = 'Created on';
export const triggerSearchResultTextSingular = 'Result';
export const triggerSearchResultTextPlural = 'Results';
export const atLeastRuleJoinText = 'AND / OR';
export const allRuleJoinText = 'AND';
export const createTriggerDeleteRuleHeader = 'Delete Rule?';
export const createTriggerDeleteRuleText = 'This action cannot be undone.';
export const createTriggerDeleteConditionHeader = 'Delete Condition?';
export const createTriggerDeleteConditionText = 'This action cannot be undone.';
export const atLeastConditionJoinText = 'AND / OR';
export const allConditionJoinText = 'AND';
export const createTriggerConditionCategoryText = 'Category';
export const createTriggerConditionTopicText = 'Topic';
export const createTriggerConditionVariableText = 'Conditional Statement';
export const createTriggerConditionCategoryPlaceholderText = 'Select a category';
export const createTriggerConditionTopicPlaceholderText = 'Select a topic';
export const createTriggerTopicVariablePlaceholderText = 'Select a variable';
export const viewMoreTriggersText = 'View More';
export const conditionalStatementVariableValuesJoinText = 'and';
export const conditionalStatementVariableMultipleValuesText = 'the values';
export const conditionalStatementVariableDateRangeText = 'Select a date range';
export const conditionalStatementVariablePercentageIndicator = '%';
export const createTriggerInvalidConditionsText =
  'Please make sure all conditions are completed before you add another rule.';
export const conditionalStatementVariableDateRangeDelimiter = ' - ';
export const viewTriggerModalHeadline = 'View Trigger';
export const createTriggerConditionPreVerbiage = 'is';
export const viewTriggerConditionEqualTo = 'equal to';
export const createTriggerConditionPostVerbiage = 'the value of';
export const createTriggerConditionWithDatePlaceholderVerbiage = 'Select a Date';
export const createTriggerConditionWithValuePlaceholderVerbiage = 'Enter a number';
export const createTriggerConditionWithTextValuePlaceholderVerbiage = 'Enter text';
export const createTriggerRuleInvalidMetIfValidConditionText =
  'You currently do not have enough conditions added to satisfy the above selection. Please add additional conditions or update the selection.';
export const createTriggerRuleInvalidMetIfInvalidConditionText =
  'You currently do not have enough conditions added to satisfy the above selection. Please complete and add additional conditions to finalize the rule.';
export const triggersAdminEditTriggerHeaderText = 'Edit Trigger';
export const createTriggerRuleInvalidMetIfInvalidRuleText =
  'You currently do not have enough rules added to satisfy the above selection. Please complete and add additional rules to finalize the trigger.';
export const createTriggerRuleInvalidMetIfValidRuleText =
  'You currently do not have enough rules added to satisfy the above selection. Please add additional rules or update the selection.';
export const viewTriggerMetIfPreText = 'Trigger is met if';
export const viewTriggerMetIfPostText = 'of the following rules are met:';
export const viewTriggerRuleMetIfPreText = 'This rule is met if';
export const viewTriggerRuleMetIfPostText = 'of the following conditions are met:';
export const triggerSuccessfullyCreatedToastText = 'Your trigger has been successfully created.';
export const triggerSuccessfullySavedToastText = 'Saved successfully!';
export const triggerFailureToCreateToastText = 'An error occurred while attempting to create your trigger.';
export const triggerCustomDevelopedText = 'Custom Developed';
export const viewTriggerEditButtonText = 'Edit';
export const editTriggerSubtitleText =
  'Note: Once your trigger is launched, you are not able to edit the rules & conditions.';
export const triggersAdminTriggerLibraryHeaderText = 'Trigger Template Library';
export const triggersAdminTriggerLibraryHelpText =
  'Easily preview and add any of these pre-made triggers to your dashboard!\nYou can edit the triggers once they are added to your dashboard.';
export const triggersAdminEditTriggerCancelModalTitle = 'Discard Changes?';
export const triggersAdminEditTriggerDiscardChanges = 'Discard Changes';
export const triggerAdminEditTriggerCancelModalBody = 'None of your changes have been saved. Do you want to proceed?';
export const editTriggerSave = 'Save';
export const editTriggerStatusTitleText = 'Status:';
export const editTriggerStatusSubtitleText = 'Update your trigger status:';
export const editTriggerDetailsHeader = 'Trigger Details:';
export const triggersAdminTriggerNeverMetText = 'This trigger has never been met.';
export const triggersAdminTriggerMetText = 'Last triggered:';
export const noCustomTriggersText = 'No custom developed triggers found.';
export const noAdminGeneratedTriggersText = 'No admin created triggers found.';
