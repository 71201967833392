import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { addChildGroups, menuManagementBaseUrl, updateGroupTreeNodes } from '@/modules/AdminMenuManagement/utils';
import { isNullOrUndefined } from '@/utils/helperFunctions';

export const menuGroupActions = {
  revertSelectedGroups({ commit, dispatch, state }) {
    commit('SET_MENU_MANAGEMENT_SELECTED_GROUPS', [...state.createNewMenu.previouslySelectedGroups]);

    dispatch('updateGroupTree');
  },
  setExcludedGroups({ commit }, excludedGroups) {
    commit('SET_EXCLUDED_GROUPS', excludedGroups);
  },
  setSelectedGroups({ commit, dispatch }, selectedGroups) {
    commit('SET_MENU_MANAGEMENT_SELECTED_GROUPS', selectedGroups);
    commit('SET_MENU_MANAGEMENT_PREVIOUSLY_SELECTED_GROUPS', selectedGroups);

    dispatch('updateGroupTree');
  },
  updateGroupTree({ commit, getters }) {
    const availableGroups = getters.availableGroups;
    updateGroupTreeNodes(availableGroups, getters.selectedGroups);

    commit('SET_AVAILABLE_GROUPS', availableGroups);
  },
  async getAvailableGroups({ commit, getters, dispatch }, parentGroupId) {
    try {
      let url = `${getters.adminMenuManagementApiBaseUrl}${menuManagementBaseUrl}/groups`;

      if (!isNullOrUndefined(parentGroupId)) {
        url += `/${parentGroupId}`;
      }

      const getAvailableGroupsResponse = await mkNetworkRequestWrapper({
        url,
        method: 'GET',
      });
      const groups = getAvailableGroupsResponse.data.data.groups;
      const parsedGroups = groups.map((group) => {
        return {
          ...group,
          disabled: getters.isEditMode ? true : getters.selectedGroups.length !== 0,
          children: group.hasChildren ? [] : undefined,
          selected: true,
          checked: true,
        };
      });

      let groupTree = [...getters.availableGroups];

      if (groupTree.length === 0) {
        groupTree = parsedGroups;
      } else {
        addChildGroups(groupTree, parsedGroups, parentGroupId);
      }

      commit('SET_AVAILABLE_GROUPS', groupTree);

      const selectedParentGroup = getters.selectedGroups.find((g) => g.groupId === parentGroupId);
      if (selectedParentGroup) {
        let selectedChildGroups = [...groups];
        if (getters.isEditMode) {
          selectedChildGroups = selectedChildGroups.filter((g) => g.menuId && g.menuId === getters.getSelectedMenu.id);
        }

        // exclude children that were excluded from overwrite dialog
        selectedChildGroups = selectedChildGroups.filter(
          (g) => !getters.getExcludedGroups.map((owg) => owg.groupId).includes(g.groupId),
        );

        dispatch('setSelectedGroups', [...getters.selectedGroups, ...selectedChildGroups]);
      }
    } catch (e) {
      dispatch('common/displayErrorNotification', e, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadInitialAvailableGroups({ getters, commit }, { groupIds }) {
    commit('SET_EDIT_GROUPS_LOADING', true);

    let groupTree = [];
    for (let idx = 0; idx < groupIds?.length; idx++) {
      let url = `${getters.adminMenuManagementApiBaseUrl}${menuManagementBaseUrl}/groups`;
      if (idx !== 0) {
        url += `/${groupIds[idx - 1]}`;
      }
      const getAvailableGroupsResponse = await mkNetworkRequestWrapper({
        url,
        method: 'GET',
      });
      const groups = getAvailableGroupsResponse.data.data.groups;

      const parsedGroups = groups.map((group) => {
        return {
          ...group,
          disabled: true,
          name: group.groupName + ' (' + group.menuName + ')',
          children: group.hasChildren ? [] : undefined,
        };
      });
      if (groups?.length === 1 && groupTree?.length === 0) {
        groupTree = parsedGroups;
      } else {
        if (idx === 0) {
          // when there isn't a single root group we just add the list of groups to the tree
          groupTree = parsedGroups;
        } else {
          addChildGroups(groupTree, parsedGroups, groupIds[idx - 1]);
        }
      }
    }

    commit('SET_AVAILABLE_GROUPS', groupTree);
    commit('SET_EDIT_GROUPS_LOADING', false);
  },
  async loadOverwritableMenuGroups({ commit, getters }, parentGroupId) {
    try {
      const options = {
        url: `${getters.adminMenuManagementApiBaseUrl}${menuManagementBaseUrl}/overwritable-menu-groups/${parentGroupId}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapper(options);
      const responseData = response.data.data;

      return responseData.groups;
    } catch (e) {
      return [];
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadSelectedGroupHierarchy({ commit, getters }) {
    try {
      const options = {
        url: `${getters.adminMenuManagementApiBaseUrl}${menuManagementBaseUrl}/group-hierarchy/${getters.getSelectedGroup.groupId}`,
        method: 'POST',
        data: {
          excludedGroupIds: getters.getExcludedGroups.map((g) => g.groupId),
        },
      };
      const response = await mkNetworkRequestWrapper(options);
      const responseData = response.data.data;

      commit(
        'SET_ASSIGNED_GROUP_IDS',
        responseData.assignedGroups.map((g) => g.groupId),
      );
      commit('SET_AVIDON_ENABLED', responseData.isAvidonEnabledForGroupSelection);
    } catch (e) {
      return [];
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  showGroupTree({ commit }, show) {
    commit('SET_SHOW_GROUP_TREE', show);
  },
  resetGroupSelection({ commit, dispatch }) {
    dispatch('setSelectedGroups', []);
    commit('SET_EXCLUDED_GROUPS', []);
    commit('SET_ASSIGNED_GROUP_IDS', []);
  },
};
