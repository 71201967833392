<template>
  <div id="app">
    <v-app>
      <LoadingScreen />
      <Modal ref="modal" />

      <Sidebar ref="sidebar" />
      <Snackbar />
      <div class="content" v-bind:class="leftMenuOpen">
        <Navbar v-if="navbarEnabled" />
        <router-view v-if="OriginUri" />
      </div>
    </v-app>
  </div>
</template>

<script>
import LoadingScreen from '@/modules/Common/components/LoadingScreen';
import Snackbar from '@/modules/Common/components/Snackbar';
import Modal from '@/modules/Common/components/Modal';
import Sidebar from '@/modules/Common/components/Sidebar';
import Navbar from '@/modules/Common/components/Navbar';
import { signalRMethods } from '@/utils/constants';
import { getOriginUriLegacy } from '@/utils/legacyInterop';
import { mapActions, mapGetters } from 'vuex';
import { isStringNullUndefinedOrEmpty, isAdminRoute, simpleThrottle } from '@/utils/helperFunctions';
import { createHubConnection } from '@/modules/Common/utils/signalRClient';
import { transperfectKonfigOption } from '@/modules/PlatformOptions/utils';
export default {
  name: 'App',
  components: {
    LoadingScreen,
    Snackbar,
    Modal,
    Sidebar,
    Navbar,
  },
  data() {
    return {
      transperfectKonfigOption,
      isScriptAlreadyLoaded: false,
      OriginUri: null,
    };
  },
  computed: {
    ...mapGetters({
      navbarEnabled: 'common/navbarEnabledState',
      isProduction: 'common/isProduction',
      bearerToken: 'authentication/accessTokenFromLegacy',
      booleanOptionValue: 'platformOptions/booleanOptionValue',
      currentUserLangPref: 'users/currentUserLangPref',
    }),
    leftMenuOpen: function() {
      return this.$store.state.common.leftMenuOpen ? 'menu-open' : '';
    },
    transperfectEnabled: function() {
      return this.booleanOptionValue(this.transperfectKonfigOption);
    },
  },
  methods: {
    ...mapActions({
      isLoading: 'common/isLoading',
      getCurrentUserInfo: 'users/getCurrentUserInfo',
      getKonfigOptions: 'platformOptions/getKonfigOptionsForUser',
      setVersion: 'common/setVersion',
      addNotification: 'common/addNotification',
      getUnreadNotifications: 'common/getUnreadNotifications',
      getUnreadMessages: 'common/getUnreadMessages',
      clearNotifications: 'common/clearNotifications',
      addUnreadMessage: 'common/addUnreadMessage',
      markMessageAsRead: 'common/markMessageAsRead',
      refreshMessages: 'common/refreshMessages',
      refreshNotifications: 'common/refreshNotifications',
      setCsrfToken: 'common/setCsrfToken',
      setOriginUriLegacy: 'common/setOriginUriLegacy',
    }),
    loadTransPerfectScript: function() {
      const transperfectElement = document.querySelector('#transperfect');
      if (transperfectElement) {
        this.isScriptAlreadyLoaded = true;
      } else if (this.transperfectEnabled && !this.isScriptAlreadyLoaded) {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://www.onelink-edge.com/moxie.min.js');
        script.setAttribute('type', 'application/javascript');
        script.setAttribute('data-oljs', 'P9E03-5864-2B3B-3D34');
        script.setAttribute('referrerpolicy', 'no-referrer-when-downgrade');
        script.id = 'transperfect';
        script.onload = () => {
          this.isScriptAlreadyLoaded = true;
        };
        script.onerror = (error) => {
          console.error('Error loading Transperfect scripts:', error);
        };
        document.body.appendChild(script);
      }
    },
    removeGlobalActivityEvents: function() {
      document.removeEventListener('click', this.handleUserActivity);
      document.removeEventListener('scroll', this.handleUserActivity);
      document.removeEventListener('mkApiRequest', this.handleUserActivity);
      document.removeEventListener('mkLegacyApiRequest', this.handleUserActivity);
    },
    addGlobalActivityEvents: function() {
      document.addEventListener('click', this.handleUserActivity, { capture: true, passive: true });
      document.addEventListener('scroll', this.handleUserActivity, { capture: true, passive: true });
      document.addEventListener('mkApiRequest', this.handleUserActivity, { capture: true, passive: true });
      document.addEventListener('mkLegacyApiRequest', this.handleUserActivity, { capture: true, passive: true });
    },
    handleUserActivity: simpleThrottle((e) => {
      parent.postMessage({ messageType: 'handleUserActivity', eventType: e.type }, '*');
    }, 1000),
  },
  async created() {
    // set location of callback uri to legacy app
    const legacyUri = await getOriginUriLegacy();
    console.log('legacyUri=', legacyUri);

    this.setOriginUriLegacy(legacyUri);
    this.OriginUri = legacyUri;
  },
  async mounted() {
    this.isLoading({ isLoadingTranslucent: false }, { root: true });

    window.addEventListener('message', (e) => {
      if (e.data && e.data.messageType === 'CsrfToken') {
        this.setCsrfToken(e.data.csrfToken);
      }
    });

    // Get token from parent
    parent.postMessage({ messageType: 'getCsrfToken' }, '*');

    // add modal to $root object for global access
    this.$root.$modal = this.$refs.modal;
    this.$root.$sidebar = this.$refs.sidebar;
    // Retrieve the current user's konfig options
    this.isLoading({ isLoadingTranslucent: false }, { root: true });
    await this.getKonfigOptions();

    // don't load google translate for an admin route
    if (!isAdminRoute(document.location.pathname)) {
      // fetch user info for validation
      await this.getCurrentUserInfo(true, { root: true });

      console.log('UserInfo set. Set Cookies');
      //clean all  cookie
      document.cookie = 'langPref=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; ';
      document.cookie =
        'langPref=; max-age=0; path=/; domain=.' +
        window.location.hostname.substring(window.location.hostname.indexOf('.') + 1);

      if (this.transperfectEnabled) {
        if (this.currentUserLangPref) {
          this.loadTransPerfectScript();
        }
        if (this.currentUserLangPref) {
          document.cookie = 'langPref=' + this.currentUserLangPref + '; path=/';
        } else {
          document.cookie = 'langPref=; max-age=0; path=/;';
        }
      }
    } else {
      // fetch user info for validation
      await this.getCurrentUserInfo(true, { root: true });
      console.log('UserInfo set for Admin Route');
    }

    // fetch unread notifications
    //await this.getUnreadNotifications();
    // fetch user messages
    //await this.getUnreadMessages();
    //connect to signalR for notifications
    const signalRConnection = await createHubConnection(this.bearerToken);
    if (signalRConnection) {
      signalRConnection.on(
        signalRMethods.showNotification,
        (message, icon, url, jsCallbackMethod, jsCallbackMethodArgs, notificationId, isGroupNotification) => {
          const notificationPayload = {
            notificationId: notificationId,
            isGroupNotification: isGroupNotification,
            message: message,
            icon: icon,
            url: url,
            jsCallbackMethod: jsCallbackMethod,
            jsCallbackMethodArgs: jsCallbackMethodArgs,
          };

          this.addNotification(notificationPayload);
        },
      );
      signalRConnection.on(signalRMethods.clearNotifications, () => {
        this.clearNotifications();
      });
      signalRConnection.on(signalRMethods.messageSent, (message) => {
        this.addUnreadMessage(message);
      });
      signalRConnection.on(signalRMethods.messageRead, (messageId) => {
        this.markMessageAsRead(messageId);
      });
      signalRConnection.on(signalRMethods.refreshMessages, (messagesResponse) => {
        this.refreshMessages(messagesResponse);
      });
      signalRConnection.on(signalRMethods.refreshNotifications, (notificationsResponse) => {
        this.refreshNotifications(notificationsResponse);
      });
      signalRConnection.start().catch(console.error);
    }

    if (!this.isProduction) {
      this.setVersion({
        major: isStringNullUndefinedOrEmpty(process.env.MAJOR_VERSION) ? '' : process.env.MAJOR_VERSION,
        build: isStringNullUndefinedOrEmpty(process.env.BUILD_VERSION) ? '' : process.env.BUILD_VERSION,
      });
    }

    this.addGlobalActivityEvents();
  },
  beforeDestroy() {
    this.removeGlobalActivityEvents();
  },
};
</script>
<style lang="scss">
@import '@/assets/css/global.scss';

#app {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: auto;
  background: $background;

  .content {
    @include transition-ease-in-out;
    min-height: 100vh;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: $background;

    &.menu-open {
      left: 16rem;
    }
  }
}
.hide {
  display: none;
}
div.skiptranslate {
  display: none !important;
}
body {
  top: 0 !important;
}
</style>
