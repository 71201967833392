import {
  TangoStore,
  TangoCardRedemption,
  TangoCart,
  TangoOrderComplete,
  TangoStoreClosed,
} from '@/modules/TangoStore/router/tangoStoreRoutesConstants';

const tangoStoreRoutes = [
  {
    path: TangoStoreClosed.path,
    name: TangoStoreClosed.name,
    PROPS: true,
    component: () => import('@/modules/TangoStore/components/StoreFrontClosed'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: TangoStore.path,
    name: TangoStore.name,
    PROPS: true,
    component: () => import('@/modules/TangoStore/components/StoreFront'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: TangoCart.path,
    name: TangoCart.name,
    PROPS: true,
    component: () => import('@/modules/TangoStore/components/Cart'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: TangoCardRedemption.path,
    name: TangoCardRedemption.name,
    component: () => import('@/modules/TangoStore/components/CardRedemption'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: TangoOrderComplete.path,
    name: TangoOrderComplete.name,
    component: () => import('@/modules/TangoStore/components/OrderPage'),
    meta: {
      requiresAuth: true,
    },
  },
];

export default tangoStoreRoutes;
