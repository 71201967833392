import {
  AllMessages,
  MessageCustomView,
  MessageSubView,
} from '@/modules/AdminMessageCenter/router/adminMessageCenterRoutesConstants';

const adminMessageCenterRoutes = [
  {
    path: AllMessages.path,
    name: AllMessages.name,
    component: () => import('@/modules/AdminMessageCenter/components/MainView'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: MessageCustomView.path,
    name: MessageCustomView.name,
    component: () => import('@/modules/AdminMessageCenter/components/MainView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: MessageSubView.path,
    name: MessageSubView.name,
    component: () => import('@/modules/AdminMessageCenter/components/MainView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];

export default adminMessageCenterRoutes;
