import {
  findAndRemoveMenuItem,
  menuItemComponentTypes,
  menuManagementBaseUrl,
  nameNotUniqueErrorCode,
  initialMenuItemResource,
  menuItemTypes,
  menuItemResourceTypes,
  menuItemSources,
  menuItemErrorCodes,
} from '@/modules/AdminMenuManagement/utils';
import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { isNullOrUndefined } from '@/utils/helperFunctions';

export const menuItemActions = {
  setMenuItemFormDirty({ commit }, isDirty) {
    commit('SET_MENU_ITEM_FORM_DIRTY', isDirty);
  },
  setSelectedMenuItem({ commit }, payload) {
    commit('SET_SELECTED_MENU_ITEM', payload);
  },
  addMenuItem({ commit, getters }, payload) {
    if (payload && payload.fixed) {
      payload.children = [];
      payload.id = getters.lastUsedMenuItemId + 1;

      commit('ADD_MENU_ITEM', payload);
      commit('SET_LAST_USED_MENU_ITEM_ID', payload.id);
      return;
    }

    const menuItem = {
      ...payload,
      children: [],
      icon: '',
      id: getters.lastUsedMenuItemId + 1,
      menuItemType: '',
      isParentMenuItem: false,
      isChildMenuItem: false,
      isSingleMenuItem: false,
      menuItemResource: { ...initialMenuItemResource },
      name: '',
      selectable: true,
      parentMenuItemId: null,
      draft: true,
    };
    commit('ADD_MENU_ITEM', { ...menuItem });
    commit('SET_LAST_USED_MENU_ITEM_ID', menuItem.id);
    commit('SET_SELECTED_MENU_ITEM', menuItem);
  },
  resetMenuItemErrorCode({ commit, getters }) {
    commit('SET_MENU_ITEM_ERROR_CODE', { selectedMenuItem: getters.selectedMenuItem, errorCode: null });
  },
  resetMenuItems({ commit, dispatch }) {
    commit('UPDATE_MENU_TREE', []);
    dispatch('setMenuItems');
  },
  setMenuItems({ commit, getters, rootGetters, dispatch }) {
    // TODO: move to separate map function to reduce duplicate code
    const menuItems = getters.getSelectedMenu.menuItems;
    const mappedMenuItems = menuItems.map((item) => {
      let triggersList = [];
      //checking to make sure trigger status is not disabled. This should probably be moved to backend
      if (item.triggers.length > 0) {
        item.triggers.forEach(async (trigger) => {
          await dispatch('triggersAdmin/loadTrigger', trigger.triggerId, { root: true });
          if (rootGetters['triggersAdmin/triggerFromId'](trigger.triggerId).status === 3) {
            triggersList.push(trigger);
          }
        });
      }
      const menuItem = {
        // Child Menu Items
        children: item.childMenuItems.map((childItem) => {
          let childMenuItem = childItem;
          let childTriggerList = [];
          //checking to make sure trigger status is not disabled. This should probably be moved to backend
          if (childItem.triggers.length > 0) {
            childItem.triggers.forEach(async (trigger) => {
              await dispatch('triggersAdmin/loadTrigger', trigger.triggerId, { root: true });
              if (rootGetters['triggersAdmin/triggerFromId'](trigger.triggerId).status === 3) {
                childTriggerList.push(trigger);
              }
            });
          }
          childMenuItem.triggers = childTriggerList;
          if (childItem.menuItemDetails.menuItemResourceType === menuItemResourceTypes.standardComponent) {
            const standardComponent = getters.standardComponents.find((component) => {
              return component.id === childMenuItem.menuItemDetails?.menuItemResourceId?.toString();
            });

            if (standardComponent) {
              childMenuItem.menuItemDetails.standardComponent = { ...standardComponent };

              if (
                standardComponent.componentType === menuItemComponentTypes.survey ||
                standardComponent.componentType === menuItemComponentTypes.tile
              ) {
                childMenuItem.menuItemDetails.componentId = parseInt(childItem.menuItemDetails.componentId);
              } else {
                childMenuItem.menuItemDetails.componentId = childItem.menuItemDetails.componentId;
              }

              if (standardComponent.source === menuItemSources.predefined) {
                commit('SET_PREDEFINED_ITEMS', standardComponent.items);
              }
            } else {
              childMenuItem.menuItemResource = {
                ...initialMenuItemResource,
                menuItemResourceType: childMenuItem.menuItemDetails?.menuItemResourceType,
              };
            }
          } else if (childItem.menuItemDetails.menuItemResourceType === menuItemResourceTypes.thirdPartyLink) {
            const thirdPartyLink = getters.thirdPartyLinks.find((link) => {
              return link.id === childMenuItem.menuItemDetails?.menuItemResourceId?.toString();
            });

            childMenuItem.menuItemDetails.thirdPartyLink = { ...thirdPartyLink };
            childMenuItem.menuItemDetails.componentId = childItem.menuItemDetails.componentId;
          }

          return {
            ...childMenuItem,
            isChildMenuItem: true,
            id: childMenuItem.menuItemId,
            selectable: true,
            menuItemResource: { ...childMenuItem.menuItemDetails },
            errorCode: childMenuItem.hasError ? 'DISABLE_DELETED_LINKED_ITEM' : null,
            draft: false,
          };
        }),
        icon: item.icon,
        isChildMenuItem: item.menuItemType === menuItemTypes.childMenuItem ?? false,
        isParentMenuItem: item.menuItemType === menuItemTypes.parentMenuItem ?? false,
        isSingleMenuItem: item.menuItemType === menuItemTypes.singleMenuItem ?? false,
        id: item.menuItemId,
        menuItemResource: { ...item.menuItemDetails },
        menuItemType: item.menuItemType,
        name: item.name,
        selectable: true,
        parentMenuItemId: item.parentMenuItemId ?? null,
        errorCode: item.hasError ? 'DISABLE_DELETED_LINKED_ITEM' : null,
        draft: false,
        triggers: triggersList,
      };

      // Root Level Menu Items
      if (item.menuItemDetails.menuItemResourceType === menuItemResourceTypes.standardComponent) {
        const standardComponent = getters.standardComponents.find((component) => {
          return component.id === item.menuItemDetails?.menuItemResourceId?.toString();
        });

        if (standardComponent) {
          menuItem.menuItemResource.standardComponent = { ...standardComponent };

          if (
            standardComponent.componentType === menuItemComponentTypes.survey ||
            standardComponent.componentType === menuItemComponentTypes.tile
          ) {
            menuItem.menuItemResource.componentId = parseInt(item.menuItemDetails.componentId);
          } else {
            menuItem.menuItemResource.componentId = item.menuItemDetails.componentId;
          }
          if (standardComponent.source === menuItemSources.predefined) {
            commit('SET_PREDEFINED_ITEMS', standardComponent.items);
          }
        } else {
          menuItem.menuItemResource = {
            ...initialMenuItemResource,
            menuItemResourceType: menuItem.menuItemResource.menuItemResourceType,
          };
        }
      } else if (item.menuItemDetails.menuItemResourceType === menuItemResourceTypes.thirdPartyLink) {
        const thirdPartyLink = getters.thirdPartyLinks.find((link) => {
          return link.id === item.menuItemDetails?.menuItemResourceId?.toString();
        });
        menuItem.menuItemResource.thirdPartyLink = { ...thirdPartyLink };
        menuItem.menuItemResource.componentId = item.menuItemDetails.componentId;
      }

      commit('ADD_MENU_ITEM', { ...menuItem });
      return menuItem;
    });

    commit(
      'SET_PARENT_MENU_ITEMS',
      mappedMenuItems.filter((mi) => mi.isParentMenuItem),
    );
    commit('UPDATE_MENU_TREE', mappedMenuItems);
  },
  saveMenuItem({ commit, dispatch, getters }, triggers) {
    const selectedMenuItem = getters.selectedMenuItem;
    const updatedMenuTree = [];

    function updateMenuItems(menuItems, targetId) {
      for (let i = menuItems.length - 1; i >= 0; i--) {
        let menuItem = menuItems[i];
        if (menuItem.id === targetId) {
          menuItem = { ...selectedMenuItem, draft: false, triggers: triggers };
          menuItem.errorCode = null;

          if (!isNullOrUndefined(selectedMenuItem.parentMenuItemId) && selectedMenuItem.parentMenuItemId !== -1) {
            const selectedParent = getters.parentMenuItems.find((pmi) => pmi.id === selectedMenuItem.parentMenuItemId);
            if (selectedParent) {
              selectedParent.children.push(menuItem);
            }
          } else {
            updatedMenuTree.unshift(menuItem);

            if (menuItem.isParentMenuItem) {
              dispatch('updateParentMenuItems');
            }
          }

          if (menuItem.children.length) {
            updateMenuItems(menuItem.children, targetId);
          }
        } else if (menuItem.children?.length) {
          let childMenuItem = menuItem.children.find((mi) => mi.id === targetId);

          if (childMenuItem) {
            Object.assign(childMenuItem, { ...selectedMenuItem, draft: false, errorCode: null, triggers: triggers });
          }

          updatedMenuTree.unshift(menuItem);
        } else if (isNullOrUndefined(menuItem.parentMenuItemId) || menuItem.parentMenuItemId === -1) {
          updatedMenuTree.unshift(menuItem);
        }
      }
    }

    updateMenuItems(getters.menuTree, selectedMenuItem.id);

    commit('UPDATE_MENU_TREE', updatedMenuTree);
  },
  setMenuItemName({ commit, dispatch, getters }, menuItemName) {
    commit('SET_MENU_ITEM_NAME', menuItemName?.trimStart());

    if (!getters.isMenuItemNameUnique) {
      dispatch('setValidationErrors', [{ errorCode: nameNotUniqueErrorCode }]);
    } else {
      dispatch('setValidationErrors', []);
    }
  },
  setOpenLinkInNewTab({ commit }, openLinkInNewTab) {
    commit('SET_OPEN_LINK_IN_NEW_TAB', openLinkInNewTab);
  },
  removeMenuItem({ dispatch, getters }) {
    const menuItemToDeleteId = getters.selectedMenuItem.id;
    const menuTree = getters.menuTree;

    const result = findAndRemoveMenuItem(menuTree, menuItemToDeleteId);
    if (result) {
      dispatch('updateParentMenuItems', true);
    }
  },
  updateParentMenuItems({ commit, getters }, remove) {
    const { id, menuItemType } = getters.selectedMenuItem;
    let parentMenuItems = getters.parentMenuItems;

    if (!remove && menuItemType === menuItemTypes.parentMenuItem && !parentMenuItems.find((pmi) => pmi.id === id)) {
      parentMenuItems.push(getters.selectedMenuItem);
    } else if (remove) {
      parentMenuItems = parentMenuItems.filter((pmi) => pmi.id !== id);
    }

    commit('SET_PARENT_MENU_ITEMS', parentMenuItems);
  },
  validateMenuItemsLinkedItems({ commit, getters }) {
    const menuTree = getters.menuTree;
    if (!menuTree.length) {
      return;
    }

    function validateLinkedItem(menuItem) {
      if (menuItem.menuItemResource.menuItemResourceType === menuItemResourceTypes.standardComponent) {
        const standardComponent = getters.standardComponents.find(
          (sc) => sc.id === menuItem.menuItemResource?.standardComponent?.id,
        );

        if (!standardComponent) {
          menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
        } else {
          if (
            standardComponent.source === menuItemSources.predefined &&
            standardComponent.items.length > 0 &&
            !standardComponent.items.find(
              (item) => item.id.toString() === menuItem.menuItemResource?.componentId.toString(),
            )
          ) {
            menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
          } else if (standardComponent.source === menuItemSources.dynamic) {
            switch (menuItem.menuItemResource?.standardComponent?.componentType) {
              case menuItemComponentTypes.survey:
                if (
                  !getters.surveys.find(
                    (s) => s.surveyId.toString() === menuItem.menuItemResource?.componentId.toString(),
                  )
                ) {
                  menuItem.menuItemResource.componentId = null;
                  menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
                } else {
                  menuItem.errorCode = null;
                }
                break;
              case menuItemComponentTypes.tile:
                if (
                  !getters.cmsTiles.find(
                    (c) => c.tileId.toString() === menuItem.menuItemResource?.componentId.toString(),
                  )
                ) {
                  menuItem.menuItemResource.componentId = null;
                  menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
                } else {
                  menuItem.errorCode = null;
                }
                break;
              default:
                menuItem.errorCode = null;
                break;
            }
          }
        }
      } else if (menuItem.menuItemResource.menuItemResourceType === menuItemResourceTypes.thirdPartyLink) {
        const thirdPartyLink = getters.thirdPartyLinks.find(
          (tpl) => tpl.id === menuItem.menuItemResource?.thirdPartyLink?.id,
        );
        if (!thirdPartyLink) {
          menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
        } else {
          switch (menuItem.menuItemResource?.thirdPartyLink?.componentType) {
            case menuItemComponentTypes.avidonPages:
              if (!getters.avidonPages.find((p) => p.pageId === menuItem.menuItemResource?.componentId)) {
                menuItem.menuItemResource.componentId = null;
                menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
              } else {
                menuItem.errorCode = null;
              }
              break;
            case menuItemComponentTypes.avidonCourse:
              if (!getters.avidonCourses.find((s) => s.courseId === menuItem.menuItemResource?.componentId)) {
                menuItem.menuItemResource.componentId = null;
                menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
              } else {
                menuItem.errorCode = null;
              }
              break;
            case menuItemComponentTypes.avidonFlow:
              if (!getters.avidonFlows.find((c) => c.flowId === menuItem.menuItemResource?.componentId)) {
                menuItem.menuItemResource.componentId = null;
                menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
              } else {
                menuItem.errorCode = null;
              }
              break;

            default:
              menuItem.errorCode = null;
              break;
          }
        }
      }
    }

    for (let i = 0; i < menuTree.length; i++) {
      validateLinkedItem(menuTree[i]);

      if (menuTree[i].children.length) {
        for (let j = 0; j < menuTree[i].children.length; j++) {
          validateLinkedItem(menuTree[i].children[j]);
        }
      }
    }

    commit('UPDATE_MENU_TREE', menuTree);
  },
  async loadDynamicData({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.adminMenuManagementApiBaseUrl}${menuManagementBaseUrl}/dynamic-data`,
        method: 'POST',
        data: {
          groupIds: getters.getAssignedGroupIds,
        },
      };
      const response = await mkNetworkRequestWrapper(options);
      const responseData = response.data.data;

      commit('SET_SURVEYS', responseData.surveys);
      commit('SET_CMS_TILES', responseData.cmsTiles);
      commit('SET_STANDARD_COMPONENTS', responseData.standardComponents);
      commit('SET_THIRD_PARTY_LINKS', responseData.thirdPartyLinks);
    } catch (e) {
      if (e.response.status === 403) {
        commit('SET_SURVEYS', []);
        commit('SET_CMS_TILES', []);
        commit('SET_STANDARD_COMPONENTS', []);
        commit('SET_THIRD_PARTY_LINKS', []);
      } else {
        dispatch('common/displayErrorNotification', e, { root: true });
      }
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
