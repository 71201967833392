// endpoints

export const communityAdminRootUrl = '/communityadmin'; // TODO: don't tie endpoint to SC
export const legacyMyAccountUrl = '/components/member/UserProfile.aspx';
export const legacyMyPalsUrl = '/components/social-challenges/pals/';
export const fileRootUrl = '/file';
export const messageEndpointUrl = '/components/message-center/View.aspx?mmId=';

// urls

export const userProfileBaseUrl = '/components/member/UserProfile.aspx';
export const userProfileCommunicatonPreferences = `${userProfileBaseUrl}?section=communicatonPreferences`;
export const communicationsBaseUrl = '/components/communications';
export const communicationsNotificationsSection = `${communicationsBaseUrl}/?section=notifications`;
export const communicationsMessagesSection = `${communicationsBaseUrl}/?section=messages`;

// types

export const httpErrorCodeUnauthorized = 401;
export const modalTypeString = 'string';
export const modalTypeComponent = 'component';
export const modalTypeMemberSearch = 'memberSearch';
export const snackbarTypeSuccess = 'success';
export const snackbarTypeError = 'error';
export const snackbarTypeInfo = 'info';
export const snackbarTypeNotification = 'notification';
export const filepondAcceptedTypes = 'image/jpeg, image/png';
export const creationStepIconTypeComplete = 'complete';
export const creationStepIconTypeActive = 'active';
export const creationStepIconTypeIncomplete = 'incomplete';
export const creationStepIconTypeNavigable = 'navigable';
export const healthStatusHealthy = 'Healthy';
export const healthStatusDegraded = 'Degraded';
export const healthStatusUnhealthy = 'Unhealthy';
export const healthStatusUnknown = 'Unknown';
export const formModeCreate = 'create';
export const formModeEdit = 'edit';

// thresholds

export const snackbarTimeout = 3000;
export const indefiniteSnackbarTimeout = 24 * 3600 * 1e3; // 24 hour (24 hr > 3600 s/hr) timeouts on "indefinte" notifications
export const breakpointSm = 600;
export const breakpointMd = 960;
export const breakpointLg = 1264;
export const breakpointXl = 1904;
export const defaultUserDebounceInterval = 500;
export const delayActionForBetterUxInterval = 50;
export const delayActionForAnimationInterval = 150;
export const delayActionForLongerAnimationInterval = 300;
export const maxMessageCount = 15;

// validation

export const validationRules = {
  email: (v) => !!(v || '').match(/@/) || 'Please enter a valid email address.',
  length: (len) => (v) => (v || '').length >= len || `You must use at least ${len} characters.`,
  password: (v) =>
    !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
    'Password must contain an upper case letter, a numeric character, and a special character',
  required: (v) => !!v || 'This field is required',
};

// verbiage

export const modalBtnTextConfirm = 'Yes';
export const modalBtnTextDeny = 'No';
export const modalBtnTextCancel = 'Cancel';
export const modalBtnTextSave = 'Save';
export const modalBtnTextDelete = 'Delete';
export const pageNotFoundMessage = `Sorry, we couldn't find that page.`;
export const unauthorizedMessage = `Sorry, you don't have permission to access this resource.`;
export const backButtonDefaultText = 'Back';
export const snackbarCloseText = 'Close';
export const snackbarOkBtnText = 'Ok';
export const displayLongLoadingTimeHeader = 'Loading Groups';
export const displayLongLoadingTimeVerbiage =
  'Load times for the group tree will vary depending on the size. For extra large group trees, you can expect to wait 1-2 minutes, we appreciate your patience!';
export const filepondErrorMessageGeneric = 'An unexpected error has occurred. Please try again later.';
export const filepondErrorMessageFiletype =
  'Please upload an image file with a valid file type. Allowed types are: .JPG, .PNG.';
export const myPalsSidebarMenuItemText = 'My Pals';
export const dashboardBackButtonText = 'Back to Dashboard';
export const settingsLinkText = 'Settings';
export const viewAllNotificationsText = 'View All Notifications';
export const viewAllMessagesText = 'View All Messages';
export const markAllAsReadText = 'Mark All As Read';
export const noNewNotificationsText = 'No new notifications';
export const noNewMessagesText = 'No new messages';

// urls

export const lazyLoadPlaceholder = 'https://picsum.photos/id/11/10/6';

// css

export const navbarItems = {
  home: {
    iconClass: 'mkicon mkicon-home',
    text: 'Home',
  },
  messages: {
    iconClass: 'mkicon mkicon-mail_outline',
    text: 'Messages & Notifications',
  },
  contrast: {
    iconClass: 'mkicon mkicon-invert_colors',
    text: 'High Contrast Mode',
  },
  account: {
    iconClass: 'mkicon mkicon-person_outline',
    text: 'My Account',
  },
  myaccount: {
    iconClass: 'mkicon mkicon-account_circle',
    text: 'My Account',
  },
  faq: {
    iconClass: 'mkicon mkicon-help_outline',
    text: 'FAQ',
  },
  signout: {
    iconClass: 'mkicon mkicon-lock_outline',
    text: 'Sign Out',
  },
  pals: {
    iconClass: 'mkicon mkicon-friends',
    text: 'My Pals',
  },
  language: {
    iconClass: 'mkicon mkicon-language',
    text: 'Language',
  },
  chat: {
    iconClass: 'mkicon mkicon-chat-bubbles-square',
    text: 'Chat',
  },
};

export const notificationsSidebarNotificationsTabId = 0;
export const notificationsSidebarMessagesTabId = 1;

export const notificationsSidebarTabItems = [
  { id: notificationsSidebarNotificationsTabId, text: 'Notifications', iconClass: 'mkicon mkicon-notifications_none' },
  { id: notificationsSidebarMessagesTabId, text: 'Messages', iconClass: 'mkicon mkicon-mail_outline' },
];

export const chatSidebarSessionsTabId = 0;
export const chatSidebarSupportTabId = 1;

export const chatSidebarTabItems = [
  { id: chatSidebarSessionsTabId, text: 'Active Sessions' },
  { id: chatSidebarSupportTabId, text: 'Support History' },
];

// formats

export const fullDateTimeFormat = 'MMMM dd yyyy - t';
export const numericalDateFormat = 'MM/dd/yyyy';

// pagination

export const offsetReplacementToken = '{:offset}';

// notifications
export const defaultNotificationTypeId = 0;
export const notificationTypeIdSplash = 11;
// gradients
export const tileGradientDisabledId = 99;
export const tileGradientCss = [
  {
    id: 1,
    description: 'BlueGreen',
    css: 'linear-gradient(315deg, #21BDB8 0%, #280684 100%)',
  },
  {
    id: 2,
    description: 'PurpleBlue',
    css: 'linear-gradient(135deg, #5374BF 0%, #9D9BFF 100%)',
  },
  {
    id: 3,
    description: 'SkyBlue',
    css: 'linear-gradient(47.22deg, #6E6CD8 5.72%, #40A0EF 48.21%, #77E1EE 94.27%)',
  },
  {
    id: 4,
    description: 'GreenYellow',
    css: 'linear-gradient(135deg, #A1FF8B 0%, #3F93FF 96.83%)',
  },
  {
    id: 5,
    description: 'Orange',
    css: 'linear-gradient(135deg, #FF5F6D 2.88%, #DDA557 100%)',
  },
  {
    id: 6,
    description: 'YellowOrange',
    css: 'linear-gradient(135deg, #FBDA61 2.88%, #FFC371 100%)',
  },
  {
    id: 7,
    description: 'LightPurple',
    css: 'linear-gradient(135deg, #7EAEFF 2.88%, #FCA6E9 100%)',
  },
  {
    id: 8,
    description: 'DeepBlue',
    css: 'linear-gradient(135deg, #3A42E1 2.88%, #620C90 98.14%)',
  },
  {
    id: 9,
    description: 'Sienna',
    css: 'linear-gradient(135deg, #E25544 2.88%, #42127E 98.14%)',
  },
  {
    id: tileGradientDisabledId,
    description: 'Disabled',
    css: 'linear-gradient(135deg, #b4b4b4 45%, #707070 100%)',
  },
];
