import { noAdminTemplatesText } from '@/modules/AdminTemplates/utils';

export const adminTemplateTypes = [
  {
    ordinal: 0,
    type: 'Template Type',
    class: 'template-type',
    noneText: noAdminTemplatesText,
  },
];

export const defaultTemplateTypeId = 0;
export const chatResponsesTemplateTypeId = 1;
export const cmsTileTextTemplateTypeId = 2;
export const messagesTemplateTypeId = 3;
export const splashNotificationsTemplateTypeId = 4;

/*
  ChatResponses = 1,
  CmsTileText = 2,
  Messages = 3,
  SplashNotifications = 4,
*/

export const createAdminTemplateTypes = [
  {
    ordinal: defaultTemplateTypeId,
    type: 'Select a Template Type',
    class: 'select-template-type',
    noneText: noAdminTemplatesText,
    visible: true,
    disabled: true,
  },
  {
    ordinal: chatResponsesTemplateTypeId,
    type: 'Chat Responses',
    class: 'chat-responses-template-type',
    visible: false,
  },
  {
    ordinal: cmsTileTextTemplateTypeId,
    type: 'CMS Tile Text',
    class: 'cms-tile-text-template-type',
    visible: true,
  },
  {
    ordinal: messagesTemplateTypeId,
    type: 'Messages',
    class: 'messages-template-type',
    visible: true,
  },
  {
    ordinal: splashNotificationsTemplateTypeId,
    type: 'Splash Notifications',
    class: 'splash-notifications-template-type',
    visible: true,
  },
];
