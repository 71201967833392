import { AdminBiometricsUser } from '@/modules/AdminBiometrics/router/adminBiometricsRoutesConstants';

const adminBiometricsRoutes = [
  {
    path: AdminBiometricsUser.path,
    name: AdminBiometricsUser.name,
    props: true,
    component: () => import('@/modules/AdminBiometrics/components/AdminBiometricsUser'),
    meta: {
      requiresAdmin: true,
    },
  },
];

export default adminBiometricsRoutes;
