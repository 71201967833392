import store from '@/store';
import jwtDecode from 'jwt-decode';
import mkNetworkRequestWrapperLegacy from '@/utils/requestWrapperLegacy';
import { accessToken, accessTokenFromLegacy, refreshEndpoint, contactId } from '@/utils/constants';
import { isStringNullUndefinedOrEmpty } from '@/utils/helperFunctions';

//during startup this gets called multiple times, to avoid multiple calls cache promise
let refreshLegacyTokensPromise = null;
export async function refreshLegacyTokens() {
  if (null !== refreshLegacyTokensPromise) {
    return refreshLegacyTokensPromise;
  }
  return (refreshLegacyTokensPromise = (async () => {
    let originUri = await getOriginUriLegacy();

    if (isStringNullUndefinedOrEmpty(originUri)) {
      window.location.href = process.env.VUE_APP_MKLEGACYURL;
      return;
    }
    const options = {
      method: 'get',
      url: `${originUri}${refreshEndpoint}`,
    };

    try {
      let tokens = await mkNetworkRequestWrapperLegacy(options);

      if (!tokens.data[`${accessToken}`]) {
        window.location.href = originUri;
      } else {
        setAuthenticationState(accessTokenFromLegacy, tokens.data[`${accessToken}`]);

        const decodedToken = jwtDecode(tokens.data[`${accessToken}`]);
        const { sub } = decodedToken;

        setAuthenticationState(contactId, Number(sub));
        refreshLegacyTokensPromise = null;
      }
    } catch (err) {
      window.location.href = originUri;
    }
  })());
}

export function setAuthenticationState(key, value) {
  if (value) {
    store.dispatch('authentication/setState', { tokenName: key, tokenValue: value });
  }
}

export function isTokenExpired() {
  const token = store.getters['authentication/accessTokenFromLegacy'];
  let result = true;
  if (token) {
    const currentTime = Date.now();
    const decodedToken = jwtDecode(token);
    const { exp } = decodedToken;
    if (exp * 1000 > currentTime) {
      result = false;
    }
  }
  return result;
}

export function getOriginUriLegacy() {
  let isIframed = window !== window.top;
  if (!isIframed) {
    return process.env.VUE_APP_MKLEGACYURL; //fallback for development only, in prod we always expect iframe
  }

  return new Promise((resolve /*, reject*/) => {
    let originUriLegacyMessageListener = (e) => {
      if (e.data && e.data.messageType === 'OriginUriLegacy') {
        window.removeEventListener('message', originUriLegacyMessageListener);

        resolve(e.data.originUriLegacy);
      }
    };
    window.addEventListener('message', originUriLegacyMessageListener);
    parent.postMessage({ messageType: 'getOriginUriLegacy' }, '*');
  });
}
