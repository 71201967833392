import { groupsMutations } from '@/modules/Groups/store/mutations';
import { groupsGetters } from '@/modules/Groups/store/getters';
import { groupsActions } from '@/modules/Groups/store/actions';

export const groupsState = {
  managedGroups: [],
  displayLoadingVerbiage: false,
};

const groupsStore = {
  namespaced: true,
  state: groupsState,
  mutations: groupsMutations,
  getters: groupsGetters,
  actions: groupsActions,
};

export default groupsStore;
