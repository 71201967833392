export const adminTemplatesDashboardHeaderText = 'Admin Templates';
export const adminTemplatesIntroText =
  'Admin Templates allow you to draft, catalog, and access canned messaging for repeat use throughout the portal. This simple tool will save you time and enables a consistent tone of communication.';
export const adminTemplatesCreateTemplateButtonText = 'Create New Template';
export const adminTemplatesSearchTriggerInputText = 'Search';
export const noAdminTemplatesText = 'There are no admin templates which fit the search criteria.';
export const noAdminTemplatesToPickText =
  'There are no templates associated with this field. Go to Portal Settings > Admin Templates to create them.';
export const noAdminTemplatesCreated = 'No templates have been created yet.';
export const adminTemplatesCreateTemplateHeaderText = 'Create New Template';
export const adminTemplatesEditTemplateHeaderText = 'Edit Template';
export const adminTemplatesPreviewTemplateHeaderText = 'Template Details';
export const adminTemplatesPreviewTemplateSecondLineText = 'Template Name and Text';
export const adminTemplatesCreateFormHeaderText = 'Template Details';
export const cancelButtonText = 'Cancel';
export const saveButtonText = 'Save';
export const useButtonText = 'Use';
export const backButtonText = 'Back';
export const templateTypeStepOneHeaderText = 'Template Type';
export const templateTypeDescription = `The Template Type indicates where the template can be accessed in the Admin Portal.
  For example, templates created with the Template Type of Messages can be accessed in the Message Center.`;
export const templateTypeStepTwoHeaderText = 'Template Name and Text';
export const createAdminTemplateCancelModalHeader = 'Cancel Admin Template Creation';
export const createAdminTemplateCancelModalText = 'Your progress will not be saved. Would you like to proceed?';
export const adminTemplateFailureToCreateToastText =
  'An error occurred while attempting to create your admin template.';
export const adminTemplateFailureToUpdateToastText =
  'An error occurred while attempting to update your admin template.';
export const adminTemplateSuccessfullyCreatedToastText = 'Your admin template has been successfully created.';
export const viewAdminTemplateModalHeadline = 'View Admin Template';
export const editAdminTemplateCancelModalTitle = 'Cancel Admin Template Changes';
export const editAdminTemplateCancelModalBody = 'None of your changes have been saved. Do you want to proceed?';
export const adminTemplateSuccessfullySavedToastText = 'Saved successfully!';
export const adminTemplateLastEditedText = 'Last Edited';
export const adminTemplateLastEditedByText = 'Edited By';
export const adminTemplateCreatedByText = 'Creator';
export const nameNotUniqueErrorMessage = 'This Template Name has already been used. Enter a new name.';
export const templateTypeHeaderText = 'Template Type';
export const templateNameHeaderText = 'Template Name';
export const templateLastEditedHeaderText = 'Last Edited';
export const templateEditedByHeaderText = 'Last Edited By';
export const templateEditHeaderText = 'Edit';
export const templateTextHeaderText = 'Template Text';
export const selectHeaderText = 'Select';
export const previewHeaderText = 'Preview';
export const noTemplateSearchResults = 'There are no templates that match your search criteria.';
export const deleteTemplateText = 'Delete Template';
export const deleteModalBodyText = 'Are you sure you want to delete this template?';
export const successfullyDeletedAdminTemplateToastText = 'Deleted successfully!';
