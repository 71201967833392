import { commonMutations } from '@/modules/Common/store/mutations';
import { commonGetters } from '@/modules/Common/store/getters';
import { commonActions } from '@/modules/Common/store/actions';
import { snackbarCloseText } from '@/modules/Common/utils/constants';

export const commonState = {
  version: {
    major: '',
    build: '',
  },
  isLoading: false,
  isTranslucent: false,
  apiUrl: process.env.VUE_APP_APIBASEURL,
  environment: process.env.VUE_APP_NODE_ENV,
  originUriLegacy: null,
  errorLogging: null,
  leftMenuOpen: false,
  callsInFlight: 0,
  overrideIsLoading: false,
  isLoadingTranslucent: false,
  scrollYPosition: 0,
  sideMenuEnabled: true,
  notificationSnackbar: {
    open: false,
    msg: '',
    type: null,
    isClosable: true,
    buttonText: snackbarCloseText,
    onClose: () => {},
  },
  modal: {
    confirmationButtonDisabled: false,
  },
  features: {
    navbarEnabled: false,
    step4Enabled: false,
    mockNumberOfPalRequests: 0,
    selectTriggersOnSocialCircle: true,
  },
  apiHealth: null,
  notifications: [],
  totalUnreadNotificationCount: 0,
  messages: [],
  totalUnreadMessagesCount: 0,
  csrfToken: '',
};

const commonStore = {
  namespaced: true,
  state: commonState,
  mutations: commonMutations,
  getters: commonGetters,
  actions: commonActions,
};

export default commonStore;
