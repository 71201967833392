//css

//icons
export const reactTypeLike = 11;

export const drawerIcons = [
  {
    className: '',
    iconCode: '',
    reactType: reactTypeLike,
  },
];

export const quantityConstant = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const TangoServiceErrorCode = {
  TangoStoreNotEnabled: 4002,
};
