import { menuItemTypes, menuItemResourceTypes } from '@/modules/AdminMenuManagement/utils';

/**
 *
 * @param {Array} menuItems
 * @param {number} targetId
 * @returns boolean
 */
export function findAndRemoveMenuItem(menuItems, targetId) {
  for (let i = menuItems.length - 1; i >= 0; i--) {
    let menuItem = menuItems[i];
    if (menuItem.id === targetId) {
      menuItems.splice(i, 1);
      return true;
    }

    if (menuItem.children && menuItem.children.length !== 0) {
      if (findAndRemoveMenuItem(menuItem.children, targetId)) {
        return true;
      }
    }
  }
}
/**
 *
 * @param {Array} menuItems
 * @param {bool} editMode
 * @returns {Array} formatted menuItems for request
 */
// TODO: refactor this to remove duplicate logic
export function buildMenuItemRequest(menuItems, isEditMode) {
  const menuItemRequests = [];

  if (!menuItems || menuItems.length === 0) {
    return menuItemRequests;
  }

  menuItems.forEach((menuItem) => {
    let parentResource = menuItem.menuItemResource;

    if (parentResource.menuItemResourceType === menuItemResourceTypes.standardComponent) {
      parentResource = menuItem.menuItemResource?.standardComponent;
    } else if (parentResource.menuItemResourceType === menuItemResourceTypes.thirdPartyLink) {
      parentResource = menuItem.menuItemResource?.thirdPartyLink;
    }

    const children = [];

    if (menuItem.menuItemType === menuItemTypes.parentMenuItem) {
      menuItem.children.forEach((child) => {
        let childResource = child.menuItemResource;

        if (childResource.menuItemResourceType === menuItemResourceTypes.standardComponent) {
          childResource = child.menuItemResource?.standardComponent;
        } else if (childResource.menuItemResourceType === menuItemResourceTypes.thirdPartyLink) {
          childResource = child.menuItemResource?.thirdPartyLink;
        }
        if (Array.isArray(menuItem.triggers)) {
          if (child.triggers.length === 0) {
            console.log('Input array is empty.');
          } else {
            const firstElement = child.triggers[0];

            if (typeof firstElement === 'object' && firstElement !== null) {
              console.log('Input array is an array of objects.');
              child.triggers = child.triggers.map((obj) => obj['triggerId']);
              console.log('ct', child.triggers);
            } else if (typeof firstElement === 'number') {
              console.log('Input array is an array of integers.');
            } else {
              console.log('Input array has mixed types or an unsupported type.');
            }
          }
        }
        children.push({
          icon: child.icon,
          name: child.name,
          url: childResource.url,
          menuItemId: isEditMode ? child.id : null,
          menuItemType: child.menuItemType,
          componentId: child.menuItemResource?.componentId?.toString(),
          menuItemResourceId: childResource?.id,
          menuItemResourceType: child.menuItemResource.menuItemResourceType,
          openLinkInNewTab: child.menuItemResource.openLinkInNewTab,
          triggers: child.triggers,
        });
      });
    }
    if (Array.isArray(menuItem.triggers)) {
      if (menuItem.triggers.length === 0) {
        console.log('Input array is empty.');
      } else {
        const firstElement = menuItem.triggers[0];

        if (typeof firstElement === 'object' && firstElement !== null) {
          console.log('Input array is an array of objects.');
          menuItem.triggers = menuItem.triggers.map((obj) => obj['triggerId']);
          console.log('pt', menuItem.triggers);
        } else if (typeof firstElement === 'number') {
          console.log('Input array is an array of integers.');
        } else {
          console.log('Input array has mixed types or an unsupported type.');
        }
      }
    }
    menuItemRequests.push({
      icon: menuItem.icon,
      name: menuItem.name,
      menuItemId: isEditMode ? menuItem?.id : null,
      url: parentResource?.url,
      menuItemType: menuItem.menuItemType,
      children: children,
      componentId: menuItem.menuItemResource?.componentId?.toString(),
      menuItemResourceId: parentResource?.id,
      menuItemResourceType: menuItem.menuItemResource.menuItemResourceType,
      openLinkInNewTab: menuItem.menuItemResource.openLinkInNewTab,
      triggers: menuItem.triggers,
    });
  });

  return menuItemRequests;
}

/**
 *
 * @param {Array} groupTree
 * @param {Array} parsedGroups
 * @param {number} targetId
 */
export function addChildGroups(groupTree, parsedGroups, targetId) {
  for (let group of groupTree) {
    if (group.groupId === parseInt(targetId)) {
      group.children = [...parsedGroups];
    } else if (group.hasChildren) {
      addChildGroups(group.children, parsedGroups, targetId);
    }
  }
}

/**
 * Updates group (checkbox) nodes in the hierarchy of a selected group to be disabled / unselectable
 * @param {Array} groups - all available groups objects
 * @param {Array} selectedGroups - currently selected group objects
 */
export function updateGroupTreeNodes(groups, selectedGroups) {
  for (let group of groups) {
    group.disabled = selectedGroups.length !== 0;

    if (group.children && group.children.length !== 0) {
      updateGroupTreeNodes(group.children, selectedGroups);
    }
  }
}
