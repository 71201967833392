<template>
  <v-container v-if="isLoading" class="container--loading" v-bind:class="backgroundCssClass" fluid>
    <div class="container--loading-icon"><LoadingIcon /></div>
  </v-container>
</template>

<script>
import LoadingIcon from '@/modules/Common/components/LoadingIcon';
import { mapGetters } from 'vuex';

export default {
  name: 'LoadingScreen',
  components: {
    LoadingIcon,
  },
  computed: {
    ...mapGetters({
      isLoading: 'common/isLoading',
      isLoadingTranslucent: 'common/isLoadingTranslucent',
    }),
    backgroundCssClass: function() {
      return this.isLoadingTranslucent ? 'translucent' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.container--loading {
  @include vertical-center-parent;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background-color: $background;

  &.translucent {
    background-color: $backgroundTranslucent;
  }

  .container--loading-icon {
    @include vertical-center-parent;
  }
}
</style>
