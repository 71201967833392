import { isNullOrUndefined, isStringNullUndefinedOrWhitespace } from '@/utils/helperFunctions';
import {
  triggerDescriptionMaxLength,
  triggerNameMaxLength,
  conditionalStatementVariableDateRangeDelimiter,
  triggerNumberMaxDecimals,
} from '@/modules/TriggersAdmin/utils';

export const triggerCreationRules = {
  name: [
    (v) => !isStringNullUndefinedOrWhitespace(v) || `Trigger Name is required.`,
    (v) =>
      (!isNullOrUndefined(v) && v.length <= triggerNameMaxLength) ||
      `Trigger name must be shorter than ${triggerNameMaxLength} characters.`,
  ],
  description: [
    (v) => !isStringNullUndefinedOrWhitespace(v) || `Trigger Description is required.`,
    (v) =>
      (!isNullOrUndefined(v) && v.length <= triggerDescriptionMaxLength) ||
      `Trigger description must be shorter than ${triggerDescriptionMaxLength} characters.`,
  ],
  requireCategoryDropdownValue: [(v) => (!isNullOrUndefined(v) && v > 0) || 'Category selection is required.'],
  requireTopicDropdownValue: [(v) => (!isNullOrUndefined(v) && v > 0) || 'Topic selection is required.'],
  requireConditionalStatementDropdownValue: [
    (v) => (!isNullOrUndefined(v) && v > 0) || 'Variable selection is required.',
  ],
  numberField: [
    (v) => !isStringNullUndefinedOrWhitespace(v) || 'Value is required.',
    (v) => !isNaN(v) || 'Value must be a number.',
    (v) =>
      (!isStringNullUndefinedOrWhitespace(v) && v.split('.')[0].length < triggerNumberMaxDecimals) ||
      'Please enter a valid number.',
  ],
  percentageField: [
    (v) => !isStringNullUndefinedOrWhitespace(v) || 'Value is required.',
    (v) => !isNaN(v) || 'Value must be a number.',
    (v) => (v >= 0 && v <= 100) || 'Percentages must be between 0 and 100.',
    (v) =>
      (!isStringNullUndefinedOrWhitespace(v) && v.split('.')[0].length < triggerNumberMaxDecimals) ||
      'Please enter a valid number.',
  ],
  textField: [(v) => !isStringNullUndefinedOrWhitespace(v) || 'Value is required.'],
  dateField: [(v) => !isStringNullUndefinedOrWhitespace(v) || 'Value is required.'],
  dateRangeField: [
    (v) =>
      (!isStringNullUndefinedOrWhitespace(v) &&
        !isStringNullUndefinedOrWhitespace(v.split(conditionalStatementVariableDateRangeDelimiter)[1])) ||
      'Value is required.',
  ],
  decimalField: [
    (v) =>
      (!isStringNullUndefinedOrWhitespace(v) && v.toString().split('.')[1].length < triggerNumberMaxDecimals) ||
      'Please enter a valid number.',
  ],
};
