import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import {
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty,
  mapTriggerRules as mapTriggerRule,
} from '@/utils/helperFunctions';
import {
  triggersBasePathUrl,
  triggersUpdateStatusUrl,
  triggersUpdateUrl,
  triggersUpdateNameAndDescriptionUrl,
  triggerPageLimit,
  triggerConditionCategoriesUrl,
  triggerFailureToCreateToastText,
  mapTriggerRequestObjectFromState,
  triggersForAssignmentUrl,
} from '@/modules/TriggersAdmin/utils';
import { defaultErrorMsg } from '@/utils/constants';

export const triggersAdminActions = {
  setCreateTriggerName({ commit }, payload) {
    commit('SET_CREATE_TRIGGER_NAME', payload);
  },
  setCreateTriggerDescription({ commit }, payload) {
    commit('SET_CREATE_TRIGGER_DESCRIPTION', payload);
  },
  setCreateTriggerMetIfCondition({ commit }, payload) {
    commit('SET_CREATE_TRIGGER_MET_IF_CONDITION', payload);
  },
  setCreateOrEditTriggerTriggerStatus({ commit }, payload) {
    commit('SET_CREATE_OR_EDIT_TRIGGER_SAVE_VALUE', payload);
  },
  setCreateTriggerStepOneValid({ commit }, payload) {
    commit('SET_CREATE_TRIGGER_STEP_ONE_VALID', payload);
  },
  setCreateTriggerStepTwoMetIfValid({ commit }, payload) {
    commit('SET_CREATE_TRIGGER_STEP_TWO_MET_IF_VALID', payload);
  },
  addCreateTriggerRule({ commit }) {
    commit('ADD_CREATE_TRIGGER_RULE');
  },
  populateStateForExistingTrigger({ commit }, payload) {
    commit('POPULATE_STATE_FOR_EXISTING_TRIGGER', payload);
  },
  deleteCreateTriggerRule({ commit }, payload) {
    commit('DELETE_CREATE_TRIGGER_RULE', payload);
  },
  addCreateTriggerRuleCondition({ commit }, payload) {
    commit('ADD_CREATE_TRIGGER_RULE_CONDITION', payload);
  },
  deleteCreateTriggerRuleCondition({ commit }, payload) {
    commit('DELETE_CREATE_TRIGGER_RULE_CONDITION', payload);
  },
  resetCreateTriggerRules({ commit }) {
    commit('RESET_CREATE_TRIGGER_RULES');
  },
  resetCreateTriggerForm({ commit }) {
    commit('SET_CREATE_TRIGGER_NAME', '');
    commit('SET_CREATE_TRIGGER_DESCRIPTION', '');
    commit('SET_CREATE_TRIGGER_MET_IF_CONDITION', 0);
    commit('SET_CREATE_OR_EDIT_TRIGGER_SAVE_VALUE', -1);
    commit('RESET_CREATE_TRIGGER_RULES');
  },
  setConditionVariableIsValid({ commit }, payload) {
    commit('SET_CONDITION_VARIABLE_IS_VALID', payload);
  },
  setTriggerFilter({ commit }, payload) {
    commit('SET_TRIGGER_FILTER', payload);
  },
  setTriggerOriginTypeFilter({ commit }, payload) {
    commit('SET_TRIGGER_ORIGIN_TYPE_FILTER', payload);
  },
  setTriggerSearchText({ commit }, payload) {
    commit('SET_TRIGGER_SEARCH_TEXT', payload);
  },
  setTriggerSortByOption({ commit }, payload) {
    commit('SET_TRIGGER_SORT_BY_OPTION', payload);
  },
  setRuleMetIfCondition({ commit }, payload) {
    commit('SET_RULE_MET_IF_CONDITION', payload);
  },
  setRuleMetIfValid({ commit }, payload) {
    commit('SET_RULE_MET_IF_VALID', payload);
  },
  setConditionCategory({ commit }, payload) {
    commit('SET_CONDITION_CATEGORY', payload);
  },
  setConditionTopic({ commit }, payload) {
    commit('SET_CONDITION_TOPIC', payload);
  },
  setConditionVariable({ commit }, payload) {
    commit('SET_CONDITION_VARIABLE', payload);
  },
  setConditionVariableIsStatement({ commit }, payload) {
    commit('SET_CONDITION_VARIABLE_IS_STATEMENT', payload);
  },
  setConditionVariableValues({ commit }, payload) {
    commit('SET_CONDITION_VARIABLE_VALUES', payload);
  },
  setReloadTriggersConditions({ commit }, payload) {
    commit('SET_RELOAD_TRIGGERS_CONDITIONS', payload);
  },
  setDuplicatedTriggerName({ commit, dispatch }, payload) {
    commit('SET_DUPLICATED_TRIGGER_NAME', payload);
    dispatch('common/setModalConfirmationButtonDisabled', isStringNullUndefinedOrEmpty(payload), { root: true });
  },
  setLoadingTriggers({ commit }, payload) {
    commit('SET_LOADING_TRIGGERS', payload);
  },
  resetCurrentTriggers({ commit }) {
    commit('RESET_CURRENT_TRIGGERS');
  },
  flashMostRecentTrigger({ commit }) {
    commit('RESET_TRIGGER_FLASH');
    commit('FLASH_MOST_RECENT_TRIGGER');
  },
  async loadAdminTriggers({ commit, getters, dispatch }, payload) {
    try {
      commit('common/INCREMENT_CALLS_IN_FLIGHT', null, { root: true });
      const { limit, offset, status, sortBy, searchText, originType, overrideCurrentTriggers } = payload;
      const options = {
        url: `${getters.triggersApiBaseUrl}${triggersBasePathUrl}`,
        method: 'get',
        params: {
          limit: !isNullOrUndefined(limit) ? limit : triggerPageLimit,
          offset: !isNullOrUndefined(offset) ? offset : 0,
          status: !isNullOrUndefined(status) ? status : getters.triggerFilterId,
          sortBy: !isNullOrUndefined(sortBy) ? sortBy : getters.triggerSortBy,
          searchText: !isNullOrUndefined(searchText) ? searchText : getters.triggerSearchText,
          originType: !isNullOrUndefined(originType) ? originType : getters.triggerFilterOriginTypeId,
        },
      };

      const triggersResponse = await mkNetworkRequestWrapper(options);
      const triggerResponseData = triggersResponse.data.data;
      const adminTriggers = isNullOrUndefined(triggerResponseData) ? [] : triggerResponseData.triggers;
      const paginationLinks = isNullOrUndefined(triggerResponseData) ? {} : triggerResponseData.links;

      const triggerTotal = isNullOrUndefined(triggerResponseData) ? 0 : triggerResponseData.total;
      const queryTotal = isNullOrUndefined(triggerResponseData) ? 0 : triggerResponseData.queryTotal;
      const customTriggersCount = isNullOrUndefined(triggerResponseData)
        ? 0
        : triggerResponseData.numberOfCustomTriggers;

      //map shape of api response into shape expected by state. set selectedTopicId for every loaded trigger
      adminTriggers.forEach((trigger) => {
        trigger.rules = trigger.rules.map((rule) => {
          return mapTriggerRule(rule);
        });
      });

      // Set flags to indicate overriding the current triggers and to attach the most recently duplicated trigger to the head of the list in state
      const adminTriggersToSetPayload = {
        adminTriggers,
        overrideCurrentTriggers: isNullOrUndefined(overrideCurrentTriggers) ? false : overrideCurrentTriggers,
      };

      commit('SET_TRIGGER_TOTAL', triggerTotal);
      commit('SET_PAGINATION_LINKS', paginationLinks);
      commit('SET_ADMIN_TRIGGERS', adminTriggersToSetPayload);
      commit('SET_TRIGGERS_QUERY_TOTAL', queryTotal);
      commit('SET_CUSTOMTRIGGERS_COUNT', customTriggersCount);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getTriggersForAssignment({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.triggersApiBaseUrl}${triggersBasePathUrl}${triggersForAssignmentUrl}`,
        method: 'get',
      };

      const apiResponse = await mkNetworkRequestWrapper(options);
      const triggersForAssignment = apiResponse.data.data;
      commit('SET_ASSIGNABLE_TRIGGERS', triggersForAssignment);
    } catch (err) {
      if (err.response.status !== 404) {
        dispatch('common/displayErrorNotification', err, { root: true });
      }
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async updateNameAndDescription({ commit, getters, dispatch }, payload) {
    try {
      const options = {
        url: `${getters.triggersApiBaseUrl}${triggersBasePathUrl}/${payload.triggerId}${triggersUpdateNameAndDescriptionUrl}`,
        method: 'put',
        data: { Name: payload.name, Description: payload.description, Status: payload.status },
      };
      const res = await mkNetworkRequestWrapper(options);
      const trigger = res.data.data;
      //todo: check if we actually need to map the response here, the api sends back a whole trigger response object so I put it here to be safe. The api should probably send back a smaller object with just the id, name, and description to update.
      trigger.rules = trigger.rules.map((rules) => {
        return mapTriggerRule(rules);
      });
      commit('UPDATE_TRIGGER_NAME_AND_DESCRIPTION', trigger);
      commit('SET_CURRENT_SELECTED_TRIGGER', trigger);
      return res;
    } catch (err) {
      err.response = {
        data: {
          message: defaultErrorMsg,
        },
      };
      dispatch('common/displayErrorNotification', err, { root: true });
      return null;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async updateTrigger({ commit, getters, dispatch }, payload) {
    try {
      const triggerUpdateData = mapTriggerRequestObjectFromState(getters.currentCreatedTriggerForm);
      const options = {
        url: `${getters.triggersApiBaseUrl}${triggersBasePathUrl}/${payload.triggerId}${triggersUpdateUrl}`,
        method: 'put',
        data: triggerUpdateData,
      };

      const res = await mkNetworkRequestWrapper(options);

      const trigger = res.data.data;
      trigger.rules = trigger.rules.map((rules) => {
        return mapTriggerRule(rules);
      });
      commit('UPDATE_TRIGGER', trigger);
      commit('SET_CURRENT_SELECTED_TRIGGER', trigger);

      return res;
    } catch (err) {
      err.response = {
        data: {
          message: defaultErrorMsg,
        },
      };
      dispatch('common/displayErrorNotification', err, { root: true });
      return null;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async updateTriggerStatus({ commit, getters, dispatch }, payload) {
    try {
      const options = {
        url: `${getters.triggersApiBaseUrl}${triggersBasePathUrl}/${payload.triggerId}${triggersUpdateStatusUrl}`,
        method: 'put',
        data: { status: Number(payload.newStatus) },
      };

      const res = await mkNetworkRequestWrapper(options);

      const trigger = res.data.data;
      //todo: check if we actually need to map the response here, the api sends back a whole trigger response object so I put it here to be safe. The api should probably send back a smaller object with just the id, name, and description to update.
      trigger.rules = trigger.rules.map((rules) => {
        return mapTriggerRule(rules);
      });
      commit('UPDATE_TRIGGER_STATUS', trigger);
      commit('UPDATE_TRIGGER_DATES', trigger);

      return res;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
      return null;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async deleteAdminTrigger({ commit, getters, dispatch }, triggerId) {
    try {
      const options = {
        url: `${getters.triggersApiBaseUrl}${triggersBasePathUrl}/${triggerId}`,
        method: 'delete',
      };
      await mkNetworkRequestWrapper(options);
      commit('REMOVE_ADMIN_TRIGGER', triggerId);
      return true;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
      return false;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async duplicateAdminTrigger({ commit, getters, dispatch }, triggerId) {
    try {
      const options = {
        url: `${getters.triggersApiBaseUrl}${triggersBasePathUrl}/${triggerId}/duplicate`,
        method: 'post',
        data: {
          name: getters.duplicateTriggerName,
        },
      };

      await mkNetworkRequestWrapper(options);
      return true;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
      return false;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadNextAdminTriggers({ commit, getters, dispatch }, loadSingle) {
    try {
      const options = {
        url: getters.nextPageTriggerMetasLink.href,
        method: getters.nextPageTriggerMetasLink.method,
      };

      if (loadSingle) {
        const offsetParamValue = getters.nextPageTriggerMetasLink.href.match('[?&]offset=([^&]+).*$')[1];
        const adjustedOffset = parseInt(offsetParamValue) - 1;
        const updatedUrl = getters.nextPageTriggerMetasLink.href.replace(
          /limit=12&offset=[0-9]{1,3}/,
          `limit=1&offset=${adjustedOffset}`,
        );
        options.url = updatedUrl;
      }

      const triggersResponse = await mkNetworkRequestWrapper(options);
      const triggersResponseData = triggersResponse.data.data;
      const paginationLinks = isNullOrUndefined(triggersResponseData) ? {} : triggersResponseData.links;
      const triggerTotal = isNullOrUndefined(triggersResponseData) ? 0 : triggersResponseData.total;
      const queryTotal = isNullOrUndefined(triggersResponseData) ? 0 : triggersResponseData.queryTotal;
      const adminTriggers = isNullOrUndefined(triggersResponse.data.data) ? [] : triggersResponse.data.data.triggers;

      commit('SET_TRIGGER_TOTAL', triggerTotal);
      commit('SET_TRIGGERS_QUERY_TOTAL', queryTotal);

      if (!loadSingle) {
        commit('SET_PAGINATION_LINKS', paginationLinks);
      }

      adminTriggers.forEach((trigger) => {
        trigger.rules = trigger.rules.map((rules) => {
          return mapTriggerRule(rules);
        });
      });
      commit('ADD_ADMIN_TRIGGERS', adminTriggers);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('SET_LOADING_TRIGGERS', false);
    }
  },
  async loadTriggerConditionCategories({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.triggersApiBaseUrl}${triggerConditionCategoriesUrl}`,
        method: 'get',
      };

      const conditionCategoriesResponse = await mkNetworkRequestWrapper(options);
      const conditionCategoriesResponseData = conditionCategoriesResponse.data.data;

      commit('SET_AVAILABLE_CONDITION_CATEGORIES', conditionCategoriesResponseData.options);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadTriggerConditionTopics({ commit, getters, dispatch }, { categoryId, dynamicTopicIds }) {
    try {
      const conditionTopics = getters.conditionTopicOptions(categoryId);
      let topicOptionsToAddToState = [];
      const filteredDynamicTopicIds = dynamicTopicIds ? dynamicTopicIds.filter((i) => !isNullOrUndefined(i)) : [];

      const forceReloadTopics = filteredDynamicTopicIds.length > 0;

      // This will currently never reload the list of surveys after the first load, so if a survey is made after the page loads
      // it won't show up until the page is reloaded
      if (isNullOrUndefined(conditionTopics) || forceReloadTopics) {
        const category = getters.conditionCategory(categoryId);
        const options = {
          url: `${category.topicsLink.href}`,
          method: 'get',
        };

        const conditionTopicsResponse = await mkNetworkRequestWrapper(options);
        const conditionTopicsResponseData = conditionTopicsResponse.data.data;
        const topicOptions = conditionTopicsResponseData.options.map((option) => {
          return { ...option, placeholder: false };
        });

        topicOptionsToAddToState = [...topicOptions];
      } else {
        topicOptionsToAddToState = [...conditionTopics];
      }

      //if dynamicTopicIds are passed in then confirm that all of them show up in the list of returned objects. If they don't, it means one of the items has been deleted and therefore a placeholder has to be added to state to prevent issues.
      //todo: add test cases for this
      if (!isNullOrUndefined(topicOptionsToAddToState) && topicOptionsToAddToState.length > 0) {
        const firstTopicOption = topicOptionsToAddToState[0];

        filteredDynamicTopicIds.forEach((dynamicTopicId) => {
          //search through the response object and confirm that each dynamic topic id appears in the options
          const matchedOption = topicOptionsToAddToState.find((o) => o.id === dynamicTopicId);
          //if it's not there, add a placeholder object
          if (isNullOrUndefined(matchedOption)) {
            const placeholderOption = {
              ...firstTopicOption,
              description: '[Removed Survey]',
              id: dynamicTopicId,
              placeholder: true,
            };
            topicOptionsToAddToState.push(placeholderOption);
          }
        });
      }
      commit('SET_AVAILABLE_CONDITION_TOPICS', {
        topics: topicOptionsToAddToState,
        categoryId,
      });
    } catch (err) {
      commit('SET_AVAILABLE_CONDITION_TOPICS', {
        topics: [],
        categoryId,
      });
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadTriggerConditionStatements({ commit, getters, dispatch }, { categoryId, topicId }) {
    try {
      let topic = getters.conditionTopicOption(categoryId, topicId);
      const options = {
        url: `${topic.statementsLink.href}`,
        method: 'get',
      };
      const conditionStatementsResponse = await mkNetworkRequestWrapper(options);
      const conditionStatementsResponseData = conditionStatementsResponse.data.data;

      commit('SET_AVAILABLE_CONDITION_STATEMENTS', {
        statements: conditionStatementsResponseData.options,
        topicId: topic.topicId,
      });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadTriggerConditionStatementOptions({ commit, getters, dispatch }, { topicId, statementId }) {
    try {
      const options = {
        url: `${getters.conditionStatementOption(topicId, statementId).statementOptionsLink.href}`,
        method: 'get',
      };

      const conditionStatementOptionsResponse = await mkNetworkRequestWrapper(options);
      const conditionStatementsOptionsResponseData = conditionStatementOptionsResponse.data.data;

      commit('SET_TRIGGER_CONDITION_STATEMENT_OPTIONS', {
        statementOptions: conditionStatementsOptionsResponseData,
        statementId,
      });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async createTrigger({ commit, getters, dispatch }) {
    try {
      const triggerToCreate = mapTriggerRequestObjectFromState(getters.currentCreatedTriggerForm);
      const options = {
        url: `${getters.triggersApiBaseUrl}${triggersBasePathUrl}`,
        method: 'post',
        data: triggerToCreate,
      };

      const createTriggerResponse = await mkNetworkRequestWrapper(options);
      const createdAdminTriggerResponseData = createTriggerResponse.data.data;

      const trigger = createdAdminTriggerResponseData.trigger;
      trigger.rules = trigger.rules.map((rules) => {
        return mapTriggerRule(rules);
      });
      //todo: this never actually does anything because the triggers dashboard reloads all triggers when you hit it after a trigger is created. We should either set state using this or rely on the dashboard to load triggers, this just causes confusion.
      commit('ADD_ADMIN_TRIGGER', trigger);

      return createdAdminTriggerResponseData;
    } catch (err) {
      err.response = {
        data: {
          message: triggerFailureToCreateToastText,
        },
      };
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadTrigger({ commit, getters, dispatch }, triggerId) {
    try {
      let trigger = getters.triggerFromId(triggerId);

      // If the trigger does not currently exist in state, call out to retrieve it
      if (isNullOrUndefined(getters.triggerFromId(triggerId))) {
        const options = {
          url: `${getters.triggersApiBaseUrl}${triggersBasePathUrl}/${triggerId}`,
          method: 'get',
        };

        const getTriggerResponse = await mkNetworkRequestWrapper(options);
        const getTriggerResponseData = getTriggerResponse.data.data;
        trigger = getTriggerResponseData.trigger;
        trigger.rules = trigger.rules.map((rules) => {
          return mapTriggerRule(rules);
        });
        commit('ADD_ADMIN_TRIGGER', trigger);
      }

      if (trigger.ruleCount > 0) {
        // Depending on which condition categories, topics, and statements are loaded in state
        // Call out to get those that are not available
        if (!getters.categoriesHaveBeenLoaded) {
          await dispatch('loadTriggerConditionCategories');
        }

        // Flatten all conditions into a single list
        const conditions = trigger.rules.flatMap((r) => r.conditions);
        const dynamicTopicIdMap = setupCategoryDynamicTopicIdsMap(conditions);

        for (const condition of conditions) {
          // Load condition topics for the category ID
          await dispatch('loadTriggerConditionTopics', {
            categoryId: condition.category,
            dynamicTopicIds: dynamicTopicIdMap[condition.category],
          });

          // Load condition topic statements for the topic ID, if none are found
          const topicStatement = getters.conditionStatementOptions(condition.topicId);
          if (isNullOrUndefined(topicStatement)) {
            await dispatch('loadTriggerConditionStatements', {
              categoryId: condition.category,
              topicId: condition.selectedTopicId,
            });
          }

          // Load condition statement options for the statement ID, if none are found
          const statementOption = getters.conditionStatementOptionValues(condition.variable);
          if (isNullOrUndefined(statementOption)) {
            await dispatch('loadTriggerConditionStatementOptions', {
              topicId: condition.topicId,
              statementId: condition.variable,
            });
          }
        }
      }

      commit('SET_CURRENT_SELECTED_TRIGGER', trigger);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};

function setupCategoryDynamicTopicIdsMap(conditions) {
  const categoryMap = {};

  for (let cond of conditions) {
    const categoryId = cond.category;

    if (isNullOrUndefined(categoryMap[categoryId])) {
      categoryMap[categoryId] = [];
    }

    if (cond.dynamicTopicId) {
      categoryMap[categoryId].push(cond.dynamicTopicId);
    }
  }

  return categoryMap;
}
