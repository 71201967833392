export const createNewMenuButtonText = 'Create New Menu';
export const createNewMenuText = 'Create New Menu';
export const adminMenuManagementSearchFieldText = 'Menu Name';
export const selectYourMenuDesignMethodText = 'Design';
export const createFromScratchText = 'Create from scratch';
export const createFromExistingMenu = 'Create from existing menu';
export const createMenuStepTwoTitleText = 'Assign Groups';
export const createMenuStepTwoSubHeaderText = 'Select Member Groups';
export const createMenuStepTwoHeaderDetailsText = `A menu can only be assigned to one group at one level of the hierarchy, and all of the selected group's
  child & grandchild groups will inherit it. Once launched, menu names will appear next to the assigned groups.
  <b><i>Warning:</i></b> Groups cannot be changed after the menu is saved.`;
export const engagementRxCustomersText = 'EngagementRx customers:';
export const engagementRxCustomerDisclaimerText =
  'If the selected group and any of its dependents have different Avidon portal IDs, you will not be allowed to link specific EngagementRx Courses, Flows, or Pages to menu items in the next step.';
export const menuNameText = 'Menu Name';
export const nameNotUniqueErrorMessage = 'A menu with this name already exists.';
export const menuItemNameNotUniqueErrorMessage = 'A menu item with this name already exists.';
export const cancelModalText = 'Your progress will not be saved. Would you like to proceed?';
export const cancelModalTitleText = 'Discard Menu?';
export const defaultSelectMenuText = '--Select Menu--';
export const createMenuStepThreeTitleText = 'Menu Details';
export const createMenuStepThreeHeaderDetailsText =
  'Upon login, users will land on the page associated with the first menu item. Note: You must build menu items in the order in which you want them to appear. You are not allowed to adjust the sort order.';
export const addMenuItemButtonText = 'Add menu item';
export const createMenuStepFourTitleText = 'Launch';
export const singleMenuItemText = 'Single Menu Item';
export const parentMenuItemText = 'Parent Menu Item';
export const childMenuItemText = 'Child Menu Item';
export const homePageText = 'Homepage';
export const menuItemDetailsText = 'Menu Item Details';
export const editStepOneTitle = 'Edit Menu Name';
export const editStepTwoTitle = 'Edit Menu Details';
export const editMenuStepTwoSubHeaderText = 'Add/Edit Menu Items';
export const editMenuStepTwoHeaderDetailsText =
  'Select menu items to edit or delete or drag and drop to adjust the sort order.';
export const editMenuText = 'Edit Menu';
export const menuItemNameText = 'Menu Item Item';
export const standardComponentText = 'Standard Components';
export const thirdPartyLinkText = '3rd Party Link';
export const customUrlText = 'Custom URL';
export const detailsText = 'Details';
export const discardMenuItemModalTitleText = 'Discard Menu Item';
export const discardMenuItemModalText = 'Your menu item will be deleted. Would you like to proceed?';
export const createChildMenuItemSelectText = 'Parent Menu Item';
export const noMenuSearchResultsText = 'There are no menus that match your search criteria.';
export const deleteMenuText =
  'Deleting this menu will cause all groups assigned to this menu to be reassigned to the standard system default menu. Do you wish to continue?';
export const deleteMenuBtnText = 'Continue';
export const deleteMenuTitleText = 'Delete Menu';
export const searchDropdownText = 'Status';
export const openLinkInNewTabText = 'Open link in a new tab';
export const editMenuSubHeaderText = 'Note: Once your menu is saved, you are not able to edit the assigned groups.';
export const overwriteCustomMenuModalTitleText = 'Overwrite Menu Assignments?';
export const overwriteCustomMenuModalContent = [
  'The group you just selected has a menu or has child group(s) with menus.',
  '<b>Select</b> the groups whose menus should be <b><i>overwritten</i></b>.',
  '<b>Deselect</b> groups if you want them to <b><i>keep</i></b> their assigned menu.',
];
export const viewGroupsEditSubHeader = 'View Assigned Groups';
export const viewGroupsEditText =
  'The groups assigned to this menu are shown below. Changes to group selections are not allowed.';
export const groupNameText = 'Group Name';
export const draftMenuToolTipText = 'This menu is in draft status. Once launched, it will be visible to these groups.';
export const discardChangesModalText = 'Your progress will not be saved. Would you like to proceed?';
export const undoSelectionText = 'Undo Selection';
export const editMenuGroupLongLoad = 'Please be patient while groups are loading...';
export const menuCannotBeEditedOrDeletedText = 'This menu cannot be edited or deleted.';
export const viewGroupsSelectedGroupNameText = 'Assigned Group';
export const viewGroupsSelectedGroupCountText = 'Count of Inherited Groups';
export const groupNotFoundText = 'Group Not Found';
export const menuDeletedSuccessfullyText = (menuName) => `Menu "${menuName}" has been deleted`;
export const discardUnsavedMenuItemModalText =
  'Your menu item has not been saved and changes will be lost if you continue. Are you sure you want to proceed?';
export const IntroText =
  'Menu Management allows you to create and manage the slide-out menu for your member portal groups. With it, you can build menus that drive user behavior & provide a highly tailored user experience.';
export const triggerTooltip = 'This menu item is only displayed to members who meet the triggered criteria.';
