import { usersMutations } from '@/modules/Users/store/mutations';
import { usersGetters } from '@/modules/Users/store/getters';
import { usersActions } from '@/modules/Users/store/actions';

export const usersState = {
  currentUserInfo: {},
  socialProfiles: [],
  privacySettings: [],
  langPref: '',
};

const usersStore = {
  namespaced: true,
  state: usersState,
  mutations: usersMutations,
  getters: usersGetters,
  actions: usersActions,
};

export default usersStore;
