<template>
  <div class="mt-10 px-4">
    <h3 class="mb-2">{{ header }}</h3>
    <div v-html="body"></div>
    <v-btn class="close-button white--text mt-5" v-on:click="close()">
      <span class="iconify icon--close white--text button-size" data-icon="eva:close-fill"></span> Close
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SplashNotification',
  props: {
    header: { type: String, required: true },
    body: { type: String, required: true },
  },
  methods: {
    close: function() {
      this.$root.$sidebar.closeSidebar();
    },
  },
};
</script>

<style lang="scss">
.contents {
  margin-top: 25px;
}

.close-button {
  background-color: #5d7a85 !important;
  width: 100%;
}

.button-size {
  font-size: 1.1rem;
}
</style>
