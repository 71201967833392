import { isNullOrUndefined } from '@/utils/helperFunctions';

export const usersMutations = {
  SET_CURRENT_USER_LANGPREF(state, langPref) {
    state.langPref = langPref;
  },
  SET_CURRENT_USER_INFO(state, payload) {
    if (!isNullOrUndefined(payload)) {
      state.currentUserInfo = payload;
    }
  },
  SET_SOCIAL_PROFILE(state, payload) {
    payload.forEach((sp) => {
      const existingSocialProfileIndex = state.socialProfiles.findIndex((esp) => esp.contactId === sp.contactId);

      if (existingSocialProfileIndex === -1) {
        state.socialProfiles.push(sp);
      } else {
        const existingSocialProfile = state.socialProfiles[existingSocialProfileIndex];
        const updatedUser = {
          ...existingSocialProfile,
          ...sp,
        };

        state.socialProfiles[existingSocialProfileIndex] = updatedUser;
      }
    });
  },
};
