export const adminMenuNameMaxLength = 30;
export const nameNotUniqueErrorCode = 'NameNotUniqueValidator';
export const specificCoursesId = '30';
export const specificFlowsId = '31';
export const customUrlMenuItemResource = { resourceId: '5', componentId: '005' };

export const createMenuSteps = [
  { number: 1, text: 'STEP 1', title: 'Design', subtitle: 'Select design method', icon: 'mdi:pencil-ruler' },
  { number: 2, text: 'STEP 2', title: 'Participants', subtitle: 'Select who sees the menu', icon: 'mdi:people-group' },
  { number: 3, text: 'STEP 3', title: 'Details', subtitle: 'Provide menu details', icon: 'mdi:clipboard-text' },
  { number: 4, text: 'STEP 4', title: 'Launch', subtitle: 'Save as draft or launch', icon: 'mdi:rocket-launch' },
];

export const designMethods = {
  createFromScratch: 1,
  createFromExistingMenu: 2,
};

export const menuStatuses = {
  draft: 0,
  inactive: 1,
  published: 2,
};

export const menuItemTypes = {
  childMenuItem: 0,
  parentMenuItem: 1,
  singleMenuItem: 2,
};

export const menuItemResourceTypes = {
  customUrl: 0,
  standardComponent: 1,
  thirdPartyLink: 2,
};

export const menuItemSources = {
  dynamic: 0,
  predefined: 1,
};

export const menuItemComponentTypes = {
  survey: 0,
  tile: 1,
  avidonCourse: 2,
  platformOption: 3,
  avidonFlow: 4,
  avidonPage: 5,
};

export const initialMenuItemResource = {
  // NOTE: This will be a menuItemResourceId, SurveyId, CmsTileId, FlowId, or CourseId
  // depending on the selected menu item component type
  componentId: null,
  menuItemResourceType: null,
  url: '',
  standardComponent: null,
  thirdPartyLink: null,
  openLinkInNewTab: false,
  menuItemResourceId: null,
};

export const menuItemErrorCodes = {
  invalidLinkedItem: 'INVALID_LINKED_ITEM',
  disabledOrDeletedLinkItem: 'DISABLE_DELETED_LINKED_ITEM',
  invalidLinkedItemForEdit: 'INVALID_EDIT_ITEM',
};

export const menuItemErrors = new Map([
  ['INVALID_LINKED_ITEM', 'This menu item is linked to an item that is not valid for the selected group(s).'],
  [
    'DISABLE_DELETED_LINKED_ITEM',
    'This menu item is linked to a disabled or deleted item and is no longer displayed in member portal menus. You must delete or update this menu item in order to save edits to the menu.',
  ],
]);
