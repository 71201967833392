import Home from '@/modules/Common/components/Home';
import ErrorResponsePage from '@/modules/Common/components/ErrorResponsePage';
import FeatureSettings from '@/modules/Common/components/FeatureSettings';
import Health from '@/modules/Common/components/Health';
import {
  Home as routeHome,
  PageNotFound,
  FeatureSettings as routeFeatureSettings,
  HealthCheck,
} from '@/router/commonRoutesConstants';
import { pageNotFoundMessage } from '@/modules/Common/utils/constants';

const commonRoutes = [
  {
    path: routeHome.path,
    name: routeHome.name,
    component: Home,
  },
  {
    path: PageNotFound.path,
    name: PageNotFound.name,
    component: ErrorResponsePage,
    props: { verbiage: pageNotFoundMessage },
  },
  {
    path: HealthCheck.path,
    name: HealthCheck.name,
    component: Health,
  },
  {
    path: routeFeatureSettings.path,
    name: routeFeatureSettings.name,
    component: FeatureSettings,
    props: true,
  },
];

export default commonRoutes;
