import { isStringNullUndefinedOrEmpty } from '@/utils/helperFunctions';
import profanityBlacklist from './profanityBlacklist.json';

/**
 * Determines if a string phrase contains a profane word from the profanity blacklist.
 *
 * @param wordOrPhraseToValidate {string}
 * @returns {{isProfane: boolean, profanePhrase: string}}
 */
const _validateForProfanity = (wordOrPhraseToValidate) => {
  // Create a filtered list of words within the phrase
  const profaneWordsInPhrase = profanityBlacklist.words.filter((word) => {
    // Replace non-characters with escaped whitespace (e.g. "bad-word" => "bad\ word", "bad word" => "bad\ word")
    const normalizedProfaneWord = word.replace(/(\W)/g, '\\$1');

    // Use a word boundary pattern to match on any part of the profane phrase, if compounded
    const wordBoundaryPattern = `\\b${normalizedProfaneWord}\\b`;

    // Ignore case while matching and search the entire string
    const wordRegex = new RegExp(wordBoundaryPattern, 'gi');
    return wordRegex.test(wordOrPhraseToValidate);
  });

  return {
    isProfane: profaneWordsInPhrase.length > 0,
    profanePhrase: profaneWordsInPhrase[0],
  };
};

/**
 * Returns a list of profanity in a user text string using the help of bad-words.js.
 *
 * @param profanePhase {string}
 * @returns {*[]}
 */
const getProfaneWords = (profanePhase) => {
  // Invalidate non-string and empty requests
  if (typeof profanePhase !== 'string' || isStringNullUndefinedOrEmpty(profanePhase)) {
    return [];
  }

  // Grab a split list of all words in the phrase to report back in the error message
  const tokenizedPhrase = profanePhase.split(' ');
  if (tokenizedPhrase.length === 0) {
    return [];
  }

  // Validate the entire phrase
  const profanityInPhrase = _validateForProfanity(profanePhase);

  // Validate the tokenized phrase
  const tokenizedProfanityInPhrase = tokenizedPhrase.reduce((profaneWords, currentWord) => {
    const profanityValidation = _validateForProfanity(currentWord);

    if (profanityValidation.isProfane) {
      profaneWords.push(profanityValidation.profanePhrase.toLowerCase());
    }

    return profaneWords;
  }, []);

  if (profanityInPhrase.isProfane || tokenizedProfanityInPhrase.length > 0) {
    // If profanity is found in the tokenized phrase, or the entire phrase, concatenate the lists for displaying words that triggered the validation
    return [...tokenizedProfanityInPhrase, profanityInPhrase.profanePhrase].reduce((nonDuplicatedList, currentWord) => {
      if (nonDuplicatedList.indexOf(currentWord) < 0) {
        nonDuplicatedList.push(currentWord.toLowerCase());
      }

      return nonDuplicatedList;
    }, []);
  }

  return [];
};

export default getProfaneWords;
