import axios from 'axios';
import store from '@/store/index';
import { isTokenExpired, refreshLegacyTokens } from '@/utils/legacyInterop';

function configureRequests(options) {
  const client = axios.create({
    baseURL: options.url,
    method: options.method,
    data: options.data,
    params: options.params,
    headers: { Authorization: `Bearer ${store.getters['authentication/accessTokenFromLegacy']}`, Pragma: `no-cache` },
  });
  return client(options);
}

export default async function mkNetworkRequestWrapper(options) {
  document.dispatchEvent(new Event('mkApiRequest'));
  if (isTokenExpired()) {
    await refreshLegacyTokens();
  }
  return configureRequests(options);
}
