import {
  AdminTemplates,
  CreateAdminTemplate,
  EditAdminTemplate,
  PickAdminTemplate,
  PreviewAdminTemplate,
} from '@/modules/AdminTemplates/router/adminTemplatesRoutesConstants';

const adminTemplateRoutes = [
  {
    path: AdminTemplates.path,
    name: AdminTemplates.name,
    component: () => import('@/modules/AdminTemplates/components/AdminTemplatesSearch.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: CreateAdminTemplate.path,
    name: CreateAdminTemplate.name,
    component: () => import('@/modules/AdminTemplates/components/CreateAdminTemplate.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: EditAdminTemplate.path,
    name: EditAdminTemplate.name,
    component: () => import('@/modules/AdminTemplates/components/EditAdminTemplate.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: PickAdminTemplate.path,
    name: PickAdminTemplate.name,
    component: () => import('@/modules/AdminTemplates/components/AdminTemplatesPicker.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: PreviewAdminTemplate.path,
    name: PreviewAdminTemplate.name,
    component: () => import('@/modules/AdminTemplates/components/PreviewAdminTemplate.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
];

export default adminTemplateRoutes;
