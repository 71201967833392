export const adminMessageCenterBaseUrl = 'components/admin-message-center/api';

export const fetchMessageLogsEndpoint = 'get-messages.aspx';
export const deleteMessageEndpoint = 'delete-message.aspx';
export const getMessageDetailsEndpoint = 'get-message.aspx';
export const saveMessageDetailsEndpoint = 'save-message.aspx';
export const fetchMessageThreads = 'get-message-threads.aspx';
export const fetchMessageThread = 'get-message-thread.aspx';
export const changeReadStatusEndpoint = 'update-read-status.aspx';
export const sendThreadReplyEndpoint = 'send-reply.aspx';
export const endScheduleMessageEndpoint = 'end-schedule.aspx';
