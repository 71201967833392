import { chartLibraryItemsInternalList } from '@/modules/Analytics/utils/constants.chartLibrary';

export const analyticsGetters = {
  analyticsApiBaseUrl: (state, getters, rootState) => rootState.common.apiUrl,
  connectedDeviceChartData: (state) => state.chartData,
  connectedDeviceFormattedData: (state) => state.formattedChartData,
  permissions: (state) => state.permissions,
  dashboardName: (state) => state.dashboardBuilderName,
  dashboardPermission: (state) => state.dashboardBuilderPermissions,
  chartLibrary: () => {
    const items = structuredClone(chartLibraryItemsInternalList);
    items.map((category) => {
      category.charts.sort((a, b) => {
        if (a.dateRangeType === b.dateRangeType) {
          return a.chartName.localeCompare(b.chartName);
        }
        return b.dateRangeType.localeCompare(a.dateRangeType);
      });
      return category;
    });
    return items;
  },
  allAdminRoles: (state) => state.adminRolesDashboardBuilderDropdown,
  allAdminUsers: (state) => state.adminUsersDashboardBuilderDropdown,
  allAdminEditorUsers: (state) => state.adminEditorUsersDashboardBuilderDropdown,
  selectedAdminRolesDashboardBuilder: (state) => state.selectedAdminRolesDashboardBuilder,
  selectedAdminUsersDashboardBuilder: (state) => state.selectedAdminUsersDashboardBuilder,
  selectedAdminUsersDashboardBuilderToView: (state) => state.selectedAdminUsersDashboardBuilderToView,
  dashboards: (state) => state.dashboards,
  dashboardLayout: (state) => state.dashboardLayout,
  dashboardNameValid: (state) => state.dashboardBuilderNameValid,
  dashboardAssignUserAccessValid: (state) => state.dashboardAssignUserAccessValid,
  dashboardAssignRoleAccessValid: (state) => state.dashboardAssignRoleAccessValid,
  dashboardAssignUserViewAccessValid: (state) => state.dashboardAssignUserViewAccessValid,
  dashboardFormValid: (state) => state.dashboardFormValid,
  isEditMode: (state) => state.isEditMode,
  selectedDashboard: (state) => state.selectedDashboard,
  defaultDashboard: (state) => {
    const defaultDashboards = state.dashboards.filter((dashboard) => dashboard.isDefault);
    return defaultDashboards.length > 0 ? defaultDashboards[0] : null;
  },
  singleDateFilter: (state) => state.singleDateFilter,
  singleDateFilterValueStart: (state) => state.singleDateFilterValueStart,
  singleDateFilterValueEnd: (state) => state.singleDateFilterValueEnd,
  filters: (state) => state.filters,
};
