// endpoints

export const refreshEndpoint = '/ajax/token-refresh.aspx';

// legacy

export const accessToken = 'access_token';
export const profile = 'profile';
export const accessTokenFromLegacy = 'accessTokenFromLegacy';
export const CSRFToken = 'CSRFToken';
export const contactId = 'contactId';
export const adminBiometricsBaseUrl = 'components/admin-biometrics/ajax.aspx';

// verbiage

export const defaultSuccessMsg = 'Your request has been completed successfully.';
export const defaultErrorMsg = 'An error has occurred. Please try again later.';
export const dateFormat = 'MM/dd/yyyy';
export const timestampFormat = 'MM/dd/yyyy t';

// types

export const epochStart = new Date('1970-1-1');

// config
export const adminRoutes = ['triggers-admin', 'social-circles-admin', 'menu-management'];

// Status Codes
export const StatusCodes = {
  NoContent: 204,
};

// SignalR Methods
export const signalRMethods = {
  clearNotifications: 'clearNotifications',
  showNotification: 'showNotification',
  messageSent: 'messageSent',
  messageRead: 'messageRead',
  refreshNotifications: 'refreshNotifications',
  refreshMessages: 'refreshMessages',
};

export const FusionChartsLicenseKey =
  '0oI2cB-21A-16E2D6E2B3B3G3H3A5D4E2E6D4C4sbdC8D5mmaB-8jE1G2awe1C2A3E2E3D3F3C9A4E5D4G3H3G2A33A18B14wjjB4A2H4jB2A16A7D-16buE3A3H2sudB2D3D2wikF1C10B8D5E5E3F4E2H4I3B8lffF4E2UH3vraE4D2C2pcC1DB4A2yyjD1B1G4D2B5B3E4C4D2B1D4C1B1C7p==';
