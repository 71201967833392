import {
  tangoStoreBasePathUrl,
  availableDollarAmountUrl,
  TangoCatalogs,
  userEmailPathUrl,
  orderPathUrl,
} from '@/modules/TangoStore/utils/endpoints';

import router from '@/router/index';
import { TangoStoreClosed } from '@/modules/TangoStore/router/tangoStoreRoutesConstants';
import { TangoServiceErrorCode } from '@/modules/TangoStore/utils/constants';
import mkNetworkRequestWrapper from '@/utils/requestWrapper';

function processTangoApiError({ dispatch }, err) {
  if (err?.response?.status === 400 && err?.response?.data?.statusCode === TangoServiceErrorCode.TangoStoreNotEnabled) {
    router.push({ name: TangoStoreClosed.name });
  } else {
    dispatch('common/displayErrorNotification', err, { root: true });
  }
}

export const tangoStoreActions = {
  setCurrentCard({ commit }, payload) {
    commit('SET_SELECTED_CARD', payload);
  },
  setFixedCardValue({ commit }, payload) {
    commit('SET_CARD_FIXED_VALUE', payload);
  },
  setVariableCardValue({ commit }, payload) {
    commit('SET_CARD_VARIABLE_VALUE', payload);
  },
  addNewItemToCart({ commit }, payload) {
    commit('ADD_ITEM_TO_CART', payload);
  },
  setCardQuantity({ commit }, payload) {
    commit('SET_CARD_QUANTITY', payload);
  },
  resetRedemptionPageState({ commit }) {
    commit('RESET_REDEMPTION_PAGE_STATE');
  },
  saveLocalCartList({ commit }, payload) {
    commit('STORE_CART_LIST', payload);
  },
  restoreCartList({ commit }, payload) {
    commit('SET_CART_LIST', payload);
  },
  async loadAvailableDollarAmount({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.tangoStoreApiBaseUrl}${availableDollarAmountUrl}`,
        method: 'GET',
      };

      const getAvailableDollarResponse = await mkNetworkRequestWrapper(options);

      commit('SET_USER_BALANCE', getAvailableDollarResponse.data.availableDollarAmount);
      commit('SET_ANNUAL_LIMIT', getAvailableDollarResponse.data.annualLimit);
    } catch (err) {
      processTangoApiError({ commit, getters, dispatch }, err);
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  setAnnualLimitMessageDisplayedOnce({ commit }) {
    commit('SET_ANNUAL_LIMIT_MESSAGE_DISPLAYED_ONCE');
  },
  async loadCatalog({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.tangoStoreApiBaseUrl}${tangoStoreBasePathUrl}/${TangoCatalogs}`,
        method: 'GET',
      };
      const catalogsResponse = await mkNetworkRequestWrapper(options);

      return catalogsResponse.data.data;
    } catch (err) {
      processTangoApiError({ commit, getters, dispatch }, err);
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async updateUserEmail({ commit, getters, dispatch }, payload) {
    try {
      const options = {
        url: `${getters.tangoStoreApiBaseUrl}${userEmailPathUrl}`,
        method: 'POST',
        data: payload,
      };
      const response = await mkNetworkRequestWrapper(options);

      return response.data.data;
    } catch (err) {
      processTangoApiError({ commit, getters, dispatch }, err);
      return err;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async placeOrder({ commit, getters, dispatch }, payload) {
    try {
      const options = {
        url: `${getters.tangoStoreApiBaseUrl}${tangoStoreBasePathUrl}${orderPathUrl}`,
        method: 'POST',
        data: { order: {}, items: payload.order },
      };
      const response = await mkNetworkRequestWrapper(options);
      commit('SET_CART_LIST', []);
      commit('SET_CUSTOMER_ID', response.data.data.customerId);
      localStorage.setItem('TangoCartList' + payload.contactId, JSON.stringify([]));
      return response.data.data;
    } catch (err) {
      processTangoApiError({ commit, getters, dispatch }, err);
      return err;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
