<template>
  <v-app-bar class="app-bar pr-2" flat>
    <v-app-bar-nav-icon class="btn--nav" v-on:click="handleOpenNavSidebar()" />

    <v-tooltip bottom>
      <template v-slot:activator="{ on: hoverActivator }">
        <v-btn v-if="customHomeIconUrl" v-on="hoverActivator" icon class="btn--nav app-bar-logo ml-3">
          <img v-bind:src="customHomeIconUrl" class="icon--nav" v-on:click="handleHomeClick()" />
        </v-btn>
      </template>
      <span>{{ navbarItems['home'].text }}</span>
    </v-tooltip>

    <v-spacer />

    <v-tooltip bottom>
      <template v-slot:activator="{ on: hoverActivator }">
        <v-btn v-on="hoverActivator" icon class="btn--nav">
          <v-badge
            color
            class="badge--nav"
            :content="totalUnreadCount"
            :value="totalUnreadCount"
            offset-x="10"
            offset-y="5"
          >
            <v-icon
              v-bind:class="navbarItems['messages'].iconClass"
              class="icon--nav"
              v-on:click="handleMessagesIconClick()"
            />
          </v-badge>
        </v-btn>
      </template>
      <span>{{ navbarItems['messages'].text }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on: hoverActivator }">
        <v-btn v-on="hoverActivator" icon class="btn--nav">
          <v-icon v-bind:class="navbarItems['contrast'].iconClass" class="icon--nav" />
        </v-btn>
      </template>
      <span>{{ navbarItems['contrast'].text }}</span>
    </v-tooltip>

    <v-menu v-model="showMenu" offset-y left min-width="200px">
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: hoverActivator }">
            <div v-on="on" v-bind="attrs" class="btn--nav">
              <v-btn v-on="hoverActivator" icon>
                <v-icon v-bind:class="navbarItems['account'].iconClass" class="icon--nav" />
              </v-btn>
            </div>
          </template>
          <span>{{ navbarItems['account'].text }}</span>
        </v-tooltip>
      </template>

      <v-list class="account-items-list">
        <v-list-item class="px-3 py-0" v-bind:href="myAccountFullUrl">
          <v-list-item-title class="d-flex align-center">
            <v-icon class="mr-1" v-bind:class="navbarItems['myaccount'].iconClass" />
            {{ navbarItems['myaccount'].text }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="px-3 py-0" v-if="faqEnabled" v-on:click="handleFaqClick()">
          <v-list-item-title class="d-flex align-center">
            <v-icon class="mr-1" v-bind:class="navbarItems['faq'].iconClass" />
            {{ faqName }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="px-3 py-0">
          <v-list-item-title class="d-flex align-center">
            <v-icon class="mr-1" v-bind:class="navbarItems['signout'].iconClass" />
            {{ navbarItems['signout'].text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip bottom>
      <template v-slot:activator="{ on: hoverActivator }">
        <v-btn v-bind:href="myPalsFullUrl" v-if="showGroupIcon" v-on="hoverActivator" icon class="btn--nav pals">
          <v-badge
            v-bind:value="showPalRequestsBadge"
            color
            class="badge--nav"
            v-bind:content="numberOfPalRequests"
            offset-x="10"
            offset-y="5"
          >
            <v-icon v-bind:class="navbarItems['pals'].iconClass" class="icon--nav" />
          </v-badge>
        </v-btn>
      </template>
      <span>{{ navbarItems['pals'].text }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on: hoverActivator }">
        <v-btn v-if="conciergeEnabled" v-on="hoverActivator" icon class="btn--nav chat">
          <v-icon v-bind:class="navbarItems['chat'].iconClass" class="icon--nav" v-on:click="handleChatIconClick()" />
        </v-btn>
      </template>
      <span>{{ navbarItems['chat'].text }}</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import {
  navbarItems,
  modalTypeString,
  modalTypeComponent,
  legacyMyAccountUrl,
  legacyMyPalsUrl,
} from '@/modules/Common/utils/constants';
import {
  enableSocialProfileOptionId,
  disablePalsFunctionalityOptionId,
  enableTranslationOptionId,
  enableConciergeChatOptionId,
  enableFaqOptionId,
  faqCopyOptionId,
  localizationOptionId,
  localizationEnglishEspanolOptionValue,
  faqNameOptionId,
  whiteHeroLogoOptionId,
} from '@/modules/PlatformOptions/utils';
import { mapGetters } from 'vuex';
import { isStringNullUndefinedOrWhitespace } from '@/utils/helperFunctions';
import MessagesNotificationsWrapper from '@/modules/Common/components/MessagesNotificationsWrapper';
import ChatSessionsSupportWrapper from '@/modules/Common/components/ChatSessionsSupportWrapper';
import SidebarNavigation from '@/modules/Common/components/SidebarNavigation';
import { isNullOrUndefined } from '../../../utils/helperFunctions';

export default {
  name: 'Navbar',
  data() {
    return {
      modalTypeString: modalTypeString,
      modalTypeComponent: modalTypeComponent,
      navbarItems: navbarItems,
      enableSocialProfileOptionId: enableSocialProfileOptionId,
      disablePalsFunctionalityOptionId: disablePalsFunctionalityOptionId,
      enableTranslationOptionId: enableTranslationOptionId,
      enableConciergeChatOptionId: enableConciergeChatOptionId,
      enableFaqOptionId: enableFaqOptionId,
      faqCopyOptionId: faqCopyOptionId,
      localizationOptionId: localizationOptionId,
      localizationEnglishEspanolOptionValue: localizationEnglishEspanolOptionValue,
      legacyMyAccountUrl: legacyMyAccountUrl,
      legacyMyPalsUrl: legacyMyPalsUrl,
      faqNameOptionId: faqNameOptionId,
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      konfigOptions: 'platformOptions/currentUserKonfigOptions',
      originUriLegacy: 'common/originUriLegacy',
      booleanOptionValue: 'platformOptions/booleanOptionValue',
      optionValue: 'platformOptions/optionValue',
      mockNumberOfPalRequests: 'common/mockNumberOfPalRequests',
      totalUnreadNotificationCount: 'common/totalUnreadNotificationCount',
      totalUnreadMessagesCount: 'common/totalUnreadMessagesCount',
    }),
    customHomeIconUrl: function() {
      const navUrl = this.konfigOptions.find((s) => s.optionId === whiteHeroLogoOptionId);
      return navUrl && !isStringNullUndefinedOrWhitespace(navUrl.optionValue) ? navUrl.optionValue : null;
    },
    socialProfileEnabled: function() {
      return this.booleanOptionValue(this.enableSocialProfileOptionId);
    },
    palsFunctionalityEnabled: function() {
      return !this.booleanOptionValue(this.disablePalsFunctionalityOptionId);
    },
    conciergeEnabled: function() {
      return this.booleanOptionValue(this.enableConciergeChatOptionId);
    },
    faqEnabled: function() {
      return this.booleanOptionValue(this.enableFaqOptionId);
    },
    faqName: function() {
      return this.optionValue(this.faqNameOptionId);
    },
    showGroupIcon: function() {
      return this.socialProfileEnabled && this.palsFunctionalityEnabled;
    },
    myAccountFullUrl: function() {
      return `${this.originUriLegacy}${this.legacyMyAccountUrl}`;
    },
    myPalsFullUrl: function() {
      return `${this.originUriLegacy}${this.legacyMyPalsUrl}`;
    },
    showPalRequestsBadge: function() {
      return !isNullOrUndefined(this.mockNumberOfPalRequests) && Number(this.mockNumberOfPalRequests) > 0;
    },
    numberOfPalRequests: function() {
      return Number(this.mockNumberOfPalRequests);
    },
    totalUnreadCount: function() {
      const totalCount = this.totalUnreadNotificationCount + this.totalUnreadMessagesCount;
      return totalCount <= 10 ? totalCount : '10+';
    },
  },
  methods: {
    handleOpenNavSidebar: async function() {
      await this.$root.$sidebar.openSidebar({
        type: modalTypeComponent,
        content: SidebarNavigation,
      });
    },
    handleFaqClick: async function() {
      // get the id of the correct FAQ copy markup
      const faqId = this.optionValue(faqCopyOptionId);

      // use the id to access the markup value
      const faqText = isNaN(faqId) ? faqId : this.optionValue(Number(faqId));
      if (isStringNullUndefinedOrWhitespace(faqText)) {
        return;
      }

      await this.$root.$sidebar.openSidebar({
        type: modalTypeString,
        content: faqText,
        right: true,
      });
    },
    handleMessagesIconClick: async function() {
      await this.$root.$sidebar.openSidebar({
        type: modalTypeComponent,
        content: MessagesNotificationsWrapper,
        right: true,
      });
    },
    handleChatIconClick: async function() {
      await this.$root.$sidebar.openSidebar({
        type: modalTypeComponent,
        content: ChatSessionsSupportWrapper,
        right: true,
      });
    },
    handleHomeClick: function() {
      const homeRoute =
        this.originUriLegacy && this.originUriLegacy.length > 0
          ? `${this.originUriLegacy}/modules/myMedikeeper/Redirect.aspx`
          : 'https://medikeeper.com';

      window.location.href = homeRoute;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tooltip__content {
  // this hides tooltips on mobile-sized viewports
  display: none;
  @include media-sm {
    display: inline-block;
  }

  z-index: 10 !important;
}
::v-deep .v-badge__badge {
  background-color: $navbarNotificationOrange;
}
.link {
  text-decoration: none;
}
.account-items-list {
  max-width: 200px;

  .v-list-item {
    min-height: 2.8rem;

    .v-list-item__title {
      color: $mediumDarkGray;
      font-size: 16px;
    }

    .v-icon {
      color: $mediumDarkGray;
      font-size: 24px;
      min-width: 1.5rem;
    }
  }
}
.app-bar {
  &.v-app-bar {
    &.v-toolbar {
      &.v-sheet {
        background-color: $mediumGray;

        .btn--nav {
          max-width: 12%;

          .v-icon {
            color: #fff;
            font-size: 1.5rem;
          }

          img {
            height: 25px;
          }

          &.app-bar-logo {
            width: auto;
          }
        }
      }
    }
  }
}
</style>
