export const triggersBasePathUrl = '/triggers';
export const triggersUpdateStatusUrl = '/status';
export const triggersUpdateUrl = '/update';
export const triggersUpdateNameAndDescriptionUrl = '/updateNameAndDescription';
export const triggersForAssignmentUrl = '/assignment';

// Trigger condition endpoints
const triggerConditionBasePath = `${triggersBasePathUrl}/conditions`;
export const triggerConditionCategoriesUrl = `${triggerConditionBasePath}/categories`;
export const triggerConditionTopicsUrl = '/topics';
export const triggerConditionStatementsUrl = '/statements';
