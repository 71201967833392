import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { initialMenuItemResource, menuItemComponentTypes, menuItemSources } from '@/modules/AdminMenuManagement/utils';
import { adminMenuManagementSearchActions } from '@/modules/AdminMenuManagement/store/actions/menuSearch.actions.js';
import { menuActions } from '@/modules/AdminMenuManagement/store/actions/menu.actions.js';
import { menuItemActions } from '@/modules/AdminMenuManagement/store/actions/menuItem.actions.js';
import { menuGroupActions } from '@/modules/AdminMenuManagement/store/actions/menuGroup.actions.js';

export const adminMenuManagementActions = {
  ...menuItemActions,
  ...menuActions,
  ...adminMenuManagementSearchActions,
  ...menuGroupActions,
  resetMenuItemResource({ commit }) {
    commit('RESET_MENU_ITEM_RESOURCE', { ...initialMenuItemResource });
  },
  setCurrentStep({ commit }, payload) {
    commit('SET_CURRENT_STEP', payload);
  },
  setEditMenuName({ commit }, payload) {
    commit('SET_EDIT_MENU_NAME', payload);
  },
  setEditMenuSelectedMenu({ commit }, payload) {
    commit('SET_EDIT_MENU_SELECTED_MENU', payload);
  },
  setEditMenuGroups({ commit }, payload) {
    commit('SET_EDIT_MENU_GROUPS', payload);
  },
  setEditMenuStatus({ commit }, payload) {
    commit('SET_EDIT_MENU_STATUS', payload);
  },
  setEditMenuItems({ commit }, payload) {
    commit('SET_EDIT_MENU_ITEMS', payload);
  },
  setCreateNewMenuDesignMethod({ commit }, payload) {
    commit('SET_CREATE_NEW_MENU_DESIGN_METHOD', payload);
  },
  setSelectedComponentId({ commit }, componentId) {
    commit('SET_SELECTED_COMPONENT_ID', componentId);
  },
  setMenuItemResourceType({ commit }, payload) {
    commit('SET_MENU_ITEM_RESOURCE_TYPE', payload);
    commit('SET_SELECTED_STANDARD_COMPONENT', null);
    commit('SET_SELECTED_THIRD_PARTY_LINK', null);
  },
  setMenuItemType({ commit }, payload) {
    commit('SET_MENU_ITEM_TYPE', payload);
  },
  setMenuItemIcon({ commit }, payload) {
    commit('SET_MENU_ITEM_ICON', payload);
  },
  setMenuItemResourceUrl({ commit }, payload) {
    commit('SET_MENU_ITEM_RESOURCE_URL', payload);
  },
  setSelectedParentMenuItemId({ commit }, parentMenuItemId) {
    commit('SET_SELECTED_PARENT_MENU_ITEM_ID', parentMenuItemId);
  },
  setValidationErrors({ commit }, validationErrors) {
    commit('SET_VALIDATION_ERRORS', validationErrors);
  },
  setSelectedStandardComponent({ commit }, standardComponent) {
    commit('SET_SELECTED_STANDARD_COMPONENT', standardComponent);

    if (standardComponent.source === menuItemSources.predefined) {
      commit('SET_PREDEFINED_ITEMS', standardComponent.items);
    }
  },
  setSelectedThirdPartyLink({ commit, dispatch, getters }, thirdPartyLink) {
    commit('SET_SELECTED_THIRD_PARTY_LINK', thirdPartyLink);

    if (thirdPartyLink.source === menuItemSources.predefined && thirdPartyLink.items.length !== 0) {
      commit('SET_PREDEFINED_ITEMS', thirdPartyLink.items);
    } else if (thirdPartyLink.source === menuItemSources.dynamic) {
      if (thirdPartyLink.componentType === menuItemComponentTypes.avidonPages && getters.avidonPages.length === 0) {
        dispatch('getAvidonPages');
      } else if (
        thirdPartyLink.componentType === menuItemComponentTypes.avidonCourse &&
        getters.avidonCourses.length === 0
      ) {
        dispatch('getAvidonCourses');
      } else if (
        thirdPartyLink.componentType === menuItemComponentTypes.avidonFlow &&
        getters.avidonFlows.length === 0
      ) {
        dispatch('getAvidonFlows');
      }
    }
  },
  async getAvidonPages({ commit, getters }) {
    try {
      commit('SET_LOADING_ITEMS', true);

      const selectedGroups = getters.selectedGroups;
      const groupId = selectedGroups[0].groupId;

      const options = {
        url: `${getters.adminMenuManagementApiBaseUrl}/avidon/pages/${groupId}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapper(options);
      const responseData = response.data.data;

      commit('SET_AVIDON_PAGES', responseData.pages);
      commit('SET_LOADING_ITEMS', false);
    } catch (e) {
      return;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getAvidonCourses({ commit, getters }) {
    try {
      commit('SET_LOADING_ITEMS', true);

      const selectedGroups = getters.selectedGroups;
      const groupId = selectedGroups[0].groupId;

      const options = {
        url: `${getters.adminMenuManagementApiBaseUrl}/avidon/courses/${groupId}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapper(options);
      const responseData = response.data.data;

      commit('SET_AVIDON_COURSES', responseData.courses);
      commit('SET_LOADING_ITEMS', false);
    } catch (e) {
      return;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getAvidonFlows({ commit, getters }) {
    try {
      commit('SET_LOADING_ITEMS', true);

      const selectedGroups = getters.selectedGroups;
      const groupId = selectedGroups[0].groupId;

      const options = {
        url: `${getters.adminMenuManagementApiBaseUrl}/avidon/flows/${groupId}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapper(options);
      const responseData = response.data.data;

      commit('SET_AVIDON_FLOWS', responseData.flows);
      commit('SET_LOADING_ITEMS', false);
    } catch (e) {
      return;
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
