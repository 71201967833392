export const tangoStoreMutations = {
  SET_USER_BALANCE(state, userBalance) {
    state.userBalance = userBalance;
  },
  SET_ANNUAL_LIMIT(state, annualLimit) {
    state.annualLimit = annualLimit;
  },
  SET_ANNUAL_LIMIT_MESSAGE_DISPLAYED_ONCE(state) {
    state.annualLimitMessageDisplayedOnce = true;
  },
  SET_SELECTED_CARD(state, card) {
    state.selectedCard = card;
  },
  SET_CARD_FIXED_VALUE(state, value) {
    state.newCartItem.fixedValue = value;
  },
  SET_CARD_VARIABLE_VALUE(state, value) {
    state.newCartItem.variableValue = value;
  },
  SET_CARD_QUANTITY(state, num) {
    state.newCartItem.quantity = num;
  },
  ADD_ITEM_TO_CART(state, item) {
    state.cart.push(item);
  },
  RESET_REDEMPTION_PAGE_STATE(state) {
    state.newCartItem.quantity = 1;
    state.newCartItem.fixedValue = null;
    state.newCartItem.variableValue = null;
  },
  STORE_CART_LIST(state, data) {
    localStorage['TangoCartList' + data.contactId] = data.value;
  },
  SET_CART_LIST(state, value) {
    state.cart = value ?? [];
  },
  SET_CUSTOMER_ID(state, id) {
    state.customerId = id;
  },
};
