import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import {
  commentsForPostUrl,
  allCommunitiesUrl,
  communityRootUrl,
  joinCommunityUrl,
  leaveCommunityUrl,
  getCommunityDetailsUrlStart,
  getCommunityDetailsUrlEnd,
  createPostUrl,
  editPostUrl,
  flagPostUrl,
  flagCommentUrl,
  pinPostUrl,
  reactPostUrl,
  reactCommentUrl,
  postInfoForCommunityUrl,
  pinnedPostInfoForCommunityUrl,
  postRootUrl,
  createCommentUrl,
  editCommentUrl,
  getCommunityMembersUrlStart,
  getCommunityMembersUrlEnd,
  commentRootUrl,
  searchCommunityMembersUrlEnd,
  getCommunityLeadersUrlStart,
  getCommunityLeadersUrlEnd,
  getAllCommunitiesUrl,
  getAllFlaggedPostAndCommentsUrl,
  getCommunitiesByIds,
  getPostsByIds,
  getCommentsByIds,
  getSocialProfilesByIds,
  dismissFlaggedPostUrl,
  dismissFlaggedCommentUrl,
  deleteFlaggedPostUrl,
  deleteFlaggedCommentUrl,
  getAvailableUsersUrl,
  searchAvailableUsersUrl,
  communityMembersUrl,
  removeCommunityMembersUrl,
  communityLeaderUrl,
  removeCommunityLeaderUrl,
  createCommunityUrl,
  editCommunityUrl,
  disableCommunityUrl,
  enableCommunityUrl,
  getCommunityGroupsUrl,
  getCommunityHeroItemsUrlStart,
  getCommunityHeroItemsUrlEnd,
  enrolledMembersPageSize,
  searchAvailableCommunityMembersUrlEnd,
  searchAvailableCommunityLeadersUrlEnd,
  privacySettingsUrlStart,
  privacySettingsUrlEnd,
  communityAdminRootUrl,
  communityTriggerAssignmentsUrl,
} from '@/modules/SocialCommunities/utils/constants';
import { httpErrorCodeUnauthorized, snackbarTypeSuccess } from '@/modules/Common/utils/constants';
import { defaultSuccessMsg } from '@/utils/constants';
import { isNullOrUndefined, isStringNullUndefinedOrEmpty } from '@/utils/helperFunctions';
import { managedGroupsShowLoadingScreenThreshold } from '../../Groups/utils/constants';

export const socialCommunitiesActions = {
  // non-network methods

  resetCommunityWallState({ commit }, payload) {
    commit('RESET_COMMUNITY_WALL_STATE', Number(payload));
  },
  resetCommunityInfoForAdmin({ commit }) {
    commit('RESET_COMMUNITY_INFO_ADMIN');
  },
  setCurrentCommunityId({ commit }, payload) {
    commit('SET_CURRENT_COMMUNITY_ID', Number(payload));
  },
  setCommunityFilterId({ commit }, payload) {
    commit('SET_COMMUNITY_FILTER_ID', payload);
  },
  incrementCommunityManagementTotalCount({ commit }) {
    commit('INCREMENT_COMMUNITY_MANAGEMENT_TOTAL_COUNT');
  },
  decrementCommunityManagementTotalCount({ commit }) {
    commit('DECREMENT_COMMUNITY_MANAGEMENT_TOTAL_COUNT');
  },
  updateCurrentCommunity({ commit, getters }, payload) {
    commit('UPDATE_CURRENT_COMMUNITY', {
      communityId: Number(getters.currentCommunityId),
      communityInfo: payload.communityInfo,
    });
    commit('REMOVE_CURRENT_COMMUNITY_HERO_ITEMS', Number(getters.currentCommunityId));
    commit('SET_COMMUNITY_HERO_ITEMS', payload.heroItems);
  },
  openPostEditor({ commit }, payload) {
    commit('OPEN_POST_EDITOR', payload);
  },
  closePostEditor({ commit }) {
    commit('CLOSE_POST_EDITOR');
  },
  resetFilteredCommunityMembers({ commit }) {
    commit('RESET_FILTERED_COMMUNITY_MEMBERS');
  },
  setPaginationStartPointUsers({ commit }, payload) {
    commit('SET_PAGINATION_START_POINT_USERS', payload);
  },
  setPaginationStartPointMembers({ commit }, payload) {
    commit('SET_PAGINATION_START_POINT_MEMBERS', payload);
  },
  setSearchInputUsers({ commit }, payload) {
    commit('SET_SEARCH_INPUT_USERS', payload);
  },
  setSearchInputMembers({ commit }, payload) {
    commit('SET_SEARCH_INPUT_MEMBERS', payload);
  },
  setCreateCommunityDesignMethod({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_DESIGN_METHOD', payload);
  },
  setCreateCommunityCategory({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_CATEGORY', payload);
  },
  setCreateCommunityTemplate({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_TEMPLATE', payload);
  },
  setCreateCommunityIcon({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_ICON', payload);
  },
  setCreateCommunityBackgroundGradient({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_BACKGROUND_GRADIENT', payload);
  },
  setCreateCommunityName({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_NAME', payload);
  },
  setCreateCommunityDescription({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_DESCRIPTION', payload);
  },
  setCreateCommunityGroups({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_GROUPS', payload);
  },
  setCreateCommunityLeadersOnly({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_LEADERS_ONLY', payload);
  },
  setCreateCommunityTriggers({ commit }, payload) {
    commit('SET_CREATE_COMMUNITY_TRIGGERS', payload);
  },
  addCreateCommunityHeroImage({ commit }, payload) {
    commit('ADD_CREATE_COMMUNITY_HERO_IMAGE', payload);
  },
  removeCreateCommunityHeroImage({ commit }, payload) {
    commit('REMOVE_CREATE_COMMUNITY_HERO_IMAGE', payload);
  },
  removeCreateCommunityAllHeroImages({ commit }) {
    commit('REMOVE_CREATE_COMMUNITY_ALL_HERO_IMAGES');
  },
  setCurrentMembersEnrolledSearchString({ commit }, payload) {
    commit('SET_CURRENT_MEMBERS_ENROLLED_SEARCH_STRING', payload);
  },

  // network methods

  async getAllCommunitiesForUser({ dispatch, commit, getters }) {
    try {
      const res = await mkNetworkRequestWrapper({
        url: `${getters.socialCommunitiesApiBaseUrl}${allCommunitiesUrl}`,
        method: 'get',
      });
      commit('SET_COMMUNITY_INFO', !isNullOrUndefined(res.data.data) ? res.data.data.communities : []);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getCommunity({ dispatch, commit, getters }, communityId) {
    try {
      const res = await mkNetworkRequestWrapper({
        url: `${getters.socialCommunitiesApiBaseUrl}${communityRootUrl}/${communityId}`,
        method: 'get',
      });
      const communityPayload = res.data.data;
      const privacyPayload = res.data.data.privacyOptions;
      communityPayload.privacyOptions = null; // TODO: fix response so we're not mutating it at all
      commit('SET_COMMUNITY_INFO', [communityPayload]);
      commit('SET_COMMUNITY_PRIVACY_SETTINGS', [privacyPayload]);
      return res.data.data;
    } catch (err) {
      if (err.response && err.response.status === httpErrorCodeUnauthorized) {
        // we need to return something here so the notif can be dispatched from the wall to have
        // the correct reason, instead of a generic err msg
        return httpErrorCodeUnauthorized;
      } else {
        dispatch('common/displayErrorNotification', err, { root: true });
      }
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getCommunityPosts({ dispatch, commit, getters }, payload) {
    try {
      const res = await mkNetworkRequestWrapper({
        url: `${getters.socialCommunitiesApiBaseUrl}${postInfoForCommunityUrl}`,
        method: 'post',
        data: payload,
      });
      const totalCountPayload = [
        {
          communityId: payload.communityId,
          totalPostCount: !isNullOrUndefined(res.data.data.totalPostCount) ? Number(res.data.data.totalPostCount) : 0,
        },
      ];
      commit('SET_COMMUNITY_TOTAL_POST_COUNT', totalCountPayload);
      const posts = res.data.data.posts;
      commit('SET_COMMUNITY_POSTS', posts);
      const postPins = res.data.data.postPins;
      commit('SET_COMMUNITY_POST_PINS', postPins);
      const postReactions = res.data.data.postReactions;
      commit('SET_COMMUNITY_POST_REACTIONS', postReactions);
      const postFlags = res.data.data.postFlags;
      commit('SET_COMMUNITY_POST_FLAGS', postFlags);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getCommunityPinnedPosts({ dispatch, commit, getters }, communityId) {
    try {
      const res = await mkNetworkRequestWrapper({
        url: `${getters.socialCommunitiesApiBaseUrl}${pinnedPostInfoForCommunityUrl}${communityId}`,
        method: 'get',
      });
      const posts = res.data.data.posts;
      commit('SET_COMMUNITY_POSTS', posts);
      const postPins = res.data.data.postPins;
      commit('SET_COMMUNITY_POST_PINS', postPins);
      const postReactions = res.data.data.postReactions;
      commit('SET_COMMUNITY_POST_REACTIONS', postReactions);
      const postFlags = res.data.data.postFlags;
      commit('SET_COMMUNITY_POST_FLAGS', postFlags);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getCommentsForPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${commentsForPostUrl}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const comments = res.data.data.comments;
      commit('SET_COMMUNITY_COMMENTS', comments);
      const commentReactions = res.data.data.commentReactions;
      commit('SET_COMMUNITY_COMMENT_REACTIONS', commentReactions);
      const commentFlags = res.data.data.commentFlags;
      commit('SET_COMMUNITY_COMMENT_FLAGS', commentFlags);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getCommunityHeroItems({ dispatch, commit, getters }, communityId) {
    try {
      const res = await mkNetworkRequestWrapper({
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityHeroItemsUrlStart}${communityId}${getCommunityHeroItemsUrlEnd}`,
        method: 'get',
      });
      let payload = res.data.data;
      commit('SET_COMMUNITY_HERO_ITEMS', payload);
      return res.data.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async createPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${createPostUrl}`,
        method: 'post',
        data: {
          text: payload.postText,
          communityId: payload.communityId,
          imageFileName: payload.postImageId,
          containsGif: payload.containsGif,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const postResponse = res.data.data;
      postResponse.commentCount = 0;
      commit('INCREMENT_COMMUNITY_POST_COUNT', Number(payload.communityId));
      commit('SET_COMMUNITY_POSTS', [postResponse]);
      return res;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async createComment({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${createCommentUrl}`,
        method: 'post',
        data: {
          text: payload.postText,
          parentPostId: payload.parentPostId,
          imageFileName: payload.postImageId,
          containsGif: payload.containsGif,
        },
      };
      const res = await mkNetworkRequestWrapper(options);

      // keeping in the null properties for now for consistency until backend refactor
      const commentResponse = res.data.data;
      const commentPayload = [commentResponse];
      commit('SET_COMMUNITY_COMMENTS', commentPayload);
      commit('INCREMENT_POST_COMMENT_COUNT', payload.parentPostId);
      return res;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async editPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${editPostUrl}`,
        method: 'post',
        data: {
          postId: payload.postId,
          text: payload.postText,
          imageFileName: payload.postImageId,
          containsGif: payload.containsGif,
        },
      };
      const res = await mkNetworkRequestWrapper(options);

      const postResponse = res.data.data;
      commit('UPDATE_EDITED_POST', postResponse);
      dispatch('closePostEditor');
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async editComment({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${editCommentUrl}`,
        method: 'post',
        data: {
          postId: payload.postId,
          text: payload.postText,
          imageFileName: payload.postImageId,
          containsGif: payload.containsGif,
        },
      };
      const res = await mkNetworkRequestWrapper(options);

      const commentResponse = res.data.data;
      commit('UPDATE_EDITED_COMMENT', commentResponse);
      dispatch('closePostEditor');
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async deletePost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${postRootUrl}`,
        method: 'delete',
        params: {
          postId: payload.postId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const deletePayload = {
        id: res.data.data.postId,
        communityId: Number(getters.currentCommunityId),
      };
      commit('DECREMENT_COMMUNITY_POST_COUNT', Number(getters.currentCommunityId));
      commit('DELETE_POST', deletePayload);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async deleteComment({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${commentRootUrl}`,
        method: 'delete',
        params: {
          postId: payload.postId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const deletePayload = {
        id: res.data.data.commentId,
        communityId: Number(getters.currentCommunityId),
      };
      const comment = getters.currentCommunityComments.find((c) => c.id === Number(payload.postId));
      if (!isNullOrUndefined(comment)) {
        commit('DECREMENT_POST_COMMENT_COUNT', comment.parentPostId);
      }
      commit('DELETE_COMMENT', deletePayload);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async getCommunityMembers({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityMembersUrlStart}${payload.communityId}${getCommunityMembersUrlEnd}`,
        method: 'get',
        params: {
          startPoint: payload.startPoint,
          pageSize: payload.pageSize,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const socialProfiles = !isNullOrUndefined(res.data.data.userProfiles) ? res.data.data.userProfiles : [];
      const socialProfilesPayload = socialProfiles.filter((sp) => !isNullOrUndefined(sp));
      commit('users/SET_SOCIAL_PROFILE', socialProfilesPayload, { root: true });

      const userInfos = !isNullOrUndefined(res.data.data.userInfos) ? res.data.data.userInfos : [];
      const userInfoPayload = userInfos.filter((ui) => !isNullOrUndefined(ui));

      commit('ADD_USER_INFO', userInfoPayload);

      const totalCountPayload = [
        {
          communityId: !isNullOrUndefined(res.data.data.communityId) ? Number(res.data.data.communityId) : 0,
          totalMemberCount: !isNullOrUndefined(res.data.data.totalMemberCount)
            ? Number(res.data.data.totalMemberCount)
            : 0,
        },
      ];
      commit('SET_COMMUNITY_TOTAL_MEMBER_COUNT', totalCountPayload);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getPagedCommunityMembers({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: isStringNullUndefinedOrEmpty(payload.link)
          ? `${getters.socialCommunitiesApiBaseUrl}${getCommunityMembersUrlStart}${payload.communityId}${getCommunityMembersUrlEnd}?pageSize=${enrolledMembersPageSize}`
          : payload.link,
        method: 'get',
      };
      const res = await mkNetworkRequestWrapper(options);
      const socialProfiles = !isNullOrUndefined(res.data.data.userProfiles) ? res.data.data.userProfiles : [];
      const communityMembers = socialProfiles.filter((sp) => !isNullOrUndefined(sp));
      const links = res.data.data.links;
      const totalMemberCount = res.data.data.totalMemberCount;
      commit('SET_CURRENT_MEMBERS_ENROLLED_META', {
        communityMembers,
        links,
        totalMemberCount,
        resetMembers: payload.resetMembers,
      });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getCommunityLeaders({ dispatch, commit, getters }, { communityId, startPoint, pageSize }) {
    const options = {
      url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityLeadersUrlStart}${communityId}${getCommunityLeadersUrlEnd}`,
      method: 'get',
      params: {
        startPoint,
        pageSize,
      },
    };
    try {
      const res = await mkNetworkRequestWrapper(options);
      const socialProfilesPayload = !isNullOrUndefined(res.data.data.communityLeadersSocialProfiles)
        ? res.data.data.communityLeadersSocialProfiles
        : [];
      commit('users/SET_SOCIAL_PROFILE', socialProfilesPayload, { root: true });
      const userInfoPayload = !isNullOrUndefined(res.data.data.communityLeadersInfo)
        ? res.data.data.communityLeadersInfo
        : [];
      commit('ADD_USER_INFO', userInfoPayload);
      const totalCountPayload = [
        {
          communityId: !isNullOrUndefined(res.data.data.communityId) ? Number(res.data.data.communityId) : 0,
          totalLeaderCount: !isNullOrUndefined(res.data.data.totalLeaderCount)
            ? Number(res.data.data.totalLeaderCount)
            : 0,
        },
      ];
      commit('SET_COMMUNITY_TOTAL_LEADER_COUNT', totalCountPayload);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getPagedCommunityLeaders({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: isStringNullUndefinedOrEmpty(payload.link)
          ? `${getters.socialCommunitiesApiBaseUrl}${getCommunityLeadersUrlStart}${payload.communityId}${getCommunityLeadersUrlEnd}?pageSize=${enrolledMembersPageSize}`
          : payload.link,
        method: 'get',
      };
      const res = await mkNetworkRequestWrapper(options);
      const socialProfiles = !isNullOrUndefined(res.data.data.communityLeadersSocialProfiles)
        ? res.data.data.communityLeadersSocialProfiles
        : [];
      const communityMembers = socialProfiles.filter((sp) => !isNullOrUndefined(sp));
      const links = res.data.data.links;
      const totalMemberCount = res.data.data.totalLeaderCount;
      commit('SET_CURRENT_MEMBERS_ENROLLED_META', {
        communityMembers,
        links,
        totalMemberCount,
        resetMembers: payload.resetMembers,
      });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async searchAvailableMembers({ dispatch, commit, getters }, payload) {
    // Set the current search string in state for the filtered members getter to use as a flag for members to return
    dispatch('setCurrentMembersEnrolledSearchString', payload.searchString);

    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityMembersUrlStart}${payload.communityId}${searchCommunityMembersUrlEnd}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const response = res.data.data;
      let socialProfilesPayload = [];
      let memberPayload = [];
      if (!isNullOrUndefined(response.communityMembers) && response.communityMembers.length > 0) {
        socialProfilesPayload = response.communityMembers
          .filter((m) => !isNullOrUndefined(m.userProfile))
          .map((m) => m.userProfile);
        memberPayload = response.communityMembers
          .filter((m) => !isNullOrUndefined(m.userProfile))
          .map((m) => m.userProfile.contactId);
      }
      commit('users/SET_SOCIAL_PROFILE', socialProfilesPayload, { root: true });
      commit('SET_CURRENT_COMMUNITY_FILTERED_MEMBERS', memberPayload);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async searchPagedAvailableMembers({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityMembersUrlStart}${payload.communityId}${searchAvailableCommunityMembersUrlEnd}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const communityMembers = !isNullOrUndefined(res.data.data.communityMembers) ? res.data.data.communityMembers : [];
      const totalMemberCount = res.data.data.totalMemberCount;
      commit('SET_CURRENT_MEMBERS_ENROLLED_META', {
        totalMemberCount,
        communityMembers,
        links: {},
        resetMembers: payload.resetMembers,
      });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async searchPagedAvailableLeaders({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityMembersUrlStart}${payload.communityId}${searchAvailableCommunityLeadersUrlEnd}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const communityMembers = !isNullOrUndefined(res.data.data.communityMembers) ? res.data.data.communityMembers : [];
      const totalMemberCount = res.data.data.totalMemberCount;
      commit('SET_CURRENT_MEMBERS_ENROLLED_META', {
        communityMembers,
        totalMemberCount,
        links: {},
        resetMembers: payload.resetMembers,
      });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async joinCommunity({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${joinCommunityUrl}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const id = Number(res.data.data.communityId);
      dispatch(
        'common/setNotification',
        {
          msg: res.data.message ? res.data.message : defaultSuccessMsg,
          type: snackbarTypeSuccess,
        },
        { root: true },
      );
      commit('TOGGLE_JOINED', id);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async leaveCommunity({ dispatch, commit, getters }, payload) {
    const options = {
      url: `${getters.socialCommunitiesApiBaseUrl}${leaveCommunityUrl}`,
      method: 'post',
      data: payload,
    };
    try {
      const res = await mkNetworkRequestWrapper(options);
      dispatch(
        'common/setNotification',
        {
          msg: res.data.message ? res.data.message : defaultSuccessMsg,
          type: 'info',
        },
        { root: true },
      );
      commit('TOGGLE_JOINED', payload.communityId);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async getCommunityDetails({ dispatch, commit, getters }, payload) {
    const options = {
      url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityDetailsUrlStart}${payload}${getCommunityDetailsUrlEnd}`,
      method: 'get',
    };
    try {
      const res = await mkNetworkRequestWrapper(options);
      const userInfoPayload = !isNullOrUndefined(res.data.data.recentMemberUserInfo)
        ? res.data.data.recentMemberUserInfo
        : [];
      commit('ADD_USER_INFO', userInfoPayload);
      return res.data.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async pinPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${pinPostUrl}`,
        method: 'post',
        data: {
          postId: payload.postId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const pin = res.data.data;
      commit('SET_COMMUNITY_POST_PINS', [pin]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async unpinPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${pinPostUrl}`,
        method: 'delete',
        params: {
          pinId: payload.pinId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const pinId = res.data.data.tagId;
      commit('REMOVE_COMMUNITY_POST_PINS', [pinId]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async flagPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${flagPostUrl}`,
        method: 'post',
        data: {
          postId: payload.postId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const flag = res.data.data;
      commit('SET_COMMUNITY_POST_FLAGS', [flag]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async unflagPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${flagPostUrl}`,
        method: 'delete',
        params: {
          flagId: payload.flagId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const flagId = res.data.data.tagId;
      commit('REMOVE_COMMUNITY_POST_FLAGS', [flagId]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async flagComment({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${flagCommentUrl}`,
        method: 'post',
        data: {
          postId: payload.postId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const flag = res.data.data;
      commit('SET_COMMUNITY_COMMENT_FLAGS', [flag]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async unflagComment({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${flagCommentUrl}`,
        method: 'delete',
        params: {
          flagId: payload.flagId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const flagId = res.data.data.tagId;
      commit('REMOVE_COMMUNITY_COMMENT_FLAGS', [flagId]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async reactPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${reactPostUrl}`,
        method: 'post',
        data: {
          postId: payload.postId,
          reactionType: payload.reactionType,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const reaction = res.data.data;
      commit('SET_COMMUNITY_POST_REACTIONS', [reaction]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async unreactPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${reactPostUrl}`,
        method: 'delete',
        params: {
          reactionId: payload.reactionId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const reactionId = res.data.data.tagId;
      commit('REMOVE_COMMUNITY_POST_REACTIONS', [reactionId]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async reactComment({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${reactCommentUrl}`,
        method: 'post',
        data: {
          postId: payload.postId,
          reactionType: payload.reactionType,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const reaction = res.data.data;
      commit('SET_COMMUNITY_COMMENT_REACTIONS', [reaction]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async unreactComment({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${reactCommentUrl}`,
        method: 'delete',
        params: {
          reactionId: payload.reactionId,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const reactionId = res.data.data.tagId;
      commit('REMOVE_COMMUNITY_COMMENT_REACTIONS', [reactionId]);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async getAllCommunitiesForAdmin({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getAllCommunitiesUrl}`,
        method: 'post',
        data: {
          communityStatusId: payload.communityStatusId,
          startPoint: payload.startPoint,
          pageSize: payload.pageSize,
        },
      };
      // the payload shape here is not exactly the same as the user side; we should
      // probably consolidate on the backend to simplify things since we are now
      // using the same mutation for both sides. see comment in mutations for more
      const res = await mkNetworkRequestWrapper(options);
      const communityPayload = !isNullOrUndefined(res.data.data) ? res.data.data.communities : [];
      const countPayload = !isNullOrUndefined(res.data.data) ? Number(res.data.data.totalCount) : 0;
      commit('SET_COMMUNITY_INFO_ADMIN', communityPayload);
      commit('SET_COMMUNITY_MANAGEMENT_TOTAL_COUNT', countPayload);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async disableCommunity({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${disableCommunityUrl}`,
        method: 'post',
        params: {
          communityId: payload,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      commit('DISABLE_COMMUNITY', {
        communityId: res.data.data.communityId,
        disableDateUtc: res.data.data.disableDateUtc,
        status: res.data.data.status,
      });
      if (getters.communityFilterId && getters.communityFilterId > 0) {
        commit('DECREMENT_COMMUNITY_MANAGEMENT_TOTAL_COUNT');
      }
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async enableCommunity({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${enableCommunityUrl}`,
        method: 'post',
        params: {
          communityId: payload,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      commit('ENABLE_COMMUNITY', {
        communityId: res.data.data.communityId,
        launchDateUtc: res.data.data.launchDateUtc,
        status: res.data.data.status,
      });
      if (getters.communityFilterId && getters.communityFilterId > 0) {
        commit('DECREMENT_COMMUNITY_MANAGEMENT_TOTAL_COUNT');
      }
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async createCommunity({ dispatch, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${createCommunityUrl}`,
        method: 'post',
        data: {
          communityName: payload.communityName,
          communityDescription: payload.communityDescription,
          communityGradient: payload.communityGradient,
          communityIcon: payload.communityIcon,
          CommunityHeroImageFileNames: payload.communityHeroImages,
          leadersOnly: payload.leadersOnly,
          assignedTriggers: payload.assignedTriggers,
          groupIds: payload.groupIds,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      return res.data.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async editCommunity({ dispatch, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${editCommunityUrl}`,
        method: 'post',
        data: {
          communityId: payload.communityId,
          communityName: payload.communityName,
          communityDescription: payload.communityDescription,
          communityGradient: payload.communityGradient,
          communityIcon: payload.communityIcon,
          communityHeroImageFileNames: payload.communityHeroImages,
          communityHeroImagesToDelete: payload.communityHeroImagesToDelete,
          leadersOnly: payload.leadersOnly,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      // we need to restructure this response entirely. we only need community details
      // and heroItems, and they should be returned as a flat object. see CommunityForm
      // line 441 for details
      return res.data.data.community;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async getAllFlaggedPostsAndComments({ dispatch, commit, getters }) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getAllFlaggedPostAndCommentsUrl}`,
        method: 'get',
      };

      const res = await mkNetworkRequestWrapper(options);

      const postFlags = res.data.data.flaggedPosts;
      commit('SET_COMMUNITY_POST_FLAGS', postFlags);

      const commentFlags = res.data.data.flaggedComments;
      commit('SET_COMMUNITY_COMMENT_FLAGS', commentFlags);

      // since flag info is decoupled from community info, we need to return unique communityIds
      // so we can fetch details about them and populate into state
      const uniqueCommunityIds = new Set(
        [...postFlags, ...commentFlags].map((p) => {
          return p.communityId;
        }),
      );

      // because the user profiles are entangled in the post, we are fetching unique contactIds
      // so the component can fetch profiles separately and pass to child components as a prop
      // instead of using a nested property
      const uniqueContactIds = new Set(
        [...postFlags, ...commentFlags].map((p) => {
          return p.postCreatedUserContactId;
        }),
      );
      return {
        communityIds: Array.from(uniqueCommunityIds),
        contactIds: Array.from(uniqueContactIds),
      };
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getCommunitiesByIds({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunitiesByIds}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      commit('SET_COMMUNITY_INFO', !isNullOrUndefined(res.data.data) ? res.data.data : []);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getSocialProfilesByIds({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getSocialProfilesByIds}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      commit('users/SET_SOCIAL_PROFILE', res.data.data, { root: true });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getPostsByIds({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getPostsByIds}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const posts = res.data.data;
      commit('SET_COMMUNITY_POSTS', posts);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getCommentsByIds({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommentsByIds}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const comments = res.data.data;
      commit('SET_COMMUNITY_COMMENTS', comments);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async dismissFlaggedPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${dismissFlaggedPostUrl}`,
        method: 'post',
        data: {
          postFlagIds: payload.postFlagIds,
          postId: Number(payload.postId),
          createdUserContactId: Number(payload.createdUserContactId),
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      commit('REMOVE_FLAGGED_POST', res.data.data);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async dismissFlaggedComment({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${dismissFlaggedCommentUrl}`,
        method: 'post',
        data: {
          postFlagIds: payload.postFlagIds,
          postId: Number(payload.postId),
          createdUserContactId: Number(payload.createdUserContactId),
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      // this response returns postId. we need to standardize this or make it less confusing
      // because the mutation calls for a commentId while the payload sends a postId
      commit('REMOVE_FLAGGED_COMMENT', res.data.data);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async deleteFlaggedPost({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${deleteFlaggedPostUrl}/${payload.postId}`,
        method: 'delete',
        params: {
          createdUserContactId: Number(payload.createdUserContactId),
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      commit('DELETE_FLAGGED_POST', res.data.data);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async deleteFlaggedComment({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${deleteFlaggedCommentUrl}/${payload.commentId}`,
        method: 'delete',
        params: {
          createdUserContactId: Number(payload.createdUserContactId),
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      // this response returns commentId, which is not inline with dismissing. we need to standardize
      // the name coming back
      commit('DELETE_FLAGGED_COMMENT', res.data.data);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async getCommunityGroups({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityGroupsUrl}`,
        method: 'get',
        params: {
          communityId: payload,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const communityGroups = isNullOrUndefined(res.data.data.communityGroups) ? [] : res.data.data.communityGroups;
      commit('SET_COMMUNITY_GROUPS', communityGroups);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async editCommunityGroups({ dispatch, commit, getters, rootGetters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityGroupsUrl}`,
        method: 'post',
        data: {
          communityId: payload.communityId,
          groupIds: payload.groupIds,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const communityGroups = isNullOrUndefined(res.data.data.communityGroups) ? [] : res.data.data.communityGroups;
      commit('SET_COMMUNITY_GROUPS', communityGroups);

      const notificationPayload = {
        msg: res.data.message ? res.data.message : defaultSuccessMsg,
        type: snackbarTypeSuccess,
        isClosable:
          !isNullOrUndefined(rootGetters['groups/managedGroups']) &&
          rootGetters['groups/managedGroups'].length > managedGroupsShowLoadingScreenThreshold,
      };
      dispatch('common/setNotification', notificationPayload, { root: true });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async editCommunityTriggerAssignments({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${communityTriggerAssignmentsUrl}`,
        method: 'post',
        data: {
          communityId: payload.communityId,
          triggerIds: payload.triggerIds,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      const communityTriggerAssignments = isNullOrUndefined(res.data.data.triggerIds) ? [] : res.data.data.triggerIds;
      commit('SET_ASSIGNED_TRIGGERS_FOR_COMMUNITY', {
        communityId: payload.communityId,
        assignedTriggerIds: communityTriggerAssignments,
      });

      if (payload.showNotification) {
        const notificationPayload = {
          msg: res.data.message ? res.data.message : defaultSuccessMsg,
          type: snackbarTypeSuccess,
          isClosable: true,
        };
        dispatch('common/setNotification', notificationPayload, { root: true });
      }
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },

  async getAvailableUsersAdmin({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getAvailableUsersUrl}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options); // we have groupIds coming back from this but they seem unused
      const results = res.data.data.users.filter((u) => !isNullOrUndefined(u.userProfile));
      const userInfoPayload = results.map((u) => {
        return {
          communityId: payload.communityId, // this is coming back at top level of res.data.data, should be flattened into the users
          contactId: u.userProfile.contactId, // this needs to come back in response not tied to userProfile
          isAdmin: u.isAdmin,
          isCommunityLeader: u.isCommunityLeader,
          isMemberOfSelectedCommunity: u.isMemberOfSelectedCommunity,
        };
      });
      commit('ADD_USER_INFO', userInfoPayload);

      const socialProfilesPayload = results.map((u) => {
        return {
          avatar: u.userProfile.avatar,
          contactId: u.userProfile.contactId,
          createdDateUtc: u.userProfile.createdDateUtc,
          fullName: u.userProfile.fullName,
          id: u.userProfile.id,
          info: u.userProfile.info,
          isAdmin: u.userProfile.isAdmin,
          nickname: u.userProfile.nickname,
          photo: u.userProfile.photo,
          tagline: u.userProfile.tagline,
        };
      });
      commit('users/SET_SOCIAL_PROFILE', socialProfilesPayload, { root: true });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async searchAvailableUsersAdmin({ dispatch, commit, getters }, payload) {
    // we need to consolidate this method. the only difference between search and get is the endpoint url
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${searchAvailableUsersUrl}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options); // we have groupIds coming back from this but they seem unused
      const results = res.data.data.users.filter((u) => !isNullOrUndefined(u.userProfile));
      const userInfoPayload = results.map((u) => {
        return {
          communityId: payload.communityId, // this is coming back at top level of res.data.data, should be flattened into the users
          contactId: u.userProfile.contactId, // this needs to come back in response not tied to userProfile
          isAdmin: u.isAdmin,
          isCommunityLeader: u.isCommunityLeader,
          isMemberOfSelectedCommunity: u.isMemberOfSelectedCommunity,
        };
      });
      commit('ADD_USER_INFO', userInfoPayload);

      const socialProfilesPayload = results.map((u) => {
        return {
          avatar: u.userProfile.avatar,
          contactId: u.userProfile.contactId,
          createdDateUtc: u.userProfile.createdDateUtc,
          fullName: u.userProfile.fullName,
          id: u.userProfile.id,
          info: u.userProfile.info,
          isAdmin: u.userProfile.isAdmin,
          nickname: u.userProfile.nickname,
          photo: u.userProfile.photo,
          tagline: u.userProfile.tagline,
        };
      });
      commit('users/SET_SOCIAL_PROFILE', socialProfilesPayload, { root: true });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getAvailableMembersAdmin({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityMembersUrlStart}${payload.communityId}${getCommunityMembersUrlEnd}`,
        method: 'get',
        params: {
          startPoint: payload.startPoint,
          pageSize: payload.pageSize,
        },
      };
      const res = await mkNetworkRequestWrapper(options);
      if (isNullOrUndefined(res?.data?.data)) {
        commit('SET_TOTAL_CURRENT_MEMBERS_COUNT', 0);
      } else {
        commit('SET_TOTAL_CURRENT_MEMBERS_COUNT', res.data.data.totalMemberCount);
      }

      const socialProfiles = !isNullOrUndefined(res.data.data.userProfiles) ? res.data.data.userProfiles : [];
      const socialProfilesPayload = socialProfiles.filter((sp) => !isNullOrUndefined(sp));
      const userInfos = !isNullOrUndefined(res.data.data.userInfos) ? res.data.data.userInfos : [];
      const userInfoPayload = userInfos.filter((ui) => !isNullOrUndefined(ui));

      commit('ADD_USER_INFO', userInfoPayload);
      commit('users/SET_SOCIAL_PROFILE', socialProfilesPayload, { root: true });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async searchAvailableMembersAdmin({ dispatch, commit, getters }, payload) {
    // we need to consolidate this method. the only difference between search and get is the endpoint url
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${getCommunityMembersUrlStart}${payload.communityId}${searchCommunityMembersUrlEnd}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const results = res.data.data.communityMembers.filter((u) => !isNullOrUndefined(u.userProfile));
      const userInfoPayload = results.map((u) => {
        return {
          communityId: payload.communityId, // this is coming back at top level of res.data.data, should be flattened into the users
          contactId: u.userProfile.contactId, // this needs to come back in response not tied to userProfile
          isAdmin: u.isAdmin,
          isCommunityLeader: u.isCommunityLeader,
          isMemberOfSelectedCommunity: u.isMemberOfSelectedCommunity,
        };
      });
      commit('ADD_USER_INFO', userInfoPayload);

      const socialProfilesPayload = results.map((u) => {
        return {
          avatar: u.userProfile.avatar,
          contactId: u.userProfile.contactId,
          createdDateUtc: u.userProfile.createdDateUtc,
          fullName: u.userProfile.fullName,
          id: u.userProfile.id,
          info: u.userProfile.info,
          isAdmin: u.userProfile.isAdmin,
          nickname: u.userProfile.nickname,
          photo: u.userProfile.photo,
          tagline: u.userProfile.tagline,
        };
      });
      commit('users/SET_SOCIAL_PROFILE', socialProfilesPayload, { root: true });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async addCommunityLeader({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${communityLeaderUrl}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      // we need to change the response to only provide these two params
      commit('ADD_COMMUNITY_LEADER', {
        communityId: !isNullOrUndefined(res.data.data.communityId) ? Number(res.data.data.communityId) : 0,
        contactId: !isNullOrUndefined(res.data.data.users[0])
          ? Number(res.data.data.users[0].userProfile.contactId)
          : 0,
      });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async removeCommunityLeader({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${removeCommunityLeaderUrl}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      // we need to change the response to only provide these two params
      commit('REMOVE_COMMUNITY_LEADER', {
        communityId: !isNullOrUndefined(res.data.data.communityId) ? Number(res.data.data.communityId) : 0,
        contactId: !isNullOrUndefined(res.data.data.users[0])
          ? Number(res.data.data.users[0].userProfile.contactId)
          : 0,
      });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async addMembersToCommunity({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${communityMembersUrl}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const notifPayload = {
        msg: res.data.message ? res.data.message : defaultSuccessMsg,
        type: snackbarTypeSuccess,
      };
      dispatch('common/setNotification', notifPayload, { root: true });
      const results = res.data.data.users.filter((u) => !isNullOrUndefined(u.userProfile));
      const userInfoPayload = results.map((u) => {
        return {
          communityId: res.data.data.communityId, // this is coming back at top level of res.data.data, should be flattened into the users
          contactId: u.userProfile.contactId, // this needs to come back in response not tied to userProfile
          isAdmin: u.isAdmin,
          isCommunityLeader: u.isCommunityLeader,
          isMemberOfSelectedCommunity: u.isMemberOfSelectedCommunity,
        };
      });
      commit('ADD_USER_INFO', userInfoPayload);

      const socialProfilesPayload = results.map((u) => {
        return {
          avatar: u.userProfile.avatar,
          contactId: u.userProfile.contactId,
          createdDateUtc: u.userProfile.createdDateUtc,
          fullName: u.userProfile.fullName,
          id: u.userProfile.id,
          info: u.userProfile.info,
          isAdmin: u.userProfile.isAdmin,
          nickname: u.userProfile.nickname,
          photo: u.userProfile.photo,
          tagline: u.userProfile.tagline,
        };
      });
      commit('users/SET_SOCIAL_PROFILE', socialProfilesPayload, { root: true });

      const updateUserInfoPayload = results.map((u) => {
        return {
          communityId: res.data.data.communityId, // this is coming back at top level of res.data.data, should be flattened into the users
          contactId: u.userProfile.contactId,
        };
      });

      commit('ADD_COMMUNITY_MEMBERS', updateUserInfoPayload);
      commit('SET_TOTAL_CURRENT_MEMBERS_COUNT', getters.getTotalCurrentMembersCount + payload.contactIds.length);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  async removeMembersFromCommunity({ dispatch, commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${removeCommunityMembersUrl}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      const results = res.data.data.users.filter((u) => !isNullOrUndefined(u.userProfile));
      const notifPayload = {
        msg: res.data.message ? res.data.message : defaultSuccessMsg,
        type: snackbarTypeSuccess,
      };
      dispatch('common/setNotification', notifPayload, { root: true });
      const updateUserInfoPayload = results.map((u) => {
        return {
          communityId: res.data.data.communityId, // this is coming back at top level of res.data.data, should be flattened into the users
          contactId: u.userProfile.contactId,
        };
      });
      commit('REMOVE_COMMUNITY_MEMBERS', updateUserInfoPayload);
      const updatedCommunityMemberCountPayload = [
        {
          communityId: !isNullOrUndefined(res.data.data.communityId) ? Number(res.data.data.communityId) : 0,
          totalMemberCount: Math.max(Number(getters.currentCommunityMemberCount - updateUserInfoPayload.length), 0),
        },
      ];

      commit('SET_TOTAL_CURRENT_MEMBERS_COUNT', getters.getTotalCurrentMembersCount - payload.contactIds.length);
      commit('SET_COMMUNITY_TOTAL_MEMBER_COUNT', updatedCommunityMemberCountPayload);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    }
  },
  resetCurrentMembersEnrolledMeta({ commit }) {
    commit('RESET_CURRENT_MEMBERS_ENROLLED_META');
  },
  resetAllMembersPaginationBreakpoint({ commit }) {
    commit('RESET_ALL_MEMBERS_PAGINATION_BREAKPOINT');
  },
  incrementAllMembersPaginationBreakpoint({ commit }) {
    commit('INCREMENT_ALL_MEMBERS_PAGINATION_BREAKPOINT');
  },
  async getCommunityPrivacySettings({ commit, getters, dispatch }, communityId) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${privacySettingsUrlStart}${communityId}${privacySettingsUrlEnd}`,
        method: 'get',
      };
      const res = await mkNetworkRequestWrapper(options);
      const privacySettings = !isNullOrUndefined(res.data.data) ? [res.data.data] : [];
      commit('SET_COMMUNITY_PRIVACY_SETTINGS', privacySettings);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getAssignedTriggers({ commit, dispatch, getters }, { communityId }) {
    try {
      const options = {
        url: `${getters.socialCommunitiesApiBaseUrl}${communityAdminRootUrl}/${communityId}/assignedTriggers`,
        method: 'GET',
      };

      const response = await mkNetworkRequestWrapper(options);

      commit('SET_ASSIGNED_TRIGGERS_FOR_COMMUNITY', { communityId, assignedTriggerIds: response.data.data.triggerIds });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },

  removeUsersInCommunity({ commit, getters }, communityId) {
    const userInfoWithoutCommunity = getters.userInfo.filter((u) => u.communityId !== communityId);
    commit('SET_USER_INFO', userInfoWithoutCommunity);
  },
};
