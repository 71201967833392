import { socialCommunitiesMutations } from '@/modules/SocialCommunities/store/mutations';
import { socialCommunitiesGetters } from '@/modules/SocialCommunities/store/getters';
import { socialCommunitiesActions } from '@/modules/SocialCommunities/store/actions';
import { enrolledMembersPageSize } from '@/modules/SocialCommunities/utils/constants';

export const socialCommunitiesState = {
  assignedTriggers: {},
  currentCommunityId: 0,
  communityInfo: [],
  communityPrivacySettings: [],
  posts: [],
  comments: [],
  postPins: [],
  postReactions: [],
  commentReactions: [],
  postFlags: [],
  commentFlags: [],
  communityHeroItems: [],
  postEditing: {
    isEditorOpen: false,
    postId: 0,
    parentPostId: 0,
    originalPostText: null,
    postType: null,
    currentPost: null,
  },
  userInfo: [],
  communityTotalPostCounts: [],
  communityTotalMemberCounts: [],
  communityTotalLeaderCounts: [],
  currentMembersEnrolledSearchString: '',
  filteredCommunityMembers: [], // search results from sidebar

  // admin state

  communityFilterId: null,
  communityGroups: [], // groups for a community
  communityManagementTotalCount: 0,

  // used for paginating through manage participants and returning paginated results from getters,
  // as well as filtering results by search string. we need to revisit that whole page at this
  // point so we can remove the need for this

  totalCurrentMembersCount: 0,
  participantsStartPointMembers: 0,
  participantsStartPointUsers: 0,
  participantsSearchStringMembers: '',
  participantsSearchStringUsers: '',

  createCommunity: {
    designMethod: '',
    selectedCategory: 0,
    selectedTemplate: 1,
    selectedIcon: 1,
    selectedBackgroundGradient: 1,
    name: null,
    description: null,
    heroImages: [],
    groups: [],
    triggers: [],
    leadersOnly: false,
  },

  // View all members modal meta information for infinite scrolling
  currentMembersEnrolledUsersMeta: {
    communityMembers: [],
    totalMemberCount: 0,
    links: {},
    currentPaginationPoint: enrolledMembersPageSize,
  },
};

const socialCommunitiesStore = {
  namespaced: true,
  state: socialCommunitiesState,
  mutations: socialCommunitiesMutations,
  getters: socialCommunitiesGetters,
  actions: socialCommunitiesActions,
};

export default socialCommunitiesStore;
