import {
  createTriggerConditionCategoryPlaceholderText,
  noDisabledTriggersText,
  noDraftTriggersText,
  noLaunchedTriggersText,
  noReadyForLaunchTriggersText,
  noTriggersText,
  noCustomTriggersText,
  noAdminGeneratedTriggersText,
} from '@/modules/TriggersAdmin/utils/verbiage';

export const createTriggerSteps = [
  { number: 1, text: 'STEP 1', title: 'Details', subtitle: 'Provide Trigger details', icon: 'fa-solid:clipboard-list' },
  { number: 2, text: 'STEP 2', title: 'Rules', subtitle: 'Set rules and conditions', icon: 'gg:list-tree' },
  { number: 3, text: 'STEP 3', title: 'Launch', subtitle: 'Save or launch', icon: 'mdi:rocket-launch' },
];

export const createTriggerMetIfConditionValues = [
  { id: 0, text: 'All', requiredNumberOfRules: 1 },
  { id: 1, text: 'At least one', requiredNumberOfRules: 2 },
  { id: 2, text: 'At least two', requiredNumberOfRules: 3 },
  { id: 3, text: 'At least three', requiredNumberOfRules: 4 },
];

export const createTriggerRuleMetIfConditionValues = [
  { id: 0, text: 'All', requiredNumberOfConditions: 1 },
  { id: 1, text: 'At least one', requiredNumberOfConditions: 2 },
  { id: 2, text: 'At least two', requiredNumberOfConditions: 3 },
  { id: 3, text: 'At least three', requiredNumberOfConditions: 4 },
];

export const createTriggerConditionCategoryValues = [
  { id: -1, text: createTriggerConditionCategoryPlaceholderText },
  { id: 1, text: 'Tracker' },
  { id: 2, text: 'Tracker - Accumulation' },
  { id: 3, text: 'Tracker - Participation' },
  { id: 4, text: 'Tracker - Achievement' },
  { id: 5, text: 'General Information' },
  { id: 6, text: 'Everflex' },
  { id: 7, text: 'Health Assessment' },
  { id: 8, text: 'Biometric Screenings' },
  { id: 9, text: 'Surveys' },
  { id: 10, text: 'Community Challenges - Tracker Based (Active)' },
  { id: 11, text: 'Community Challenges - Tracker Based (Inactive)' },
  { id: 12, text: 'Community Challenges - Question (Active)' },
  { id: 13, text: 'Community Challenges - Question (Inactive)' },
  { id: 14, text: 'Pal to Pal Challenges' },
  { id: 15, text: 'Incentive & Rewards' },
  { id: 16, text: 'Plan for Wellness' },
  { id: 17, text: 'Social Circles' },
  { id: 18, text: 'Self Help Works' },
];

// trigger status

export const triggerStatusAll = 0;
export const triggerStatusDraft = 1;
export const triggerStatusReadyForLaunch = 2;
export const triggerStatusLaunched = 3;
export const triggerStatusDisabled = 4;
export const triggerStatuses = [
  {
    ordinal: triggerStatusAll,
    status: 'All',
    class: 'all',
    noneText: noTriggersText,
  },
  {
    ordinal: triggerStatusDraft,
    status: 'Draft',
    class: 'draft',
    noneText: noDraftTriggersText,
    icon: 'akar-icons:paper',
  },
  {
    ordinal: triggerStatusReadyForLaunch,
    status: 'Ready for launch',
    class: 'ready-for-launch',
    noneText: noReadyForLaunchTriggersText,
    icon: 'akar-icons:paper',
  },
  {
    ordinal: triggerStatusLaunched,
    status: 'Launched',
    class: 'launched',
    noneText: noLaunchedTriggersText,
    icon: 'mdi:rocket-launch',
  },
  {
    ordinal: triggerStatusDisabled,
    status: 'Disabled',
    class: 'disabled',
    noneText: noDisabledTriggersText,
    icon: 'fe:disabled',
  },
];

// trigger sort options

export const triggerSortByDefault = 0;
export const triggerSortByCreationDateAscending = 1;
export const triggerSortByCreationDateDescending = 2;
export const triggerSortByNameAscending = 3;
export const triggerSortByNameDescending = 4;
export const triggerSortByOptions = [
  {
    ordinal: triggerSortByCreationDateAscending,
    option: 'Creation Date (Ascending)',
  },
  {
    ordinal: triggerSortByCreationDateDescending,
    option: 'Creation Date (Descending)',
  },
  {
    ordinal: triggerSortByNameAscending,
    option: 'Trigger Name (Ascending)',
  },
  {
    ordinal: triggerSortByNameDescending,
    option: 'Trigger Name (Descending)',
  },
];

// condition variable types
export const triggerVariableDropdownEqualId = 0;
export const triggerVariableDropdownBetweenId = 1;
export const triggerVariableDropdownGreaterId = 2;
export const triggerVariableDropdownGteId = 3;
export const triggerVariableDropdownLessId = 4;
export const triggerVariableDropdownLteId = 5;
export const triggerVariableDropdownBeforeId = 6;
export const triggerVariableDropdownAfterId = 7;

export const triggerVariableDropdownEqual = { id: triggerVariableDropdownEqualId, text: 'equal to' };
export const triggerVariableDropdownBetween = { id: triggerVariableDropdownBetweenId, text: 'in between' };
export const triggerVariableDropdownGreater = { id: triggerVariableDropdownGreaterId, text: 'greater than' };
export const triggerVariableDropdownGte = { id: triggerVariableDropdownGteId, text: 'greater than or equal to' };
export const triggerVariableDropdownLess = { id: triggerVariableDropdownLessId, text: 'less than' };
export const triggerVariableDropdownLte = { id: triggerVariableDropdownLteId, text: 'less than or equal to' };
export const triggerVariableDropdownBefore = { id: triggerVariableDropdownBeforeId, text: 'before' };
export const triggerVariableDropdownAfter = { id: triggerVariableDropdownAfterId, text: 'after' };

// Maps to Mk::Domain::Triggers::Enum::TriggerConditionStatementType integral values
export const triggerVariableTypeText = 0;
export const triggerVariableTypeNumber = 1;
export const triggerVariableTypePercentage = 2;
export const triggerVariableTypeDate = 3;
export const triggerVariableTypeDateRange = 4;
export const triggerVariableTypePredicate = 5;
export const triggerVariableTypeEnum = 6;
export const triggerVariableTypeUnknown = 7;
export const triggerVariableTypeDataDriven = 8;

export const triggerVariableConditionTypes = [
  {
    id: triggerVariableTypeText,
    preVerbiage: 'is equal to',
    postVerbiage: 'the value of',
    placeholder: 'Enter text',
    dropdownItems: [triggerVariableDropdownEqual],
  },
  {
    id: triggerVariableTypeNumber,
    preVerbiage: 'is',
    postVerbiage: 'the value of',
    placeholder: 'Enter a number',
    dropdownItems: [
      triggerVariableDropdownEqual,
      triggerVariableDropdownBetween,
      triggerVariableDropdownGreater,
      triggerVariableDropdownGte,
      triggerVariableDropdownLess,
      triggerVariableDropdownLte,
    ],
  },
  {
    id: triggerVariableTypeDate,
    preVerbiage: 'is',
    postVerbiage: 'the value of',
    placeholder: 'Select a Date',
    dropdownItems: [
      triggerVariableDropdownEqual,
      triggerVariableDropdownBetween,
      triggerVariableDropdownBefore,
      triggerVariableDropdownAfter,
    ],
  },
  { id: triggerVariableTypeEnum, preVerbiage: 'is equal to' },
  { id: triggerVariableTypePredicate, preVerbiage: 'is equal to' },
  { id: triggerVariableTypeDataDriven, preVerbiage: 'is equal to' },
  {
    id: triggerVariableTypePercentage,
    preVerbiage: 'is',
    postVerbiage: 'the value of',
    placeholder: 'Enter a number',
    dropdownItems: [
      triggerVariableDropdownEqual,
      triggerVariableDropdownBetween,
      triggerVariableDropdownGreater,
      triggerVariableDropdownGte,
      triggerVariableDropdownLess,
      triggerVariableDropdownLte,
    ],
  },
];

// origin types

export const triggerOriginTypeCustom = 0;
export const triggerOriginTypeSystemGenerated = 1;
export const triggerOriginTypeAdminCreated = 2;

export const triggerOriginTypes = [
  {
    value: triggerOriginTypeCustom,
    text: 'Custom Developed',
    class: 'custom',
    noneText: noCustomTriggersText,
  },
  {
    value: triggerOriginTypeAdminCreated,
    text: 'Created by Admin',
    class: 'admin-generated',
    noneText: noAdminGeneratedTriggersText,
  },
];

// step three statuses

export const createTriggerStepThreeTriggerStatuses = [
  {
    label: 'Launch',
    value: triggerStatusLaunched,
  },
  {
    label: 'Save as a draft',
    value: triggerStatusDraft,
  },
  {
    label: 'Save as Ready for Launch',
    value: triggerStatusReadyForLaunch,
  },
];

// edit trigger status

export const editTriggerStatuses = [
  {
    label: 'Launched',
    value: triggerStatusLaunched,
    displayForTriggerStatuses: [
      triggerStatusDraft,
      triggerStatusReadyForLaunch,
      triggerStatusLaunched,
      triggerStatusDisabled,
    ],
  },
  {
    label: 'Draft',
    value: triggerStatusDraft,
    displayForTriggerStatuses: [triggerStatusDraft, triggerStatusReadyForLaunch],
  },
  {
    label: 'Ready for Launch',
    value: triggerStatusReadyForLaunch,
    displayForTriggerStatuses: [triggerStatusDraft, triggerStatusReadyForLaunch],
  },
  {
    label: 'Disabled',
    value: triggerStatusDisabled,
    displayForTriggerStatuses: [triggerStatusLaunched, triggerStatusDisabled],
  },
];

//trigger condtion topic types
export const triggerTopicTypeStatic = 0;
export const triggerTopicTypeSurvey = 1;
