<template>
  <div class="container--sidebar-nav pt-10">
    <div v-if="showMyPalsMenuItem" class="my-pals-item text-center py-3" v-on:click="handleMyPalsClick()">
      {{ myPalsSidebarMenuItemText }}
    </div>
  </div>
</template>

<script>
import { legacyMyPalsUrl, myPalsSidebarMenuItemText } from '@/modules/Common/utils/constants';
import { enableSocialProfileOptionId, disablePalsFunctionalityOptionId } from '@/modules/PlatformOptions/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'SidebarNavigation',
  data() {
    return {
      myPalsSidebarMenuItemText: myPalsSidebarMenuItemText,
      enableSocialProfileOptionId: enableSocialProfileOptionId,
      disablePalsFunctionalityOptionId: disablePalsFunctionalityOptionId,
      legacyMyPalsUrl: legacyMyPalsUrl,
    };
  },
  computed: {
    ...mapGetters({
      konfigOptions: 'platformOptions/currentUserKonfigOptions',
      booleanOptionValue: 'platformOptions/booleanOptionValue',
      originUriLegacy: 'common/originUriLegacy',
    }),
    socialProfileEnabled: function() {
      return this.booleanOptionValue(this.enableSocialProfileOptionId);
    },
    palsFunctionalityEnabled: function() {
      return !this.booleanOptionValue(this.disablePalsFunctionalityOptionId);
    },
    showMyPalsMenuItem: function() {
      return this.socialProfileEnabled && this.palsFunctionalityEnabled;
    },
    myPalsFullUrl: function() {
      return `${this.originUriLegacy}${this.legacyMyPalsUrl}`;
    },
  },
  methods: {
    handleMyPalsClick: function() {
      window.location.href = this.myPalsFullUrl;
      this.$root.$sidebar.closeSidebar();
    },
  },
};
</script>
<style lang="scss" scoped>
.container--sidebar-nav {
  .my-pals-item {
    color: $mediumDarkGray;
    font-size: 0.9rem;
    border-left: 3px solid transparent;
    transition: border-color 0.2s linear;
    cursor: pointer;

    &:hover {
      border-color: $sidebarNavItemHoverBlue;
      color: $navyBlue;
    }
  }
}
</style>
