export const searchButtonText = 'Search';
export const resetButtonText = 'Reset';
export const defaultNoDataText = 'No matching records.';
export const dateOfBirthText = 'Date of Birth';
export const valueText = 'Value';
export const unsavedChangesMessageText =
  'You have unsaved changes. If you continue, unsaved changes will be lost. Are you sure want to continue?';
export const successfullySavedToastText = 'Saved successfully!';
export const discardChangesText = 'Discard Changes';
export const saveChangesText = 'Save Changes';
export const emailText = 'Email';
export const genderText = 'Gender';
export const doneText = 'Done';
export const saveAsDraftText = 'Save as a draft';
export const launchText = 'Launch';
export const gradientIconSelectorCategoryBackgroundText = 'Background';
export const gradientIconSelectorCategoryIconText = 'Icon';
export const cancelButtonText = 'Cancel';
export const nextButtonText = 'Next';
export const backButtonText = 'Back';
export const saveButtonText = 'Save';
export const discardButtonText = 'Discard';
export const submitText = 'Submit';
export const noText = 'No';
export const successfullyCreatedToastText = (context) => `Your ${context} has been successfully created.`;
export const viewModalHeadline = (context) => `View ${context}`;
