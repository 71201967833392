import axios from 'axios';

function configureRequests(options) {
  const client = axios.create({
    baseURL: options.url,
    method: options.method,
    data: options.data,
    withCredentials: true,
  });
  return client(options);
}

export default async function mkNetworkRequestWrapperLegacy(options) {
  document.dispatchEvent(new Event('mkLegacyApiRequest'));
  return configureRequests(options);
}
