export const tangoStoreGetters = {
  tangoStoreApiBaseUrl: (state, getters, rootState) => rootState.common.apiUrl,
  getUserBalance: (state) => {
    return state.userBalance;
  },
  getAnnualLimit: (state) => {
    return state.annualLimit;
  },
  getAnnualLimitMessageDisplayedOnce: (state) => {
    return state.annualLimitMessageDisplayedOnce;
  },
  getSelectedCard: (state) => {
    return state.selectedCard;
  },
  getSelectedFixedValue: (state) => {
    return state.newCartItem.fixedValue;
  },
  getSelectedVariableValue: (state) => {
    return state.newCartItem.variableValue;
  },
  getSelectedQuantity: (state) => {
    return state.newCartItem.quantity;
  },
  getCart: (state) => {
    return state.cart;
  },
  getCustomerId: (state) => {
    return state.customerId;
  },
};
