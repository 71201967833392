<template>
  <v-dialog
    v-if="loaded"
    v-model="dialog"
    v-bind:persistent="persistent"
    :max-width="maxWidth"
    v-on:click:outside="handleOverlayClick()"
    v-on:keydown.esc.prevent="handleEscapeKeydown()"
  >
    <v-card id="modal" class="modal--global" flat>
      <v-container class="container--header" v-bind:class="containerHeaderClass" fluid>
        <v-row no-gutters>
          <v-col cols="11">
            <v-card-title class="headline">{{ this.headline }}</v-card-title>
          </v-col>
          <v-col class="text-right" cols="1">
            <v-btn v-if="closeIcon" class="btn btn--close" icon v-on:click="closeModal()">
              <span id="x-btn-on-modal" class="iconify icon--close" data-icon="eva:close-fill"></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-card-text>
        <div v-if="type === modalTypeString" class="content--string">{{ this.content }}</div>
        <div v-else-if="renderChildComponent" class="content--component">
          <component
            ref="modalComponent"
            v-bind:is="content"
            v-bind:class="className"
            v-bind:modalComponentProps="modalComponentProps"
            v-bind:isModalOpen="dialog"
          />
        </div>
      </v-card-text>
      <v-card-actions v-if="showBtns" class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          v-if="allowConfirmationButtonDisabling"
          class="btn btn--confirm mr-2"
          id="confirm-btn"
          v-bind:style="btnConfirmCss"
          v-on:click="handleConfirm()"
          v-bind:disabled="confirmationButtonIsDisabled"
        >
          {{ btnConfirmText }}
        </v-btn>
        <v-btn
          v-else
          id="confirm-cancel"
          class="btn btn--confirm mr-2"
          v-bind:style="btnConfirmCss"
          v-on:click="handleConfirm()"
        >
          {{ btnConfirmText }}
        </v-btn>
        <v-btn id="deny-cancel" class="btn btn--deny" v-bind:style="btnDenyCss" v-on:click="handleDeny()">
          {{ btnDenyText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  modalTypeString,
  modalTypeComponent,
  modalTypeMemberSearch,
  modalBtnTextConfirm,
  modalBtnTextCancel,
} from '@/modules/Common/utils/constants';
import { openModalHelper, closeModalHelper } from '@/modules/Common/utils/modalScrollHelpers';
import { isNullOrUndefined } from '@/utils/helperFunctions';
import { delayActionForAnimationInterval } from '@/modules/Common/utils/constants';
import { mapGetters } from 'vuex';
export default {
  name: 'Modal',
  data() {
    return {
      mediumBlue: '#2196f3', // TODO: try to use global css vars here
      lightGray: '#e6e6e6',
      dialog: false,
      resolve: null,
      reject: null,
      type: null,
      headline: null,
      content: null,
      className: '',
      showBtns: false,
      persistent: false,
      closeIcon: false,
      transparentHeader: false,
      modalBtnTextConfirm: modalBtnTextConfirm,
      modalBtnTextCancel: modalBtnTextCancel,
      btnConfirmText: '',
      btnDenyText: '',
      btnConfirmBgColor: '',
      btnDenyBgColor: '',
      modalTypeString: modalTypeString,
      modalTypeComponent: modalTypeComponent,
      modalTypeMemberSearch: modalTypeMemberSearch,
      modalComponentProps: null,
      allowConfirmationButtonDisabling: false,
      loaded: true,
      size: null,
      sizes: {
        xs: 200,
        sm: 400,
        md: 600,
        lg: 800,
      },
    };
  },
  computed: {
    ...mapGetters({
      confirmationButtonIsDisabled: 'common/modalConfirmationButtonIsDisabled',
    }),
    containerHeaderClass: function() {
      let result = '';
      if (this.type === modalTypeString || modalTypeMemberSearch) {
        result = ' modal--string-member-search';
      }
      if (this.transparentHeader) {
        result += ' transparent';
      }
      return result;
    },
    btnConfirmCss: function() {
      return {
        background: this.btnConfirmBgColor,
      };
    },
    btnDenyCss: function() {
      return {
        background: this.btnDenyBgColor,
      };
    },
    maxWidth() {
      return this.sizes[this.size] ?? 1200;
    },
    renderChildComponent: function() {
      return this.type === modalTypeMemberSearch || this.type === modalTypeComponent;
    },
  },
  methods: {
    openModal: function(payload) {
      this.size = payload.size;
      this.loaded = true;
      this.persist = payload.persist ?? false;
      this.dialog = true;
      this.type = payload.type;
      this.headline = payload.headline;
      this.content = payload.content;
      this.className = payload.className;
      this.showBtns = !isNullOrUndefined(payload.showBtns) ? payload.showBtns : true;
      this.persistent = !isNullOrUndefined(payload.persistent) ? payload.persistent : false;
      this.closeIcon = !isNullOrUndefined(payload.closeIcon) ? payload.closeIcon : !this.persistent;
      this.transparentHeader = !isNullOrUndefined(payload.transparentHeader) ? payload.transparentHeader : false;
      this.emitOnClose = !isNullOrUndefined(payload.emitOnClose) ? payload.emitOnClose : false;

      this.btnConfirmBgColor = !isNullOrUndefined(payload.btnConfirmBgColor)
        ? payload.btnConfirmBgColor
        : this.mediumBlue;
      this.btnDenyBgColor = !isNullOrUndefined(payload.btnDenyBgColor) ? payload.btnDenyBgColor : this.lightGray;

      this.btnConfirmText = !isNullOrUndefined(payload.btnConfirmText)
        ? payload.btnConfirmText
        : this.modalBtnTextConfirm;
      this.btnDenyText = !isNullOrUndefined(payload.btnDenyText) ? payload.btnDenyText : this.modalBtnTextCancel;

      this.modalComponentProps = payload.modalComponentProps;
      this.confirmationButtonDisabledCondition = payload.confirmationButtonDisabledCondition;
      this.allowConfirmationButtonDisabling = isNullOrUndefined(payload.allowConfirmationButtonDisabling)
        ? false
        : payload.allowConfirmationButtonDisabling;
      openModalHelper();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    closeModal: function(result) {
      result ??= false;

      this.dialog = false;

      closeModalHelper();

      this.unloadModal();

      if (this.emitOnClose) {
        this.resolve(result);
      } else {
        return;
      }

      // check if the function modalComponentCloseMethod() exists on the child component. if so, fires it on modal close
      // this allows us to run custom event handlers on close
      // note: DOES NOT handle clicking outside; use modalComponentDismissMethod() instead
      if (Object.prototype.hasOwnProperty.call(this.$refs.modalComponent ?? {}, 'modalComponentCloseMethod')) {
        this.$refs.modalComponent.modalComponentCloseMethod();
      }
    },
    // these methods are all firing closeModalHelper() multiple times for some reason
    handleDeny: function() {
      this.resolve(false);
      this.dialog = false;
      closeModalHelper();

      this.unloadModal();
    },
    handleConfirm: function() {
      this.resolve(true);
      this.dialog = false;
      closeModalHelper();

      this.unloadModal();
    },
    handleOverlayClick: function() {
      if (this.persistent) {
        return;
      }

      this.resolve(false);
      closeModalHelper();

      this.unloadModal();

      if (!this.emitOnClose) {
        return;
      }

      // check if the function modalComponentDismissMethod() exists on the child component. if so, fires it on modal dismiss
      // this allows us to run custom event handlers when user clicks outside
      // note: DOES NOT handle clicking close; use modalComponentCloseMethod() instead
      if (Object.prototype.hasOwnProperty.call(this.$refs.modalComponent, 'modalComponentDismissMethod')) {
        this.$refs.modalComponent.modalComponentDismissMethod();
      }
    },
    handleEscapeKeydown: function() {
      this.resolve(false);
      closeModalHelper();

      this.unloadModal();
    },
    unloadModal: function() {
      // Don't unload modal from DOM
      if (this.persist) {
        return;
      }

      // Allow closing animation to finish before removing modal from the DOM.
      setTimeout(() => {
        this.loaded = false;
      }, delayActionForAnimationInterval);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal--global {
  .container--header {
    height: 3.375rem;
    position: relative;
    z-index: 100;

    &.modal--string-member-search {
      background: $modalHeaderBackground;
    }

    .btn--close {
      top: -0.1rem;

      .iconify {
        font-size: 1.125rem;
      }
    }
  }

  .content--string,
  .content--component > div {
    color: $filepondGray;
    padding: 2.5rem 1rem;
  }
}

::v-deep .v-dialog,
.v-dialog.v-dialog--active,
.v-dialog.dialog-transition-leave-active {
  width: auto;
  min-width: 20rem;
  overflow-x: hidden;

  .v-card__text {
    padding: 0;
  }
}

::v-deep .v-card__title.headline {
  font-size: 1rem !important;
  padding: 0 0.4rem;
  font-weight: 500;
  word-break: break-word;
}

::v-deep .v-card__actions {
  .v-btn {
    text-transform: capitalize;

    &.btn--confirm {
      color: #fff;
    }

    &.btn--deny {
      color: #525252;
    }
  }
}

::v-deep .v-dialog.v-dialog--active {
  background: #fff;
}
</style>
