<template>
  <v-container class="px-4" fluid>
    <BackButton v-bind:destination="navigateBackTo" />

    <div class="pt-4">
      Status:
      <p v-bind:style="healthStatusStyle">{{ healthStatus }}</p>
      <pre>
        {{ this.currentApiHealth | pretty }}
      </pre>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isNullOrUndefined, isStringNullUndefinedOrEmpty } from '@/utils/helperFunctions';
import {
  healthStatusDegraded,
  healthStatusHealthy,
  healthStatusUnhealthy,
  healthStatusUnknown,
} from '@/modules/Common/utils/constants';
import BackButton from '@/modules/Common/components/BackButton';
import { SocialCommunities } from '@/modules/SocialCommunities/router/socialCommunitiesRoutesConstants';

export default {
  name: 'Health',
  components: { BackButton },
  props: {
    navigateBackTo: {
      type: String,
      required: true,
      default: () => SocialCommunities.name,
    },
  },
  computed: {
    ...mapGetters({
      currentApiHealth: 'common/currentApiHealth',
    }),
    healthStatus: function() {
      return isNullOrUndefined(this.currentApiHealth) ? healthStatusUnknown : this.currentApiHealth.status;
    },
    healthStatusStyle: function() {
      return isNullOrUndefined(this.currentApiHealth)
        ? null
        : {
            color: this.healthStatusColor,
          };
    },
    healthStatusColor: function() {
      switch (this.healthStatus) {
        case healthStatusHealthy:
          return 'green';
        case healthStatusDegraded:
          return 'orange';
        case healthStatusUnhealthy:
          return 'red';
        default:
          return 'gray';
      }
    },
  },
  methods: mapActions({
    getApiHealthStatus: 'common/getApiHealthStatus',
  }),
  filters: {
    pretty: function(value) {
      if (isStringNullUndefinedOrEmpty(value)) {
        return '';
      }

      return JSON.stringify(value, null, 2);
    },
  },
  async mounted() {
    await this.getApiHealthStatus();
  },
};
</script>

<style scoped></style>
