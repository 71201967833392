<template>
  <router-link id="backToDashboard" class="link--dashboard d-inline-block" v-bind:to="{ name: destination }">
    <v-btn v-bind:class="buttonClass">
      <span class="iconify icon--hero back" data-icon="ic:round-navigate-before"></span>
      <span class="icon--text back">
        {{ text }}
      </span>
    </v-btn>
  </router-link>
</template>
<script>
import { backButtonDefaultText } from '@/modules/Common/utils/constants';
export default {
  name: 'BackButton',
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    destination: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: backButtonDefaultText,
    },
  },
  data() {
    return {
      backButtonDefaultText: backButtonDefaultText,
    };
  },
  computed: {
    buttonClass: function() {
      return `btn--back ${this.inverted && 'inverted'} ${this.outlined && 'outlined'}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.link--dashboard {
  text-decoration: none;
}

.v-btn {
  padding: 0;

  .iconify {
    &.icon--hero {
      color: $navyBlue;
      height: 2rem;
      width: 2rem;

      // IE11

      flex-direction: column;
      min-height: 1.5rem;
      border-radius: 50%;
    }

    &.icon--hero:before {
      transform: scale(1.1); // IE11
    }
  }

  .icon--text {
    letter-spacing: normal;
    min-width: 3rem; // IE11
  }

  &.outlined {
    color: $mediumBlue;
    border: 1px solid $mediumBlue;

    .iconify {
      &.icon--hero {
        color: $mediumBlue;
      }
    }

    .icon--text {
      color: $mediumBlue;
    }
  }

  &.inverted {
    background-color: $mediumBlue !important;

    .iconify {
      &.icon--hero {
        color: #f5f5f5;
      }
    }

    .icon--text {
      color: white;
    }
  }
}
</style>
