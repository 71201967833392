export const TriggersAdmin = {
  name: 'TriggersAdmin',
  path: '/triggers-admin',
};

export const CreateTrigger = {
  name: 'CreateTrigger',
  path: '/triggers-admin/create',
};

export const EditTrigger = {
  name: 'EditTrigger',
  path: '/triggers-admin/edit/:triggerId',
};

export const TriggerLibrary = {
  name: 'TriggerLibrary',
  path: '/triggers-admin/library',
};
