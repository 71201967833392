import { adminTemplatesMutations } from '@/modules/AdminTemplates/store/mutations';
import { adminTemplatesGetters } from '@/modules/AdminTemplates/store/getters';
import { adminTemplatesActions } from '@/modules/AdminTemplates/store/actions';
import { defaultTemplateTypeId, noAdminTemplatesText } from '@/modules/AdminTemplates/utils';

export const adminTemplatesState = {
  createOrEditAdminTemplate: {
    templateType: 0,
    name: '',
    text: '',
    isFormValid: false,
    validationErrors: [],
  },
  searchAdminTemplate: {
    searchText: '',
    adminTemplateSearchTemplateType: 0,
    filteredTemplates: [],
    allTemplateTypes: [
      {
        ordinal: defaultTemplateTypeId,
        type: 'Show All Template Types',
        class: 'select-template-type',
        noneText: noAdminTemplatesText,
        enabled: true,
      },
    ],
    allTemplates: [],
    searchResultsPaginationInfo: {},
  },
  selectedAdminTemplate: null,
};

const adminTemplatesStore = {
  namespaced: true,
  state: adminTemplatesState,
  mutations: adminTemplatesMutations,
  getters: adminTemplatesGetters,
  actions: adminTemplatesActions,
};

export default adminTemplatesStore;
