const platformOptionsMutations = {
  SET_MENU_OPTIONS(state, menuOptions) {
    state.menuOptions = menuOptions;
  },
  SET_KONFIG_OPTIONS(state, konfigOptions) {
    state.konfigOptions = konfigOptions;
  },
};

export default platformOptionsMutations;
