import { analyticsMutations } from '@/modules/Analytics/store/mutations';
import { analyticsGetters } from '@/modules/Analytics/store/getters';
import { analyticsActions } from '@/modules/Analytics/store/actions';

export const analyticsState = {
  chartData: [],
  formattedChartData: [],
  dashboardBuilderName: '',
  dashboardBuilderPermissions: 1,
  adminRolesDashboardBuilderDropdown: [],
  adminUsersDashboardBuilderDropdown: [],
  adminEditorUsersDashboardBuilderDropdown: [],
  selectedAdminUsersDashboardBuilder: [],
  selectedAdminRolesDashboardBuilder: [],
  selectedAdminUsersDashboardBuilderToView: [],
  dashboardBuilderNameValid: false,
  dashboardAssignRoleAccessValid: false,
  dashboardAssignUserAccessValid: false,
  dashboardAssignUserViewAccessValid: false,
  dashboardFormValid: false,
  selectedDashboard: {},
  isEditMode: false,
  permissions: {
    canViewDashboard: false,
    canCreateDashboard: false,
    canAssignDashboard: false,
  },
  dashboards: [],
  dashboardLayout: { items: [] },
  singleDateFilter: 0,
  singleDateFilterValueStart: '',
  singleDateFilterValueEnd: '',
  filters: {},
};

const analyticsStore = {
  namespaced: true,
  state: analyticsState,
  mutations: analyticsMutations,
  getters: analyticsGetters,
  actions: analyticsActions,
};

export default analyticsStore;
