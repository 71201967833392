import { loadTopics, knowledgeHubBasePathUrl, logUserClick } from '@/modules/KnowledgeHub/utils/endpoints';

import mkNetworkRequestWrapper from '@/utils/requestWrapper';

export const knowledgeHubActions = {
  async fetchTopics({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.knowledgeHubApiBaseUrl}${knowledgeHubBasePathUrl}/${loadTopics}`,
        method: 'GET',
      };
      let loadTopicsResponse = await mkNetworkRequestWrapper(options);

      return loadTopicsResponse.data.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async logUserArticleClick({ commit, getters }, payload) {
    try {
      const options = {
        url: `${getters.knowledgeHubApiBaseUrl}${knowledgeHubBasePathUrl}/${logUserClick}/${payload}`,
        method: 'post',
      };
      await mkNetworkRequestWrapper(options);

      return true;
    } catch (err) {
      console.log(err);
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
