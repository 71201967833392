export const SocialCommunities = {
  name: 'SocialCommunities',
  path: '/social-circles',
};

export const CommunityWall = {
  name: 'CommunityWall',
  path: '/social-circles/circle/:communityId',
};

export const SocialCommunitiesAdmin = {
  name: 'SocialCommunitiesAdmin',
  path: '/social-circles-admin',
};

export const FlaggedPostsAdmin = {
  name: 'FlaggedPostsAdmin',
  path: '/social-circles-admin/flagged-posts',
};

export const CreateCommunitySteps = {
  name: 'CreateCommunitySteps',
  path: '/social-circles-admin/create-circle',
};

export const ManageParticipantsAdmin = {
  name: 'ManageParticipantsAdmin',
  path: '/social-circles-admin/manage-participants/:communityId',
};

export const ManageCommunityGroupsAdmin = {
  name: 'ManageCommunityGroupsAdmin',
  path: '/social-circles-admin/manage-groups/:communityId',
};
