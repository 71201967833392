<template>
  <v-navigation-drawer
    class="sidebar--global"
    v-model="drawer"
    v-bind:right="right"
    fixed
    temporary
    v-bind:permanent="drawer"
    width="375px"
    v-click-outside="handleClickOutside"
  >
    <div class="header--sidebar" v-bind:class="headerClass">
      <v-btn class="btn btn--close" icon v-on:click="closeSidebar()">
        <span class="iconify icon--close" data-icon="eva:close-fill"></span>
      </v-btn>
    </div>
    <div v-if="type === modalTypeString" class="content--string pa-2 pt-9">{{ content }}</div>
    <div v-else-if="type === modalTypeComponent" class="content--component pt-9">
      <component v-if="drawer" ref="sidebarComponent" v-bind:is="content" v-bind="sidebarComponentProps" />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { modalTypeString, modalTypeComponent, delayActionForAnimationInterval } from '@/modules/Common/utils/constants';
import { isNullOrUndefined } from '@/utils/helperFunctions';
import { openModalHelper, closeModalHelper } from '@/modules/Common/utils/modalScrollHelpers';
export default {
  name: 'Sidebar',
  data() {
    return {
      modalTypeString: modalTypeString,
      modalTypeComponent: modalTypeComponent,
      delayActionForAnimationInterval: delayActionForAnimationInterval,
      drawer: false,
      right: false,
      type: null,
      content: null,
      persistent: false,
      sidebarComponentProps: null,
    };
  },
  computed: {
    headerClass: function() {
      return `d-flex justify-${this.right ? 'start' : 'end'}`;
    },
  },
  methods: {
    openSidebar: function(payload) {
      openModalHelper();
      this.right = !isNullOrUndefined(payload.right) ? payload.right : false;
      this.type = payload.type;
      this.content = payload.content;
      this.sidebarComponentProps = payload.sidebarComponentProps;
      this.persistent = !isNullOrUndefined(payload.persistent) ? payload.persistent : false;
      setTimeout(() => {
        this.drawer = true;
      }, delayActionForAnimationInterval);
    },
    handleClickOutside: function() {
      if (this.drawer) {
        this.closeSidebar();
      }
    },
    closeSidebar: function() {
      if (this.persistent) {
        return;
      }
      this.content = null;
      closeModalHelper();
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar--global {
  .header--sidebar {
    position: absolute;
    width: 100%;
    background: white;
  }
  .btn--close {
    font-size: 2rem;
  }
}
</style>
