export const authenticationGetters = {
  isAuthenticated: (state) => {
    return !!state.accessTokenFromLegacy;
  },
  accessTokenFromLegacy: (state) => {
    return state.accessTokenFromLegacy;
  },
  contactId: (state) => {
    return Number(state.contactId);
  },
};
