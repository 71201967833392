import { isNullOrUndefined } from '@/utils/helperFunctions';
import { snackbarCloseText } from '@/modules/Common/utils/constants';

export const commonGetters = {
  commonApiBaseUrl: (state) => {
    return state.apiUrl;
  },
  csrfToken: (state) => state.csrfToken,
  navbarEnabledState: (state) => (isNullOrUndefined(state.features) ? false : state.features.navbarEnabled),
  step4EnabledState: (state) => (isNullOrUndefined(state.features) ? false : state.features.step4Enabled),
  mockNumberOfPalRequests: (state) =>
    isNullOrUndefined(state.features) ? false : state.features.mockNumberOfPalRequests,
  isProduction: (state) => {
    const environment = state.environment.toLowerCase();
    //"production" environments are different for Emblem. EMUAT, EMPREP, and PRODUCTION are all considered production environments as far as emblem is concerned.
    return (
      environment === 'production' || environment === 'emuat' || environment === 'emprep' || environment === 'emprod'
    );
  },
  originUriLegacy: (state) => {
    console.log('originUriLegacy(getter)=' + state.originUriLegacy);
    return state.originUriLegacy;
  },
  isLoading: (state) => {
    return state.overrideIsLoading ? true : state.callsInFlight > 0;
  },
  isLoadingTranslucent: (state) => {
    return state.isLoadingTranslucent;
  },
  notificationType: (state) => {
    return isNullOrUndefined(state.notificationSnackbar) ? undefined : state.notificationSnackbar.type;
  },
  notificationMessage: (state) => {
    return isNullOrUndefined(state.notificationSnackbar) ? '' : state.notificationSnackbar.msg;
  },
  notificationButtonText: (state) => {
    return isNullOrUndefined(state.notificationSnackbar) ? snackbarCloseText : state.notificationSnackbar.buttonText;
  },
  notificationIsClosable: (state) => {
    return isNullOrUndefined(state.notificationSnackbar) ? false : state.notificationSnackbar.isClosable;
  },
  notificationOnCloseCallback: (state) => {
    return isNullOrUndefined(state.notificationSnackbar) ? () => {} : state.notificationSnackbar.onClose;
  },
  scrollYPosition: (state) => {
    return Number(state.scrollYPosition);
  },
  version: (state) => {
    return state.version;
  },
  modalConfirmationButtonIsDisabled: (state) => {
    return state.modal.confirmationButtonDisabled === true;
  },
  currentApiHealth: (state) => {
    return state.apiHealth;
  },
  notifications: (state) => {
    return state.notifications;
  },
  showSelectTriggers: (state) => {
    return state.features.selectTriggersOnSocialCircle;
  },
  sideMenuEnabled: (state) => {
    return state.sideMenuEnabled;
  },
  totalUnreadNotificationCount: (state) => state.totalUnreadNotificationCount,
  messages: (state) => {
    return state.messages;
  },
  totalUnreadMessagesCount: (state) => state.totalUnreadMessagesCount,
};
