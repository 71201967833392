import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { getCurrentUserInfoUrl, getUserInfoUrl } from '@/modules/Users/utils/constants';
import { isNullOrUndefined } from '@/utils/helperFunctions';

let getCurrentUserInfoPromise = null;
export const usersActions = {
  async getCurrentUserInfo({ commit, dispatch, rootGetters }) {
    if (getCurrentUserInfoPromise) {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
      return getCurrentUserInfoPromise;
    }
    return (getCurrentUserInfoPromise = (async () => {
      try {
        const options = {
          url: `${rootGetters['common/commonApiBaseUrl']}${getCurrentUserInfoUrl}`,
          method: 'get',
        };
        const res = await mkNetworkRequestWrapper(options);
        commit('SET_SOCIAL_PROFILE', !isNullOrUndefined(res.data.data.userProfile) ? [res.data.data.userProfile] : []);
        commit('SET_CURRENT_USER_LANGPREF', res.data.data.languageCode ? res.data.data.languageCode : '');
        commit('SET_CURRENT_USER_INFO', {
          contactId: !isNullOrUndefined(res.data.data.userProfile) ? Number(res.data.data.userProfile.contactId) : 0,
          isUserAdmin: res.data.data.isUserAdmin ? res.data.data.isUserAdmin : false,
          isUserCommunityLeader: res.data.data.isUserCommunityLeader ? res.data.data.isUserCommunityLeader : false,
          communityIds: res.data.data.communityIds ? res.data.data.communityIds : [],
        });
      } catch (err) {
        dispatch('common/displayErrorNotification', err, { root: true });
      } finally {
        commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
      }
    })());
  },
  async getUserInfo({ commit, dispatch, rootGetters }, payload) {
    try {
      const options = {
        url: `${rootGetters['common/commonApiBaseUrl']}${getUserInfoUrl}`,
        method: 'post',
        data: payload,
      };
      const res = await mkNetworkRequestWrapper(options);
      commit('SET_SOCIAL_PROFILE', !isNullOrUndefined(res.data.data.userProfiles) ? res.data.data.userProfiles : []);
      commit(
        'socialCommunities/ADD_USER_INFO',
        !isNullOrUndefined(res.data.data.userInfos) ? res.data.data.userInfos : [],
        { root: true },
      );
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
