export const adminMessageCenterGetters = {
  adminMessageCenterApiBaseUrl: (state, getters, rootState) => rootState.common.apiUrl,
  searchParams: (state) => state.searchParams,
  searchResults: (state) => state.searchResults,
  searchResultsIsLoading: (state) => state.searchResultsIsLoading,
  messageData: (state) => state.messageData,
  messageLogs: (state) => {
    return state.message;
  },
  messageThread: (state) => state.messageThread,
  messageThreads: (state) => state.messageThreads,
  memberId: (state) => state.memberId,
  threadReply: (state) => state.threadReply,
  filterCriteria: (state) => state.messageData.filterOptions.filterCriteria,
  selectedCriteriaOptions: (state) => state.selectedCriteriaOptions,
  selectedCriteriaOptionValues: (state) => state.selectedCriteriaOptionValues,
};
