import { knowledgeHubGetters } from '@/modules/KnowledgeHub/store/getters';
import { knowledgeHubActions } from '@/modules/KnowledgeHub/store/actions';

const knowledgeHubStore = {
  namespaced: true,
  getters: knowledgeHubGetters,
  actions: knowledgeHubActions,
};

export default knowledgeHubStore;
