import { isNullOrUndefined } from '@/utils/helperFunctions';

// you need to include all 4 arguments for using rootGetters, despite the others being unused

export const usersGetters = {
  currentUserLangPref: (state) => {
    return state.langPref;
  },
  currentUserSocialProfile: (state, getters, rootState, rootGetters) => {
    return state.socialProfiles.find((sp) => sp.contactId === rootGetters['authentication/contactId']);
  },
  userInfoExists: (state, getters, rootState, rootGetters) => {
    return !isNullOrUndefined(
      state.socialProfiles.find((sp) => sp.contactId === rootGetters['authentication/contactId']),
    );
  },
  isUserAdmin: (state) => {
    let result = false;
    if (!isNullOrUndefined(state.currentUserInfo)) {
      result = state.currentUserInfo.isUserAdmin;
    }
    return result;
  },
  isUserCommunityLeader: (state) => {
    let result = false;
    if (!isNullOrUndefined(state.currentUserInfo)) {
      result = state.currentUserInfo.isUserCommunityLeader;
    }
    return result;
  },
  communityLeaderCommunityIds: (state) => {
    let result = [];
    if (!isNullOrUndefined(state.currentUserInfo)) {
      result = state.currentUserInfo.communityIds;
    }
    return result;
  },
  socialProfiles: (state) => {
    return state.socialProfiles;
  },
};
