// thresholds

export const triggerFormFieldTriggerDescriptionInitialRows = 3;
export const triggersAdminSearchTriggerInputTextLengthThreshold = 2;
export const triggerPageLimit = 36;
export const triggerNameMaxLength = 200;
export const triggerDescriptionMaxLength = 280;
export const triggerNumberMaxDecimals = 10;

// identifiers
export const createTriggerConditionPlaceholderId = -1;

//pages
export const createTriggersPage = 'Create';
export const editTriggersPage = 'Edit';

//placeholders
export const createTriggerPlaceholderCondition = {
  tempId: 0,
  category: -1,
  topicId: -1,
  selectedTopicId: -1,
  dynamicTopicId: null,
  variable: -1,
  variableIsStatement: 0,
  variableValueIsValid: false,
  variableValues: {
    primaryValue: null,
    secondaryValue: null,
  },
};

//verbiage
export const surveyDoesNotExist =
  'One or more trigger conditions is tied to a survey that no longer exists. Please remove the condition or reassign to an existing survey.';
export const selectTriggersPlaceholder = 'Select Triggers';
