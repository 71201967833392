import store from '@/store';
import { isIE11 } from '@/utils/helperFunctions';

export const openModalHelper = () => {
  if (isIE11()) {
    return;
  }
  const html = document.documentElement;
  const position = window.scrollY;
  html.classList.add('modal--open');
  html.style.top = `-${position}px`;
  store.dispatch('common/setScrollYPosition', Number(position));
};

export const closeModalHelper = () => {
  if (isIE11()) {
    return;
  }
  const html = document.documentElement;
  const position = store.getters['common/scrollYPosition'];
  html.classList.remove('modal--open');
  html.style.top = null;
  window.scrollTo(0, position);
};
