export const CHART_TYPES = {
  PieChart: 'PieChart',
  BarChart: 'BarChart',
  ColumnChart: 'ColumnChart',
  MsBarChart: 'MsBarChart',
  MsColumnChart: 'MsColumnChart',
  NumericChart: 'NumericChart',
};
const CHART_IMAGES = {
  PieChart: 'Pie 2D.png',
  BarChart: 'Bar Chart.png',
  ColumnChart: 'Column 2D.png',
  MsBarChart: 'MultiSeriese Bar 2D.png',
  MsColumnChart: 'Stacked Bar Chart.png',
  NumericChart: 'Numeric.png',
};

export const DATE_TYPE = {
  SingleDate: 'Single',
  ComparisonDate: 'Comparison',
};

export const chartLibraryItemsInternalList = [
  {
    chartCategoryId: 0,
    chartCategoryName: 'Engagement Statistics',
    charts: [
      {
        chartId: 0,
        chartName: 'Devices Connected by Device Type',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'description',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'devices-connected-by-type',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
        },
        sampleData: [
          {
            label: 'Apple Health',
            value: 20,
          },
          {
            label: 'Garmin',
            value: 40,
          },
          {
            label: 'Apple Watch',
            value: 10,
          },
          {
            label: 'Fitbit',
            value: 30,
          },
        ],
      },
      {
        chartId: 1,
        chartName: 'Total Logins',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the total number of logins in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'total-logins',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 789100,
      },
      {
        chartId: 112,
        chartName: 'Unique Logins',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the number of total unique logins.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'unique-logins',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 80000,
      },
      {
        chartId: 115,
        chartName: 'Total Logins (Comparison)',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.MsColumnChart,
        description:
          'Displays the total number of logins in the portal in the report range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.MsColumnChart,
        endpoint: 'total-logins-compare',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        categories: [
          {
            category: [{ label: 'Date Range' }],
          },
        ],
        sampleData: [
          {
            seriesname: '01/01/2023 - 12/31/2023',
            data: [{ value: '789000' }],
          },
          {
            seriesname: '01/01/2022 - 12/31/2022',
            data: [{ value: '567000' }],
          },
          {
            seriesname: '01/01/2021 - 12/31/2021',
            data: [{ value: '456000' }],
          },
        ],
      },
      {
        chartId: 2,
        chartName: 'Top 5 Challenges by Member Enrollment (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the top 5 challenges by the percentage of members who enrolled in the challenge in the report range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'top-5-challenges-by-enrollment',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: 'Stress Less in the New Year Challenge 2024',
            value: 72,
          },
          {
            label: 'Unplug Those Devices Challenge 2024',
            value: 57,
          },
          {
            label: 'Go the Distance Miles Challenge 2024 - miles',
            value: 38,
          },
          {
            label: 'Stay Hydrated Team Challenge 2024',
            value: 44,
          },
          {
            label: 'Go Green Save the Planet Challenge',
            value: 25,
          },
        ],
      },
      {
        chartId: 3,
        chartName: 'Connected Device Rate',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who connected at least one device in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'connected-device-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
        },
        sampleData: 80,
      },
      {
        chartId: 8,
        chartName: 'Engagement by Key Activities',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the percentage of eligible members who have registered, completed the HRA, completed a challenge, earned points, completed a program, connected a device, or joined a social circle in the report date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'engagement-by-Key-activities',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: [
          {
            label: 'Registered',
            value: 85,
          },
          {
            label: 'Completed the HRA',
            value: 75,
          },
          {
            label: 'Completed at least 1 challenge',
            value: 55,
          },
          {
            label: 'Completed a program',
            value: 65,
          },
          {
            label: 'Connected a device',
            value: 75,
          },
          {
            label: 'Joined a social circle',
            value: 80,
          },
        ],
      },
      {
        chartId: 10,
        chartName: 'Engagement by Key Activities',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.MsBarChart,
        description:
          'Compare the percentage of eligible members who have registered, completed the HRA, completed at least 1 challenge, earned points, completed a program, connected a device, or joined a social circle over. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.MsBarChart,
        endpoint: 'engagement-by-Key-activities-ms',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        categories: [
          {
            category: [
              {
                label: 'Registered',
              },
              {
                label: 'Completed the HRA',
              },
              {
                label: 'Completed at least 1 challenge',
              },
              {
                label: 'Completed a program',
              },
              {
                label: 'Connected a device',
              },
              {
                label: 'Joined a social circle',
              },
            ],
          },
        ],
        sampleData: [
          {
            seriesname: '01/01/2020 - 01/01/2022',
            data: [
              {
                value: '200',
              },
              {
                value: '100',
              },
              {
                value: '110',
              },
              {
                value: '70',
              },
              {
                value: '150',
              },
              {
                value: '20',
              },
            ],
          },
          {
            seriesname: '01/01/2022 - 01/01/2024',
            data: [
              {
                value: '350',
              },
              {
                value: '220',
              },
              {
                value: '210',
              },
              {
                value: '90',
              },
              {
                value: '110',
              },
              {
                value: '10',
              },
            ],
          },
        ],
      },
      {
        chartId: 104,
        chartName: 'Total Incentives Earned (points)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the total points earned for all eligible users in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'incentives-total-earned-points',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 30000,
      },
      {
        chartId: 105,
        chartName: 'Average Incentives Earned (points)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the average points earned per eligible user in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'incentives-average-earned-points',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 150,
      },
      {
        chartId: 107,
        chartName: 'Earned Points Rate',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of eligible members who earned points in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'incentives-earned-points-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 12.0,
      },
      {
        chartId: 108, //new charts
        chartName: 'Total Eligible Members',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.MsColumnChart,
        description:
          'Displays the total number of eligible users in the portal. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.MsColumnChart,
        endpoint: 'total-eligible-members-compare',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        categories: [
          {
            category: [{ label: 'Date Range' }],
          },
        ],
        sampleData: [
          {
            seriesname: '01/01/2023 - 01/31/2023',
            data: [{ value: '300000' }],
          },
          {
            seriesname: '02/01/2023- 02/28/2023',
            data: [{ value: '325000' }],
          },
          {
            seriesname: '03/01/2023 - 03/31/2023',
            data: [{ value: '350000' }],
          },
        ],
      },
      {
        chartId: 114,
        chartName: 'Total Eligible Members',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the total number of eligible users in the portal in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'total-eligible-members',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 350000,
      },
      {
        chartId: 113,
        chartName: 'Average minutes spent in portal',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the average time your users spent in the portal in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'average-time-spent',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 35,
      },
      {
        chartId: 109,
        chartName: 'Login Method',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays percentage of users that login via browser versus mobile application versus both.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'login-method',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: 'Mobile only',
            value: 10,
          },
          {
            label: 'Desktop only',
            value: 50,
          },
          {
            label: 'Both login methods',
            value: 40,
          },
        ],
      },
      {
        chartId: 110,
        chartName: 'Gender Distribution',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the gender distribution of members within the selected date range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'gender-distribution',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: 'Male',
            value: 30,
          },
          {
            label: 'Female',
            value: 62,
          },
          {
            label: 'Undefined',
            value: 8,
          },
        ],
      },
      {
        chartId: 116,
        chartName: 'Member Type Distribution',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Display the percentage breakdown of eligible members by type within the selected date range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'member-type',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
        },
        sampleData: [
          {
            label: 'Member',
            value: 75,
          },
          {
            label: 'Spouse',
            value: 20,
          },
          {
            label: 'Dependent (Child)',
            value: 4,
          },
          {
            label: 'Dependent (Family)',
            value: 1,
          },
        ],
      },
      {
        chartId: 111,
        chartName: 'Challenge Completion Rate',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who completed at least 1 challenge in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'challenge-completion-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 0.3,
      },
      {
        chartId: 131,
        chartName: 'Total Steps',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the total steps for all members in the report date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'tracker-total-steps',
        chartSettings: {
          paletteColors: 'mkstandard',
          xaxisname: 'Date Range',
          yaxisname: 'Total Member Steps',
        },
        sampleData: [
          {
            seriesname: '5/1/2024 - 5/31/2024',
            data: [{ value: '1425000' }],
          },
          {
            seriesname: '6/1/2024 - 6/30/2024',
            data: [{ value: '1500000' }],
          },
          {
            seriesname: '7/1/2024 - 7/31/2024',
            data: [{ value: '1387500' }],
          },
        ],
      },
      {
        chartId: 132,
        chartName: 'Average Total Steps',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the average total steps for all members in the report date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'tracker-average-steps',
        chartSettings: {
          paletteColors: 'mkstandard',
          xaxisname: 'Date Range',
          yaxisname: 'Average Total Member Steps',
        },
        sampleData: [
          {
            label: '5/1/2024 - 5/31/2024',
            value: '9500',
          },
          {
            label: '6/1/2024 - 6/30/2024',
            value: '7500',
          },
          {
            label: '7/1/2024 - 7/31/2024',
            value: '8250',
          },
        ],
      },
      {
        chartId: 133,
        chartName: 'Total Activity Minutes',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the total activity minutes for all members in the report date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'tracker-total-active-minutes',
        chartSettings: {
          paletteColors: 'mkstandard',
          xaxisname: 'Date Range',
          yaxisname: 'Total Activity Minutes',
        },
        sampleData: [
          {
            seriesname: '5/1/2024 - 5/31/2024',
            data: [{ value: '9500' }],
          },
          {
            seriesname: '6/1/2024 - 6/30/2024',
            data: [{ value: '7500' }],
          },
          {
            seriesname: '7/1/2024 - 7/31/2024',
            data: [{ value: '8250' }],
          },
        ],
      },
      {
        chartId: 134,
        chartName: 'Average Total Activity Minutes',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the average total activity minutes for all members in the report date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.MsColuColumnChartmnChart,
        endpoint: 'tracker-average-active-minutes',
        chartSettings: {
          paletteColors: 'mkstandard',
          xaxisname: 'Date Range',
          yaxisname: 'Average Activity Minutes',
        },
        sampleData: [
          {
            seriesname: '5/1/2024 - 5/31/2024',
            data: [{ value: '101' }],
          },
          {
            seriesname: '6/1/2024 - 6/30/2024',
            data: [{ value: '56 ' }],
          },
          {
            seriesname: '7/1/2024 - 7/31/2024',
            data: [{ value: '97' }],
          },
        ],
      },
      {
        chartId: 117,
        chartName: 'Total Tango Dollars Spent ($)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the total Tango dollars spent in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'tango-total-dollars-spent',
        chartSettings: {
          paletteColors: 'mkstandard',
          numberprefix: '$',
        },
        sampleData: 30000,
      },
      {
        chartId: 118,
        chartName: 'Average Tango Dollars Spent ($)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the average Tango dollars spent per user who spent Tango dollars in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'tango-average-dollars-spent',
        chartSettings: {
          paletteColors: 'mkstandard',
          numberprefix: '$',
        },
        sampleData: 150,
      },
    ],
  },
  {
    chartCategoryId: 1,
    chartCategoryName: 'Health Assessment Statistics',
    charts: [
      {
        chartId: 100,
        chartName: 'HRA Completed by Age Group (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.ColumnChart,
        description: 'Displays the percent of members who took the HRA by age group in the report range.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'hra-by-age-group',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 0,
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: '16-17',
            value: 100,
          },
          {
            label: '18-29',
            value: 20,
          },
          {
            label: '30-39',
            value: 40,
          },
          {
            label: '40-49',
            value: 80,
          },
          {
            label: '50-59',
            value: 30,
          },
          {
            label: '60+',
            value: 60,
          },
        ],
      },
      {
        chartId: 101,
        chartName: 'HRA Completed by Gender (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percent of members who took the HRA by gender in the report range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'hra-by-gender',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: 'Male',
            value: 47,
          },
          {
            label: 'Female',
            value: 53,
          },
        ],
      },
      {
        chartId: 102,
        chartName: 'Meet Exercise Recommendations by BMI Class (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the percentage of members who meet exercise recommendations by BMI class in the report range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'members-bmi-class',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: 'Underweight (<18.5)',
            value: 3,
          },
          {
            label: 'Normal (18.5-24.9)',
            value: 12,
          },
          {
            label: 'Overweight (25-29.9)',
            value: 46,
          },
          {
            label: 'Obese (Class 1/Class II - 30-39.9)',
            value: 35,
          },
          {
            label: 'Extremely Obese (Class III >=40)',
            value: 4,
          },
        ],
      },
      {
        chartId: 103,
        chartName: ' Self-Reported Health Status Breakdown (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage of members by self-reported health status in the report range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'self-reported-health-status',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
          showLabels: '0',
        },
        sampleData: [
          {
            label: 'Poor',
            value: 13,
          },
          {
            label: 'Fair',
            value: 25,
          },
          {
            label: 'Good',
            value: 38,
          },
          {
            label: 'Excellent',
            value: 16,
          },
          {
            label: 'Unreported',
            value: 8,
          },
        ],
      },
      {
        chartId: 301,
        chartName: 'Self-Reported Health Is the Same or Better (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members whose self-reported health is the same or better this year in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/is-same-or-better',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 51,
      },
      {
        chartId: 302,
        chartName: 'Meet Exercise Recommendations (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who meet exercise recommendations in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/meet-exercise-recommendations',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 49,
      },
      {
        chartId: 303,
        chartName: ' Interested in Exercising More (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who are interested in exercising more in the future in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/interested-in-exercising-more',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 33,
      },
      {
        chartId: 304,
        chartName: 'Less Than 6oz Sugary Beverages Per Day (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who normally drink 6 oz of sugar sweetened beverages a day in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/less-than-6oz-sugary-beverages-per-day',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 24,
      },
      {
        chartId: 305,
        chartName: 'Eat 5+ Fruits and Veggies Per Day (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who eat 5+ fruits and veggies a day in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/eat-5-fruits-and-veggies-per-day',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 33,
      },
      {
        chartId: 306,
        chartName: 'Eat Fried Foods or Pastries Most Days (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who eat fried foods or pastries on most days in the reported date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/eat-fried-foods-pastries-most-days',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 55,
      },
      {
        chartId: 307,
        chartName: 'Eat Fast food > 3 Days Per Week (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who eat fast food more than 3 days/week in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/eat-fast-food-3-days-per-week',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 45,
      },
      {
        chartId: 308,
        chartName: 'Floss Regularly (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of eligible members who regularly floss in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/floss-regularly',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 22,
      },
      {
        chartId: 309,
        chartName: ' Dental Checkups in Last 6 Months (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who had dental checkups in the last 6 months in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/dental-checkups-in-last-6-months',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 41,
      },
      {
        chartId: 310,
        chartName: 'Brush 2x Per Day (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of eligible members who brush twice a day in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/brush-2x-per-day',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 65,
      },
      {
        chartId: 311,
        chartName: 'Drink Occasionally or Less (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who drink occasionally or less in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/drink-occasionally-or-less',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 73,
      },
      {
        chartId: 312,
        chartName: 'Do Not Use Tobacco (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of eligible members who do not use tobacco in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/do-not-use-tobacco',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 32,
      },
      {
        chartId: 313,
        chartName: 'Sleep Soundly, Feel Rested (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who sleep soundly, feel rested in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/sleep-soundly-feel-rested',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 65,
      },
      {
        chartId: 314,
        chartName: 'Sick Days in Last 12 Months Due to Stress (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who took sick days in the last 12 months due to stress in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/sick-days-last-12-months-stress',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 21,
      },
      {
        chartId: 315,
        chartName: 'Emotional State Caused Missing Daily Activities (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who said emotional state caused them to miss out on daily activities in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/emotional-state-caused-daily-activities',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 13,
      },
      {
        chartId: 316,
        chartName: 'High Risk for Stress (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who are at high risk for stress in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/high-risk-for-stress',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 41,
      },
      {
        chartId: 317,
        chartName: 'High Risk for Lack of Exercise (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who are at high risk for lack of exercise in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/high-risk-for-lack-of-exercise',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 31,
      },
      {
        chartId: 318,
        chartName: 'High Risk for Tobacco Use (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of eligible members who are at high risk for tobacco use in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/high-risk-for-tobacco-use',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 55,
      },
    ],
  },
];
