import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { menuManagementBaseUrl } from '@/modules/AdminMenuManagement/utils';
import { isStringNullUndefinedOrEmpty } from '@/utils/helperFunctions';

export const adminMenuManagementSearchActions = {
  setSearchParams({ commit }, searchParams) {
    commit('SET_SEARCH_PARAMS', searchParams);
  },
  setSearchPaginationInfo({ commit }, paginationInfo) {
    commit('SET_SEARCH_PAGINATION_INFO', paginationInfo);
  },
  async loadExistingMenus({ dispatch, commit, rootGetters }) {
    try {
      const response = await mkNetworkRequestWrapper({
        url: `${rootGetters['common/commonApiBaseUrl']}${menuManagementBaseUrl}/existing-menus`,
        method: 'GET',
      });

      commit('SET_ALL_MENUS', response.data?.data?.existingMenus ?? []);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async searchMenus({ dispatch, commit, getters, rootGetters }) {
    try {
      commit('SET_IS_SEARCHING', true);
      const params = new URLSearchParams({});
      const { itemsPerPage, page } = getters.paginationInfo;

      const offset = itemsPerPage * (page - 1);
      params.append('offset', offset);
      params.append('limit', itemsPerPage);

      const { menuName, menuStatus, groupName } = getters.searchParams;

      if (menuStatus !== null && menuStatus !== -1) {
        params.append('status', menuStatus);
      }

      if (!isStringNullUndefinedOrEmpty(menuName)) {
        params.append('menuName', menuName.trim());
      }

      if (!isStringNullUndefinedOrEmpty(groupName)) {
        params.append('groupName', groupName.trim());
      }

      const res = await mkNetworkRequestWrapper({
        url: `${rootGetters['common/commonApiBaseUrl']}${menuManagementBaseUrl}?${params.toString()}`,
        method: 'GET',
      });

      const menus = res.data?.data?.menus ?? [];

      commit('SET_SEARCH_RESULTS', menus);
      commit('SET_TOTAL_SEARCH_RESULTS', res.data.data.totalCount);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
      commit('SET_IS_SEARCHING', false);
    }
  },
  async loadStandardMenu({ dispatch, commit, getters, rootGetters }) {
    try {
      commit('SET_IS_SEARCHING', true);

      const response = await mkNetworkRequestWrapper({
        url: `${rootGetters['common/commonApiBaseUrl']}${menuManagementBaseUrl}/standard-menu`,
        method: 'GET',
      });
      const { standardMenuGroupCount } = response.data.data;

      const standardMenu = getters.searchResults.find((r) => r.id === -1);
      if (standardMenu) {
        standardMenu.totalGroupCount = standardMenuGroupCount;
      }
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
      commit('SET_IS_SEARCHING', false);
    }
  },
};
