import platformOptionsMutations from '@/modules/PlatformOptions/store/mutations';
import platformOptionsGetters from '@/modules/PlatformOptions/store/getters';
import platformOptionsActions from '@/modules/PlatformOptions/store/actions';

export const platformOptionsState = {
  menuOptions: {},
  konfigOptions: {},
};

const platformOptionsStore = {
  namespaced: true,
  state: platformOptionsState,
  mutations: platformOptionsMutations,
  getters: platformOptionsGetters,
  actions: platformOptionsActions,
};

export default platformOptionsStore;
