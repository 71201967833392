import { defaultTemplateTypeId } from '@/modules/AdminTemplates/utils';

export const adminTemplatesGetters = {
  createAdminTemplateName: (state) => {
    return state.createOrEditAdminTemplate.name;
  },
  createAdminTemplateText: (state) => {
    return state.createOrEditAdminTemplate.text;
  },
  createAdminTemplateType: (state) => {
    return state.createOrEditAdminTemplate.templateType;
  },
  adminTemplateSearchText: (state) => {
    return state.searchAdminTemplate.searchText;
  },
  adminTemplateSearchTemplateType: (state) => {
    return state.searchAdminTemplate.adminTemplateSearchTemplateType;
  },
  adminTemplateSearchResultsPaginationInfo: (state) => {
    return state.searchAdminTemplate.searchResultsPaginationInfo;
  },
  filteredAdminTemplates: (state) => {
    return state.searchAdminTemplate.filteredTemplates;
  },
  allAdminTemplates: (state) => {
    return state.searchAdminTemplate.allTemplates;
  },
  allTemplateTypes: (state) => {
    return state.searchAdminTemplate.allTemplateTypes;
  },
  enabledTemplateTypes: (state) => {
    return state.searchAdminTemplate.allTemplateTypes.filter((t) => t.enabled);
  },
  createAdminTemplateFormValid: (state) =>
    state.createOrEditAdminTemplate.isFormValid &&
    state.createOrEditAdminTemplate.text !== '' &&
    state.createOrEditAdminTemplate.templateType !== defaultTemplateTypeId,
  createAdminTemplateFormDirty: (state) =>
    state.createOrEditAdminTemplate.name !== '' ||
    state.createOrEditAdminTemplate.text !== '' ||
    state.createOrEditAdminTemplate.templateType !== defaultTemplateTypeId,
  adminTemplatesApiBaseUrl: (state, getters, rootState) => rootState.common.apiUrl,
  selectedAdminTemplate: (state) => state.selectedAdminTemplate,
  validationErrors: (state) => state.createOrEditAdminTemplate.validationErrors,
  hasEdits: ({ createOrEditAdminTemplate: { name, text }, selectedAdminTemplate }) =>
    selectedAdminTemplate && (name !== selectedAdminTemplate.name || text !== selectedAdminTemplate.text),
};
