import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { isNullOrUndefined } from '@/utils/helperFunctions';
import { konfigOptionsUrl } from '@/modules/PlatformOptions/utils';

const platformOptionsActions = {
  async getKonfigOptionsForUser({ commit, rootGetters, dispatch }) {
    try {
      const options = {
        url: `${rootGetters['common/commonApiBaseUrl']}${konfigOptionsUrl}`,
        method: 'get',
      };

      const konfigOptionsResponse = await mkNetworkRequestWrapper(options);
      const konfigOptionsWithMeta = isNullOrUndefined(konfigOptionsResponse.data.data)
        ? {}
        : konfigOptionsResponse.data.data;

      commit('SET_KONFIG_OPTIONS', konfigOptionsWithMeta);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};

export default platformOptionsActions;
