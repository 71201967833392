<template>
  <div class="container--tabs mt-10 px-4">
    <v-tabs v-model="tab" grow>
      <v-tab v-for="item in chatSidebarTabItems" v-bind:key="item.id" class="tab">
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="pa-2">
        active sessions placeholder
      </v-tab-item>
      <v-tab-item class="pa-2">
        support history placeholder
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { chatSidebarTabItems, chatSidebarSessionsTabId } from '@/modules/Common/utils/constants';
export default {
  name: 'ChatSessionsSupportWrapper',
  data() {
    return {
      chatSidebarTabItems: chatSidebarTabItems,
      chatSidebarSessionsTabId: chatSidebarSessionsTabId,
      tab: chatSidebarSessionsTabId,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-tabs-slider-wrapper {
  display: none;
}
::v-deep .v-slide-group__wrapper {
  border-radius: 4px;
}
::v-deep .v-tabs-bar {
  height: 3rem;
}
::v-deep .v-tab {
  font-weight: normal;
  font-size: 0.8rem;
  color: $mediumGray;
  background: $lightGray;
  &.v-tab--active {
    color: $background;
    background: $chatSidebarTabsBlueGray;
  }
}
.tab {
  text-transform: none;
  letter-spacing: normal;
}
</style>
