import { Main } from '@/modules/KnowledgeHub/router/knowledgeHubRoutesConstants';

const knowledgeHubRoutes = [
  {
    path: Main.path,
    name: Main.name,
    component: () => import('@/modules/KnowledgeHub/components/Main'),
    meta: {
      requiresAuth: true,
    },
  },
];

export default knowledgeHubRoutes;
