export const mapTriggerRequestObjectFromState = (currentState) => {
  const triggerToCreate = {
    name: currentState.name,
    description: currentState.description,
    metIfCondition: currentState.metIfCondition,
    triggerStatus: currentState.triggerStatus,
    rules: currentState.rules.map((rule) => {
      return {
        tempId: rule.tempId,
        metIfCondition: rule.metIfCondition,
        conditions: rule.conditions.map((condition) => {
          return {
            tempId: condition.tempId,
            categoryId: condition.category,
            topicId: condition.topicId,
            dynamicTopicId: condition.dynamicTopicId,
            variableId: condition.variable,
            variableIsStatement: condition.variableIsStatement,
            variableValues: {
              primaryValue: condition.variableValues.primaryValue,
              secondaryValue: condition.variableValues.secondaryValue,
            },
          };
        }),
      };
    }),
  };
  return triggerToCreate;
};
