import analyticsRoutes from '@/modules/Analytics/router';
export const PERMISSIONS_CONSTANTS = {
  CanViewDashboard: 3003,
  CanCreateDashboard: 3004,
  CanAssignDashboard: 3005,
};
export const dashboardBuilderDropdownList = [
  { label: 'Editors only', value: 1 },
  { label: 'Admin roles', value: 2 },
  { label: 'Specific admins', value: 3 },
];
export const ANALYTICS_VIEW_TYPE = {
  Dashboard: 'dashboard',
  DashboardBuilder: 'dashboard-builder',
  DashboardEditor: 'dashboard-editor',
};

export const ANALYTICS_SUB_VIEW_TYPE = {
  Dashboard: { path: 'dashboard', displayText: 'Dashboard' },
  DashboardBuilder: { path: 'dashboard-builder', displayText: 'Dashboard Builder' },
};

export const analyticsSideMenuItems = [
  {
    displayText: 'Dashboard',
    route: analyticsRoutes[1].name,
    visible: false,
    selected: false,
    params: { viewType: ANALYTICS_VIEW_TYPE.Dashboard, subViewType: ANALYTICS_SUB_VIEW_TYPE.Dashboard.path },
  },
  {
    displayText: 'Dashboard Builder',
    route: analyticsRoutes[1].name,
    selected: false,
    visible: true,
    params: {
      viewType: ANALYTICS_VIEW_TYPE.DashboardBuilder,
      subViewType: ANALYTICS_SUB_VIEW_TYPE.DashboardBuilder.path,
    },
  },
];

export const LibraryTemplateItems = [
  {
    id: 0,
    name: '1 Column',
    type: 'library-one-col',
    icon: require('@/assets/img/analytics/library_1_col.png'),
    props: {
      slots: [null],
    },
  },
  {
    id: 1,
    name: '2 Column',
    type: 'library-two-col',
    icon: require('@/assets/img/analytics/library_2_col.png'),
    props: {
      slots: [null, null],
    },
  },
  {
    id: 2,
    name: 'Left Sidebar',
    type: 'library-two-col-left',
    icon: require('@/assets/img/analytics/library_2_col_l.png'),
    props: {
      slots: [null, null],
    },
  },
  {
    id: 3,
    name: 'Right Sidebar',
    type: 'library-two-col-right',
    icon: require('@/assets/img/analytics/library_2_col_r.png'),
    props: {
      slots: [null, null],
    },
  },
  {
    id: 4,
    name: '3 Column',
    type: 'library-three-col',
    icon: require('@/assets/img/analytics/library_3_col.png'),
    props: {
      slots: [null, null, null],
    },
  },
  {
    id: 5,
    name: '4 Column',
    type: 'library-four-col',
    icon: require('@/assets/img/analytics/library_4_col.png'),
    props: {
      slots: [null, null, null, null],
    },
  },
  {
    id: 6,
    name: 'Title Section',
    type: 'library-title',
    icon: require('@/assets/img/analytics/library_title.png'),
    props: {
      text: '',
    },
  },
  {
    id: 7,
    name: 'Text Section',
    type: 'library-text',
    icon: require('@/assets/img/analytics/library_text.png'),
    props: {
      text: '',
    },
  },
];
export const filtersDropdownSingle = [
  { label: 'Current (YTD)', value: 0 },
  { label: 'Last Month', value: 1 },
  { label: 'Last Quarter', value: 2 },
  { label: 'Last Year', value: 3 },
  { label: 'Custom', value: 4 },
];
