export const AdminTemplates = {
  name: 'AdminTemplates',
  path: '/admin-templates/search',
};

export const CreateAdminTemplate = {
  name: 'CreateAdminTemplate',
  path: '/admin-templates/create',
};

export const EditAdminTemplate = {
  name: 'EditAdminTemplate',
  path: '/admin-templates/edit/:adminTemplateId',
};

export const PickAdminTemplate = {
  name: 'PickAdminTemplate',
  path: '/admin-templates/pick/:adminTemplateType',
};

export const PreviewAdminTemplate = {
  name: 'PreviewAdminTemplate',
  path: '/admin-templates/preview/:adminTemplateId',
};
