import {
  triggerSortByCreationDateDescending,
  triggerStatusAll,
  triggerOriginTypeAdminCreated,
} from '@/modules/TriggersAdmin/utils';
import { triggersAdminMutations } from '@/modules/TriggersAdmin/store/mutations';
import { triggersAdminGetters } from '@/modules/TriggersAdmin/store/getters';
import { triggersAdminActions } from '@/modules/TriggersAdmin/store/actions';

export const triggersAdminState = {
  triggers: {
    createOrEditTrigger: {
      name: null,
      description: null,
      metIfCondition: 0,
      triggerStatus: -1,
      stepOneValid: false,
      stepTwoMetIfValid: true,
      rules: [
        {
          tempId: 0,
          metIfCondition: 0,
          metIfIsValid: false,
          conditions: [
            {
              tempId: 0,
              category: -1,
              selectedTopicId: -1,
              topicId: -1,
              dynamicTopicId: null,
              variable: -1,
              variableIsStatement: 0,
              variableValueIsValid: false,
              variableValues: {
                primaryValue: null,
                secondaryValue: null,
              },
            },
          ],
        },
      ],
    },
    availableConditions: {
      categories: [],
      topics: {},
      statements: {},
      statementOptions: {},
    },
    adminTriggers: [],
    assignableTriggers: [],
    reloadTriggers: {
      onSearch: true,
      onFilter: true,
      onSort: true,
    },
    filterId: triggerStatusAll,
    filterOriginTypeId: triggerOriginTypeAdminCreated,
    searchText: '',
    sortBy: triggerSortByCreationDateDescending,
    queryTotal: 0,
    triggerTotal: 0,
    customTriggersCount: 0,
    loadingTriggers: false,
    duplicatedTriggerName: '',
    paginationLinks: {},
    selectedTrigger: null,
  },
};

const triggersAdminStore = {
  namespaced: true,
  state: triggersAdminState,
  mutations: triggersAdminMutations,
  getters: triggersAdminGetters,
  actions: triggersAdminActions,
};

export default triggersAdminStore;
