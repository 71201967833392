import {
  enumTypePost,
  enumTypeComment,
  maxPinPosts,
  communityStatusDisabled,
  manageParticipantsPageSize,
} from '@/modules/SocialCommunities/utils/constants';
import {
  isNullOrUndefined,
  isArrayEmpty,
  convertUtcStringToLocal,
  convertNullValuesInArrayOfObjectsToEmpty,
  isStringNullUndefinedOrEmpty,
} from '@/utils/helperFunctions';

export const socialCommunitiesGetters = {
  socialCommunitiesApiBaseUrl: (state, getters, rootState) => {
    return rootState.common.apiUrl;
  },
  communityFilterId: (state) => {
    return state.communityFilterId;
  },
  communityManagementTotalCount: (state) => {
    return state.communityManagementTotalCount;
  },
  allCommunities: (state) => {
    let result = [];
    if (!isNullOrUndefined(state.communityInfo)) {
      result = state.communityInfo.sort(
        (a, b) => convertUtcStringToLocal(b.createdDateUtc) - convertUtcStringToLocal(a.createdDateUtc),
      );
    }
    return result;
  },
  currentUser: (state, getters, rootState, rootGetters) => {
    let result = null;
    const members = getters.currentCommunityMembers;
    result = members.find((member) => member.contactId === rootGetters['authentication/contactId']);
    return result;
  },
  hasCurrentUserJoinedCommunities: (state) => {
    let result = false;
    const joined = state.communityInfo.filter((c) => c.userJoined === true);
    if (joined.length > 0) {
      result = true;
    }
    return result;
  },
  currentCommunityId: (state) => {
    return state.currentCommunityId;
  },
  currentCommunity: (state) => {
    return !isNullOrUndefined(state.communityInfo)
      ? state.communityInfo.find((c) => c.id === state.currentCommunityId)
      : null;
  },
  currentCommunityName: (state) => {
    let result = null;
    if (!isNullOrUndefined(state.communityInfo)) {
      const community = state.communityInfo.find((c) => c.id === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.name;
      }
    }
    return result;
  },
  currentCommunitySubtitle: (state) => {
    let result = null;
    if (!isNullOrUndefined(state.communityInfo)) {
      const community = state.communityInfo.find((c) => c.id === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.subtitle;
      }
    }
    return result;
  },
  currentCommunityBackgroundGradient: (state) => {
    let result = null;
    if (!isNullOrUndefined(state.communityInfo)) {
      const community = state.communityInfo.find((c) => c.id === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.backgroundGradient;
      }
    }
    return result;
  },
  currentCommunityIcon: (state) => {
    let result = null;
    if (!isNullOrUndefined(state.communityInfo)) {
      const community = state.communityInfo.find((c) => c.id === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.icon;
      }
    }
    return result;
  },
  currentCommunityStatus: (state) => {
    let result = null;
    if (!isNullOrUndefined(state.communityInfo)) {
      const community = state.communityInfo.find((c) => c.id === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.status;
      }
    }
    return result;
  },
  isCurrentCommunityLeadersOnly: (state) => {
    let result = false;
    if (!isNullOrUndefined(state.communityInfo)) {
      const community = state.communityInfo.find((c) => c.id === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.leadersOnly;
      }
    }
    return result;
  },
  isCurrentCommunityDisabled: (state) => {
    let result = false;
    if (!isNullOrUndefined(state.communityInfo)) {
      const community = state.communityInfo.find((c) => c.id === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.status === communityStatusDisabled;
      }
    }
    return result;
  },
  currentCommunityPosts: (state) => {
    let result = [];
    let unsortedPosts = [];
    if (!isNullOrUndefined(state.posts)) {
      unsortedPosts = state.posts.filter((p) => p.communityId === state.currentCommunityId);
      result = unsortedPosts.sort(
        (a, b) => convertUtcStringToLocal(b.createdDateUtc) - convertUtcStringToLocal(a.createdDateUtc),
      );
    }
    return result;
  },
  currentCommunityComments: (state) => {
    let result = [];
    let unsortedComments = [];
    if (!isNullOrUndefined(state.posts)) {
      unsortedComments = state.comments.filter((c) => c.communityId === state.currentCommunityId);
      result = unsortedComments.sort(
        (a, b) => convertUtcStringToLocal(a.createdDateUtc) - convertUtcStringToLocal(b.createdDateUtc),
      );
    }
    return result;
  },
  currentCommunityPinnedPosts: (state) => {
    let result = [];
    let unsortedPins = [];
    if (!isArrayEmpty(state.postPins)) {
      state.postPins.forEach((pp) => {
        let post = state.posts.find((p) => p.id === pp.postId && pp.communityId === state.currentCommunityId);
        if (!isNullOrUndefined(post)) {
          // this is not ideal; we don't want to mutate like this on a getter if we can avoid it
          post.pinId = pp.pinId;
          post.pinCreatedDateUtc = pp.createdDateUtc;
          post.pinnedByAdmin = pp.pinnedByAdmin;
          unsortedPins.push(post);
        }
      });
      result = unsortedPins.sort(
        (a, b) => convertUtcStringToLocal(b.pinCreatedDateUtc) - convertUtcStringToLocal(a.pinCreatedDateUtc),
      );
    }
    return result;
  },
  currentCommunityPinnedPostsMetadata: (state) => {
    return !isNullOrUndefined(state.postPins)
      ? state.postPins.filter((pp) => pp.communityId === state.currentCommunityId)
      : [];
  },
  currentCommunityOverMaxPins: (state, getters) => {
    return getters.currentCommunityPinnedPosts.length >= maxPinPosts;
  },
  currentCommunityHeroItems: (state) => {
    return !isNullOrUndefined(state.communityHeroItems)
      ? state.communityHeroItems.filter((hi) => hi.communityId === state.currentCommunityId)
      : [];
  },
  currentCommunityPostReactions: (state) => {
    return !isNullOrUndefined(state.postReactions)
      ? state.postReactions.filter((pr) => pr.communityId === state.currentCommunityId)
      : [];
  },
  currentCommunityCommentReactions: (state) => {
    return !isNullOrUndefined(state.commentReactions)
      ? state.commentReactions.filter((cr) => cr.communityId === state.currentCommunityId)
      : [];
  },
  currentCommunityPostFlags: (state, getters, rootState, rootGetters) => {
    return !isNullOrUndefined(state.postFlags)
      ? state.postFlags.filter(
          (pf) =>
            pf.communityId === state.currentCommunityId &&
            pf.createdUserContactId === rootGetters['authentication/contactId'],
        )
      : [];
  },
  currentCommunityCommentFlags: (state, getters, rootState, rootGetters) => {
    return !isNullOrUndefined(state.commentFlags)
      ? state.commentFlags.filter(
          (cf) =>
            cf.communityId === state.currentCommunityId &&
            cf.createdUserContactId === rootGetters['authentication/contactId'],
        )
      : [];
  },
  currentCommunityUserInfo: (state) => {
    return !isNullOrUndefined(state.userInfo)
      ? state.userInfo.filter((ui) => ui.communityId === state.currentCommunityId)
      : [];
  },
  currentCommunityMembers: (state, getters, rootState, rootGetters) => {
    let result = [];
    if (!isNullOrUndefined(state.userInfo)) {
      result = state.userInfo
        .filter((ui) => ui.communityId === state.currentCommunityId && ui.isMemberOfSelectedCommunity)
        .map((ui) => ui.contactId)
        .reduce((profiles, contactId) => {
          const profile = rootGetters['users/socialProfiles'].find((p) => p.contactId === contactId);
          if (profile) {
            profiles.push(profile);
          }
          return profiles;
        }, []);
    }
    return result;
  },
  currentCommunityMemberCount: (state) => {
    let result = 0;
    if (!isNullOrUndefined(state.communityTotalMemberCounts)) {
      const community = state.communityTotalMemberCounts.find((tmc) => tmc.communityId === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.totalMemberCount;
      }
    }
    return result;
  },
  currentCommunityLeaders: (state, getters, rootState, rootGetters) => {
    let result = [];
    if (!isNullOrUndefined(state.userInfo)) {
      result = state.userInfo
        .filter((ui) => ui.communityId === state.currentCommunityId && ui.isCommunityLeader)
        .map((ui) => ui.contactId)
        .reduce((profiles, contactId) => {
          const profile = rootGetters['users/socialProfiles'].find((p) => p.contactId === contactId);
          if (profile) {
            profiles.push(profile);
          }

          return profiles;
        }, []);
    }
    return result;
  },
  currentCommunityLeaderCount: (state) => {
    let result = 0;
    if (!isNullOrUndefined(state.communityTotalLeaderCounts)) {
      const community = state.communityTotalLeaderCounts.find((tlc) => tlc.communityId === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.totalLeaderCount;
      }
    }
    return result;
  },
  currentCommunityMemberSearchResults: (state, getters, rootState, rootGetters) => {
    let result;
    if (!isArrayEmpty(state.filteredCommunityMembers)) {
      result = state.filteredCommunityMembers.reduce((profiles, contactId) => {
        const profile = rootGetters['users/socialProfiles'].find((p) => p.contactId === contactId);
        if (profile) {
          profiles.push(profile);
        }
        return profiles.filter((p) => !isNullOrUndefined(p));
      }, []);
    } else {
      // If no filtered members are returned from search, use the search string as a flag, no need for a getter (yet) as we'll only reference it here
      // i.e. Users searches and no results are found, return no filtered users
      // i.e. On page load, no filtered users exists and no search string has been set, return the current list of members
      result = isStringNullUndefinedOrEmpty(state.currentMembersEnrolledSearchString)
        ? getters.currentCommunityMembers
        : [];
    }
    return result;
  },
  hasCommunityLeaders: (state) => {
    let result = false;
    if (!isNullOrUndefined(state.communityTotalLeaderCounts)) {
      const community = state.communityTotalLeaderCounts.find((tlc) => tlc.communityId === state.currentCommunityId);
      if (!isNullOrUndefined(community)) {
        result = community.totalLeaderCount > 0;
      }
    }
    return result;
  },
  isUserLeaderInCurrentCommunity: (state, getters, rootState, rootGetters) => {
    return getters.currentCommunityLeaders.some((l) => l.contactId === rootGetters['authentication/contactId']);
  },
  postEditing: (state) => {
    return state.postEditing;
  },
  adminFlaggedPostIds: (state) => {
    const uniqueFlaggedPostIds = new Set(state.postFlags.map((f) => f.postId));
    return Array.from(uniqueFlaggedPostIds);
  },
  adminFlaggedCommentIds: (state) => {
    const uniqueFlaggedCommentIds = new Set(state.commentFlags.map((f) => f.commentId));
    return Array.from(uniqueFlaggedCommentIds);
  },
  allFlaggedPosts: (state, getters) => {
    const allFlaggedPosts = [];
    getters.adminFlaggedPostIds.forEach((id) => {
      // not pretty, but we need to ensure both pieces of state exist before we construct this
      // getter or else stuff upstream starts breaking
      const flaggedPost = state.posts.find((p) => p.id === id);
      if (!isNullOrUndefined(flaggedPost)) {
        const community = state.communityInfo.find((c) => c.id === flaggedPost.communityId);
        if (!isNullOrUndefined(community)) {
          allFlaggedPosts.push({
            communityId: Number(flaggedPost.communityId),
            communityName: community.name,
            flag: state.postFlags.filter((f) => f.postId === flaggedPost.id),
            post: flaggedPost,
            postType: enumTypePost,
            contactId: flaggedPost.createdUserContactId,
          });
        }
      }
    });
    return allFlaggedPosts;
  },
  allFlaggedComments: (state, getters) => {
    const allFlaggedComments = [];
    getters.adminFlaggedCommentIds.forEach((id) => {
      // not pretty, but we need to ensure both pieces of state exist before we construct this
      // getter or else stuff upstream starts breaking
      const flaggedComment = state.comments.find((c) => c.id === id);
      if (!isNullOrUndefined(flaggedComment)) {
        const commentFlag = state.commentFlags.find((c) => c.commentId === flaggedComment.id);
        const community = state.communityInfo.find((c) => c.id === commentFlag.communityId);
        if (!isNullOrUndefined(community)) {
          allFlaggedComments.push({
            communityId: Number(flaggedComment.communityId),
            communityName: community.name,
            flag: state.commentFlags.filter((f) => f.commentId === flaggedComment.id),
            post: flaggedComment,
            postType: enumTypeComment,
            contactId: flaggedComment.createdUserContactId,
          });
        }
      }
    });
    return allFlaggedComments;
  },
  userInfo: (state) => {
    return state.userInfo;
  },
  communityGroups: (state) => {
    return state.communityGroups;
  },
  currentCommunityPrivacySettings: (state, getters) => {
    return state.communityPrivacySettings.find((ps) => ps.communityId === getters.currentCommunityId);
  },
  currentCommunityPrivacySettingsAreAvailable: (_, getters) => {
    return !isNullOrUndefined(getters.currentCommunityPrivacySettings);
  },
  currentCommunityFullNamesAllowed: (state, getters) => {
    let result = false;
    if (!isNullOrUndefined(getters.currentCommunityPrivacySettings)) {
      result = getters.currentCommunityPrivacySettings.allowFullNames;
    }
    return result;
  },
  currentCommunityProfilePicsAllowed: (state, getters) => {
    let result = false;
    if (!isNullOrUndefined(getters.currentCommunityPrivacySettings)) {
      result = getters.currentCommunityPrivacySettings.allowProfilePics;
    }
    return result;
  },
  manageParticipantsMembersStartPoint: (state) => {
    return state.participantsStartPointMembers;
  },
  manageParticipantsUsersStartPoint: (state) => {
    return state.participantsStartPointUsers;
  },
  currentCommunityAvailableMembers: (state, getters, rootState, rootGetters) => {
    let result = [];
    if (!isNullOrUndefined(state.userInfo)) {
      result = state.userInfo
        .filter((ui) => ui.communityId === state.currentCommunityId && ui.isMemberOfSelectedCommunity)
        .map((ui) => ui.contactId)
        .reduce((profiles, contactId) => {
          profiles.push(rootGetters['users/socialProfiles'].find((p) => p.contactId === contactId));
          return profiles;
        }, []);
      result = convertNullValuesInArrayOfObjectsToEmpty(result);
      result = result.filter((r) => {
        const fullNameIndex = r.fullName
          ? r.fullName.toLowerCase().indexOf(state.participantsSearchStringMembers.toLowerCase())
          : -1;
        const nickNameIndex = r.nickname
          ? r.nickname.toLowerCase().indexOf(state.participantsSearchStringMembers.toLowerCase())
          : -1;

        return fullNameIndex > -1 || nickNameIndex > -1;
      });
    }
    return result.slice(
      state.participantsStartPointMembers,
      state.participantsStartPointMembers + manageParticipantsPageSize,
    );
  },
  currentCommunityAvailableUsers: (state, getters, rootState, rootGetters) => {
    let result = [];
    if (!isNullOrUndefined(state.userInfo)) {
      result = state.userInfo
        .filter((ui) => ui.communityId === state.currentCommunityId)
        .map((ui) => ui.contactId)
        .reduce((profiles, contactId) => {
          profiles.push(rootGetters['users/socialProfiles'].find((p) => p.contactId === contactId));
          return profiles;
        }, []);
      result = convertNullValuesInArrayOfObjectsToEmpty(result);
      result = result.filter((r) => {
        const fullNameIndex = r.fullName
          ? r.fullName.toLowerCase().indexOf(state.participantsSearchStringUsers.toLowerCase())
          : -1;
        const nickNameIndex = r.nickname
          ? r.nickname.toLowerCase().indexOf(state.participantsSearchStringUsers.toLowerCase())
          : -1;

        return fullNameIndex > -1 || nickNameIndex > -1;
      });
    }
    return result.slice(
      state.participantsStartPointUsers,
      state.participantsStartPointUsers + manageParticipantsPageSize,
    );
  },
  participantsSearchStringMembers: (state) => {
    return state.participantsSearchStringMembers;
  },
  participantsSearchStringUsers: (state) => {
    return state.participantsSearchStringUsers;
  },
  createCommunityDesignMethod: (state) => {
    return state.createCommunity.designMethod;
  },
  createCommunitySelectedCategory: (state) => {
    return state.createCommunity.selectedCategory;
  },
  createCommunitySelectedTemplate: (state) => {
    return state.createCommunity.selectedTemplate;
  },
  createCommunityBackgroundGradient: (state) => {
    return Number(state.createCommunity.selectedBackgroundGradient);
  },
  createCommunityIcon: (state) => {
    return Number(state.createCommunity.selectedIcon);
  },
  createCommunityName: (state) => {
    return state.createCommunity.name;
  },
  createCommunityDescription: (state) => {
    return state.createCommunity.description;
  },
  createCommunityNewHeroImages: (state) => {
    return state.createCommunity.heroImages;
  },
  createCommunityGroups: (state) => {
    return state.createCommunity.groups;
  },
  createCommunityLeadersOnly: (state) => {
    return state.createCommunity.leadersOnly;
  },
  createCommunityAssignedTriggers: (state) => {
    return state.createCommunity.triggers;
  },
  currentCommunityTotalPostCount: (state) => {
    let result = 0;
    if (!isNullOrUndefined(state.communityTotalPostCounts)) {
      const postCount = state.communityTotalPostCounts.find((pc) => pc.communityId === state.currentCommunityId);
      if (!isNullOrUndefined(postCount)) {
        result = postCount.totalPostCount;
      }
    }
    return result;
  },
  currentMembersEnrolledUsers: (state) => {
    return state.currentMembersEnrolledUsersMeta.communityMembers;
  },
  currentMembersEnrolledTotalCount: (state) => {
    return state.currentMembersEnrolledUsersMeta.totalMemberCount;
  },
  currentMembersEnrolledLinks: (state) => {
    return state.currentMembersEnrolledUsersMeta.links;
  },
  currentMembersPaginationBreakpoint: (state) => {
    return state.currentMembersEnrolledUsersMeta.currentPaginationPoint;
  },
  getPostById: (state) => (postId) => state.posts.find((post) => post.id === postId),
  getAssignedTriggersForCommunity: (state) => (communityId) => state.assignedTriggers[communityId],
  getTotalCurrentMembersCount: (state) => state.totalCurrentMembersCount,
};
