// navbar konfig option ids

export const enableSocialProfileOptionId = 568;
export const disablePalsFunctionalityOptionId = 588;
export const enableTranslationOptionId = 215;
export const enableConciergeChatOptionId = 263;
export const enableFaqOptionId = 232;
export const faqNameOptionId = 560;
export const faqCopyOptionId = 51;
export const companyNameOptionId = 1;
export const localizationOptionId = 239;
export const whiteHeroLogoOptionId = 244;
export const engagementRxEnabledOptionId = 701;
export const avidonPortalIdOptionId = 712;
export const avidonEnabledOptionId = 686;

// navbar konfig option values

export const localizationEnglishEspanolOptionValue = '1';

//transperfect konfig option id
export const transperfectKonfigOption = 809;
