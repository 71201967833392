import {
  TriggersAdmin,
  CreateTrigger,
  TriggerLibrary,
  EditTrigger,
} from '@/modules/TriggersAdmin/router/triggersAdminRoutesConstants';

const triggersAdminRoutes = [
  {
    path: TriggersAdmin.path,
    name: TriggersAdmin.name,
    component: () => import('@/modules/TriggersAdmin/components/TriggersAdmin'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: CreateTrigger.path,
    name: CreateTrigger.name,
    component: () => import('@/modules/TriggersAdmin/components/CreateTrigger/CreateTriggerSteps'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: TriggerLibrary.path,
    name: TriggerLibrary.name,
    component: () => import('@/modules/TriggersAdmin/components/TriggerLibrary/TriggerLibrary'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: EditTrigger.path,
    name: EditTrigger.name,
    component: () => import('@/modules/TriggersAdmin/components/EditTrigger/EditTrigger'),
    meta: {
      requiresAdmin: true,
      redirectIfErrorPath: TriggersAdmin.path,
    },
  },
];

export default triggersAdminRoutes;
