import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { getManagedGroupsMetaUrl, getManagedGroupsUrl } from '@/modules/SocialCommunities/utils/constants';
import { managedgroupsPathUrl } from '@/modules/Groups/utils/constants';

export const groupsActions = {
  async getManagedGroups({ dispatch, commit, rootGetters }) {
    try {
      const options = {
        url: `${rootGetters['socialCommunities/socialCommunitiesApiBaseUrl']}${getManagedGroupsUrl}`,
        method: 'get',
      };
      const res = await mkNetworkRequestWrapper(options);

      const managedGroups = res.data.data.managedGroupList;

      if (!managedGroups.some((mg) => mg.nestLevel === 1)) {
        managedGroups.unshift({
          nestLevel: 1,
          name: '',
          groupId: -1,
        });
      }

      commit('SET_MANAGED_GROUPS', managedGroups);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getManagedGroupsMeta({ dispatch, commit, rootGetters }) {
    try {
      const options = {
        url: `${rootGetters['socialCommunities/socialCommunitiesApiBaseUrl']}${getManagedGroupsMetaUrl}`,
        method: 'get',
      };

      const res = await mkNetworkRequestWrapper(options);
      return res.data.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getManagedGroupsResource({ dispatch, commit, getters }, resourceId) {
    try {
      const options = {
        url: `${getters.managedGroupsApiBaseUrl}${managedgroupsPathUrl}?resourceId=${resourceId}`,
        method: 'get',
      };
      const res = await mkNetworkRequestWrapper(options);

      const managedGroups = res.data.data.managedGroupList;

      if (!managedGroups.some((mg) => mg.nestLevel === 1)) {
        managedGroups.unshift({
          nestLevel: 1,
          name: '',
          groupId: -1,
        });
      }

      commit('SET_MANAGED_GROUPS', managedGroups);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  setDisplayLoadingVerbiage({ commit }, shouldDisplay) {
    commit('SET_DISPLAY_LOADING_VERBIAGE', shouldDisplay);
  },
};
