export const AnalyticsCustomView = {
  name: 'AnalyticsMainView',
  path: '/Analytics/main/:viewType/',
};

export const AnalyticsSubView = {
  name: 'AnalyticsSubView',
  path: '/Analytics/main/:viewType/:subViewType',
};
export const AnalyticsLanding = {
  name: 'Main',
  path: '/Analytics/main',
};
