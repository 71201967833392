import { adminBiometricsMutations } from '@/modules/AdminBiometrics/store/mutations';
import { adminBiometricsGetters } from '@/modules/AdminBiometrics/store/getters';
import { adminBiometricsActions } from '@/modules/AdminBiometrics/store/actions';

export const adminBiometricsState = {
  userBiometricsDetails: {},
  userScreenings: [],
  addEditBiometricsScreeningForm: {
    isDirty: false,
  },
  selectedBiometricsScreening: null,
};

const adminBiometricsStore = {
  namespaced: true,
  state: adminBiometricsState,
  mutations: adminBiometricsMutations,
  getters: adminBiometricsGetters,
  actions: adminBiometricsActions,
};
export default adminBiometricsStore;
