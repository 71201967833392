import {
  adminTemplatesBasePathUrl,
  adminTemplateFailureToCreateToastText,
  adminTemplateFailureToUpdateToastText,
  chatResponsesTemplateTypeId,
  messagesTemplateTypeId,
  cmsTileTextTemplateTypeId,
  splashNotificationsTemplateTypeId,
} from '@/modules/AdminTemplates/utils';
import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { convertUtcStringToLocal } from '@/utils/helperFunctions';
import { numericalDateFormat } from '@/modules/Common/utils';

export const adminTemplatesActions = {
  setCreateAdminTemplateName({ commit }, payload) {
    commit('SET_CREATE_ADMIN_TEMPLATE_NAME', payload);
  },
  setCreateAdminTemplateText({ commit }, payload) {
    commit('SET_CREATE_ADMIN_TEMPLATE_TEXT', payload);
  },
  setCreateAdminTemplateType({ commit }, payload) {
    commit('SET_CREATE_ADMIN_TEMPLATE_TYPE', payload);
    commit('SET_CREATE_ADMIN_TEMPLATE_TEXT', '');
    commit('SET_CREATE_ADMIN_TEMPLATE_NAME', '');
    commit('REMOVE_VALIDATION_ERROR');
  },
  setSearchAdminTemplateType({ commit }, payload) {
    commit('SET_SEARCH_ADMIN_TEMPLATE_TYPE', payload);
  },
  setSearchAdminTemplateText({ commit }, payload) {
    commit('SET_SEARCH_ADMIN_TEMPLATE_TEXT', payload);
  },
  setCreateAdminTemplateFormValid({ commit }, payload) {
    commit('SET_CREATE_ADMIN_TEMPLATE_FORM_VALID', payload);
  },
  setSearchResultsPaginationInfo({ commit }, payload) {
    commit('SET_SEARCH_RESULTS_PAGINATION_INFO', payload);
  },
  resetAdminTemplateForm({ commit }) {
    commit('SET_CREATE_ADMIN_TEMPLATE_NAME', '');
    commit('SET_CREATE_ADMIN_TEMPLATE_TEXT', '');
    commit('SET_CREATE_ADMIN_TEMPLATE_TYPE', 0);
    commit('SET_CURRENT_SELECTED_ADMIN_TEMPLATE', null);
    commit('SET_VALIDATION_ERRORS', []);
  },
  resetSearchAdminTemplateState({ commit }) {
    commit('SET_SEARCH_ADMIN_TEMPLATE_TEXT', '');
    commit('SET_SEARCH_ADMIN_TEMPLATE_TYPE', 0);
  },
  removeValidationError({ commit }, payload) {
    commit('REMOVE_VALIDATION_ERROR', payload);
  },
  async createAdminTemplate({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.adminTemplatesApiBaseUrl}${adminTemplatesBasePathUrl}`,
        method: 'POST',
        data: {
          name: getters.createAdminTemplateName,
          text: getters.createAdminTemplateText,
          templateType: getters.createAdminTemplateType,
        },
      };

      const response = await mkNetworkRequestWrapper(options);

      commit('RESET_VALIDATION_ERRORS');

      return response.data.data;
    } catch (err) {
      commit('SET_VALIDATION_ERRORS', err?.response?.data?.errors);

      err.response = {
        data: {
          message: adminTemplateFailureToCreateToastText,
        },
      };
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async deleteAdminTemplate({ commit, getters, dispatch }, payload) {
    try {
      const adminTemplateId = payload ? payload : getters.selectedAdminTemplate.adminTemplateId;
      const options = {
        url: `${getters.adminTemplatesApiBaseUrl}${adminTemplatesBasePathUrl}/${adminTemplateId}`,
        method: 'DELETE',
      };

      const response = await mkNetworkRequestWrapper(options);

      if (response.status === 204) {
        dispatch('resetAdminTemplateForm');
        const updatedAdminTemplates = getters.filteredAdminTemplates.filter(
          (at) => at.adminTemplateId !== adminTemplateId,
        );

        commit('SET_FILTERED_ADMIN_TEMPLATES', updatedAdminTemplates);

        return true;
      }

      return false;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async loadAdminTemplate({ commit, getters, dispatch }, adminTemplateId) {
    try {
      const options = {
        url: `${getters.adminTemplatesApiBaseUrl}${adminTemplatesBasePathUrl}/${adminTemplateId}`,
        method: 'GET',
      };

      const getAdminTemplateResponse = await mkNetworkRequestWrapper(options);
      const { adminTemplate } = getAdminTemplateResponse.data.data;

      commit('SET_CREATE_ADMIN_TEMPLATE_NAME', adminTemplate.name);
      commit('SET_CREATE_ADMIN_TEMPLATE_TEXT', adminTemplate.text);
      commit('SET_CREATE_ADMIN_TEMPLATE_TYPE', adminTemplate.templateTypeId);
      commit('SET_CURRENT_SELECTED_ADMIN_TEMPLATE', adminTemplate);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async updateAdminTemplate({ commit, getters, dispatch }) {
    try {
      const adminTemplateId = getters.selectedAdminTemplate.adminTemplateId;
      const options = {
        url: `${getters.adminTemplatesApiBaseUrl}${adminTemplatesBasePathUrl}/${adminTemplateId}`,
        method: 'PUT',
        data: {
          name: getters.createAdminTemplateName,
          text: getters.createAdminTemplateText,
          templateType: getters.createAdminTemplateType,
        },
      };

      const response = await mkNetworkRequestWrapper(options);

      commit('RESET_VALIDATION_ERRORS');

      return response.data.data;
    } catch (err) {
      commit('SET_VALIDATION_ERRORS', err?.response?.data?.errors);

      err.response = {
        data: {
          message: adminTemplateFailureToUpdateToastText,
        },
      };

      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async searchAdminTemplate(
    { commit, getters, dispatch },
    { adminTemplateSearchText, adminTemplateSearchTemplateType, includeOriginalText },
  ) {
    try {
      const params = new URLSearchParams({
        searchText: adminTemplateSearchText,
        adminTemplateType: adminTemplateSearchTemplateType,
        includeOriginalText: includeOriginalText ?? false,
      });

      const options = {
        url: `${getters.adminTemplatesApiBaseUrl}${adminTemplatesBasePathUrl}?${params.toString()}`,
        method: 'GET',
      };

      const getAdminTemplateResponse = await mkNetworkRequestWrapper(options);
      const adminTemplates = getAdminTemplateResponse.data.data.templates;

      adminTemplates.map(
        (template) =>
          (template.editedDateUtc = convertUtcStringToLocal(template.editedDateUtc).toFormat(numericalDateFormat)),
      );

      commit('SET_FILTERED_ADMIN_TEMPLATES', adminTemplates);

      if (adminTemplateSearchText === '' && adminTemplateSearchTemplateType === 0) {
        const availableTemplateTypes = [
          ...getters.allTemplateTypes,
          {
            ordinal: chatResponsesTemplateTypeId,
            type: 'Chat Responses',
            class: 'chat-responses-template-type',
            enabled: adminTemplates.some((template) => template.templateTypeId === 1),
          },
          {
            ordinal: cmsTileTextTemplateTypeId,
            type: 'CMS Tile Text',
            class: 'cms-tile-text-template-type',
            enabled: adminTemplates.some((template) => template.templateTypeId === 2),
          },
          {
            ordinal: messagesTemplateTypeId,
            type: 'Messages',
            class: 'messages-template-type',
            enabled: adminTemplates.some((template) => template.templateTypeId === 3),
          },
          {
            ordinal: splashNotificationsTemplateTypeId,
            type: 'Splash Notifications',
            class: 'splash-notifications-template-type',
            enabled: adminTemplates.some((template) => template.templateTypeId === 4),
          },
        ];
        commit('SET_AVAILABLE_TEMPLATE_TYPES', availableTemplateTypes);
        commit('SET_ALL_ADMIN_TEMPLATES', adminTemplates);
      }
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
