<template>
  <div>
    <div
      class="d-flex align-center px-3 mb-2"
      v-bind:class="hasMessages ? 'justify-space-between' : 'justify-end'"
    ></div>

    <Message v-for="(message, index) in messagesList" v-bind="message" v-bind:key="index" />

    <v-alert class="text-center font-weight-bold" color="grey--text text--darken-1" v-if="!hasMessages">
      {{ noNewMessagesText }}
    </v-alert>

    <div class="text-center px-4 mt-4">
      <v-btn id="view-all-messages-btn" class="white--text" v-bind:href="viewAllMessagesUrl" small block>
        {{ viewAllMessagesText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Message from '@/modules/Common/components/Message';
import {
  noNewMessagesText,
  viewAllMessagesText,
  communicationsMessagesSection,
  buildMkLegacyUri,
} from '@/modules/Common/utils';
export default {
  name: 'NotificationsList',
  components: { Message },
  computed: {
    ...mapGetters({
      messagesList: 'common/messages',
      originUriLegacy: 'common/originUriLegacy',
    }),
    viewAllMessagesUrl: function() {
      return buildMkLegacyUri(this.originUriLegacy, communicationsMessagesSection);
    },
  },
  data() {
    return {
      noNewMessagesText: noNewMessagesText,
      viewAllMessagesText: viewAllMessagesText,
      communicationsMessagesSection: communicationsMessagesSection,
    };
  },
  methods: {
    hasMessages: function() {
      return this.messagesList.length !== 0;
    },
  },
};
</script>

<style lang="scss" scoped>
#view-all-messages-btn {
  background-color: #b3d89c;
  text-transform: none;
  font-weight: 400;
  font-size: 0.9rem;

  &:hover {
    background-color: #97ca77;
  }
}
</style>
