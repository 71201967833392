import { isNullOrUndefined, isStringNullUndefinedOrWhitespace } from '@/utils/helperFunctions';
import getProfaneWords from '@/utils/profanityFilter';

// endpoints

export const socialCommunitiesBaseUrl = '/social-communities';
export const communityRootUrl = '/community';
export const postRootUrl = '/post';
export const commentRootUrl = '/comment';
export const fileRootUrl = '/file';
export const createPostUrl = `${postRootUrl}/create`;
export const editPostUrl = `${postRootUrl}/edit`;
export const pinPostUrl = `${postRootUrl}/pin`;
export const flagPostUrl = `${postRootUrl}/flag`;
export const postInfoForCommunityUrl = `${postRootUrl}/PostInfoForCommunity/`;
export const commentsForPostUrl = `${postRootUrl}/CommentsForPost/`;
export const pinnedPostInfoForCommunityUrl = `${postRootUrl}/PinnedPostInfoForCommunity/`;
export const flagCommentUrl = `${commentRootUrl}/flag`;
export const reactPostUrl = `${postRootUrl}/react`;
export const reactCommentUrl = `${commentRootUrl}/react`;
export const createCommentUrl = `${commentRootUrl}/create`;
export const editCommentUrl = `${commentRootUrl}/edit`;
export const joinCommunityUrl = `${communityRootUrl}/join`;
export const leaveCommunityUrl = `${communityRootUrl}/leave`;
export const allCommunitiesUrl = `${communityRootUrl}/allcommunitiesforuser`;
export const getCommunityMembersUrlStart = `${communityRootUrl}/`;
export const getCommunityMembersUrlEnd = '/members';
export const getCommunityLeadersUrlStart = `${communityRootUrl}/`;
export const getCommunityLeadersUrlEnd = '/leaders';
export const getCommunityDetailsUrlStart = `${communityRootUrl}/`;
export const getCommunityDetailsUrlEnd = '/details';
export const searchCommunityMembersUrlEnd = '/searchmembers';
export const searchAvailableCommunityMembersUrlEnd = '/members/search';
export const searchAvailableCommunityLeadersUrlEnd = '/leaders/search';
export const getCommunityHeroItemsUrlStart = `${communityRootUrl}/`;
export const getCommunityHeroItemsUrlEnd = `/heroitems`;
export const privacySettingsUrlStart = `${communityRootUrl}/`;
export const privacySettingsUrlEnd = '/privacysettings';

// admin endpoints

export const communityAdminRootUrl = '/communityadmin';
export const getAllCommunitiesUrl = `${communityAdminRootUrl}/communities`;
export const getCommunitiesByIds = `${communityAdminRootUrl}/getcommunitiesbyids`;
export const getPostsByIds = `${communityAdminRootUrl}/getpostsbyids`;
export const getCommentsByIds = `${communityAdminRootUrl}/getcommentsbyids`;
export const getSocialProfilesByIds = `${communityAdminRootUrl}/getsocialprofilesbyids`;
export const subscriptionRootUrl = '/subscription';
export const getAllFlaggedPostAndCommentsUrl = `${communityAdminRootUrl}/getallflaggedposts`;
export const dismissFlaggedPostUrl = `${communityAdminRootUrl}/DismissPostFlags`;
export const dismissFlaggedCommentUrl = `${communityAdminRootUrl}/DismissCommentFlags`;
export const deleteFlaggedPostUrl = `${communityAdminRootUrl}/flagged-posts`;
export const deleteFlaggedCommentUrl = `${communityAdminRootUrl}/flagged-comments`;
export const createCommunityUrl = `${communityAdminRootUrl}/community/create`;
export const editCommunityUrl = `${communityAdminRootUrl}/community/edit`;
export const disableCommunityUrl = `${communityAdminRootUrl}/community/disable`;
export const enableCommunityUrl = `${communityAdminRootUrl}/community/enable`;
export const getAvailableUsersUrl = `${communityAdminRootUrl}/community/users`;
export const searchAvailableUsersUrl = `${communityAdminRootUrl}/community/searchusers`;
export const communityMembersUrl = `${communityAdminRootUrl}/community/members`;
export const removeCommunityMembersUrl = `${communityAdminRootUrl}/community/RemoveCommunityMembers`;
export const communityLeaderUrl = `${communityAdminRootUrl}/community/leader`;
export const removeCommunityLeaderUrl = `${communityAdminRootUrl}/community/RemoveCommunityLeader`;
export const getManagedGroupsUrl = `${communityAdminRootUrl}/managedgroups`;
export const getManagedGroupsMetaUrl = `${subscriptionRootUrl}/managedgroups/meta`;
export const getCommunityGroupsUrl = `${communityAdminRootUrl}/community/groups`;
export const communityTriggerAssignmentsUrl = `${communityAdminRootUrl}/community/triggers`;

// thresholds

export const seeMoreThreshold = 300;
export const postPaginationSize = 30;
export const commentPaginationSize = 5;
export const enrolledMembersThreshold = 24;
export const enrolledMembersPageSize = 50;
export const communityLeadersThreshold = 24;
export const maxPinPosts = 10;
export const reactDrawerCloseDelay = 250;
export const maxSidebarProfiles = 24;
export const manageParticipantsPageSize = 5;
export const giphyGifsReturnedLimit = 26;
export const giphyContentRating = 'g';
export const createCommunityStepTwoMaxImages = 4;
export const communityManagementPageSize = 12;

// names

export const overPinnedThreshold = 'overPinnedThreshold';
export const communityLeaders = 'communityLeaders';
export const adminUsername = 'Admin';

// verbiage

export const landingPageTitleText = 'Social Circles';
export const landingPageText =
  'Welcome! Social Circles are a great way to meet like-minded people and stay on track of your wellness goals. Join a circle (or four) and get fit with your friends!';
export const availableCommunitiesText = 'Available Circles';
export const myCommunitiesText = 'My Circles';
export const noCommunitiesText =
  'Looks like your administrator has not created any Social Circles yet! Contact your administrator or check back later.';
export const noAvailableCommunitiesText =
  "There aren't any available Social Circles to join right now, check back later or reach out to your administrator to create a new Social Circle!";
export const joinCommunityButtonText = 'Join';
export const leaveCommunityButtonText = 'Leave';
export const leaveCommunityModalHeader = 'Leave Circle';
export const leaveCommunityModalText = 'Are you sure you want to leave this circle?';
export const viewCommunityText = 'View Circle';
export const viewCommunityDetailsText = 'View Circle Details';
export const leaveCommunityWallButtonText = 'Leave Circle';
export const postBoxPlaceholderText = 'Post on the circle wall';
export const buttonTextPost = 'Post';
export const buttonTextReply = 'Reply';
export const seeMoreText = 'See More';
export const seeLessText = 'See Less';
export const seeMorePostsText = 'See More Posts';
export const seeAllCommentsText = 'Show older comments';
export const membersEnrolledHeaderText = 'Members Enrolled';
export const membersEnrolledTotalText = 'Total';
export const searchMembersPlaceholderText = 'Search Members';
export const searchLeadersPlaceholderText = 'Search Leaders';
export const searchMembersNoResultsText = 'No members found';
export const viewAllMembersText = 'View all members';
export const communityLeadersHeaderText = 'Circle Leaders';
export const communityLeadersTotalText = 'Total';
export const viewAllCommunityLeadersText = 'View all circle leaders';
export const viewAllMembersModalHeaderText = 'Members Enrolled';
export const viewAllCommunityLeadersModalHeaderText = 'Circle Leaders';
export const pinPostModalHeader = 'Pin Post';
export const pinPostModalText = 'Are you sure you want to pin this post?';
export const pinPostOverThresholdModalText = `There are already ${maxPinPosts} pinned posts for this circle. To make sure your pinned post stands out, consider removing another pinned post. Are you sure you want to pin this post?`;
export const unpinPostModalHeader = 'Unpin Post';
export const unpinPostModalText = 'Are you sure you want to unpin this post?';
export const unpinNotAllowedText =
  'This post has been pinned by your admin. You do not have permission to unpin this post.';
export const pinnedPostDecoratorText = 'Pinned Posts';
export const reactDrawerIconHoverText = 'React to Post';
export const reactDrawerText = 'React';
export const deletePostModalHeader = 'Delete Post';
export const deletePostModalText = 'Are you sure you want to delete this post?';
export const deleteCommentModalHeader = 'Delete Comment';
export const deleteCommentModalText = 'Are you sure you want to delete this comment?';
export const newEditModalHeader = 'Edit Another Post';
export const newEditModalText =
  'You are currently editing. If you edit another post, you will lose all your changes. Are you sure?';
export const editPostNotChangedText = 'Please edit your post first before saving.';
export const editPostEmptyText = 'Please add an image or text to your post before saving.';
export const editedText = '(edited)';
export const noCommunityPostsText =
  'There are no posts in this circle yet. Write something to get the conversation started!';
export const viewCommunityDetailsModalHeaderText = 'Viewing circle details for ';
export const communityDetailsModalMostRecentMembersText = 'Recently Joined Members';
export const communityDetailsModalTotalMembersText = 'Circle Member';
export const communityDetailsModalCommunityLeadersText = 'Circle Leader';
export const communityDetailsModalJoinCommunityText = 'Join the circle!';
export const communityDetailsPreviouslyLeftText = 'You left this circle on ';
export const dropFileText = 'Drop an image here or click to browse';
export const seeAllPinnedPostModalHeader = 'Viewing All Pins';
export const seeAllPinnedPostBtnText = 'See All Pins';
export const seeFewerPinnedPostBtnText = 'See Fewer Pins';
export const commentCountCommentTextSingular = 'Comment';
export const commentCountCommentTextPlural = 'Comments';
export const commentCountZero = 'No';
export const metaActionPinPost = 'Pin';
export const metaActionUnpinPost = 'Unpin';
export const metaActionFlagPost = 'Flag';
export const metaActionUnflagPost = 'Unflag';
export const metaActionEditPost = 'Edit';
export const metaActionDeletePost = 'Delete';
export const disabledCommunityBtnText = 'Circle Disabled';
export const disabledCommunityNotification = 'This circle has been disabled. Please contact your administrator.';
export const disabledCommunityAdminNotification = 'This circle has been disabled';
export const unjoinedCommunityNotification =
  'You are not authorized to view this circle. Please try joining the circle first.';
export const communityTileRecommendedText = 'Recommended Circle';
export const circleWallProfanityValidationMessage = 'Please refrain from multiple uses of profanity in Social Circles.';
export const giphySearchPlaceholderText = 'Search GIPHY';
export const giphyUnavailableText = 'The gif library is currently unavailable please check back again later.';
export const giphyNoResultsText = 'No gif results found.';
export const replaceItemModalHeader = 'Replace Item?';
export const replaceItemPostModalText = 'There is an item added to the post, would you like to replace it?';
export const replaceItemCommentModalText = 'There is an item added to the comment, would you like to replace it?';
export const manageSocialCommunitiesCreateCommunityTextTemp = 'Create Social Circle (new)';
export const seeMoreCirclesText = 'See More Circles';
export const modalBtnTextEditPostImageReplace = 'Replace';

// admin verbiage

export const manageSocialCommunitiesHeaderText = 'Manage Social Circles';
export const manageSocialCommunitiesNoCommunitiesText =
  'You currently do not have any Social Circles. Click Create Social Circle to start creating your first Social Circle!';
export const manageSocialCommunitiesCreateCommunityText = 'Create Social Circle';
export const manageSocialCommunitiesViewFlaggedPostsText = 'View Flagged Posts';
export const communityTileAssignedToText = 'Assigned To';
export const communityTileGroupsSingularText = 'Group';
export const communityTileGroupsPluralText = 'Groups';
export const communityTileGroupsZeroText = 'No Groups';
export const communityTileEnrolledText = 'Enrolled';
export const communityTileMenuActionsText = 'Actions';
export const communityWallEditMenuText = 'Edit Circle';
export const flaggedPostsHeaderText = 'Flagged Posts';
export const flaggedRowTextStart = 'was flagged by';
export const flaggedRowTextEndSingular = 'user';
export const flaggedRowTextEndPlural = 'users';
export const flaggedRowReportedText = 'Reported';
export const flaggedRowMenuActionsText = 'Actions';
export const flaggedRowBtnViewText = 'View';
export const flaggedRowBtnDeleteText = 'Delete';
export const flaggedRowBtnDismissText = 'Dismiss';
export const createCommunityHeaderText = 'Create New Circle';
export const createCommunityDetailSectionText = 'Circle Details';
export const createCommunityImageSectionText = 'Circle Images';
export const communityFormLabelAddImageText =
  'Please upload four images related to the topic of this Social Circle. These images will display at the top of the Circle Wall for members to view. If you do not upload any images, the background color selected above will display.';
export const communityFormActiveCommunityText = 'Circle is Active';
export const communityFormFieldCommunityIconText = 'Select Circle Icon and Background';
export const communityFormFieldCommunityNamePlaceholder = 'Circle Name';
export const communityFormFieldCommunityDescriptionPlaceholder = 'Circle Description';
export const communityFormFieldCommunityLeaderCommunityText = 'Circle Leaders Only';
export const communityFormSwitchLeadersOnlyText =
  'If this is enabled, only users who are Circle Leaders can join this circle.';
export const communityFormBtnCancelText = 'Cancel';
export const communityFormBtnNextText = 'Next';
export const communityFormBtnSaveText = 'Save';
export const flaggedRowTextPostTag = 'post';
export const flaggedRowTextCommentTag = 'comment';
export const deletedUserText = '(deleted user)';
export const editCommunitySuccessNotif = 'Circle has been edited.';
export const disableCommunityModalHeaderText = 'Disable Circle';
export const disableCommunityModalText = 'Are you sure you want to disable this circle?';
export const launchCommunityModalHeaderText = 'Launch Circle';
export const launchCommunityModalText = 'Are you sure you want to launch this circle?';
export const communityTileDisabledText = 'Disabled On';
export const comunityTileLaunchText = 'Launched On';
export const comunityTileCreateText = 'Created On';
export const manageParticipantsHeaderText = 'Manage Participants';
export const manageParticipantsHelpText = `This page allows you to add or remove members from the Social Circle as well as choose Circle Leaders.
A Circle Leader will display with a star badge on their icon and have the ability to pin posts.
To add Circle Leader status to a Current Member, click on the icon next to their name.`;
export const manageParticipantsMembersListText = 'Circle Members';
export const manageParticipantsAddMembersListText = 'Add Members';
export const manageParticipantsSelectAllText = 'Select All';
export const manageParticipantsNoDataText = 'No users found';
export const manageParticipantsMemberHeaderText = 'Current Members';
export const manageParticipantsUserHeaderText = 'Available Users';
export const manageParticipantsSearchMembersText = 'Search members';
export const manageParticipantsSearchUsersText = 'Search users';
export const manageParticipantsBtnAddUsersText = 'Add';
export const manageParticipantsBtnRemoveMembersText = 'Remove';
export const manageParticipantsBtnAddUsersTooltip = 'Add Selected Users';
export const manageParticipantsBtnRemoveMembersTooltip = 'Remove Selected Members';
export const manageParticipantsAddLeaderText = 'Add Circle Leader';
export const manageParticipantsRemoveLeaderText = 'Remove Circle Leader';
export const manageParticipantsBtnSearchText = 'Search';
export const manageParticipantsBtnClearText = 'Clear';
export const addCommunityLeaderModalHeaderText = 'Add Circle Leader';
export const addCommunityLeaderModalText = 'Add this member as a circle leader?';
export const removeCommunityLeaderModalHeaderText = 'Remove Circle Leader';
export const removeCommunityLeaderModalText = 'This member will no longer be a circle leader. Are you sure?';
export const manageGroupsMenuTitle = 'Manage Access';
export const manageAccessHeaderText = 'Manage Access for';
export const manageGroupsHeaderText = 'Manage Groups';
export const manageGroupsSubtitleText = 'Manage access via assigned triggers and groups.';
export const manageGroupsSaveBtnText = 'Save Access';
export const manageGroupsRedirectBtnText = 'Go to Circle Wall';
export const communityTileTriggers = 'TRIGGERS';
export const manageGroupsDisclaimerText =
  'Please note that groups which contain users who have already joined this Circle cannot be deselected.';
export const flaggedPostsNoDataText = 'Your Social Circles are engaging well! There are no flagged posts at this time.';
export const communityTileAdminLeadersOnlyText = 'Leaders Only';
export const tileFilterAllText = 'All';
export const disableCommunityNotificationText = 'The circle has been disabled.';
export const enableCommunityNotificationText = 'The circle has been launched.';
export const createCommunityStepOneHeaderMethod = 'Select your circle design method:';
export const createCommunityStepOneOptionScratch = 'Create from scratch';
export const createCommunityStepOneOptionTemplate = 'Use a template';
export const createCommunityStepOneTypeScratch = 'scratch';
export const createCommunityStepOneTypeTemplate = 'template';
export const createCommunityStepTwoHeader = 'Circle Details';
export const createCommunityStepTwoIconHeader = 'Circle Icon';
export const createCommunityStepTwoIconDescription = 'Select Circle icon and background';
export const createCommunityStepTwoDetailsHeader = 'Circle Name and Description';
export const createCommunityStepTwoHeroImagesHeader = 'Hero Images (Optional)';
export const createCommunityStepTwoHeroImagesDescription = `Please upload four images related to the topic of this Social Circle. These images will display at the top of the Circle Wall for members to view. If you do not upload any images, the background color selected above will display.`;
export const createCommunityStepThreeHeader = 'Participants';
export const createCommunityStepThreeGroupsHeader = 'Select Member Groups';
export const createCommunityStepThreeGroupsText =
  'Please note that groups which contain users who have already joined this Circle cannot be deselected.\nAt least one group must be selected.';
export const createCommunityStepThreeLeadersOnlyHeader = 'Circle Leaders Only';
export const createCommunityStepThreeLeadersOnlyText =
  'If this is turned on, only members who are leaders in one or more circle can join.\nThis setting cannot be updated once a participant has joined this Circle.';
export const createCommunityStepThreeLeadersOnlyOn = 'On';
export const createCommunityStepThreeLeadersOnlyOff = 'Off';
export const createCommunityBtnNext = 'Next';
export const createCommunityBtnSave = 'Save & Submit';
export const createCommunityBtnBack = 'Back';
export const createCommunityBtnCancel = 'Cancel';
export const createCommunityStepOneCategoryDropdownLabel = 'Select theme';
export const createCommunityStepOneHeaderTemplate = 'Select a template';
export const createCommunityCancelModalHeader = 'Cancel Create New Circle';
export const createCommunityCancelModalText = 'Your progress will not be saved. Would you like to proceed?';
export const createCommunityChangeDesignMethodModalHeader = 'Update Design Method';
export const createCommunityChangeDesignMethodModalText =
  'This update will reset all the fields. Would you like to proceed?';
export const createCommunityChangeTemplateModalHeader = 'Update Template';
export const createCommunityChangeTemplateModalText =
  'This update will reset all the fields. Would you like to proceed?';
export const createCommunitySaveSuccessNotification = 'All your Circle details have been successfully saved.';
export const createCommunityStepThreeAssignTriggersHeader = 'Assign Triggers';
export const createCommunityStepThreeAssignTriggersDescription =
  'All users in selected groups are eligible to be part of this Circle. In order to limit access, please assign triggers below.';
export const assignTriggersText = 'Assign Triggers';

// types

export const postTypeParent = 'parent';
export const postTypeComment = 'comment';
export const postTypePinned = 'pinned';
export const reactTypeLike = 11;
export const reactTypeLove = 12;
export const reactTypeHighFive = 13;
export const reactTypeDislike = 14;
export const reactTypeEmphasize = 15;
export const reactTypeQuestion = 16;
export const drawerIcons = [
  {
    className: 'love',
    iconCode: 'emojione-monotone:black-heart',
    reactType: reactTypeLove,
  },
  {
    className: 'like',
    iconCode: 'ant-design:like-filled',
    reactType: reactTypeLike,
  },
  {
    className: 'dislike',
    iconCode: 'ant-design:dislike-filled',
    reactType: reactTypeDislike,
  },
  {
    className: 'high-five',
    iconCode: 'fluent:hand-left-16-filled',
    reactType: reactTypeHighFive,
  },
  {
    className: 'exclamation',
    iconCode: 'fa-solid:exclamation',
    reactType: reactTypeEmphasize,
  },
  {
    className: 'question',
    iconCode: 'fa-solid:question',
    reactType: reactTypeQuestion,
  },
];
export const profileDisplayShow = 'show';
export const profileDisplayAlways = 'always';
export const profileDisplayHide = 'hide';
export const adminAvatarUrl = '/v8/images/component-specific/social-challenges/admin-avatars/AdminIcon.svg';
export const communityStatusLaunched = 1;
export const communityStatusNotReady = 2;
export const communityStatusDisabled = 3;
export const noLaunchedCommunitiesText = 'No Social Circles have been launched yet.';
export const noNotReadyCommunitiesText = 'No Social Circles are not ready.';
export const noDisabledCommunitiesText = 'No Social Circles have been disabled.';
export const noFilteredCommunitiesDefaultText = 'No Social Circles exist in the selected filter.';
export const communityStatusItems = [
  { id: communityStatusLaunched, status: 'Launched', class: 'launched', noneText: noLaunchedCommunitiesText },
  { id: communityStatusNotReady, status: 'Not Ready', class: 'not-ready', noneText: noNotReadyCommunitiesText },
  { id: communityStatusDisabled, status: 'Disabled', class: 'disabled', noneText: noDisabledCommunitiesText },
];
export const communityMemberInitialSearchObject = {
  fullName: '',
  nickname: '',
};

export const createCommunitySteps = [
  { number: 1, text: 'STEP 1', title: 'Design', subtitle: 'Select design method', icon: 'fa-solid:pencil-ruler' },
  { number: 2, text: 'STEP 2', title: 'Details', subtitle: 'Provide Circle details', icon: 'fa-solid:clipboard-list' },
  { number: 3, text: 'STEP 3', title: 'Participants', subtitle: 'Select who can join', icon: 'fa-solid:users' },
  { number: 4, text: 'STEP 4', title: 'Settings', subtitle: 'Privacy settings', icon: 'fa-solid:cog' },
];

// admin types

export const communityTileMenuItemManageCommunity = { title: 'Manage Social Circle', routeName: 'CommunityWall' };
export const communityTileMenuItemDisable = 'Disable Social Circle';
export const communityTileMenuItemLaunch = 'Launch Social Circle';
export const communityTileMenuItemManageParticipants = {
  title: 'Manage Participants',
  routeName: 'ManageParticipantsAdmin',
};
export const enumTypePost = 0;
export const enumTypeComment = 1;
export const flaggedRowActionMenuItems = [
  { title: 'View', routeName: '#' },
  { title: 'Remove', routeName: '#' },
  { title: 'Ignore', routeName: '#' },
];
export const manageParticipantsMembersList = 'availableMembers';
export const manageParticipantsUsersList = 'availableUsers';
export const manageParticipantsTypeMember = 'member';
export const manageParticipantsTypeUser = 'user';

// css

export const postBoxButtonHeight = 50;
export const offsetFromHeroTop = 450;

// validation

export const manageParticipantsSearchMinLength = 3;
export const socialCirclesPostValidationRules = {
  profane: (v) => {
    const profaneWords = getProfaneWords(v);
    const containsProfaneWords = profaneWords.length > 0;
    let validationMessage;

    if (containsProfaneWords) {
      switch (profaneWords.length) {
        case 1:
          validationMessage = `Please refrain from using '${profaneWords[0]}' within a Social Circle.`;
          break;
        case 2:
          validationMessage = `Please refrain from using '${profaneWords[0]}' and '${profaneWords[1]}', within a Social Circle.`;
          break;
        case 3:
          validationMessage = `Please refrain from using '${profaneWords[0]}', '${profaneWords[1]}', and '${profaneWords[2]}' within a Social Circle.`;
          break;
        default:
          validationMessage = circleWallProfanityValidationMessage;
          break;
      }
    }

    return !containsProfaneWords || validationMessage;
  },
  required: (v) => !isStringNullUndefinedOrWhitespace(v) || 'Please add an image or text.',
};

export const manageParticipantsSearchRules = {
  searchString: [
    (v) =>
      (!isNullOrUndefined(v) && v.length >= manageParticipantsSearchMinLength) ||
      v === '' ||
      `Search string must be at least ${manageParticipantsSearchMinLength} characters`,
  ],
};
export const communityNameMaxLength = 30;
export const communityDescriptionMaxLength = 60;
export const communityCreationRules = {
  name: [
    (v) => !isStringNullUndefinedOrWhitespace(v) || `Circle Name is required.`,
    (v) =>
      (!isNullOrUndefined(v) && v.length <= communityNameMaxLength) ||
      `Circle Name is limited to ${communityNameMaxLength} characters maximum.`,
  ],
  description: [
    (v) => !isStringNullUndefinedOrWhitespace(v) || `Circle Description is required.`,
    (v) =>
      (!isNullOrUndefined(v) && v.length <= communityDescriptionMaxLength) ||
      `Circle Description is limited to ${communityDescriptionMaxLength} characters maximum.`,
  ],
};

// 3rd party

export const giphySearchApiEndpoint = 'https://api.giphy.com/v1/gifs/search';
export const giphyTrendingApiEndpoint = 'https://api.giphy.com/v1/gifs/trending';
export const giphyMediaPrefix = 'https://media.giphy.com/media/';
export const giphyMediaSuffix = '/giphy.gif';
export const giphyApiKey = process.env.VUE_APP_GIPHY_API_KEY;

// event bus keys

export const clearMemberEnrolledViewAllList = 'clearMemberEnrolledViewAllList';
