import { designMethods, menuItemTypes } from '@/modules/AdminMenuManagement/utils/constants';

const adminMenuManagementSearchMutations = {
  SET_ALL_MENUS(state, payload) {
    state.search.allMenus = payload;
  },
  SET_MENU_STATUS(state, payload) {
    state.createNewMenu.status = payload;
  },
  SET_SEARCH_PAGINATION_INFO(state, paginationInfo) {
    state.search.paginationInfo = paginationInfo;
  },
  SET_SEARCH_RESULTS(state, menus) {
    state.search.searchResults = menus;
  },
  SET_SELECTED_STATUS_FILTER(state, status) {
    state.search.selectedStatus = status;
  },
  SET_IS_SAVE_BUTTON_ENABLED(state, payload) {
    state.isEditSaveButtonEnabled = payload;
  },
  SET_SEARCH_PARAMS(state, searchParams) {
    state.search.searchParams = {
      ...state.search.searchParams,
      ...searchParams,
    };
  },
  SET_TOTAL_SEARCH_RESULTS(state, totalCount) {
    state.search.totalCount = totalCount;
  },
  SET_IS_SEARCHING(state, isSearching) {
    state.search.searching = isSearching;
  },
};

export const adminMenuManagementMutations = {
  ...adminMenuManagementSearchMutations,
  ADD_MENU_ITEM(state, payload) {
    state.createNewMenu.menuTree.push(payload);
  },
  SET_SELECTED_MENU_ITEM(state, payload) {
    state.createNewMenu.selectedMenuItem = payload;
  },
  RESET_MENU_WIZARD(state) {
    state.showTree = false;
    state.currentStep = 1;
    state.availableGroups = [];
    state.createNewMenu = {
      designMethod: designMethods.createFromScratch,
      lastUsedMenuItemId: 0,
      menuName: '',
      menuTree: [],
      parentMenuItems: [{ id: -1, name: 'Parent Menu Item' }],
      selectedExistingMenu: null,
      previouslySelectedGroups: [],
      assignedGroupIds: [],
      selectedGroups: [],
      excludedGroups: [],
      selectedMenuItem: null,
      validationErrors: [],
      selectedMenu: null,
      status: null,
      isAvidonEnabledForSelectedGroups: false,
    };
    state.standardComponents = [];
    state.surveys = [];
    state.thirdPartyLinks = [];
    state.cmsTiles = [];
    state.avidonCourses = [];
    state.avidonFlows = [];
    state.avidonPages = [];
    state.editMenu = {
      openGroupNodes: [],
    };
  },
  SET_SELECTED_MENU(state, menu) {
    state.createNewMenu.selectedMenu = menu;
  },
  RESET_MENU_ITEM_RESOURCE(state, menuItemResource) {
    state.createNewMenu.selectedMenuItem.menuItemResource = menuItemResource;
  },
  SET_CREATE_NEW_MENU_DESIGN_METHOD(state, payload) {
    state.createNewMenu.designMethod = payload;
  },
  SET_LOADING_ITEMS(state, payload) {
    state.loadingItems = payload;
  },
  SET_MENU_NAME(state, payload) {
    state.createNewMenu.menuName = payload;
  },
  SET_EDIT_MODE(state, payload) {
    state.isEditMode = payload;
  },
  SET_IS_EDIT_DIRTY(state, payload) {
    state.isEditFormDirty = payload;
  },
  SET_EDIT_GROUPS_LOADING(state, payload) {
    state.isEditGroupLoading = payload;
  },
  SET_CURRENT_STEP(state, payload) {
    state.currentStep = payload;
  },
  SET_MENU_MANAGEMENT_SEARCH_RESULTS(state, searchResults) {
    state.adminMenuManagement.searchResults = searchResults;
  },
  SET_AVAILABLE_GROUPS(state, groups) {
    state.availableGroups = groups;
  },
  SET_MENU_MANAGEMENT_PREVIOUSLY_SELECTED_GROUPS(state, groups) {
    state.createNewMenu.previouslySelectedGroups = groups;
  },
  SET_MENU_MANAGEMENT_SELECTED_GROUPS(state, groups) {
    state.createNewMenu.selectedGroups = groups;
  },
  SET_EXCLUDED_GROUPS(state, groups) {
    state.createNewMenu.excludedGroups = groups;
  },
  SET_SELECTED_COMPONENT_ID(state, componentId) {
    state.createNewMenu.selectedMenuItem.menuItemResource = {
      ...state.createNewMenu.selectedMenuItem.menuItemResource,
      componentId,
    };
  },
  SET_SELECTED_EXISTING_MENU(state, menu) {
    state.createNewMenu.selectedExistingMenu = menu;
  },
  SET_EDIT_MENU_NAME(state, name) {
    state.editMenu.menuName = name;
  },
  SET_EDIT_MENU_GROUPS(state, groups) {
    state.editMenu.selectedGroups = groups;
  },
  SET_AVIDON_PAGES(state, avidonPages) {
    state.avidonPages = avidonPages;
  },
  SET_AVIDON_COURSES(state, avidonCourses) {
    state.avidonCourses = avidonCourses;
  },
  SET_AVIDON_FLOWS(state, avidonFlows) {
    state.avidonFlows = avidonFlows;
  },
  SET_CMS_TILES(state, cmsTiles) {
    state.cmsTiles = cmsTiles;
  },
  SET_STANDARD_COMPONENTS(state, standardComponents) {
    state.standardComponents = standardComponents;
  },
  SET_SURVEYS(state, surveys) {
    state.surveys = surveys;
  },
  SET_THIRD_PARTY_LINKS(state, thirdPartyLinks) {
    state.thirdPartyLinks = thirdPartyLinks;
  },
  SET_LAST_USED_MENU_ITEM_ID(state, id) {
    state.createNewMenu.lastUsedMenuItemId = id;
  },
  SET_MENU_ITEM_NAME(state, menuItemName) {
    state.createNewMenu.selectedMenuItem.name = menuItemName;
  },
  SET_MENU_ITEM_RESOURCE_TYPE(state, menuItemResourceType) {
    state.createNewMenu.selectedMenuItem.menuItemResource.menuItemResourceType = menuItemResourceType;
  },
  SET_MENU_ITEM_TYPE(state, menuItemType) {
    state.createNewMenu.selectedMenuItem.isParentMenuItem = menuItemType === menuItemTypes.parentMenuItem;
    state.createNewMenu.selectedMenuItem.isChildMenuItem = menuItemType === menuItemTypes.childMenuItem;
    state.createNewMenu.selectedMenuItem.isSingleMenuItem = menuItemType === menuItemTypes.singleMenuItem;
    state.createNewMenu.selectedMenuItem.menuItemType = menuItemType;
  },
  SET_MENU_ITEM_RESOURCE_URL(state, url) {
    state.createNewMenu.selectedMenuItem.menuItemResource.url = url;
  },
  SET_MENU_ITEM_ICON(state, icon) {
    state.createNewMenu.selectedMenuItem.icon = icon;
  },
  SET_OPEN_LINK_IN_NEW_TAB(state, openLinkInNewTab) {
    state.createNewMenu.selectedMenuItem.menuItemResource.openLinkInNewTab = openLinkInNewTab;
  },
  SET_PARENT_MENU_ITEMS(state, menuItems) {
    state.createNewMenu.parentMenuItems = menuItems;
  },
  SET_VALIDATION_ERRORS(state, validationErrors) {
    state.createNewMenu.validationErrors = validationErrors;
  },
  SET_SELECTED_PARENT_MENU_ITEM_ID(state, parentMenuItemId) {
    state.createNewMenu.selectedMenuItem.parentMenuItemId = parentMenuItemId;
  },
  SET_PREDEFINED_ITEMS(state, items) {
    state.createNewMenu.predefinedItems = items;
  },
  SET_SELECTED_STANDARD_COMPONENT(state, standardComponent) {
    state.createNewMenu.selectedMenuItem.menuItemResource.standardComponent = standardComponent;
  },
  SET_SELECTED_THIRD_PARTY_LINK(state, thirdPartyLink) {
    state.createNewMenu.selectedMenuItem.menuItemResource.thirdPartyLink = thirdPartyLink;
  },
  SET_MENU_ITEM_FORM_DIRTY(state, isDirty) {
    state.createNewMenu.isMenuItemFormDirty = isDirty;
  },
  UPDATE_MENU_TREE(state, menuTree) {
    state.createNewMenu.menuTree = menuTree;
  },
  SET_OPEN_GROUP_NODES(state, openGroupNodes) {
    state.editMenu.openGroupNodes = openGroupNodes;
  },
  SET_EDIT_STEP_ONE_VALID(state, valid) {
    state.isStepOneValid = valid;
  },
  SET_EDIT_STEP_TWO_VALID(state, valid) {
    state.isStepTwoValid = valid;
  },
  SET_MENU_ITEM_ERROR_CODE(state, { selectedMenuItem, errorCode }) {
    if (!selectedMenuItem.errorCode) {
      return;
    }

    let menuItem = null;

    if (selectedMenuItem.parentMenuItemId && selectedMenuItem.parentMenuItemId !== -1) {
      const parentMenuItem = state.createNewMenu.menuTree.find((mi) => mi.id === selectedMenuItem.parentMenuItemId);
      if (parentMenuItem) {
        menuItem = parentMenuItem.children.find((mi) => mi.id === selectedMenuItem.id);
      }
    } else {
      menuItem = state.createNewMenu.menuTree.find((mi) => mi.id === selectedMenuItem.id);
    }

    if (menuItem) {
      menuItem.errorCode = errorCode;
    }
  },
  SET_SHOW_GROUP_TREE(state, show) {
    state.showTree = show;
  },
  SET_ASSIGNED_GROUP_IDS(state, groupIds) {
    state.createNewMenu.assignedGroupIds = groupIds;
  },
  SET_AVIDON_ENABLED(state, enabled) {
    state.createNewMenu.isAvidonEnabledForSelectedGroups = enabled;
  },
};
