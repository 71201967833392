<template>
  <div class="container--tabs">
    <v-tabs v-model="tab" grow>
      <v-tab v-for="item in notificationsSidebarTabItems" v-bind:key="item.id" class="tab">
        <v-badge
          v-bind:id="`${item.text.toLowerCase()}-badge`"
          v-bind:content="itemCount(item.id)"
          v-if="itemCount(item.id) !== 0"
          inline
          color="red accent-2"
        >
        </v-badge>
        <v-icon v-bind:class="item.iconClass" class="mr-1"></v-icon>
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="py-2">
        <NotificationsList />
      </v-tab-item>
      <v-tab-item class="py-2">
        <MessagesList />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  notificationsSidebarTabItems,
  notificationsSidebarNotificationsTabId,
  notificationsSidebarMessagesTabId,
} from '@/modules/Common/utils/constants';
import NotificationsList from '@/modules/Common/components/NotificationsList';
import MessagesList from '@/modules/Common/components/MessagesList';
export default {
  name: 'MessagesNotificationsWrapper',
  components: { NotificationsList, MessagesList },
  computed: {
    ...mapGetters({
      notificationList: 'common/notifications',
      totalUnreadNotificationCount: 'common/totalUnreadNotificationCount',
      totalUnreadMessagesCount: 'common/totalUnreadMessagesCount',
    }),
    itemCount: function() {
      return (itemId) => {
        switch (itemId) {
          case notificationsSidebarNotificationsTabId:
            return this.totalUnreadNotificationCount;
          case notificationsSidebarMessagesTabId:
            return this.totalUnreadMessagesCount;
          default:
            return 0;
        }
      };
    },
  },
  data() {
    return {
      notificationsSidebarTabItems: notificationsSidebarTabItems,
      notificationsSidebarNotificationsTabId: notificationsSidebarNotificationsTabId,
      notificationsSidebarMessagesTabId: notificationsSidebarMessagesTabId,
      tab: notificationsSidebarNotificationsTabId,
    };
  },
};
</script>

<style lang="scss" scoped>
.tab {
  text-transform: none;
  letter-spacing: normal;
}
</style>
