export const notificationTypeIcons = new Map([
  [
    0,
    {
      name: 'default',
      mkicon: 'today',
      iconify: 'ic:baseline-today',
    },
  ],
  [
    1,
    {
      name: 'Biometric Update',
      mkicon: 'fingerprint',
      iconify: 'ic:baseline-fingerprint',
    },
  ],
  [
    2,
    {
      name: 'Health Assessment Update',
      mkicon: 'assessment',
      iconify: 'ic:baseline-assessment',
    },
  ],
  [
    3,
    {
      name: 'Incentives & Rewards',
      mkicon: 'card_giftcard',
      iconify: 'ic:baseline-card-giftcard',
    },
  ],
  [
    4,
    {
      name: 'Feature Change',
      mkicon: 'settings',
      iconify: 'ci:settings-filled',
    },
  ],
  [
    5,
    {
      name: 'Social Challenge',
      mkicon: 'directions_run',
      iconify: 'ic:round-directions-run',
    },
  ],
  [
    6,
    {
      name: 'Provider Appointment',
      mkicon: 'event',
      iconify: 'bx:calendar-event',
    },
  ],
  [
    7,
    {
      name: 'Message Center',
      mkicon: 'markunread',
      iconify: 'ic:baseline-markunread',
    },
  ],
  [
    8,
    {
      name: 'Surveys',
      mkicon: 'dvr',
      iconify: 'ic:baseline-dvr',
    },
  ],
  [
    9,
    {
      name: 'Web Inquiry',
      mkicon: 'assignment-late',
      iconify: 'ic:baseline-assignment-late',
    },
  ],
  [
    10,
    {
      name: 'Fond',
      mkicon: 'fiber_manual_record',
      iconify: 'ic:baseline-fiber-manual-record',
    },
  ],
  [
    11,
    {
      name: 'Splash Notifications',
      mkicon: 'notifications',
      iconify: 'ic:baseline-notifications',
    },
  ],
  [
    12,
    {
      name: 'Chat Activity',
      mkicon: 'chat-bubbles-square',
      iconify: 'clarity:talk-bubbles-line',
    },
  ],
  [
    13,
    {
      name: 'Community Wall',
      mkicon: 'pan_tool',
      iconify: 'ic:baseline-pan-tool',
    },
  ],
  [
    14,
    {
      name: 'Sharing & Permissions',
      mkicon: 'people_outline',
      iconify: 'bi:people',
    },
  ],
  [
    15,
    {
      name: 'Admin Generated',
      mkicon: 'markunread',
      iconify: 'ic:baseline-markunread',
    },
  ],
  [
    16,
    {
      name: 'Scheduled Meeting',
      mkicon: 'today',
      iconify: 'ic:baseline-today',
    },
  ],
  [
    18,
    {
      name: 'Message Center',
      mkicon: 'markunread',
      iconify: 'ic:baseline-markunread',
    },
  ],
  [
    19,
    {
      name: 'Avidon Message',
      mkicon: 'people',
      iconify: 'bi:people-fill',
    },
  ],
]);
