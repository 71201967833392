import {
  SocialCommunities,
  CommunityWall,
  SocialCommunitiesAdmin,
  FlaggedPostsAdmin,
  CreateCommunitySteps,
  ManageParticipantsAdmin,
  ManageCommunityGroupsAdmin,
} from '@/modules/SocialCommunities/router/socialCommunitiesRoutesConstants';

const socialCommunitiesRoutes = [
  {
    path: SocialCommunities.path,
    name: SocialCommunities.name,
    component: () => import('@/modules/SocialCommunities/Index'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: CommunityWall.path,
    name: CommunityWall.name,
    component: () => import('@/modules/SocialCommunities/components/CommunityWall'),
    meta: {
      requiresAuth: true,
      redirectIfErrorPath: SocialCommunities.path,
    },
  },
  {
    path: SocialCommunitiesAdmin.path,
    name: SocialCommunitiesAdmin.name,
    component: () => import('@/modules/SocialCommunities/components/admin/Index'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: FlaggedPostsAdmin.path,
    name: FlaggedPostsAdmin.name,
    component: () => import('@/modules/SocialCommunities/components/admin/FlaggedPostsAdmin'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: CreateCommunitySteps.path,
    name: CreateCommunitySteps.name,
    component: () => import('@/modules/SocialCommunities/components/admin/CreateCommunity/CreateCommunitySteps'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: ManageParticipantsAdmin.path,
    name: ManageParticipantsAdmin.name,
    component: () => import('@/modules/SocialCommunities/components/admin/ManageParticipantsAdmin'),
    meta: {
      requiresAdmin: true,
      redirectIfErrorPath: SocialCommunitiesAdmin.path,
    },
  },
  {
    path: ManageCommunityGroupsAdmin.path,
    name: ManageCommunityGroupsAdmin.name,
    component: () => import('@/modules/SocialCommunities/components/admin/ManageGroups'),
    meta: {
      requiresAdmin: true,
      redirectIfErrorPath: SocialCommunitiesAdmin.path,
    },
  },
];

export default socialCommunitiesRoutes;
