import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'filepond-polyfill';
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import smoothscroll from 'smoothscroll-polyfill';
import IconifyIcon from '@/modules/Common/components/IconifyIcon';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

smoothscroll.polyfill();

Vue.config.productionTip = false;
Vue.directive('visible', (el, bind) => {
  el.style.visibility = bind.value ? 'visible' : 'hidden';
});

// Register global variables
Vue.prototype.$colors = {
  danger: '#ff5252',
  mediumBlue: '#2196f3',
};

// Register component globally
Vue.component('IconifyIcon', IconifyIcon);

let vue = new Vue({
  router,
  store,
  FilePond,
  vuetify,
  render: (h) => h(App),
});
vue.$mount('#app');
