import { tangoStoreMutations } from '@/modules/TangoStore/store/mutations';
import { tangoStoreGetters } from '@/modules/TangoStore/store/getters';
import { tangoStoreActions } from '@/modules/TangoStore/store/actions';

export const tangoStoreState = {
  userBalance: 0,
  annualLimit: null,
  annualLimitMessageDisplayedOnce: false,
  selectedCard: null,
  newCartItem: {
    fixedValue: null,
    variableValue: null,
    quantity: 1,
  },
  cart: [],
  customerId: null,
};

const tangoStoreStore = {
  namespaced: true,
  state: tangoStoreState,
  mutations: tangoStoreMutations,
  getters: tangoStoreGetters,
  actions: tangoStoreActions,
};

export default tangoStoreStore;
