import { HubConnectionBuilder } from '@microsoft/signalr';

export function createHubConnection(bearerToken) {
  if (!bearerToken) {
    console.error('user must be logged in to start signalR connection.');
    return null;
  }
  let hubUrl = process.env.VUE_APP_SIGNALRBASEURL;
  if (!hubUrl) {
    return null;
  }
  return (
    new HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => bearerToken,
      })
      //.configureLogging(LogLevel.Trace)
      .build()
  );
}
