import { authenticationMutations } from '@/modules/Authentication/store/mutations';
import { authenticationGetters } from '@/modules/Authentication/store/getters';
import { authenticationActions } from '@/modules/Authentication/store/actions';

export const authenticationState = {
  accessTokenFromLegacy: null,
  contactId: 0,
};

const authenticationStore = {
  namespaced: true,
  state: authenticationState,
  mutations: authenticationMutations,
  getters: authenticationGetters,
  actions: authenticationActions,
};

export default authenticationStore;
