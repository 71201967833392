export const adminBiometricsMutations = {
  SET_BIOMETRICS_RETURN_URL(state, returnUrl) {
    state.returnUrl = returnUrl;
  },
  SET_USER_BIOMETRICS_DETAILS(state, userDetails) {
    state.userBiometricsDetails = userDetails;
  },
  SET_USER_BIOMETRICS_SCREENINGS(state, screenings) {
    state.userScreenings = screenings;
  },
  SET_ADD_EDIT_BIOMETRICS_SCREENING_FORM_DIRTY(state, isDirty) {
    state.addEditBiometricsScreeningForm.isDirty = isDirty;
  },
  SET_SELECTED_BIOMETRICS_SCREENING(state, selectedBiometricsScreening) {
    state.selectedBiometricsScreening = selectedBiometricsScreening;
  },
};
