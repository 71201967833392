<template>
  <div class="container--error">
    <div class="icon mb-4" />
    <p class="verbiage">{{ verbiage }}</p>
    <p class="link">Click <router-link v-bind:to="{ name: 'Home' }">here</router-link> to go back to the home page.</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorResponsePage',
  props: {
    verbiage: {
      type: String,
      default: null,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.container--error {
  @include vertical-center-parent;
  height: 100vh;
  width: 100vw;
  flex-direction: column;

  .icon {
    background-image: url('~@/assets/img/medikeeper-icon.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    min-height: 12rem;
    min-width: 12rem;
  }
}
</style>
