import { adminMessageCenterGetters } from '@/modules/AdminMessageCenter/store/getters';
import { adminMessageCenterActions } from '@/modules/AdminMessageCenter/store/actions';
import { adminMessageCenterMutations } from '@/modules/AdminMessageCenter/store/mutations';
import {
  adminMessageListFilter,
  McMessageSendType,
  McMessageSentStatus,
  McScheduleType,
  StopSendingAfterType,
  McRecurringPatternFrequencyType,
  McDailyRecurringIntervalType,
  McMonthlyRecurringIntervalType,
  McYearlyRecurringIntervalType,
  McWeekOfMonth,
  DayOfWeek,
  RecipientCriteriaDropdown,
} from '@/modules/AdminMessageCenter/utils/constants';

export const messageState = {
  searchResults: [],
  searchResultsIsLoading: false,
  searchParams: {
    page: 1,
    df: null,
    dt: null,
    tab: 'AllMessages',
    receivedMessageStatus: adminMessageListFilter[0].id,
  },
  messageThreads: {},
  messageThread: {},
  memberId: null,
  threadReply: '',
  selectedCriteria: [],
  selectedCriteriaOptions: [],
  messageData: {
    isDraft: true,
    messageId: 0,
    body: '',
    createdBy: '',
    createdByText: '',
    sendType: McMessageSendType.SecureMessage,
    subject: '',
    status: McMessageSentStatus.Draft,
    hippaWarning: false,
    notificationLink: null,
    membersCanReply: false,
    isScheduledToSendWithinAnHour: false,
    filterOptions: {
      filterType: RecipientCriteriaDropdown.SelectAll,
      filterCriteria: [],
      triggerIds: [],
      stopSendingTriggeredMessagesOn: null,
    },
    groupIds: [],
    schedule: {
      scheduleType: McScheduleType.SpecificDates,
      specificDateOccurrences: [],
      recurringPattern: {
        startDate: {
          dateTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString(),
          timeZoneId: 'Pacific Standard Time',
          dateTimeOffset: null,
        },
        stopSendingAfterType: StopSendingAfterType.EndDate,
        stopSendingDate: {
          dateTime: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
          timeZoneId: 'Pacific Standard Time',
          dateTimeOffset: null,
        },
        stopSendingNumberOfOccurrences: 1,
        frequencyType: McRecurringPatternFrequencyType.Daily,
        dailyRecurringProperties: {
          intervalType: McDailyRecurringIntervalType.EveryNDays,
          intervalDays: 1,
        },
        weeklyRecurringProperties: {
          intervalWeeks: 1,
          daysOfWeek: [],
        },
        monthlyRecurringProperties: {
          intervalType: McMonthlyRecurringIntervalType.DayOfMonth,
          intervalMonths: 1,
          dayOfMonth: 1,
          week: McWeekOfMonth.Third,
          dayOfWeek: DayOfWeek.Thursday,
        },
        yearlyRecurringProperties: {
          intervalType: McYearlyRecurringIntervalType.DayOfMonth,
          intervalYears: 1,
          month: 6,
          dayOfMonth: 1,
          week: McWeekOfMonth.Third,
          dayOfWeek: DayOfWeek.Thursday,
        },
      },
    },
    attachments: [],
  },
};

const adminMessageCenterStore = {
  namespaced: true,
  state: messageState,
  getters: adminMessageCenterGetters,
  actions: adminMessageCenterActions,
  mutations: adminMessageCenterMutations,
};

export default adminMessageCenterStore;
