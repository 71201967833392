<template>
  <v-list-item v-bind:dense="true" v-on:click="handleClick()">
    <v-list-item-icon class="mr-2 d-flex align-center">
      <span class="iconify notification-type-icon" v-bind:data-icon="notificationTypeIcon"></span>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="text-wrap">{{ notification.message }}</v-list-item-title>
    </v-list-item-content></v-list-item
  >
</template>

<script>
import { notificationTypeIdSplash, defaultNotificationTypeId } from '@/modules/Common/utils/constants';
import SplashNotification from './SplashNotification.vue';
import { modalTypeComponent } from '@/modules/Common/utils/constants';
import { notificationTypeIcons } from '@/modules/Common/utils';
import { mapActions } from 'vuex';
export default {
  name: 'Notification',
  props: {
    notification: {
      isGroupNotification: null,
      message: null,
      notificationId: null,
      icon: null,
      url: null,
      typeId: null,
      splashHeader: null,
      splashBody: null,
    },
  },
  methods: {
    ...mapActions({
      markNotificationAsRead: 'common/markNotificationAsRead',
    }),
    handleClick: async function() {
      const notification = this.notification;

      await this.markNotificationAsRead({
        notificationId: this.notification.notificationId,
        isGroupNotification: this.notification.isGroupNotification,
      });

      if (notification.typeId === notificationTypeIdSplash) {
        this.$root.$sidebar.closeSidebar();

        await this.$root.$sidebar.openSidebar({
          type: modalTypeComponent,
          content: SplashNotification,
          right: true,
          sidebarComponentProps: { header: notification.splashHeader, body: notification.splashBody },
        });
      } else {
        window.location.href = notification.url;
      }
    },
  },
  computed: {
    notificationTypeIcon: function() {
      if (!this.notification.typeId) {
        return notificationTypeIcons.get(defaultNotificationTypeId).iconify;
      }

      return notificationTypeIcons.get(this.notification.typeId).iconify;
    },
    url: function() {
      return this.notification.typeId !== notificationTypeIdSplash ? this.notification.url : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  border-bottom: 1px dotted $mkDivider;

  .v-list-item__icon {
    align-self: center;

    .notification-type-icon {
      font-size: 1.125rem;
    }
  }
}
</style>
