import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import commonRoutes from '@/router/commonRoutes';
import socialCommunitiesRoutes from '@/modules/SocialCommunities/router';
import triggersAdminRoutes from '@/modules/TriggersAdmin/router';
import adminBiometricsRoutes from '@/modules/AdminBiometrics/router';
import adminMenuManagementRoutes from '@/modules/AdminMenuManagement/router';
import { PageNotFound, FeatureSettings } from '@/router/commonRoutesConstants';
import { refreshLegacyTokens } from '@/utils/legacyInterop';
import { isValidRouteParamId } from '@/utils/helperFunctions';
import { snackbarTypeError } from '@/modules/Common/utils/constants';
import { defaultErrorMsg } from '@/utils/constants';
import adminTemplateRoutes from '../modules/AdminTemplates/router';
import tangoStoreRoutes from '@/modules/TangoStore/router';
import knowledgeHubRoutes from '@/modules/KnowledgeHub/router';
import analyticsRoutes from '@/modules/Analytics/router';
import adminMessageCenterRoutes from '../modules/AdminMessageCenter/router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...commonRoutes,
    ...socialCommunitiesRoutes,
    ...triggersAdminRoutes,
    ...adminTemplateRoutes,
    ...adminBiometricsRoutes,
    ...adminMenuManagementRoutes,
    ...tangoStoreRoutes,
    ...knowledgeHubRoutes,
    ...analyticsRoutes,
    ...adminMessageCenterRoutes,
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

async function checkIfUserIsAdmin() {
  store.dispatch('common/isLoading', { isLoadingTranslucent: false }, { root: true });
  await store.dispatch('users/getCurrentUserInfo');
  return store.getters['users/isUserAdmin'];
}

async function redirectIfNotLoggedIn(checkForAdmin) {
  if (store.getters['authentication/isAuthenticated']) {
    // this will always be false on first visit, as route guard checks before any component lifecycles
  } else {
    await refreshLegacyTokens();
    if (!store.getters['authentication/accessTokenFromLegacy']) {
      window.location.href = process.env.VUE_APP_MKLEGACYURL;
    }
  }
  if (checkForAdmin) {
    let userIsAdmin = await checkIfUserIsAdmin();
    if (!userIsAdmin) {
      window.location.href = process.env.VUE_APP_MKLEGACYURL;
    }
  }
}

router.beforeEach((to, from, next) => {
  store.dispatch('common/isLoading', { isLoadingTranslucent: false }, { root: true });
  if (!to.matched.length || (store.getters['common/isProduction'] && to.fullPath === FeatureSettings.path)) {
    next({ name: PageNotFound.name });
  }
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    redirectIfNotLoggedIn(true);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    redirectIfNotLoggedIn(false);
  }

  const { meta, params } = to;
  if (params.triggerId || params.communityId) {
    const id = params.triggerId ?? params.communityId;

    if (!isValidRouteParamId(id)) {
      store.dispatch(
        'common/setNotification',
        {
          msg: defaultErrorMsg,
          type: snackbarTypeError,
        },
        { root: true },
      );
      next({ path: meta.redirectIfErrorPath });
    }
  }

  // Close lingering notifications on page nav
  if (store.getters['common/notificationIsClosable']) {
    store.dispatch('common/closeNotification');
  }
  store.dispatch('common/decrementCallsInFlight', null, { root: true });
  next();
});

export default router;
