import {
  AnalyticsLanding,
  AnalyticsSubView,
  AnalyticsCustomView,
} from '@/modules/Analytics/router/analyticsRoutesConstants';
const analyticsRoutes = [
  {
    path: AnalyticsLanding.path,
    name: AnalyticsLanding.name,
    component: () => import('@/modules/Analytics/components/Main'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: AnalyticsCustomView.path,
    name: AnalyticsCustomView.name,
    component: () => import('@/modules/Analytics/components/Main'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: AnalyticsSubView.path,
    name: AnalyticsSubView.name,
    component: () => import('@/modules/Analytics/components/Main'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];

export default analyticsRoutes;
