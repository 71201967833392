import mkNetworkRequestWrapperLegacy from '@/utils/requestWrapperLegacy';
import { adminBiometricsBaseUrl } from '@/utils/constants';

export const adminBiometricsActions = {
  setBiometricsReturnUrl({ commit }, payload) {
    commit('SET_BIOMETRICS_RETURN_URL', payload);
  },
  setAddEditBiometricsScreeningFormIsDirty({ commit }, payload) {
    commit('SET_ADD_EDIT_BIOMETRICS_SCREENING_FORM_DIRTY', payload);
  },
  setSelectedBiometricsScreening({ commit }, { selectedBiometricsScreening }) {
    commit('SET_SELECTED_BIOMETRICS_SCREENING', selectedBiometricsScreening);
  },
  async getUserBiometricsDetails({ commit, dispatch, rootGetters }, payload) {
    try {
      const originUri = rootGetters['common/originUriLegacy'];

      const options = {
        url: `${originUri}/${adminBiometricsBaseUrl}?action=user-details&contactId=${payload}`,
        method: 'GET',
      };

      const response = await mkNetworkRequestWrapperLegacy(options);

      commit('SET_USER_BIOMETRICS_DETAILS', { ...response.data, contactId: payload });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getUserBiometricsScreening({ commit, dispatch, getters, rootGetters }, { screeningDate }) {
    try {
      const originUri = rootGetters['common/originUriLegacy'];

      const contactId = getters.userBiometricsDetails.contactId;

      const options = {
        url: `${originUri}/${adminBiometricsBaseUrl}?action=screening&contactId=${contactId}&recordDate=${screeningDate}`,
        method: 'GET',
      };

      const response = await mkNetworkRequestWrapperLegacy(options);

      const combinedDataPoints = getters.userBiometricsDetails.dataPoints.map((dp) => {
        const matchingDataPoint = response.data.find((e) => e.elementId === dp.elementId);

        return {
          elementId: dp.elementId,
          value: matchingDataPoint ? matchingDataPoint.value : '',
        };
      });

      commit('SET_SELECTED_BIOMETRICS_SCREENING', {
        dataPointValues: combinedDataPoints,
        recordDate: screeningDate,
      });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getUserBiometricsScreenings({ commit, dispatch, rootGetters }, payload) {
    try {
      const originUri = rootGetters['common/originUriLegacy'];

      const options = {
        url: `${originUri}/${adminBiometricsBaseUrl}?action=screenings&contactId=${payload}`,
        method: 'GET',
      };

      const response = await mkNetworkRequestWrapperLegacy(options);
      const formattedResponse = response.data;
      formattedResponse.map((record) => {
        record.formattedDate = new Date(record.screeningDate).toLocaleDateString();
        record.entryType = record.entryType === 0 ? 'File Upload' : 'Manual Entry';
      });
      commit('SET_USER_BIOMETRICS_SCREENINGS', formattedResponse);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async saveBiometricAssessment({ commit, dispatch, rootGetters }, payload) {
    try {
      const token = rootGetters['common/csrfToken'];
      const originUri = rootGetters['common/originUriLegacy'];

      const options = {
        url: `${originUri}/${adminBiometricsBaseUrl}?action=screening`,
        method: 'POST',
        data: payload,
        headers: { 'X-CSRFToken': token },
      };

      return await mkNetworkRequestWrapperLegacy(options);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
