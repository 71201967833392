import Vue from 'vue';
import Vuex from 'vuex';
import adminTemplatesStore from '@/modules/AdminTemplates/store';
import adminBiometricsStore from '@/modules/AdminBiometrics/store';
import authenticationStore from '@/modules/Authentication/store';
import commonStore from '@/modules/Common/store';
import usersStore from '@/modules/Users/store';
import groupsStore from '@/modules/Groups/store';
import socialCommunitiesStore from '@/modules/SocialCommunities/store';
import triggersAdminStore from '@/modules/TriggersAdmin/store';
import platformOptionsStore from '@/modules/PlatformOptions/store';
import adminMenuManagementStore from '@/modules/AdminMenuManagement/store';
import tangoStoreStore from '@/modules/TangoStore/store';
import analyticsStore from '@/modules/Analytics/store';
import knowledgeHubStore from '@/modules/KnowledgeHub/store';
import adminMessageCenterStore from '@/modules/AdminMessageCenter/store';
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    adminTemplates: adminTemplatesStore,
    adminBiometrics: adminBiometricsStore,
    analytics: analyticsStore,
    authentication: authenticationStore,
    common: commonStore,
    users: usersStore,
    groups: groupsStore,
    socialCommunities: socialCommunitiesStore,
    triggersAdmin: triggersAdminStore,
    platformOptions: platformOptionsStore,
    adminMenuManagement: adminMenuManagementStore,
    tangoStore: tangoStoreStore,
    knowledgeHub: knowledgeHubStore,
    adminMessageCenter: adminMessageCenterStore,
  },
});

export default store;
